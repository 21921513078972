import React, { Fragment, useState } from "react";
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, CustomInput } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../../components_cfdi/filters";
import exportE from "../../imgComponents/export.png";
import { Exportgs } from "./Exportgs";
import "./tablecontainer.css";
const TableContainer = ({
  paginado,
  consultas,
  exportar,
  columns,
  data,
  renderRowSubComponent,
  nametable,
  Gmodal,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.id),
        pageIndex: 0,
        pageSize: 10,
      },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect
  );
  const [gs, setgs] = useState([]);
  const handleshet = () => {
    exportData("gs", false);
  };
  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "gs") {
      console.log("entra gs");
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (typeof data[i][j] === "string" && data[i][j].startsWith("$")) {
            const newValue = parseFloat(data[i][j].replace(/[$,]/g, ""));
            data[i][j] = newValue;
          }
          // else if (typeof data[i][j] === "string" && data[i][j].includes(",")) {
          //    const newValue = data[i][j].replace(/,/g, " ");
          //     data[i][j] = newValue;
          // }
        }
      }
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      setgs(csvString);
      console.log(csvString);
    }
    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      // XLSX example

      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    //PDF example
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }

    // Other formats goes here
    return false;
  }
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="containerExportC">
        <div className="expen-6">
          {exportar === true ? (
            <>
              <img
                src={exportE}
                className=" "
                alt=""
                id=""
                title="Exportar"
                onClick={() => {
                  exportData("csv", false);
                  setOpen(false);
                }}
              ></img>
              <Exportgs
                data={data}
                page={page}
                handleshet={handleshet}
                gs={gs}
                nametable={nametable}
              ></Exportgs>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Fragment>
        <div className={data?.length <= 10 ? "" : "tableFixHead"}>
          <Table bordered hover {...getTableProps()}>
            <thead className="fijoHeader">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div {...column.getSortByToggleProps()} title={"Ordenar"}>
                        {column.render("Header")}
                        {generateSortingIndicator(column)}
                      </div>
                      <Filter column={column} />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded && (
                      <tr>
                        <td colSpan={visibleColumns.length}>
                          {renderRowSubComponent(row)}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>

          <div
            className="sk-fading-circle"
            style={{ display: Gmodal === true ? "" : "none" }}
          >
            <div className="sk-circle1 sk-circle"></div>
            <div className="sk-circle2 sk-circle"></div>
            <div className="sk-circle3 sk-circle"></div>
            <div className="sk-circle4 sk-circle"></div>
            <div className="sk-circle5 sk-circle"></div>
            <div className="sk-circle6 sk-circle"></div>
            <div className="sk-circle7 sk-circle"></div>
            <div className="sk-circle8 sk-circle"></div>
            <div className="sk-circle9 sk-circle"></div>
            <div className="sk-circle10 sk-circle"></div>
            <div className="sk-circle11 sk-circle"></div>
            <div className="sk-circle12 sk-circle"></div>
          </div>
        </div>

        <Row style={{ maxWidth: 1300, margin: "0 auto", textAlign: "center" }}>
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={2} style={{ marginTop: 7 }}>
            Pagina{" "}
            <strong>
              {pageIndex + 1} de {pageOptions.length}
            </strong>
          </Col>

          <Col md={4}>
            <CustomInput
              id="select"
              type="select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {data.length < 10
                ? [
                    data.length,
                    Math.round(data.length / 4),
                    Math.round(data.length / 2),
                  ].map((pageSize, index) => (
                    <option key={index} value={pageSize}>
                      {paginado} {pageSize}
                    </option>
                  ))
                : [
                    10,
                    Math.round(data.length / 4),
                    Math.round(data.length / 2),
                    data.length,
                  ].map((pageSize, index) => (
                    <option key={index} value={pageSize}>
                      {paginado} {pageSize}
                    </option>
                  ))}
            </CustomInput>
          </Col>
          <Col md={3}>
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
        <h3 className="footer-table-container">
          {consultas} {page.length} de {data.length}
        </h3>
      </Fragment>
    </>
  );
};

export default TableContainer;
