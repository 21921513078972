//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//COMPONENTES
import TableContainer from "./Table_Container_Tax";
import SET_CONCEPT from "./tools/set_concept";
import SubTable from "./Get_Tax_Concept";
import Put_concept from "./Put_Concept";
import Set_Tax_Concept from "./Set_Tax_Concept";
import  {get_concept_config} from "../../../services/invoices/invoice";
//ESTILOS
import "../../estilo.css";
import "./tableclientes.css";
import "../../modal.css";
//IMAGENES/ICONOS
import SAVE from "../../../imgComponents/save.png";
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import CREATE from "../../../imgComponents/create.png";
import ADD from "../../../imgComponents/add.png";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CONSUL_CONCEPT = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  const handleSubmit = async() => {
    setOpen(true);
    setMenssage("Consultando conceptos...");
    try {
      const dt= await get_concept_config();
      console.log(dt);
      setData(dt.data);
      setOpen(false);
      setMenssage("");
    } catch (err) {
      console.log("error", err);
      setData([]);
      setMenssage("");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);


  const handle_reconsult = (e) => {
    if(e===true){
      handleSubmit();
    }
  }


  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              alt=""
              title="Crear Concepto"
              src={CREATE}
              className="cancel"
              onClick={async () => {
                const result = await CustomDialog(<SET_CONCEPT></SET_CONCEPT>, {
                  className: "modalTItle",
                  title: "Seleccione la configuracion del concepto",
                  showCloseIcon: true,
                  isCanClose: false,
                });
                handle_reconsult(result);
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_concept_subtable", row.original.id);
          }

          return (
            <a
              title="Consultar impuestos del concepto"
              {...row.getToggleRowExpandedProps()}
            >
              {row?.isExpanded ? (
                <span title="Consultar impuestos del concepto">🔼</span>
              ) : (
                <span
                  title="Consultar impuestos del concepto"
                  onClick={guardardato}
                >
                  🔽
                </span>
              )}
            </a>
          );
        },
      },

      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() {
            window.location = "/consult_configure_taxes";
          }
          function DeleteTax() {
            setMenssage("Eliminando impuesto...");
            setOpen(true);
            axios
              .delete(
                `https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/concepts/${row.original.id}`,
                {
                  headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "access-token": sessionStorage.getItem(
                      "getAccesTokenRefresh"
                    ),
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                alert("Impuesto eliminado correctamente");
                setOpen(false);
                window.location = "/consult_concept";
                setMenssage("");
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
                setMenssage("");
                console.log();
                alert("Error.," + err.response.data.errorMessage);
              });
          }

          return (
            <div>
              <img
                alt=""
                title="Agregar impuesto al concepto"
                src={ADD}
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_Tax_Concept
                      base={row.original.valor_unitario}
                      id_conceptADD={row.original.id}
                    ></Set_Tax_Concept>,
                    {
                      className: "modalTItle",
                      title: "Asignar impuesto al concepto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="centerText, taxes"
              ></img>

              <img
                alt=""
                title="Editar concepto"
                src={SAVE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <Put_concept
                      Concepton={row.original.concept}
                      Clave_prod_servn={row.original.clave_prod_serv}
                      clave_unidadn={row.original.clave_unidad}
                      Descripcionn={row.original.descripcion}
                      No_identificacionn={row.original.no_identificacion}
                      Objeto_impn={row.original.objeto_imp}
                      Peso_en_kgn={row.original.peso_en_kg}
                      Unidadn={row.original.unidad}
                      Valor_unitarion={row.original.valor_unitario}
                      id_concept={row.original.id}
                    ></Put_concept>,
                    {
                      className: "modalTItle",
                      title: "Editar concepto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="centerText, taxes"
              ></img>

              <img
                alt=""
                title="Eliminar concepto"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Concepto?",
                    "Eliminar Concepto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Descripción",
        accessor: "descripcion",
      },
      {
        Header: "Valor Unitario",
        accessor: "valor_unitario",
      },
      {
        Header: "Clave Producto Servicio",
        accessor: "clave_prod_serv",
      },
      {
        Header: "Clave Unidad",
        accessor: "clave_unidad",
      },
      {
        Header: "Unidad",
        accessor: "unidad",
      },
      {
        Header: "Número de Identificación",
        accessor: "no_identificacion",
      },
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          onClose={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {menssage}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div>
        <h1>CONSULTAR CONCEPTOS</h1>
      </div>
      <br></br>

      <div className="TableConcept">
        <div className="">
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CONSUL_CONCEPT;
