//PAQUETERIAS
import React, { useState, useMemo } from "react";
import {useDialog, Confirm } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";
//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import {SelectColumnFilter} from "../../filters";
import { get_invoices } from "../../../services/invoices/invoice";
import { cancel_invoice } from "../../../services/invoices/invoice";
//ESTILOS CSS
import "../../modal.css";

//IMAGENES
import Motivo from "../../../imgComponents/motivo.png";
import Spinner from "../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";

const JOIN_INVOICE_CANCEL = ({ reason_cancel, open,id_cancel}) => {
  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [mj, setmj] = useState("");  
  const [isLoding, setIsLoding] = useState(false);
  const [viewR, setviewR] = useState(false);
  const [uuidRelation, setuuidRelation] = useState("");
  const dialog = useDialog();

  const handleChage = () => {
    setviewR(false);
  };

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsLoding(true);
    setData([]);
    setmj("Buscando...")
    try {
      var dt = await get_invoices(fi, ff, sessionStorage.getItem("rfcBusqueda"), null, null, false);
      setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
      setIsLoding(false); 
    } catch (err) {
      setData([]);
      setIsLoding(false);
    }   
  };



  const handlehancelR = async() => {
    setmj("Cancelando factura...")

    setIsLoding(true);
    var data = {
      uuid: sessionStorage.getItem("rowUuid"),
      motivo: reason_cancel,
      cancellation_request_id: sessionStorage.getItem("faCancel"),
      relacionado: uuidRelation,
    };
    try {
      await cancel_invoice(id_cancel,data);
      alert("Exito se cancelo la factura")
      setIsLoding(false);
      dialog.close(true);
    } catch (err) {
      catchErrorModul("Cancelar Factura",JSON.stringify(data),JSON.stringify( err?.response));
      alert(JSON.stringify(err.response.data));
      dialog.close(true);
      setIsLoding(false);
    }
   
  };


  const columns = useMemo(
    () => [
      {
        Header: "Relacionar",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function setRelacion() {
            sessionStorage.setItem("aCancelar", row.original.uuid_sat);
            setuuidRelation(row.original.uuid_sat);
            setviewR(true);
          }
          return (
            <div className="iconCenter">
              <img
                alt=""
                className=" cancel"
                src={Motivo}
                onClick={async () => {
                  const result = await Confirm(
                    "Factura relacionada: " + row.original.uuid_sat,
                    "ESTA USTED SEGURO DE RELACIONAR ESTA FACTURA, DE NO SER ASI DE AL BOTON DE CANCELAR PARA ESCOJER OTRA"
                  );
                  if (result) {
                    setRelacion(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        Filter: SelectColumnFilter,
        filter: "equals",
        id: "seriee",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Folio",
        accessor: "folio",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Uuid",
        accessor: "uuid_sat",
      }, 
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
    ],
    []
  );

  return (
    <div className="">
      <p
        className="contentMCancel"
        title="Seleeccionar otra factura"
        onClick={handleChage}
      >
        Factura relacionada:{uuidRelation}
      </p>

      <div style={{ display: viewR === true ? "none" : "" }}>
        <div className="containerCosultCancel">
          <div className="bp-bus1">
            <input
              type="text"
              className="fi"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha inicial"
              onChange={(event) => setfi(event.target.value)}
            ></input>
          </div>
          <div className="bp-bus2">
            <input
              type="text"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha Final"
              onChange={(event) => setff(event.target.value)}
            ></input>
          </div>
          <div className="bp-bus">
            <button className="button" onClick={handleSubmit} type="button">
              Buscar
            </button>
          </div>
        </div>

        {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={mj}
          />
        ) : (
          <div>
            <TableContainer
              id="uno"
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              consultas={"Facturas"}
              exportar={true} 
            />
          </div>
        )}

        
      </div>

      <br></br>
      <br></br>

      <div
          className="contanerBtnCancelR"
          style={{ display: uuidRelation!=="" ? "" : "none" }}
        >
          <div className="bp-4">
            <Button
             onClick={async() =>{
              const result = await Confirm('¿Esta usted seguro de cancelar esta factura?','Cancelar Factura','Si','No');
              if(result){
                handlehancelR();
                }
              }            
            }
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              CANCELAR FACTURA
            </Button>
          </div>
          <div className="bp-5">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              SALIR
            </Button>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
      

    </div>
  );
};

export default JOIN_INVOICE_CANCEL;
