//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import Set_Policy from "./Set_Policy";
import SubTable from "./Get_Datail_Policy";
import Put_expense_extranjero from "../expense_extranjero/Put_Expense";
import Put_policy from "./Put_policy";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../expense.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import ADD from "../../imgComponents/add.png";
import PDF from "../../imgComponents/pdf.png";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableConsultPolicy = () => {

  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes=  añoantes-1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  
  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${añoantes}-${mesantes}-${dia}`;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");
  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");

  const handleSubmit = (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando pólizas...");
    console.log(fi);
    console.log(ff);

    axios
      .get(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/policys`, 
      {
        params: {
          di:fi,
          df:ff,       
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
       
      })
      .then((response) => {
        const data = response.data;
        const data2 = data.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));
        console.log(data2);
        setData(data2);
        setOpen(false);
        setMenssage("")
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setOpen(false);

        setMenssage("")

      });
  };

  
  const handleReconsulta = (reconsultar)=>{

    if(reconsultar===true){
      handleSubmit();
    }
    else{
      console.log("no se reconsulto");
    }

  }
  useEffect(() => {
    handleSubmit();
  }, []);



  const columns = useMemo(
    
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {

          return(
          <img
                title="Crear Póliza"
                src={CREATE}
                onClick={async () => {
                  
                  const result = await CustomDialog(
                    
                      <Set_Policy ></Set_Policy>,
                    {
                      className: "modalTItle",
                      title:
                      "Crear Póliza",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                  {handleReconsulta(result)}
                
              }}
              ></img>
            )
        },
        Cell: ({ row }) => {

          function guardardato(){
            sessionStorage.setItem("id_policy_subtable",row.original.id);
          }

          function Denegado(){
            alert("No tienes el rol necesario para ver los detalles del gasto");
          }

          if(sessionStorage.getItem("PERMISOVERDETALLESGASTO")==="ROLE_EXPENSE_ADMINISTRATIVOS"){

          return(
          <a title="Consultar impuestos del concepto" {...row.getToggleRowExpandedProps()} >
            {row?.isExpanded ? <span title="Consultar detalles de la póliza" >🔼</span>: <span title="Consultar detalles de la póliza" onClick={guardardato}>🔽</span>}
          </a>
          )
          }else{
            return(
            <span title="Consultar detalles del gasto" onClick={Denegado}>🔽</span>
            );
          }
          },
      },
      
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
        

          
          function DeleteTax(result) {
            setMenssage("Eliminando Póliza...")
            setOpen(true);
            axios
              .delete(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/policys/${row.original.id}`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                alert("Póliza eliminada correctamente")
                setOpen(false);
                setMenssage("");
                setTimeout(handleReconsulta(result), 3000);
                
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
                setMenssage("")
                console.log()
                alert("Error.,"+ err.response.data.errorMessage); 
              });


          }

          return (
            <div>
              <img
                title="Inyectar Póliza"
                src={ADD}
                onClick={async () => {
                    const result = await CustomDialog(
                      <Put_policy 
                        codCocept={row.original.concept}
                        codType={row.original.type}
                        codId={row.original.id}
                        codAction={"INJECTION"}
                        codFecha={row.original.export_date}
                        ></Put_policy>,
                    {
                      className: "modalTItle",
                      title:
                        "Editar Póliza",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                  {handleReconsulta(result)}
                 
                  
                  
                
              }}
                className="centerText, taxes"
              ></img>

              <img
                title="Editar Póliza"
                src={SAVE}
                onClick={async () => {
                  if(row.original.modificable === true){
                    const result = await CustomDialog(
                      <Put_policy 
                        codCocept={row.original.concept}
                        codType={row.original.type}
                        codId={row.original.id}
                        codAction={"UPDATE"}
                        codFecha={row.original.export_date}
                        ></Put_policy>,
                    {
                      className: "modalTItle",
                      title:
                        "Editar Póliza",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                  {handleReconsulta(result)}
                  }else{
                    alert("Esta póliza ya no se puede modificar.")
                  }
                  
                  
                
              }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Póliza"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta póliza?",
                    "Eliminar Póliza",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },
      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Fecha",
        accessor: "export_date",
      },
      {
        Header: "Estado",
        accessor: "status",
      },
      {
        Header: "Orden de descarga",
        accessor: "selection_order",
      },
      {
        Header: "Modificable",
        accessor: (d) => {
          return d.modificable === true ? "✅" : "❌";
        },
      }
	
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style2} >
              <div className="centermodaldescarga">
                <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                  <img src={Spinner}></img>
                </Typography>
                <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                  {menssage}
                </Typography>
              </div>
            </Box>
        </Modal>
        </div>

      <div><h1>CONSULTAR POLIZAS</h1></div>
      <br></br>
      

      <div class="containerExpense">
            <div class="expen-1 color-1">
            <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
            </div>

            <div class="expen-2 color-2">
            <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
            </div>


            <div class="expen-3 color-5">

            <Button  
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit} >
                  Buscar
                </Button>
              
            </div>
          </div>




     

          <div className="TableReport PolicyTable">
        <div style={{overflowX:"auto"}}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable verData={true}/>
               </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableConsultPolicy;
