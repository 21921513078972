import TABLE_EXPENSE_EXTRANJERO from "../../components_Expenses/expense_extranjero/Consult_Expenses_extranjero";
import { Component } from "react";

export class SetConsulExpensesExtranjero extends Component {
  render() {
    return (
      <div className="tc">
        <TABLE_EXPENSE_EXTRANJERO></TABLE_EXPENSE_EXTRANJERO>
      </div>
    );
  }
}

export default SetConsulExpensesExtranjero;
