import React, { useState, useRef,useEffect } from "react";
import {
  Container,
  Card,
  CardContent,
  makeStyles,
  Grid,
  TextField,
} from "@material-ui/core";
import QrReader from "react-qr-reader";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import axios from "axios";
import Set_bussines_qr from "./set_bussines_qr";
import Modal_Table from "../main_components/modal/modal_Table";

// console.log(socioNegocioNuevo);
import { useDialog } from "react-st-modal";

function Update_bussines_p() {
  const dialog = useDialog();

  // const [text, setText] = useState('');
  // const [imageUrl, setImageUrl] = useState('');
  const [scanResultFile, setScanResultFile] = useState("Sin resultados");
  const [retornar, setRetornar] = useState("");
  const retornar2 = setRetornar;
  const [scanResultWebCam, setScanResultWebCam] = useState("Sin resultados");
  const classes = useStyles();
  const qrRef = useRef(null);
  const [isLoding, setIsLoding] = useState(false);
  const [registergeneral, setRegisterGeneral] = useState(false);
  const [registerBussnes, setregisterBussnes] = useState(false);
  const [modalT, setmodalT] = useState(false);

  
  const [business_partnerUp, setbusiness_partnerUp] = useState('');
  const [rfcUp, setrfcUp] = useState('');
  const [nameUp, setnameUp] = useState('');
  const [first_last_nameUp, setfirst_last_nameUp] = useState('');
  const [second_last_nameUp, setsecond_last_nameUp] = useState('');
  const [tax_regimeUp, settax_regimeUp] = useState('');
  const [capital_regimeUp, setcapital_regimeUp] = useState('');
  const [emailUp, setemailUp] = useState('');
  const [streetUp, setstreetUp] = useState('');
  const [outdoor_numberUp, setoutdoor_numberUp] = useState('');
  const [inside_numberUp, setinside_numberUp] = useState('');
  const [neighborhoodUp, setneighborhoodUp] = useState('');
  const [zip_codeUp, setzip_codeUp] = useState('');
  const [cityUp, setcityUp] = useState('');
  const [stateUp, setstateUp] = useState('');

  useEffect(() => {
    setbusiness_partnerUp(business_partnerUp);
    setrfcUp(rfcUp);
    setnameUp(nameUp);
    setfirst_last_nameUp(first_last_nameUp);
    setsecond_last_nameUp(second_last_nameUp);
    settax_regimeUp(tax_regimeUp);
    setcapital_regimeUp(capital_regimeUp);
    setemailUp(emailUp);
    setstreetUp(streetUp);
    setoutdoor_numberUp(outdoor_numberUp);
    setinside_numberUp(inside_numberUp);
    setneighborhoodUp(neighborhoodUp);
    setzip_codeUp(zip_codeUp);
    setcityUp(cityUp);
    setstateUp(stateUp);

  }, []);
  

  // const generateQrCode = async () => {
  //   try {
  //         const response = await QRCode.toDataURL(text);
  //         setImageUrl(response);
  //   }catch (error) {
  //     console.log(error);
  //   }
  // }
  const handleErrorFile = (error) => {
    // console.log(error);
  };
  const handleScanFile = (result) => {
    if (result) {
     

      var data = JSON.stringify({
        url: result,
      });
      setmodalT(true);
      var config = {
        method: "put",
        url: 'https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners',
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setScanResultFile(JSON.stringify(response.data));
          setmodalT(false);
          alert("Código escaneado");
        })
        .catch(function (error) {

    
          sessionStorage.setItem(
            "business_partner",
            error?.response?.data?.business_partner?.business_partner
          );
          sessionStorage.setItem(
            "capital_regime",
            error?.response?.data?.business_partner?.capital_regime
          );
          sessionStorage.setItem(
            "email",
            error?.response?.data?.business_partner?.email
          );
          sessionStorage.setItem(
            "first_last_name",
            error?.response?.data?.business_partner?.first_last_name
          );
          sessionStorage.setItem(
            "name",
            error?.response?.data?.business_partner?.name
          );
          sessionStorage.setItem(
            "rfc",
            error?.response?.data?.business_partner?.rfc
          );
          sessionStorage.setItem(
            "second_last_name",
            error?.response?.data?.business_partner?.second_last_name
          );
          sessionStorage.setItem(
            "tax_regime",
            error?.response?.data?.business_partner?.tax_regime
          );
          sessionStorage.setItem("city", error?.response?.data?.address?.city);
          sessionStorage.setItem(
            "inside_number",
            error?.response?.data?.address?.inside_number
          );
          sessionStorage.setItem(
            "neighborhood",
            error?.response?.data?.address?.neighborhood
          );
          sessionStorage.setItem(
            "outdoor_number",
            error?.response?.data?.address?.outdoor_number
          );
          sessionStorage.setItem(
            "state",
            error?.response?.data?.address?.state
          );
          sessionStorage.setItem(
            "street",
            error?.response?.data?.address?.street
          );
          sessionStorage.setItem(
            "zip_code",
            error?.response?.data?.address?.zip_code
          );
          try {
            setScanResultFile("No se pudo encontar el socio de negocios: ");
            setmodalT(false);
          } catch (error) {
            alert("Escanear denuevo");
          }
        });
    }
  };
  const onScanFile = () => {
    qrRef.current.openImageDialog();
  };
  const handleErrorWebCam = (error) => {
    console.log(error);
  };
  const handleScanWebCam = (result) => {
    if (result) {
      var url = { url: result };
  
      setmodalT(true);

      var data = JSON.stringify({
        url: result,
      });

      var config = {
        method: "put",
        url: 'https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners',
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setScanResultWebCam(JSON.stringify(response.data));
          setmodalT(false);
        })
        .catch(function (error) {
          
          sessionStorage.setItem(
            "business_partner",
            error?.response?.data?.business_partner?.business_partner
          );
          sessionStorage.setItem(
            "capital_regime",
            error?.response?.data?.business_partner?.capital_regime
          );
          sessionStorage.setItem(
            "email",
            error?.response?.data?.business_partner?.email
          );
          sessionStorage.setItem(
            "first_last_name",
            error?.response?.data?.business_partner?.first_last_name
          );
          sessionStorage.setItem(
            "name",
            error?.response?.data?.business_partner?.name
          );
          sessionStorage.setItem(
            "rfc",
            error?.response?.data?.business_partner?.rfc
          );
          sessionStorage.setItem(
            "second_last_name",
            error?.response?.data?.business_partner?.second_last_name
          );
          sessionStorage.setItem(
            "tax_regime",
            error?.response?.data?.business_partner?.tax_regime
          );
          sessionStorage.setItem("city", error?.response?.data?.address?.city);
          sessionStorage.setItem(
            "inside_number",
            error?.response?.data?.address?.inside_number
          );
          sessionStorage.setItem(
            "neighborhood",
            error?.response?.data?.address?.neighborhood
          );
          sessionStorage.setItem(
            "outdoor_number",
            error?.response?.data?.address?.outdoor_number
          );
          sessionStorage.setItem(
            "state",
            error?.response?.data?.address?.state
          );
          sessionStorage.setItem(
            "street",
            error?.response?.data?.address?.street
          );
          sessionStorage.setItem(
            "zip_code",
            error?.response?.data?.address?.zip_code
          );

          try {
            setScanResultWebCam(error?.response?.data?.message);
            setmodalT(false);
            setRegisterGeneral(error?.response?.data?.business_partner?.business_partner.length>3?true:false);
          } catch (error) {
            alert("Escanear denuevo");
          }
        });
    }
  };

  

  const handleRedirect =()=>{
    setregisterBussnes(true);
  }
  return (
    <div>
     <Modal_Table
        open={true}
        message={""}
        modalT={modalT}
      ></Modal_Table>
        <div style={{display:registerBussnes===false?"":"none"}}>
          <Container className={classes.conatiner}>
            <Card>
              <CardContent>
                <Grid container justifyContent="space-around" spacing={1}>
                  {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <TextField label="Enter Text Here" onChange={(e) => setText(e.target.value)}/>
                          <Button className={classes.btn} variant="contained" 
                            color="primary" onClick={() => generateQrCode()}>Generar QR</Button>
                            <br/>
                            <br/>
                            <br/>
                            {imageUrl ? (
                              <a href={imageUrl} download>
                                  <img src={imageUrl} alt="img"/>
                              </a>) : null}
                      </Grid> */}
                  {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                        <Button className={classes.btn} variant="contained" onClick={onScanFile}>Selecciona el QR a Escanear</Button>
                        <QrReader
                          ref={qrRef}
                          delay={300}
                          style={{width: '100%'}}
                          onError={handleErrorFile}
                          onScan={handleScanFile}
                          legacyMode
                        />
                        <h3>Código escaneado: {scanResultFile}</h3>
                      </Grid> */}
                  <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <QrReader
                      delay={1000}
                      style={{ width: "100%" }}
                      onError={handleErrorWebCam}
                      onScan={handleScanWebCam}
                    />
                    <h3 className="ResponseCodeQR">Código escaneado: {scanResultWebCam}</h3>
                  </Grid>
                </Grid>
              </CardContent>
              <div className="importanMax">
                
              <Box style={{display:registergeneral===true?"":"none"}}>
                <Button
                  onClick={handleRedirect}
                  sx={{ m: 2, width: "87%" }}
                  variant="contained"
                  style={{ background: "#5e5cd7" }}
                >
                  REGISTRAR NUEVO SOCIO
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    dialog.close(true);
                  }}
                  sx={{ m: 2, width: "40%" }}
                  variant="contained"
                  style={{ background: "green" }}
                >
                  Actualizar Tabla
                </Button>

                <Button
                  onClick={() => {
                    dialog.close();
                  }}
                  sx={{ m: 2, width: "43%" }}
                  variant="contained"
                  style={{ background: "red" }}
                >
                  CANCELAR
                </Button>
              </Box>
              </div>
            </Card>
          </Container>
        </div>

        <div style={{display:registerBussnes===false?"none":""}}>
          <Set_bussines_qr
         
          ></Set_bussines_qr>

        </div>
      
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  conatiner: {
    marginTop: 40,
  },
  title: {
    display: "flex",
    alignItems: "center",
    background: "#3f51b5",
    color: "#fff",
    padding: 20,
    textAlign: "center",
  },
  btn: {
    marginTop: 10,
    marginBottom: 20,
  },
}));
export default Update_bussines_p;
