//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";
import Set_Detail_Expense from "./Set_Detail_Expense";
import Put_Detail_Expense from "./Put_Detail_Expense";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../expense.css";

//IMAGENES
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import SAVE from "../../imgComponents/save.png";
import ADD from "../../imgComponents/add.png";
import reconsultaTable from "../../imgComponents/reconsultaTable.png";

import { faBullseye } from "@fortawesome/free-solid-svg-icons";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SubTable({ recarga }) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);

  const dialog = useDialog();

  const handleSubmit = (event) => {
    event?.preventDefault();
    setReconsulta(true);
    axios
      .get(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${sessionStorage.getItem(
          "id_expense_subtable"
        )}/details`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        setData(data);
        const cargos = data.filter((item) => item.efect === "CARGO");
        const abonos = data.filter((item) => item.efect === "ABONO");

        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);

        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        console.log("==>cargos<==");
        console.log(sumCargos);
        console.log("==>abonos<==");
        console.log(sumAbonos);
        if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
          setEquality("Los datos cuadran");
          console.log("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cuadran");
          console.log("Los datos no cuadran");
          setError_msg("");
          setSuccess_msg("none");
        }

        setReconsulta(false);
      })
      .catch((err) => {
        setData([]);
        setReconsulta(false);
      });
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: () => {
          return (
            <div>
              <img
                title="Agregar detalle"
                src={ADD}
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_Detail_Expense></Set_Detail_Expense>,
                    {
                      className: "modalTItle modalExpenseG",
                      title: "Selecciona la configuracion del detalle",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(result);
                  }
                }}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() {}
          function DeleteTax(result) {
            setOpen(true);
            axios
              .delete(
                `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${sessionStorage.getItem(
                  "id_expense_subtable"
                )}/details/${row.original.id}`,
                {
                  headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                setOpen(false);
                handleReconsulta(result);
              })
              .catch((err) => {
                var error = err?.response?.data?.errorMessage;
                alert("Error.," + error);
                setOpen(false);
              });
          }

          return (
            <div>
              <img
                title="Editar Detalle"
                src={SAVE}
                onClick={async () => {
                  if (row.original.modifiable === true) {

                  const result = await CustomDialog(
                    <Put_Detail_Expense
                      id_detail={row.original.id}
                      codaccounts={row.original.account}
                      codconcept={row.original.concept}
                      codamount={row.original.ammount}
                      codeffect={row.original.efect}
                      codidConcept={row.original.id_account}
                    ></Put_Detail_Expense>,
                    {
                      title: "Editar detalle del gasto",
                      className: "modalTItle modalExpenseG",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(result);
                  }
                }else{
                  alert("Este detalle ya no se puede modificar")
                }
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Detalle"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este detalle del gasto?",
                    "Eliminar Detalle",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Cuenta",
        accessor: "account",
      },
      {
        Header: "Importe",
        accessor: "ammount",
      },
      {
        Header: "Efecto",
        accessor: "efect",
      },

      {
        Header: "Concepto Factura",
        accessor: "invoice_supplier_concept",
      },

      {
        Header: "RFC",
        accessor: "rfc",
      },

      {
        Header: "UUID",
        accessor: "uuid",
      },
      {
        id: "reconsulta",
        Header: () => {
          return (
            <div>
              <br></br>
              Modificable
              <img
                title="Actualizar"
                src={reconsultaTable}
                onClick={handleSubmit}
                className="centerText, taxes"
              ></img>
            </div>
          );
        },
        accessor: (d) => {
          return d.modifiable === true ? "✅" : "❌";
        },
      },
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  className=""
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className=""
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="TableConsultarTodo2">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
          <StickyTable>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
            />
          </StickyTable>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default SubTable;
