//Paqueterias
import  { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {  useDialog } from "react-st-modal";
//Componentes
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { get_warehouse } from "../../../services/inventory/inventory";
//Estilos
import "./movements.css"

const SET_MOVEMENTS = () => {
  const dialog = useDialog();
  const [warehouse, setwarehouse] = useState([]);
  const [origin, setorigin] = useState(-1);
  const [destination, setdestination] = useState(-1);
  const [modalT] = useState(false);

  

  const handleSubmit = (event) => {
    sessionStorage.setItem("origin",origin);
    sessionStorage.setItem("destination",destination);  
    window.location="/werehouse_movements";
  };

  const handle_warehouse = async () => {
    try {
      const dt= await get_warehouse();
      const data=dt.data;
        var dtAdd = {
          id: -1,
          warehouse_name: "ENTRADA",
        };

        data.push(dtAdd);
        console.log(data)
        setwarehouse(data);
    } catch (error) {
      setwarehouse([]);
    }
  };

  useEffect(() => {
    handle_warehouse();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  return (
    <div>
      <div>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      </div>
      <div>
        <form>
          <div className="create_movements">
            <div className="mv-5">
              <TextField
                select
                label="Origen"
                child
                value={origin}
                sx={{ m: 1.5, width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
                onChange={(e)=>setorigin(e.target.value)}

              >
                {warehouse?.map((option) => (
                  <option
                    key={option?.id}
                    value={option?.id}
                    selected={option?.id}
                  >
                    {option?.warehouse_name}
                  </option>
                ))}
              </TextField>
            </div>

            <div className="mv-6">
              <TextField
                select
                child
                label="Destino"
                value={destination}
                sx={{ m: 1.5, width: "100%" }}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  native: true,
                }}
                onChange={(e)=>setdestination(e.target.value)}
              >
                {warehouse?.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    selected={option.id}
                  >
                    {option.warehouse_name}
                  </option>
                ))}
              </TextField>
            </div>

          <div className="mv-7">
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "100%" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              CONSULTAR
            </Button>
            </div>
            <div className="mv-8">

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "100%" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close(false);
              }}
            >
              cancelar
            </Button>
            </div>
            </div>
        </form>
      </div>
    </div>
  );
};

export default SET_MOVEMENTS;
