import TABLE_EXPENSE_REPORT from '../../components_Expenses/expense_report/Consult_Report';
import { Component } from "react";


export class SetConsulReport extends Component {
  
  

  render() {

   
      

      return (    
      
      <div className="tc">
      <TABLE_EXPENSE_REPORT></TABLE_EXPENSE_REPORT>

      </div>
          );
       }     
}

export default SetConsulReport;
