import { useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";

function EditRulesSE(props) {

const dialog = useDialog();

console.log(props);

const [user, setuser]=useState(props.row.original.user_name);
const [bloq, setbloq]=useState(props.row.original.blocked);
const [enable, setenable]=useState(props.row.original.enable);
const [daysBefore, setdaysBefore]=useState(props.row.original.lower_limit_days);
const [weeksBefore, setweeksBefore]=useState(props.row.original.lower_limit_week);
const [monthsBefore, setmonthsBefore]=useState(props.row.original.lower_limit_month);
const [limInf, setlimInf]=useState(props.row.original.lower_limit_ammount);
const [limSup, setlimSup]=useState(props.row.original.upper_limit_ammount);
const [bandera, setbandera]=useState(true);
  
const [textdays, settextdays]=useState(false);
const [textweek, settextweek]=useState(false);
const [textmonth, settextmonth]=useState(false);
console.log(props.row.original.enable);
const URL_BASE = "https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com";

//crear nueva regla para enviar gastos
function updaterule(){

console.log(bloq);
console.log(enable);
console.log("dias "+daysBefore);
console.log("semanas "+weeksBefore);
console.log("meses "+monthsBefore);
console.log("limite inferior "+limInf);
console.log("limite superior"+limSup);

props.reconsultar(true);
setbandera(false);
refresh_token ();
setTimeout(() => {
  var data = {
    "enable": enable, //boolean = Habilitado | Deshabilitado
    "user_name": user,//string = Nombre del usuario que tendrá la regla de negocio
    "lower_limit_days": daysBefore,//Días antes de la fecha
    "lower_limit_week": weeksBefore,  //Semanas antes de la fecha
    "lower_limit_month": monthsBefore, //Meses antes de la fecha
    "blocked": bloq, //boolean = Bloqueado SI | NO
    "upper_limit_ammount": limSup, //float = Monto superior del gasto
    "lower_limit_ammount": limInf//float = Monto inferior del gasto
}
var url = `${URL_BASE}/od/expense-income-rules/${props.row.original.id}`;
var axiosConfig = {
  headers: {
    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    "Content-Type": "application/json",
  },
}

axios
.put(url, data,axiosConfig)
.then((response) => {
  console.log(response);
  props.alertas("Regla de gastos actualizada con éxito", true);
  props.reconsultar(false);
  dialog.close();
  props.handleSubmit();
})
.catch((err) => {
  console.log("error put", err);
  catchErrorModul("Regla de envio de Gastos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));

  props.alertas("No se pudo registrar la regla de gastos", false);
  props.reconsultar(false);
  setbandera(true);
  });
}, 2000);
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

return (
    <div>
      <div className="addrulesSE">
          <div className="gridrule1">
            <div className="rule1">
            <TextField
              label="Usuario"
              value={user}
              sx={{ m: 1.5, width: "100%" }}
              InputLabelProps={{ shrink: true }}
              disabled
            ></TextField>
            </div>
            <div className="rule2">
            <TextField
              select
              label="Habilitado"
              value={enable}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(event.target.value==="true"?true:false)}  
            >
              <option key={1} value={true} selected >
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
            </div>
          </div>
          <div className="gridrule2">
          <div className="rule3">
            <TextField
              select
              label="Bloqueado"
              value={bloq}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setbloq(event.target.value==="true"?true:false)}  
            >
              <option key={1} value={false} selected >
                  {"NO"}
              </option>
              <option key={2} value={true}>
                  {"SI"}
              </option>
            </TextField>
            </div>
            
            <div className="rule4">
            <TextField
              label="Monto Inferior del Gasto"
              value={limInf}
              type={"number"}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setlimInf(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="rule5">
            <TextField
              label="Monto Superior del Gasto"
              type={"number"}
              value={limSup}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setlimSup(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            
          </div>
          <div className="gridrule3">
            
          <div className="rule6">
            <TextField
              label="Días Antes de la Fecha"
              type={"number"}
              value={daysBefore}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) =>{
                const value = event.target.value;
                if (value >= 0 && value <= 15) {
                  setdaysBefore(value);
                }
              }}   
              onSelect={()=> settextdays(true)}
              onBlur={()=>settextdays(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="gendoc" style={textdays===true?{"display":"block"}:{"display":"none"}}>Número de días antes que tiene de generación del documento (0-15)</p>
            </div>
            <div className="rule7">
            <TextField
              label="Semanas Antes de la Fecha"
              type={"number"}
              value={weeksBefore}
              sx={{ m: 1.5, width: "95%" }}
              onChange={(event) =>{
                const value = event.target.value;
                if(value >=0 && value <=8){
                  setweeksBefore(event.target.value)
                }
              }} 
              onSelect={()=> settextweek(true)}
              onBlur={()=>settextweek(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
            <p className="gendoc" style={textweek===true?{"display":"block"}:{"display":"none"}}>Número de semanas antes que tiene de generación del documento (0-8)</p>
            </div>
            <div className="rule8">
            <TextField
              label="Meses Antes de la Fecha"
              type={"number"}
              value={monthsBefore}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) =>{
                const value = event.target.value;
                if(value >=0 && value <=13){
                  setmonthsBefore(event.target.value);
                }
              }}  
              onSelect={()=> settextmonth(true)}
              onBlur={()=>settextmonth(false)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
             <p className="gendoc" style={textmonth===true?{"display":"block"}:{"display":"none"}}>Número de meses antes que tiene de generación del documento (0-13)</p>
            </div>
            
          </div>
         <br></br>
          <br></br>
          <Box>
            {
              bandera===true?              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={updaterule}
          >
            Actualizar Regla
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default EditRulesSE;
