//PAQUETERIAS
import React, { useEffect, useState, useMemo,useRef } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { StickyTable, Row, Cell } from "react-sticky-table";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import SubTable from "./Get_datail";
import Put_expense from "./Put_Expense";
import Set_Grup_Expense from "./Set_Grup_Expense";
import Put_expense_extranjero from "../expense_extranjero/Put_Expense";
import Put_expense_no_deducible from "../expense_no_deducible/Put_Expense";
import Modal_Table from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../expense.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import ADD from "../../imgComponents/add.png";
import PDF from "../../imgComponents/pdf.png";
import Agrupar from '../../imgComponents/agrupar.png';

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TableExpense_Report = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes=  añoantes-1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  
  

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${añoantes}-${mesantes}-${dia}`;
  
  const [sumCantidad, setSumCantidad] = useState(0);

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const notificationSystemRef = useRef();
  const [modalT, setmodalT] = useState(false);
  const [modalGeneral, setmodalGeneral] = useState(true);


  const handleSubmit = (event) => {
    event?.preventDefault();
    if(totalG===0){

    
    setmodalT(true);
    setMenssage("Consultando gastos...");
    console.log(fi);
    console.log(ff);
    axios
      .get(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses`,
        {
          params: {
            di: fi,
            df: ff,
            origin: "",
          },
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        const data2 = data.sort((a, b) =>
          a.id < b.id ? 1 : a.id > b.id ? -1 : 0
        );

        console.log(data2);

        setData(data2);
        setmodalGeneral(false);
        setmodalT(false);
        setMenssage("");
        setDifT([]);
        setSumCantidad(0);
        setTotalG(0);
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setmodalT(false);
        setMenssage("");
        setmodalGeneral(false);

      });
    }else{
      alert("Verifica que no tengas ningun gasto seleccionado");
    }
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input title="Seleccionar gasto" type="checkbox" ref={resolvedRef} {...rest} />
        </>
      );
    }
  );

  const [difT, setDifT] = React.useState([]);
  const [totalG, setTotalG] = React.useState(0);

  const result = difT?.filter(idExpense => idExpense?.id !='elminarEsto');

  console.log(result);

  useEffect(() => {
    const handleExpense = () => {
      setDifT(difT);
      setTotalG(totalG);
      setSumCantidad(sumCantidad);
    };
    handleExpense();
  }, []);



  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
            const resultDif = difT?.filter(idExpense => idExpense!==null);

          return (
            // <Set_Grup_Expense grupExpense={difT} ></Set_Grup_Expense>

            <img
                title="Agrupar Gasto"
                src={Agrupar}
                onClick={async () => {
                      const result = await CustomDialog(
                       <div>
                        <div className="loader"></div>
                        <Set_Grup_Expense grupExpense={resultDif} ></Set_Grup_Expense>
                        
                        </div> ,
                        {
                          className: "modalTItle",
                          title: "Agrupando gastos...",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                      {
                        handleReconsulta(result);
                      }
                }}
                ></img>
          );
        },
        Cell: ({ row }) => {

          function downloadPDF(typedocumentexmlorpdf) {
            setmodalT(true);
            setmodalGeneral(true);

            setMenssage("Descargando pdf...");
            const uri = `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${row.original.id}`;

            axios
              .get(uri, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                const data = response.data;
                var blob = base64toBlob(data.file);
                const blobUrl = URL.createObjectURL(blob);
                const downloadLink = document.createElement("a");
                const fileName = row.original.file_name + ".pdf";
                downloadLink.href = blobUrl;
                let arr = row.original.file_name.split("/");

                downloadLink.download = arr[4] + ".pdf";
                downloadLink.click();
                setTimeout(() => {
                  setmodalT(false);
                  setmodalGeneral(false);

                }, 2000);
              })
              .catch((err) => {
                console.log("error", err);
                setmodalT(false);
                setmodalGeneral(false);

              });
          }

          function base64toBlob(base64Data) {
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize;
              const end = Math.min(begin + sliceSize, bytesLength);

              const bytes = new Array(end - begin);
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: "application/pdf" });
          }

          function DeleteTax(result) {
            setMenssage("Eliminando gasto...");
            setmodalT(true);
            axios
              .delete(
                `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${row.original.id}`,
                {
                  headers: {
                    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((response) => {
                alert("Gasto eliminado correctamente");
                setmodalT(false);
                setMenssage("");
                setTimeout(handleReconsulta(result), 3000);
              })
              .catch((err) => {
                console.log("error", err);
                setmodalT(false);
                setMenssage("");
                console.log();
                alert("Error.," + err.response.data.errorMessage);
              });
          }


          function guardardato() {
            sessionStorage.setItem("id_expense_subtable", row.original.id);
          }

          function Denegado() {
            alert("No tienes el rol necesario para ver los detalles del gasto");
          }

          if (
            sessionStorage.getItem("PERMISOVERDETALLESGASTO") ===
            "ROLE_EXPENSE_ADMINISTRATIVOS"
          ) {
            return (
              <div>
              <a
                title="Consultar impuestos del concepto"
                {...row.getToggleRowExpandedProps()}
              >
                {row?.isExpanded ? (
                  <span title="Consultar detalles del gasto">🔼</span>
                ) : (
                  <span
                    title="Consultar detalles del gasto"
                    onClick={guardardato}
                  >
                    🔽
                  </span>
                )}
              </a>

              <img
                title="Descargar pdf"
                src={PDF}
                onClick={() => downloadPDF("pdf")}
                className=""
              ></img>

              <img
                title="Editar Gasto"
                src={SAVE}
                onClick={async () => {
                  if (row.original.modifiable === true) {
                    
                    if (row.original.origin === "EXTRANJERO") {
                      const result = await CustomDialog(
                        <Put_expense_extranjero 
                          codCocept={row.original.concept}
                          codDescripcion={row.original.description}
                          codDate={row.original.date}
                          codImporte={row.original.ammount}
                          codAccount={row.original.account}
                          codIdPayment={row.original.paiement_account}
                          id_expense={row.original.id}
                          codidConcept={row.original.id_account}
                          ></Put_expense_extranjero>,
                        {
                          className: "modalTItle",
                          title: "Editar Gasto",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      );
                      {
                        handleReconsulta(result);
                      }

                
                    }
                    else if(row.original.origin === "NO DEDUCIBLES") {

                      const result = await CustomDialog(
                        <Put_expense_no_deducible 
                          codCocept={row.original.concept}
                          codDescripcion={row.original.description}
                          codDate={row.original.date}
                          codImporte={row.original.ammount}
                          codAccount={row.original.account}
                          codIdPayment={row.original.paiement_account}
                          id_expense={row.original.id}
                          codidConcept={row.original.id_account}
                          ></Put_expense_no_deducible>,
                        {
                          className: "modalTItle",
                          title: "Editar Gasto",
                          showCloseIcon: true,
                          isCanClose: false,
                        }
                      
  
                      );
                      {
                        handleReconsulta(result);
                      }
                      
                    }else{

                    const result = await CustomDialog(
                      <Put_expense
                        codCocept={row.original.concept}
                        codDescripcion={row.original.description}
                        codDate={row.original.date}
                        codImporte={row.original.ammount}
                        codAccount={row.original.account}
                        codIdPayment={row.original.paiement_account}
                        id_expense={row.original.id}
                        codidConcept={row.original.id_account}
                      ></Put_expense>,
                      {
                        className: "modalTItle",
                        title: "Editar Gasto",
                        showCloseIcon: true,
                        isCanClose: false,
                      }
                    );
                    
                    {
                      handleReconsulta(result);
                    }
                    }
                   
                  } else {
                    alert("Este gasto ya no se puede modificar.");
                  }
                }}
                className="centerText, taxes"
              ></img>

             
            
              </div>
            );
          } else {
            return (
              <span title="Consultar detalles del gasto" onClick={Denegado}>
                🔽
              </span>
            );
          }
        },
      },

      

      {
        Header: "No.",
        accessor: "spend_folio_number",
      },
      {
        Header: "F. Recepción",
        accessor: "date",
      },
      {
        Header: "F. Documento",
        accessor: "date_document",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      },
      {
        Header: "Origen",
        accessor: "origin",
      },
      {
        Header: "Pagado",
        accessor: (d) => {
          return d.modifiable === false ? "✅" : "❌";
        },
      },
      {
        Header: "Cuenta de pago",
        accessor: "payment",
      },
      {
        Header: "F. Vencimiento",
        accessor: "scheduled_payment_date",
      },
      {
        Header: "F. Pago",
        accessor: "payment_date",
      },
      
      { Header: "Proveedor",
        accessor: "business_partner_name"
      },

      {
        id: "selection2",

        Header: ({ getToggleAllRowsSelectedProps }) => (<div></div>),

        Cell: ({ row, value, onChange }) => {

          function idExpense() {
            if (row.isSelected === false) {    
              if(difT[row.index]?.length!=0){
                difT[row.index] =  {  
                  id: row.original.id,
                  importe:row.original.ammount,
                };
            }else{
              setDifT([
                ...difT,
                  {
                    id: row.original.id,
                    importe:row.original.ammount,
                  },
                ]);
            }
         

            } else {
          
              difT[row.index] = {
                id:"elminarEsto"
              };
              setDifT(difT);
            }

          
          }


          useEffect(() => {
            const handleExpense = () => {
              setDifT(difT);
              console.log(difT);
              setTotalG(totalG);
              setSumCantidad(sumCantidad);

            };
            handleExpense();
          }, []);

        useEffect(() => {
          const handleSumar = () => {
            const result = difT?.filter(idExpense => idExpense?.id !='elminarEsto');

            const sumar = result?.map((saldo) => parseFloat(isNaN(saldo?.importe)==true?0:saldo.importe))
              .reduce((previous, current) => {
                return previous + current;
              }, 0);
            setSumCantidad((sumar.toFixed(2)));
            setTotalG(result?.length);
          };
          handleSumar();
        });


        if(row.original.modifiable===true){

       
          return (
            <div className="campoPagoSelect">
              <IndeterminateCheckbox
                className="NoInput"
                onClick={idExpense}
                onChange={idExpense}
                {...row.getToggleRowSelectedProps()}
              />
            </div>
          );
        }else{
          return(<div></div>)
        }
        },
      },
    ],
    []
  );

  return (
    <div className="">
       <Modal_Table message={menssage} modalGeneral={modalGeneral} modalT={modalT}></Modal_Table>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>


      <div>
        <h1>Reporte de Gastos</h1>
      </div>
      <br></br>

      
      <div className="TableReport">
        <div className="">
        <div class="containerExpenseReport">
        <div class="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div class="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div class="expen-3 color-4">
          <input
            type="text"
            placeholder="Busqueda por RFC"
            className="fExpense"
            onChange={(event) => setRFC(event.target.value)}
          ></input>
        </div>

        <div class="expen-4 color-5">
          <input
            type="text"
            className="fExpense"
            placeholder="Busqueda por socio de negocios"
            onChange={(event) => setBP(event.target.value)}
          ></input>
        </div>

        <div class="expen-5  color-5">
          <Button
            className="botonExpense"
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
        <div class="expen-8  color-5">
          <h3>Seleccionados: {totalG}</h3>
        </div>
        <div class="expen-7  color-5">
          <h3>Total de Gastos: {sumCantidad}</h3>
        </div>
      </div>
      <StickyTable>

          <TableContainer
            columns={columns}
            exportEx={false}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable verData={true} />
                </div>
              );
            }}
          />
          </StickyTable>
        </div>
      </div>
    </div>
  );
};

export default TableExpense_Report;
