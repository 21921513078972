import  { useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import "../../cashclosing.css";

const SET_REPORT = ({ data }) => {
  const dialog = useDialog();
  const [ff, setff] = useState();

  const handleSubmit=()=>{
    sessionStorage.setItem("FilterData",ff);
    dialog.close(true);
  }

  return (
    <div>
      <div className="dateffcutbox">
        <input
          type="text"
          className="fExpense"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
      <Box>
        <Button
          variant="contained"
          sx={{ m: 1.5, width: "45%" }}
          className="ButtonModal"
          onClick={handleSubmit}
        >
          CONSULTAR
        </Button>

        <Button
          variant="contained"
          sx={{ m: 1.5, width: "45%" }}
          className="ButtonModal2"
          onClick={() => {
            dialog.close(false);
          }}
        >
          cancelar
        </Button>
      </Box>
    </div>
  );
};

export default SET_REPORT;
