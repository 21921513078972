//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import { StickyTable, Row, Cell } from "react-sticky-table";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import { SelectColumnFilter } from "../components_cfdi/filters";
import TableContainer from "./TableContainerBusnes";
import SubTable from "./subTable";
import Set_bussines_p from "../form_business_partner/set_bussines_p";
import Update_bussines_p from "../form_business_partner/update_bussines_p.jsx";
import UpdateBP from "./UpdateBP";
import Modal_Table from "../main_components/modal/modal_Table";
import AlertResponse from "../main_components/alerts/alertResponse";
//ESTILOS
import "../components_cfdi/estilo.css";
import "./tableclientes.css";
import "../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../imgComponents/save.png";
import DELETE from "../imgComponents/borrar.png";
import Spinner from "../imgComponents/S.gif";
import CREATE from "../imgComponents/create.png";
import CODEQR from "../imgComponents/codigoqr.png";
import SUCCESSFULLY from "../imgComponents/successfully.png";

var style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },

    success: {
      // Applied only to the success notification item
      color: "red",
    },
  },
};

const TableUpdateBusnes = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");

  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData([]);
    console.log("----");

    console.log(cancel);
    setIsLoding(true);
    setTimeout(() => {
      setmodalGeneral(false);

      axios
        .get(
          `https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners?`,
          {
            params: { role: "FACTURA", enable: cancel },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setmodalT(false);

          setData(data);
          setIsLoding(false);
          if (msjAler?.length > 1) {
            notificationSystemRef.current.addNotification({
              message: (
                <AlertResponse msj={msjAler} view={true}></AlertResponse>
              ),
              level: "success",
              position: "br",
              autoDismiss: 10,
            });
          }
        })
        .catch((err) => {
          var errroM = err?.response?.data?.errorMessage;
          setData([]);
          notificationSystemRef.current.addNotification({
            message: (
              <AlertResponse
                msj={
                  errroM === undefined
                    ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                    : errroM
                }
                view={false}
              ></AlertResponse>
            ),
            level: "error",
            position: "br",
            autoDismiss: 60,
          });
          setmodalT(false);
        });
    }, 2000);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <>
              <img
                title="Crear socio de negocio "
                src={CREATE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_bussines_p actualiza={false}></Set_bussines_p>,
                    {
                      className: "modalSetBussnes",
                      title: "Crear socio de negocio",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "Socio de negocio creado correctamente"
                    );
                  }
                }}
              ></img>
            </>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("socioHabilitado", row.original.id);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: () => {
          return (
            <>
              <p style={{ color: "white" }}>Acciones</p>
              <img
                title="Actualizar socio de negocios por código QR"
                src={CODEQR}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Update_bussines_p></Update_bussines_p>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar socio de negocios",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "Exito socio de negocios creado/actualizado correctamente"
                    );
                  }
                }}
              ></img>
            </>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function DeleteBusnes() {
            setmenssage("Eliminando socio de negocios");
            setmodalGeneral(true);
            let axiosConfig = {
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
            };
            setIsLoding(true);
            setmensajeDescarga("Eliminando");
            axios
              .delete(
                `https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/businesspartners/${row.original.id}`,
                axiosConfig
              )
              .then((res) => {
                handleReconsulta(
                  true,
                  "Socio de negocios eliminado correctamente"
                );
              })

              .catch((err) => {
                var errroM = err?.response?.data?.errorMessage;
                notificationSystemRef.current.addNotification({
                  message: (
                    <AlertResponse
                      msj={
                        errroM === undefined
                          ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                          : errroM
                      }
                      view={false}
                    ></AlertResponse>
                  ),
                  level: "error",
                  position: "br",
                  autoDismiss: 60,
                });
              });
          }

          return (
            <div>
              <img
                title="Actualizar socio de negocios"
                src={SAVE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <UpdateBP
                      id_busnes={row.original.id}
                      code={row.original.code}
                      business_partner={row.original.business_partner}
                      tax_regime={row.original.tax_regime}
                      name={row.original.name}
                      first_last_name={row.original.first_last_name}
                      second_last_name={row.original.second_last_name}
                      balance={row.original.balance}
                      credit_days={row.original.credit_days}
                      business_partner_type={row.original.business_partner_type}
                      capital_regime={row.original.capital_regime}
                      partner_type={row.original.partner_type}
                      person_type={row.original.person_type}
                      cfdi_use={row.original.cfdi_use}
                      email={row.original.email}
                      phone={row.original.phone}
                      metodo_pago={row.original.metodo_pago}
                      paymethod={row.original.paymethod}
                      account_number={row.original.account_number}
                      allow_invoicing={row.original.allow_invoicing}
                      street={row.original.street}
                      outdoor_number={row.original.outdoor_number}
                      inside_number={row.original.inside_number}
                      neighborhood={row.original.neighborhood}
                      zip_code={row.original.zip_code}
                      city={row.original.city}
                      state={row.original.state}
                      town={row.original.town}
                      country={row.original.country}
                      reference={row.original.reference}
                      address_type={row.original.address_type}
                      subsidiary={row.original.subsidiary}
                      tax_residence={row.original.tax_residence}
                      num_reg_id_trib={row.original.num_reg_id_trib}
                      enable={row.original.enable}
                      rfc_bussnes={row.original.rfc}
                    ></UpdateBP>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar socio de negocio",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "Socio de negocios actualizado correctamente"
                    );
                  }
                }}
              ></img>

              <img
                src={DELETE}
                className="centerText, cancel"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Socio de Negocios?",
                    "Eliminar socio de negocios",
                    "Si",
                    "No"
                  );

                  if (result) {
                    DeleteBusnes();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Socio de Negocios",
        accessor: "business_partner",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "Tipo de persona",
        accessor: "person_type",
      },
      {
        Header: "E-MAIL",
        Filter: SelectColumnFilter,
        accessor: (d) => {
          return d.email?.length > 1 ? "SI" : "NO";
        },
      },
      {
        Header: "Método de pago",
        accessor: "paymethod",
        Filter: SelectColumnFilter,
      },
      {
        Header: "CFDI",
        accessor: "cfdi_use",
      },
      {
        Header: "Código Postal",
        accessor: "zip_code",
      },
      {
        Header: "Regimen Fiscal",
        accessor: "tax_regime",
      },
    ],
    []
  );

  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <Modal_Table
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <div className="">
        <div className="">
          <div className="containerBussnesP">
            <div className="expen-1 color-1" style={{ paddingTop: "10px" }}>
              <select
                id="canceladas-si-no"
                onChange={(event) => setCancel(event.target.value)}
              >
                <option>Clientes Habilitados/Deshabilitados</option>
                <option value={true}>Habilitados</option>
                <option value={false}>Deshabilitados</option>
              </select>
            </div>

            <div className="expen-2 color-1">
              <button className="button" onClick={handleSubmit} type="button">
                Buscar
              </button>
            </div>
          </div>

          <div className="TableCOnsultaClientes">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableUpdateBusnes;
