import SUCCESSFULLY from "../../imgComponents/successfully.png";

const AlertResponse = ({msj,view}) => {
  return (
    <div>
        <div className="alertNotificationContainer" style={{display:view===true?"":"none"}}>
        <div className="ctnAler">
            <img alt="IMG" className="iconctnAlert" src={SUCCESSFULLY}></img>
        </div>
        <div className="ctnAler2">
            <p className="alertNotification">¡EXITO!</p>
        </div>
        <div className="ctnAler3">
            <p className="alertNotificationP">{msj}</p>
        </div>
        </div>
        <div className="alertNotificationContainerError" style={{display:view===false?"":"none"}}>
        <div className="ctnAler">
            <img alt="IMG" className="iconctnAlert" src={SUCCESSFULLY}></img>
        </div>
        <div className="ctnAler2">
            <p className="alertNotification">¡Error!</p>
        </div>
        <div className="ctnAler3">
            <p className="alertNotificationP">{msj}</p>
        </div>
        </div>
    </div>
  );
};

export default AlertResponse;
