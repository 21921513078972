import { Component } from "react";

import TablePromotions from "../../../componentSociosNegocios/componentpromotions/TablePromotions";
export class SetPromotions extends Component {
  
  render() {

   
      
      return (    
      
      <div className="tc" >
        <TablePromotions/>
      </div>
          );
       }     
}

export default SetPromotions;


