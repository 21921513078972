//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef,PureComponent } from "react";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { SelectColumnFilter } from "../components_cfdi/filters";
import TableContainer from "../componentsUpdateBusnes/TableContainerBusnes";

import MODAL_TABLE from "../main_components/modal/modal_Table";
import AlertResponse from "../main_components/alerts/alertResponse";
//ESTILOS
import "../components_cfdi/estilo.css";
import "../componentsUpdateBusnes/tableclientes.css";
import "../components_cfdi/modal.css";
// import DEMO2_DATA from "../Content/dta.json";
import DEMO2_DATA from "../data/dataproyecto206.json";
import DATA_HIST from "../data/histograma.json";
import GRAPH from "./detail/graph";
//IMAGENES/ICONOS
import SAVE from "../imgComponents/save.png";
import { Bar, Brush, CartesianGrid, Cell, ComposedChart, Label, Legend, Line, ReferenceLine, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from "recharts";
import { Checkbox } from "@material-ui/core";
import "./demo.css";

const DEMO2 = () => {
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [tabla, settable] = useState(true);
  const [viewg1, setviewg1]=useState(true);
  const [viewg2, setviewg2]=useState(false);
  const [viewg3, setviewg3]=useState(false);
  const [viewg4, setviewg4]=useState(false);

  const filteredData = DATA_HIST.filter(item => item.ejey >= 0);


    const handleSubmit = (msjAler) => {
    setmodalT(true);
    // setData([]);
    console.log("----");

    console.log(cancel);
    setIsLoding(true);
      setmodalGeneral(false);

     
          setmodalT(false);
          console.log(DEMO2_DATA)
          // setData(DEMO2_DATA);
          setIsLoding(false);
          if (msjAler?.length > 1) {
            notificationSystemRef.current.addNotification({
              message: (
                <AlertResponse msj={msjAler} view={true}></AlertResponse>
              ),
              level: "success",
              position: "br",
              autoDismiss: 10,
            });
          }
        
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  
  const columns = useMemo(
    () => [
     
      /*{
        Header: "Graficas",
        id: "Acciones",

        Cell: ({ valor, row }) => {
         

          return (
            <div>
              <img
                title="Actualizar socio de negocios"
                src={SAVE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <GRAPH
                      data={DEMO2_DATA}
                     
                    ></GRAPH>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar socio de negocio",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  
                }}
              >

              </img>

             
            </div>
          );
        },
      },*/
      {
        Header: "Sample Number",
        accessor: "Sample Number",
      },
      {
        Header: "Date",
        accessor: "Date",
      },
      {
        Header: "Machine",
        accessor: "Machine",
      },
      {
        Header: "Cavity",
        accessor: "Cavity",
      },
      {
        Header: "Shift",
        accessor: "Shift",

      },
      {
        Header: "Obs1 (Inicio)",
        accessor: "Obs1 (inicio)",
      },
      {
        Header: "Obs2 (Medio)",
        accessor: "Obs2 (medio)",
      },
      {
        Header: "Obs3 (Fin)",
        accessor: "Obs3 (fin)",
      },
      {
        Header: "Xave",
        accessor:"Xave"
      },
      {
        Header: "UcL",
        accessor:"UCL"
      },
      {
        Header:"Average",
        accessor:"Average"
      },
      {
        Header:"LCL", 
        accessor:"LCL"
      },
      {
        Header:"Range",
        accessor:"Range"
      },
      {
        Header:"UCL2",
        accessor:"UCL2"
      },
      {
        Header:"Average5",
        accessor:"Average5"
      },
      {
        Header:"LCL8",
        accessor:"LCL8"
      }
    ],
    []
  );

  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div className="">
          <div className="opcions_table_graphic">
            <button className="button1pp" 
              onClick={()=>{settable(true)}}
            >Tabla</button>
            <button className="button2pp"
             onClick={()=>{settable(false)}}
            >Gráficas</button>
          </div>
          {
            tabla===true?
            <div className="TableCOnsultaClientes">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={DEMO2_DATA}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                  </div>
                );
              }}
            />
          </div>:
      <div className="graficos">

          <div className="botones-rchar">

          <div className="option1">
            <label className="labelcheck">XBar Chart 
            </label>
              <Checkbox
                checked={viewg1}
                onChange={()=>{
                  setviewg1(true);
                  setviewg2(false);
                  setviewg3(false);
                  setviewg4(false);
                }}
                inputProps={{"aria-label": "primary checkbox"}}
                style={{"padding":"10px", "transform":"scale(1.3)"}}
              />
          </div>
          <div className="option2">
            <label className="labelcheck">R Chart 
            </label>
              <Checkbox
                checked={viewg2}
                onChange={()=>{
                  setviewg1(false);
                  setviewg2(true);
                  setviewg3(false);
                  setviewg4(false);
                }}
                inputProps={{"aria-label": "primary checkbox"}}
                style={{"padding":"10px", "transform":"scale(1.3)"}}
              />
          </div>  
          <div className="option3">
            <label className="labelcheck">SubGroups 
            </label>
              <Checkbox
                checked={viewg3}
                onChange={()=>{
                  setviewg1(false);
                  setviewg2(false);
                  setviewg3(true);
                  setviewg4(false);
                }}
                inputProps={{"aria-label": "primary checkbox"}}
                style={{"padding":"10px", "transform":"scale(1.3)"}}
              />
          </div> 
          <div className="option4">
            <label className="labelcheck">Histograma 
            </label>
              <Checkbox
                checked={viewg4}
                onChange={()=>{
                  setviewg1(false);
                  setviewg2(false);
                  setviewg3(false);
                  setviewg4(true);
                }}
                inputProps={{"aria-label": "primary checkbox"}}
                style={{"padding":"10px", "transform":"scale(1.3)"}}
              />
          </div>  
         </div>
      <div className="graficas-prueba">
      {
        viewg4===true?
        <ResponsiveContainer width="100%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={filteredData}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
          <>
            <XAxis dataKey="ejex"> 
              <Label value="ejex" offset={-10} position="insideBottom"/>
            </XAxis>
            <YAxis dataKey="ejey"/>
            <Brush dataKey="ejex" height={30} stroke="#8884d8" />  
            <Tooltip
             formatter={(value) => `${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
            />
          </>  
        <ReferenceLine y={0} stroke="#000" />         
           <Bar dataKey="ejey" stackId="ejey" name="ejey" fill="#7121AA"/>
           <Line type="monotone" name="histo" dataKey="histo" stroke="red" strokeDasharray="5 5" strokeWidth={3} dot={false}/>:<></>
      </ComposedChart>
      </ResponsiveContainer> :
    
        viewg3===true?
          <ResponsiveContainer width="100%" maxHeight={450} minHeight={500}>
            <ScatterChart
              data={DEMO2_DATA}
              margin={{
                top: 20,
                right: 50,
                bottom: 50,
                left: 10
              }}
            > 
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Sample Number">
            <Label value="Sample Number" offset={-10} position="insideBottom"/>
          </XAxis>
          <YAxis 
            domain={[10.805, 10.817]}
          />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Brush dataKey="Xave" height={30} stroke="#8884d8" />  
          <ReferenceLine y={0} stroke="#000" />    
            <Scatter name="Obs1 (inicio)" dataKey="Obs1 (inicio)" fill="#8884d8"></Scatter>
            <Scatter name="Obs2 (medio)" dataKey="Obs2 (medio)" fill="#8884d8"></Scatter>
            <Scatter name="Obs3 (fin)" dataKey="Obs3 (fin)" fill="#8884d8"></Scatter>
          </ScatterChart>
        </ResponsiveContainer>:
        <>
        <ResponsiveContainer width="100%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={DEMO2_DATA}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
       
          <>
            <XAxis dataKey="Sample Number"> 
              <Label value="Sample Number" offset={-10} position="insideBottom"/>
            </XAxis>
            <YAxis 
            
              dataKey={viewg1===true?"Xave":viewg2===true?"Range":"xave"}  
              label={{value:viewg1===true?"Xave":viewg2===true?"Range":"xave", angle:90}} 
              
              domain={
                viewg1 === true ? [10.805, 10.817] :
                viewg2 === true ? [0.000, 0.007] : null
              } 
            />
            <Brush dataKey="Xave" height={30} stroke="#8884d8" />  
            <Tooltip
             formatter={(value) => `${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
            />
          </>  
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" />         
        {
          viewg1===true?
          <>
          <Line type="monotone" name="UCL" dataKey="UCL" stroke="red" strokeDasharray="5 5" strokeWidth={1} dot={false}/>:<></>
          <Line type="monotone" name="LCL" dataKey="LCL" stroke="red" strokeDasharray="5 5" strokeWidth={1} dot={false}/>:<></>
          <Line type="monotone" name="Average" dataKey="Average" stroke="#0FA739" strokeWidth={1} dot={false}/>:<></>
          <Line type="monotone" name="Xave" dataKey="Xave" stroke="#14337E" strokeWidth={2}/>:<></>
          </>:
          viewg2===true?
          <>
          <Line type="monotone" name="UCL2" dataKey="UCL2" stroke="red" strokeDasharray="5 5" strokeWidth={1} dot={false}/>:<></>
          <Line type="monotone" name="LCL8" dataKey="LCL8" stroke="red" strokeDasharray="5 5" strokeWidth={1} dot={false}/>:<></>
          <Line type="monotone" name="Average5" dataKey="Average5" stroke="#0FA739" strokeWidth={1} dot={false}/>:<></>
          <Line type="monotone" name="Range" dataKey="Range" stroke="#14337E" strokeWidth={2}/>:<></>
          </>:<></>
        }
      </ComposedChart>
      </ResponsiveContainer>    
        </>
      }
      </div>
      </div>
      }
        </div>
    </div>
  );
};

export default DEMO2;
