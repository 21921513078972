import React, { useEffect, useState, useMemo, useRef} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import TableContainer from "../TableContainer";
import { StickyTable } from "react-sticky-table";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_products, post_promotion_details } from "../../services/businesspartners/businesspartners";

function AddproductPromotion(props) {

const notificationSystemRef = useRef();
const dialog = useDialog(); 
const [difT, setDifT] = React.useState([{ id: "eliminar" }]);
const [products, setproducts] = useState([]);
const [bandera, setbandera]=useState(true);

  useEffect(()=>{
    handleproducts();
  },[]);

  function alertascreate(msj,status){

    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'tr', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'tr', 
        autoDismiss: 60, 
      });
    }  
  }


function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}
    
const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      //cuando se selecciona uno por uno
      React.useEffect(() => {
        //console.log("entra");
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            title="Seleccionar venta"
            
            ref={resolvedRef}
            {...rest}
            style={{ width: 20 + "px" }}
          />
        </>
      );
    }
);

const handleproducts = async ()=>{
  await refresh_token ();
  try {
    var d = await get_products();
    setproducts(d === null ? [] : d.data.productList);
  } catch (error) {
    console.log(error);
  } finally {
  } 
}
const itemStyle = {
  padding: "2px",
  border: "1px solid silver",
  color: "Black",
  width: "100px",
  height: "20px",
};
const textStyle = {
  ...itemStyle,
  padding: "3px 2px",
  "font-size":"12px"
};
const filtrar = async () => {
  
  const arreglo = (difT.filter(item=>item.select!=="noselect"));
  
  if(arreglo.length!==0){
    console.log(arreglo);

    var bam = 0;
    arreglo.map(item=>{
      if(item.quantity===undefined || item.quantity===0 || item.promoPrice===0){
        bam=1;
      }
    })

    if(bam===1){
      alertascreate("No puede tener productos en precio o cantidad igual a 0", false);
      return null;
    }


    await refresh_token ();
    setbandera(false);
    props.reconsultar(true);

    var data = {
      details:arreglo
    } 

    try {
      const dt = await post_promotion_details(sessionStorage.getItem("id_detalles"), data);
      console.log(dt);
      props.alertas("Detalles agregados con éxito", true);
      props.reconsultar(false);
      props.detallespromotions();
      dialog.close();
    } catch (err) {  
      console.log("error post", err);
      catchErrorModul("Promociones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));

      props.alertas("Error al agregar detalles a la promoción", false);
      props.reconsultar(false);
      setbandera(true);
    }
  }
  else{
    props.alertas("No ha seleccionado ningún producto", false);
  }
}
useEffect(() => {
  const handlerestar = () => {
      setDifT(difT);
  };
  handlerestar();
});  
const columns = useMemo(
  () => [  
     {
        Header: "Código",
        accessor: "code",
      },
      {
       Header: "Nombre",
       accessor: "product_name",
     },
     {
        Header: "Precio",
        Cell:({ row, value}) => {
        
        const [text2, setText2] = useState(value ?? 0.00);     
       
        useEffect(() => {
          const handlerestar = () => {
              setDifT(difT);
          };
          handlerestar();
        });     

        useEffect(() => {
          setText2(value);
        }, [value]);

        const handleInputChange3 = (e) => {
          console.log("entra 1");
          setText2(e.target.value);
          row.original.cost = e.target.value;
        }        
        const handleInputChange4 = (e) => {
          console.log("entra 2");
          setText2(0);
        };
        const handleSaveClick2 = () => {   
          console.log("entra 3");  
           
          difT[row.index].promoPrice = parseFloat(text2);
          setDifT(difT);
          console.log(difT)
        };
        return(
          row.isSelected===true?
            <input type={"number"} 
            value={row.original.cost}
            style={textStyle} 
            onChange={handleInputChange3}
            onClick={handleInputChange4}
            onBlur={handleSaveClick2}
          />:
          <input type={"number"} 
          value={""}
          style={textStyle} 
          disabled
         />
            
          )    
        }     
     },
     {
        Header: "Cantidad",
        Cell:({ row, value}) => {
        
        const [text, setText] = useState(value ?? 0.00);     
       
        useEffect(() => {
          const handlerestar = () => {
              setDifT(difT);
          };
          handlerestar();
        });     

        useEffect(() => {
          setText(value);
        }, [value]);

        const handleInputChange = (e) => {
          console.log("entra 1");
          setText(e.target.value);
          row.original.quantity = e.target.value;
        }        
        const handleInputChange2 = (e) => {
          console.log("entra 2");
          setText(0);
        };
        const handleSaveClick = () => {   
          console.log("entra 3");  
           
          difT[row.index].quantity = parseInt(text);
          setDifT(difT);
          
          console.log(difT)
          
        };
          
        return(
          row.isSelected===true?
           
            <input type={"number"} 
              style={textStyle}
              value={row.original.quantity}
              onChange={handleInputChange}
              onClick={handleInputChange2}
              onBlur={handleSaveClick}
            />:
            <input type={"number"} 
              style={textStyle} 
              value={""}
              disabled
            />
          )    
        }    
     },
     {
      Header:"Agregar",
      Cell:
      ({ row, value }) => {       
        const [text, setText] = React.useState(value ?? "");
        const [text2, setText2] = React.useState(value ?? "");
        var rowDate = row.getToggleRowSelectedProps();

  if(rowDate.checked===true){          
          
  console.log("entra al checkbox");
  
  difT[row.index]={
       id_campo: row.index,
       promoPrice: row.original.cost,
       productName: row.original.product_name,
       productCode: row.original.code,
       quantity: row.original.quantity,
       select:"select"
  }
  }else{  
    difT[row.index]={
      id_campo: row.index,
      promoPrice:0.00,
      productName: row.original.product_name,
      productCode: row.original.code,
      quantity: 0.00,
      select:"noselect"
    }
    
  }

function folio() {
  
  //console.log(difT);
    if (row.isSelected === true) { 
      
      row.original.quantity = 0.00;
      
      if (difT[row.index]?.length!== 0) {
        difT[row.index] = {
            id_campo: null,
            promoPrice: 0,
            productName: row.original.product_name,
            productCode: row.original.code,
            quantity: 0,
         };
       }
       } else {
        //entra cuando se selecciona una casilla
              difT[row.index] = {
                id_campo: row.index,
                promoPrice: text2,
                productName: row.original.product_name,
                productCode: row.original.code,
                quantity: text,
              };
              //console.log("entra");
              setDifT(difT);
      }        
  }
          return (
            <div className="">
              {" "}
              <IndeterminateCheckbox
                className="NoInput"
                onChange={folio}
                onClick={folio}
                {...row.getToggleRowSelectedProps()}
                title="Seleccionar Producto"
                />
            </div>
            );
        },
      },    
    ],
    []
);

  return (
    <div>
        <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  
      <div className="subtablepromotions">
        <br/>
          <h3>PROMOCIÓN: {sessionStorage.getItem("datospromos")}
          </h3>
           <StickyTable>
            <TableContainer
              paginado={"Promociones"}
              consultas={"Promociones Consultadas"}
              columns={columns}
              data={products}
              style={{ overflowx: 10 }}
            />
          </StickyTable> 
          <br/>

          <Box>
            {
              bandera===true?
              <Button
              style={{"background-color":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "30ch" }}
              onClick={filtrar}
            >
              Agregar Detalles
            </Button>:
            <Button
            style={{"background-color":"green", "height":"35px"}}
            variant="contained"
            disabled
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/> </Button>
            }
           
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default AddproductPromotion;
