//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";


//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import {get_products} from "../../services/inventory/inventory"
import CREATE_UPDATE from "./tools/create_update";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import TAX from "./tools/tax";

const PRODUCTS = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    try {
        const dt= await get_products(sessionStorage.getItem("id_product_line"),false);
        setData(dt.data);
        setOpen(false);
    } catch (err) {
        setData([]);
        setMenssage("");
    }
   
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              alt=""
              title="Crear Linea de producto"
              src={CREATE}
              className="AddDatapointer"
              onClick={async () => {
                const result = await CustomDialog(<CREATE_UPDATE name_line={sessionStorage.getItem("id_product_line")} code={""} description={""} enable={false} method={"CREATE"} name_supplier={null} id_supp={null}></CREATE_UPDATE>, {
                  className: "modalProduct",
                  title: "Seleccione la configuracion del impuesto",
                  showCloseIcon: true,
                  isCanClose: false,
                });
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("id_product_tax", row.original.id);
          }

          return (
            <div>
            
            <a
              title="Configuración de impuestos"
              {...row.getToggleRowExpandedProps()}
            >
              {row?.isExpanded ? (
                <span title="Configuración del impuesto">🔼</span>
              ) : (
                <span title="Configuración del impuesto" onClick={guardardato}>
                  🔽
                </span>
              )}
            </a>
            <img
              alt=""
              title="Consultar configuraciones"
              src={VER}
              className="centerText, taxes"
            ></img>

            <img
              alt=""
              title="Asignar configuración"
              src={SAVE}
              onClick={async () => {
                const result = await CustomDialog(
                  <CREATE_UPDATE name_line={row.original.name_line} code={row.original.code} description={row.original.description} enable={row.original.enable} method={"UPDATE"} id={row.original.id} name_supplier={row.original.business_partner} id_supp={row.original.id_business_partner_provider_fk} ></CREATE_UPDATE>,
                  {
                    className: "modalTItle",
                    title: "Seleccione la configuracion del impuesto",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
              className="centerText, taxes"
            ></img>

            <img
              alt=""
              title="Eliminar impuesto"
              src={DELETE}
              className="centerText, taxes"
              onClick={async () => {
                const result = await Confirm(
                  "¿Esta usted seguro de eliminar este Impuesto?",
                  "Eliminar Impuesto",
                  "Si",
                  "No"
                );
                if (result) {

                } else {
                }
              }}
            ></img>
          </div>
          );
        },
      },

      

      
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Nombre del producto",
        accessor: "product_name",
      },
      {
        Header: "Descripción",
        accessor: "description"
      },
      {
        Header: "Código de barras",
        accessor: "barcode"
      },
      {
        Header: "Tipo",
        accessor: "type"

      },
      {
        Header: "Unidad",
        accessor: "unit"
      },
      {
        Header: "Conversión",
        accessor: "unit_conversion"
      },
      {
        Header: "Minima",
        accessor: "minimum_sales_unit"
      },
      {
        Header: "Unidad SAT",
        accessor: "clave_unidad"
      },
      {
        Header: "Piezas",
        accessor: "pieces"
      },
      {
        Header: "Marca",
        accessor: "brand"
      },
      {
        Header: "Activo",
        accessor: (d) => {
          return d.enable === true ? "Si" : "NO";
        },
      },
      {
        Header: "Clave SAT",
        accessor: "clave_prod_serv"
      },
     
    ],
    []
  );

  return (
    <div className="">
      <div>
        
      </div>

      <div>
        <h1>CONSULTAR LINEA DE PRODUCTOS</h1>
      </div>
      <br></br>

      <div className="TableTaxes TableProduct">
        <div className="">
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <TAX />
                </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PRODUCTS;
