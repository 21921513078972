//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";


//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import {get_movements} from "../../services/inventory/inventory"
import { previous_date } from "../../main_components/date/date";
import { date_current } from "../../main_components/date/date";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import CREATE from "../../imgComponents/create.png";
import VIEW from "../../imgComponents/lupa.png";

import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import SubTableContainer from "../../main_components/Table/SubTableContainer";
import SET_MOVEMENTS from "./tools/create_movements";
import CONSULT_DETAIL_WAREHOUSE from "./detail/consult_detail_sale";
const MOVEMENTS = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [fi, setfi] = useState(previous_date);
  const [ff, setff] = useState(date_current);

  const handleSubmit = async(event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    try {
        const dt= await get_movements(fi,ff);
        setData(dt.data);
        setOpen(false);
    } catch (err) {
        setData([]);
        setMenssage("");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {
          return (
            <img
              alt=""
              title="Crear Linea de producto"
              src={CREATE}
              className="cancel"
              onClick={async () => {
                const result = await CustomDialog(
                  <SET_MOVEMENTS></SET_MOVEMENTS>
                  , {
                  className: "modalProduct",
                  title: "Seleccione la configuracion del impuesto",
                  showCloseIcon: true,
                  isCanClose: false,
                });
              }}
            ></img>
          );
        },
        Cell: ({ row }) => {


          return (
            <>
             <img
              alt=""
              title="Mostrar detalles del movimiento"
              src={VIEW}
              className="cancel"
              onClick={async () => {
                const result = await CustomDialog(
                  <CONSULT_DETAIL_WAREHOUSE 
                    id={row.original.id} 
                    date={row.original.movement_date} 
                    rows={row.original.rows} 
                    note={row.original.note}
                    reference={row.original.reference}
                    warehouse_name={row.original.warehouse_name}
                    sale={row.original.sale}
                    returns={row.original.returns}
                    type={row.original.type}
                    movement_type={row.original.movement_type}
                  ></CONSULT_DETAIL_WAREHOUSE>
                  , {
                  className: "modalProduct",
                  title: "Seleccione la configuracion del impuesto",
                  showCloseIcon: true,
                  isCanClose: false,
                });
              }}
            ></img>            
            </>
          );
        },
      },
    
      {
        Header: "Partida",
        accessor: "movement_date",
      },
      {
        Header: "Almacen",
        accessor: "warehouse_name",
      },
      {
        Header: "Tipo",
        accessor: "movement_type"
      },
      {
        Header: "Nota",
        accessor: "note"
      },
      {
        Header: "Usuario",
        accessor: "fullusername"
      },
      {
        Header: "Venta",
        accessor: "sale"
      },
      {
        Header: "Devoluciones",
        accessor: "returns"
      },  
    ],
    []
  );

  return (
    <div className="">
      <div>
        
      </div>

      <div>
        <h1>CONSULTAR LINEA DE PRODUCTOS</h1>
      </div>
      <br></br>
      <div className="containerMovements">
        <div className="pdc-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="pdc-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="pdc-3 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="TableTaxes">
        <div className="">
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            
          />
        </div>
      </div>
    </div>
  );
};

export default MOVEMENTS;
