import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_cabinets_models, get_cabinets_warehouse, get_status_cabinets, put_cabinets } from "../../../services/assets/assets";


function EditFreez(props) {

  console.log(props.row.original);
 
const dialog = useDialog();

const [bandera, setbandera]=useState(true);
//variables de congeladores
const [economic_number, seteconomic_number]=useState(props.row.original.economic_number);
const [fixed_asset, setfixed_asset]=useState(props.row.original.fixed_asset);
const [serial_number, setserial_number]=useState(props.row.original.serial_number);
const [capitalization_date, setcapitalization_date]=useState(props.row.original.capitalization_date);
const [note, setnote]=useState(props.row.original.note);
const [id_warehouse, setid_warehouse]=useState(props.row.original.id_warehouse_fk);
const [id_cabinets_state, setid_cabinets_state]=useState(props.row.original.id_cabinets_state_fk);
const [id_cabinets_model, setid_cabinets_model]=useState(props.row.original.id_cabinets_model_fk);
const [lending, setlending]=useState(props.row.original.lending);
const [production_year, setproduction_year]=useState(props.row.original.production_year);

const [datawarehouse, setdatawarehouse]=useState([]);
const [datamodels, setdatamodels]=useState([]);
const [datastatus, setdatastatus]=useState([]);


useEffect(() => {
  hablealmacen();
  handlemodelos();
  handlestatus();
}, [])



const handlemodelos = async () => {
  try {  
    var d = await get_cabinets_models();
    setdatamodels(d === null ? [] : d.data.models);
  } catch (error) {
    console.error(error);
    setdatamodels([]);
  } finally {
  } 
}
const hablealmacen = async () => {
  try {  
    var d = await get_cabinets_warehouse();
    setdatawarehouse(d === null ? [] : d.data.warehouses);
  } catch (error) {
    console.error(error);
    setdatawarehouse([]);
  } finally {
  } 
}

const handlestatus = async () => {
  try {  
    var d = await get_status_cabinets();
    setdatastatus( d === null ? [] : d.data.cabinet_status);
  } catch (error) {
    console.error(error);
    setdatastatus([]);
  } finally {
  }  
}


const UpdateFrezz = async () => {

props.reconsultar(true);
setbandera(false);
refresh_token ();
  var data = {
    "economic_number": economic_number, //String = numero economico
    "fixed_asset": fixed_asset, //String = numero de activo fijo
    "serial_number": serial_number, //String
    "capitalization_date": capitalization_date, // Date
    "note": note, //String | null
    "id_warehouse": parseInt(id_warehouse),//int
    "id_cabinets_state": parseInt(id_cabinets_state),//int | null   
    "id_cabinets_model": parseInt(id_cabinets_model),//int | null 
    "lending": lending==="true" || lending===true ?true : false,//Boolean = ¿Congelador prestado?
    "production_year": production_year, //String
  }
  try {  
    var d = await put_cabinets(props.row.original.id, data);
    console.log(d);
    props.alertas("Congelador actualizado con éxito", true);
    props.reconsultar(false);
    dialog.close();
    props.handleSubmit();
  }catch (error) {
    console.log("error post", error);
    setbandera(true);
    props.reconsultar(false);
    if(error.response.status===500){
      props.alertas("No se pudo actualizar el congelador, vuelva a intertarlo", false);
    }else{
      props.alertas("Error al actualizar el congelador", false);
    }
  } 
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="form-frezz">
          <div className="gridfrez1">
            <div className="frez1">
            <TextField
              label="Número Economico"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={economic_number}
              onChange={(event) => seteconomic_number(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="frez2">
            <TextField
              label="Número Activo Fijo"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={fixed_asset}
              onChange={(event) => setfixed_asset(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="frez3">
            <TextField
              label="Número Serie"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={serial_number}
              onChange={(event) => setserial_number(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridfrez2">
            <div className="frez4">
            <label className="label-date">Fecha Capitalización</label>
            <input
                  type="text"
                  className="fi" 
                  required
                  disabled={!bandera}
                  style={{"width":"100%"}}
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  value={capitalization_date}
                placeholder="Fecha"
                onChange={(event) => setcapitalization_date(event.target.value)}
            ></input>
            </div>
            <div className="frez5">
            <TextField
                  select
                  label="Congelador Prestado"
                  disabled={!bandera}
                  value={lending}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setlending(event.target.value)}  
                 >
                  <option key={1} value={false}>
                      {"No"}
                  </option>                 
                  <option key={2} value={true}>
                      {"SI"}
                  </option>
             </TextField>
            </div>
            <div className="frez6">
            <TextField
              label="Año de produccción"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={production_year}
              onChange={(event) => setproduction_year(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridfrez3">
              <div className="frez7">
                  <TextField
                  select
                  label="Almacén"
                  disabled={!bandera}
                  value={id_warehouse}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_warehouse(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Almacén"}
                  </option>
                  {
                    datawarehouse?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.warehouse_name}
                  </option>
                    ))
                  }
             </TextField>
              </div>
              <div className="frez8">
                  <TextField
                  select
                  label="Estado Congelador"
                  disabled={!bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  value={id_cabinets_state}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_state(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Estado Congelador"}
                  </option>
                  {
                     datastatus?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.description}
                       </option>
                    ))
                  }
             </TextField>
              </div>
              <div className="frez9">
                  <TextField
                  select
                  label="Modelo Congelador"
                  disabled={!bandera}
                  value={id_cabinets_model}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_cabinets_model(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Selecciona Empleado"}
                  </option>
                  {
                     datamodels?.map(item=>(
                      <option key={item.id} value={item.id}>
                      {item.name_model}
                       </option>
                    ))
                  }
             </TextField>
              </div>
          </div>
          <div className="gridfrez4">
            <div className="frez10">
            <TextField
              label="Nota"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={note}
              onChange={(event) => setnote(event.target.value)}  
             InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
           
            {/* <div className="frez12">
            <TextField
                  select
                  label="send_to_dms"
                  disabled={!bandera}
                  value={send_to_dms}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setsend_to_dms(event.target.value)}  
                 >
                  <option key={1} value={false}>
                      {"No"}
                  </option>                 
                  <option key={2} value={true}>
                      {"SI"}
                  </option>
             </TextField>
            </div>
            <div className="frez13">
            <TextField
                  select
                  label="id_delivery_record_dms"
                  disabled={!bandera}
                  value={id_delivery_record_dms}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => setid_delivery_record_dms(event.target.value)}  
                 >
                  <option key={0} value={0}>
                      {"Seleccione"}
                  </option>                 
             </TextField>
            </div> */}
          </div>
             
            
          <Box>
            {
              bandera===true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "40ch" }}
            onClick={UpdateFrezz}
          >
            Actualizar Congelador
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "40ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default EditFreez;
