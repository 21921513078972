//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";
import FormControlLabel from "@mui/material/FormControlLabel";

//COMPONENTES
import TableContainer from "../../Table_Container_Cashclosing";
import CONSULT_RETURN_CASHCLOSING from "./detail/consult_return_cashclosing";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import { base64toBlob } from "../../../main_components/donwloadfuncion/download";
import {download_invoice} from "../../../services/invoices/invoice"
import { SelectColumnFilter } from "../../../components_cfdi/filters";
import { MaterialUISwitch } from "../../../componentsUpdateBusnes/UpdateBP";
import PUT_CASHCLOSING from "./detail/put_sale_cashclosing";
import CONSULT_DETAIL_SALE from "./detail/consult_detail_sale";
import SUMMARY_CASHCLOSING from "./summary_cashclosing/summary_cashclosing";
import FILTER_CASHCLOSING from "./filter_cashclosing";
import CONSULT_CASHCLOSING_WAREHOUSE from "./warehose.jsx/consult_cashclosing_warehouse";
import { toinvoice_cashclosing } from "../../../services/cashclosing/cashclosing";
import { get_sale_cashclosing } from "../../../services/cashclosing/cashclosing";
import { cancel_sale_cashclosing } from "../../../services/cashclosing/cashclosing";
import { generate_invoice_cashclosing } from "../../../services/cashclosing/cashclosing";
import { save_cashclosing } from "../../../services/cashclosing/cashclosing";
import { close_cashclosing } from "../../../services/cashclosing/cashclosing";
import { toinvoice_fromdocument } from "../../../services/cashclosing/cashclosing";
//ESTILOS
import "../../../components_cfdi/estilo.css";
import "../../cashclosing.css";
import "../../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import PUBLIC from "../../../imgComponents/publico_general.png";
import PDF from "../../../imgComponents/pdf.png";
import RECARGA from "../../../imgComponents/actualizar.png";
import INVOICE from "../../../imgComponents/invoice.png";
import CP from "../../../imgComponents/invoice_cp.png";

import CANCEL from "../../../imgComponents/cancelado.png";
import CONFIG from "../../../imgComponents/config.png";
import VIEW from "../../../imgComponents/lupa.png";
import CANCELSALE from "../../../imgComponents/aaa.png";

import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";

const Consult_cut_box_sale = () => {
  const [data, setData] = useState([]);
  const [dataSinFil, setdataSinFil] = useState([]);
  const [menssage, setMenssage] = useState("");
  const [ticketS, setticketS] = useState("");
  const [modalT, setmodalT] = useState(true);
  const [modalGeneral, setmodalGeneral] = useState(true);

  //Filtros resumen de liquidación
  const [filtersColumsView, setfiltersColumsView] = useState(true);
  const [filtersbonusdevView, setfiltersbonusdevView] = useState(true);
  const [filterVPCo, setfilterVPCo] = useState(false);
  const [filterVPCr, setfilterVPCr] = useState(false);
  const [filterVSCo, setfilterVSCo] = useState(false);
  const [filterVSCr, setfilterVSCr] = useState(false);
  const [filterVO, setfilterVO] = useState(false);

  //Venta primaria crédito
  const [vPSBonificacion, setvPSBonificacion] = useState(0);
  const [vPSDevolucion, setvPSDevolucion] = useState(0);
  const [vPCTotal, setvPCTotal] = useState(0);
  const [vPCAdeudo, setvPCAdeudo] = useState(0);
  const [vPCPagado, setvPCPagado] = useState(0);
  //Venta primaria contado
  const [vPSContadoBonificacion, setvPSContadoBonificacion] = useState(0);
  const [vPSContadoDevolucion, setvPSContadoDevolucion] = useState(0);
  const [vPContadoTotal, setvPContadoTotal] = useState(0);
  const [vPContadoAdeudo, setvPContadoAdeudo] = useState(0);
  const [vPContadoPagado, setvPContadoPagado] = useState(0);
  //Venta primaria crédito
  const [vSSBonificacion, setvSSBonificacion] = useState(0);
  const [vSSDevolucion, setvSSDevolucion] = useState(0);
  const [vSCTotal, setvSCTotal] = useState(0);
  const [vSCAdeudo, setvSCAdeudo] = useState(0);
  const [vSCPagado, setvSCPagado] = useState(0);
  //Venta secundaria contado
  const [vSSContadoBonificacion, setvSSContadoBonificacion] = useState(0);
  const [vSSContadoDevolucion, setvSSContadoDevolucion] = useState(0);
  const [vSContadoTotal, setvSContadoTotal] = useState(0);
  const [vSContadoAdeudo, setvSContadoAdeudo] = useState(0);
  const [vSContadoPagado, setvSContadoPagado] = useState(0);

  //Venta primaria crédito
  const [obBonificacion, setobBonificacion] = useState(0);
  const [obDevolucion, setobDevolucion] = useState(0);
  const [obTotal, setobTotal] = useState(0);
  const [obAdeudo, setobAdeudo] = useState(0);
  const [obPagado, setobPagado] = useState(0);
  
  //Filtros sobre las columnas
  const [viewPV, setviewPV] = useState(localStorage.getItem("viewPV") === null? true: localStorage.getItem("viewPV") === "true"? true: false);
  const [viewSN, setviewSN] = useState(localStorage.getItem("viewSN") === null? true: localStorage.getItem("viewSN") === "true"? true: false);
  const [viewF, setviewF] = useState(localStorage.getItem("viewF") === null? true: localStorage.getItem("viewF") === "true"? true: false);
  const [viewTp, setviewTp] = useState(localStorage.getItem("viewTp") === null? true: localStorage.getItem("viewTp") === "true"? true: false);
  const [viewSt, setviewSt] = useState(localStorage.getItem("viewSt") === null? true: localStorage.getItem("viewSt") === "true"? true: false);
  const [viewDesc, setviewDesc] = useState(localStorage.getItem("viewDesc") === null? true: localStorage.getItem("viewDesc") === "true"? true: false);
  const [viewImp, setviewImp] = useState(localStorage.getItem("viewImp") === null? true: localStorage.getItem("viewImp") === "true"? true: false);
  const [viewT, setviewT] = useState(localStorage.getItem("viewT") === null? true: localStorage.getItem("viewT") === "true"? true: false);
  const [viewAd, setviewAd] = useState(localStorage.getItem("viewAd") === null? true: localStorage.getItem("viewAd") === "true"? true: false);
  const [viewPgd, setviewPgd] = useState(localStorage.getItem("viewPgd") === null? true: localStorage.getItem("viewPgd") === "true"? true: false);
  const [viewPg, setviewPg] = useState(localStorage.getItem("viewPg") === null? true: localStorage.getItem("viewPg") === "true"? true: false);
  const [viewCd, setviewCd] = useState(localStorage.getItem("viewCd") === null? true: localStorage.getItem("viewCd") === "true"? true: false);
  const [viewVd, setviewVd] = useState(localStorage.getItem("viewVd") === null? true: localStorage.getItem("viewVd") === "true"? true: false);
  const [viewCp, setviewCp] = useState(localStorage.getItem("viewCp") === null? true: localStorage.getItem("viewCp") === "true"? true: false);
  const [viewfc, setviewfc] = useState(localStorage.getItem("viewfc") === null? false : localStorage.getItem("viewfc") === "true"? true: false);
  const [viewrfc, setviewrfc] = useState(localStorage.getItem("viewrfc") === null ? false : localStorage.getItem("viewrfc") === "true"? true: false);
  const [viewGd, setviewGd] = useState(localStorage.getItem("viewGd") === null ? false: localStorage.getItem("viewGd") === "true" ? true: false);
  const [viewOp, setviewOp] = useState(localStorage.getItem("viewOp") === null ? false: localStorage.getItem("viewOp") === "true" ? true: false);
  const [viewNf, setviewNf] = useState(localStorage.getItem("viewNf") === null ? false: localStorage.getItem("viewNf") === "true" ? true: false);
  const [viewVc, setviewVc] = useState(localStorage.getItem("viewVc") === null ? false : localStorage.getItem("viewVc") === "true"? true: false);
  const [viewTikect, setviewTikect] = useState(localStorage.getItem("viewTikect") === "null"? true: localStorage.getItem("viewTikect") === "true"? true: false);


  //consulta todas las ventas
  const handleSubmit = async (event) => {
    event?.preventDefault();
    setMenssage("Consultando ventas...");
    setData([]);
    setmodalT(true);
      setmodalGeneral(false);

      try {
        var rf_token= await refresh_token();
        if(rf_token === true){

          const dt= await get_sale_cashclosing(sessionStorage.getItem("dateiSale"), sessionStorage.getItem("datefSale"),sessionStorage.getItem("routeSale"));
          console.log(dt)
          setmodalT(false);
          const data2 = dt.data.sort((a, b) => a.id < b.id ? 1 : a.id > b.id ? -1 : 0);
          setData(data2);
          setdataSinFil(data2);
          // OBSEQUIOS TOTALES
          const obsequioD = dt.data.filter((sale) => sale.saletype === "OBSEQUIO");
          handelObsequio(obsequioD);
          // NO OBSEQUIOS
          const noObsequio =dt.data.filter((sale) => sale.saletype !== "OBSEQUIO");

          // VENTA PRIMARIA TOTALES
          handelVPContado(noObsequio.filter((sale) =>sale.generate_debt_pos === true && sale.pay_condition_pos === "CONTADO" && sale.partner_type === "PROPIO"));

          // VENTA PRIMARIA CREDITO TOTALES

          handelVPCredito(noObsequio.filter((sale) =>sale.generate_debt_pos === true &&sale.pay_condition_pos !== "CONTADO" && sale.partner_type === "PROPIO"));

          // VENTA SECUNDARIA TOTALES
          const ventaSecundaria = noObsequio.filter((sale) => sale.generate_debt_pos === false);
          const ventaSecundaria2 = noObsequio.filter((sale) => sale.generate_debt_pos === true);

          // VENTA SECUNDARIA CONTADO TOTALES

          const vSContado = ventaSecundaria.filter((sale) => sale.pay_condition_pos === "CONTADO").filter((sale) => sale.partner_type !== "PROPIO");
          const vSContado2 = ventaSecundaria2.filter((sale) => sale.pay_condition_pos === "CONTADO").filter((sale) => sale.partner_type === "EXTERNO");

          const vSContadoAll = vSContado.concat(vSContado2);

          handelVSContado(vSContadoAll);
          // VENTA SECUNDARIA CREDITO TOTALES

          const vSCredito = ventaSecundaria.filter((sale) => sale.pay_condition_pos !== "CONTADO").filter((sale) => sale.partner_type !== "PROPIO");
          const vSCredito2 = ventaSecundaria2.filter((sale) => sale.pay_condition_pos !== "CONTADO").filter((sale) => sale.partner_type === "EXTERNO");
          const vSCreditoAll = vSCredito.concat(vSCredito2);

          handelVSCredito(vSCreditoAll);
          setMenssage("");
      }else{}
      } catch (err) {
        console.log("error", err);
        setData([]);
        setmodalT(false);
        setMenssage("");
      }
    
  };
  //Calcula todas las ventas primarias de contado
  const handelVPContado = (vPContado) => {
    setvPContadoTotal(vPContado?.map((sales) => parseFloat(sales?.total)).reduce((p, c) => p + c, 0));
    setvPContadoAdeudo(vPContado?.map((sales) => parseFloat(sales?.debt)).reduce((p, c) => p + c, 0));
    setvPContadoPagado(vPContado?.map((sales) => parseFloat(sales?.payed)).reduce((p, c) => p + c, 0));
    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevContado = vPContado.filter((sale) => sale?.returns !== null).map((item) => item.returns);
    setvPSContadoBonificacion(bonDevContado.reduce((p, c) => p + c.reduce((a, b) => (b.type === "BONIFICACION" ? a + b.total : a), 0),0));
    setvPSContadoDevolucion(bonDevContado.reduce((p, c) => p + c.reduce((a, b) => (b.type === "DEVOLUCION" ? a + b.total : a), 0),0));
  };

  //Calcula todas las ventas primarias de crédito
  const handelVPCredito = (vPCredito) => {
    const sumvPCreditoTotal = vPCredito?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => {return previous + current;}, 0);
    setvPCTotal(sumvPCreditoTotal);
    const sumvPCreditoAdeudo = vPCredito?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => {return previous + current;}, 0);
    setvPCAdeudo(sumvPCreditoAdeudo);
    const sumvPCreditoPagado = vPCredito?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => {return previous + current;}, 0);
    setvPCPagado(sumvPCreditoPagado);

    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevCreditoFilter = vPCredito.filter((sale) => sale?.returns !== null);
    const bonDevCredito = bonDevCreditoFilter.map((item) => {const container = item.returns;return container;});
    // console.log(bonDevCredito);
    const bonDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0; 
    });
    // // console.log(bonDevCreditoAAPP);
    const totalbonDevCreditoAAPP = bonDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // // console.log(totalbonDevCreditoAAPP);
    setvPSBonificacion(totalbonDevCreditoAAPP);

    const devDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0; 

    });
    // // console.log(devDevCreditoAAPP);
    const totaldevDevCreditoAAPP = devDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // console.log(totaldevDevCreditoAAPP);
    setvPSDevolucion(totaldevDevCreditoAAPP);
  };
  //Calcula todas las ventas secudarias de contado
  const handelVSContado = (vSContado) => {
    const sumvPContadoTotal = vSContado?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => {return previous + current;}, 0);
    setvSContadoTotal(sumvPContadoTotal);
    const sumvPContadoAdeudo = vSContado?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => {return previous + current;}, 0);
    setvSContadoAdeudo(sumvPContadoAdeudo);
    const sumvPContadoPagado = vSContado?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => {return previous + current;}, 0);
    setvSContadoPagado(sumvPContadoPagado);

    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevContadoFilter = vSContado.filter((sale) => sale?.returns !== null);
    const bonDevContado = bonDevContadoFilter.map((item) => {const container = item.returns;return container;});
    const bonDevContadoAAPP = bonDevContado.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0; 

    });
    const totalbonDevContadoAAPP = bonDevContadoAAPP.reduce((a, b) => a + b, 0);
    setvSSContadoBonificacion(totalbonDevContadoAAPP);
    const devDevContadoAAPP = bonDevContado.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0; 
    });
    const totaldevDevContadoAAPP = devDevContadoAAPP.reduce((a, b) => a + b, 0);
    setvSSContadoDevolucion(totaldevDevContadoAAPP);
  };
  //Calcula todas las ventas secudarias de crédito
  const handelVSCredito = (vSCredito) => {
    const sumvPCreditoTotal = vSCredito?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => {return previous + current;}, 0);
    setvSCTotal(sumvPCreditoTotal);
    const sumvPCreditoAdeudo = vSCredito?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => {return previous + current;}, 0);
    setvSCAdeudo(sumvPCreditoAdeudo);
    const sumvPCreditoPagado = vSCredito?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => {return previous + current;}, 0);
    setvSCPagado(sumvPCreditoPagado);

    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevCreditoFilter = vSCredito.filter(
      (sale) => sale?.returns !== null
    );

    const bonDevCredito = bonDevCreditoFilter.map((item) => {
      const container = item.returns;
      return container;
    });
    // console.log(bonDevCredito);

    const bonDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0; 

    });
    // // console.log(bonDevCreditoAAPP);
    const totalbonDevCreditoAAPP = bonDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // // console.log(totalbonDevCreditoAAPP);
    setvSSBonificacion(totalbonDevCreditoAAPP);

    const devDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0; 

    });
    // // console.log(devDevCreditoAAPP);
    const totaldevDevCreditoAAPP = devDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // console.log(totaldevDevCreditoAAPP);
    setvSSDevolucion(totaldevDevCreditoAAPP);
  };
  //Calcula todas las ventas de obsequio
  const handelObsequio = (obsequioD) => {const sumvPCreditoTotal = obsequioD?.map((sales) => parseFloat(sales?.total)).reduce((previous, current) => {return previous + current;}, 0);
    setobTotal(sumvPCreditoTotal);
    const sumvPCreditoAdeudo = obsequioD?.map((sales) => parseFloat(sales?.debt)).reduce((previous, current) => {return previous + current;}, 0);
    setobAdeudo(sumvPCreditoAdeudo);
    const sumvPCreditoPagado = obsequioD?.map((sales) => parseFloat(sales?.payed)).reduce((previous, current) => {return previous + current;}, 0);
    setobPagado(sumvPCreditoPagado);
    //BONIFICACION / DEVOLUCION TOTALES
    const bonDevCreditoFilter = obsequioD.filter((sale) => sale?.returns !== null);
    const bonDevCredito = bonDevCreditoFilter.map((item) => {const container = item.returns;return container;});
    const bonDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "BONIFICACION" ? item[i].total : 0;
        return container;
      }
      return 0; 
    });
    const totalbonDevCreditoAAPP = bonDevCreditoAAPP.reduce((a, b) => a + b, 0);
    setobBonificacion(totalbonDevCreditoAAPP);

    const devDevCreditoAAPP = bonDevCredito.map((item) => {
      for (var i = 0; i < item.length; i++) {
        const container = item[i].type === "DEVOLUCION" ? item[i].total : 0;
        return container;
      }
      return 0; 
    });
    // // console.log(devDevCreditoAAPP);
    const totaldevDevCreditoAAPP = devDevCreditoAAPP.reduce((a, b) => a + b, 0);
    // console.log(totaldevDevCreditoAAPP);
    setobDevolucion(totaldevDevCreditoAAPP);
  };
  //Reconsulta todas las ventas
  const handleReconsulta = (result) => {
    if (result === true) {
      handleSubmit();
    } else {
    }
  };

  //Filtrar ventas primarias de contado
  const handleFIlterSalePrimaryC = () => {
    const vPContado = dataSinFil.filter((sale) =>sale.saletype !== "OBSEQUIO" && sale.generate_debt_pos === true && sale.partner_type === "PROPIO" && sale.pay_condition_pos === "CONTADO");
    if (data.length !== vPContado.length) {
      setData(vPContado);
      setfilterVPCo(true);
      setfilterVPCr(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    } else {
      setData(dataSinFil);
      setfilterVPCo(false);
      setfilterVPCr(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    }
  };
  const handleFIlterSalePrimaryCredit = () => {
    const vPCredito = dataSinFil.filter((sale) => sale.saletype !== "OBSEQUIO" && sale.generate_debt_pos === true && sale.partner_type === "PROPIO" && sale.pay_condition_pos !== "CONTADO");
    if (data.length !== vPCredito.length) {
      setData(vPCredito);
      setfilterVPCr(true);
      setfilterVPCo(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    } else {
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
      setData(dataSinFil);
    }
  };
  const handleFIlterSaleSecundaryC = () => {
    const factureAll = dataSinFil.filter((sale) => sale.saletype !== "OBSEQUIO" && sale.pay_condition_pos === "CONTADO" &&((sale.generate_debt_pos === false && sale.partner_type !== "PROPIO") ||(sale.generate_debt_pos === true && sale.partner_type === "EXTERNO")));
    if (data.length !== factureAll.length) {
      setData(factureAll);
      setfilterVSCo(true);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    } else {
      setData(dataSinFil);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVSCr(false);
      setfilterVO(false);
    }
  };
  const handleFIlterSaleSecundaryCredit = () => {
    const factureAll = dataSinFil.filter((sale) => 
    sale.saletype !== "OBSEQUIO" &&((sale.generate_debt_pos === false && sale.partner_type !== "PROPIO") ||(sale.generate_debt_pos === true && sale.partner_type === "EXTERNO")) && sale.pay_condition_pos !== "CONTADO");
    if (data.length !== factureAll.length) {
      setData(factureAll);
      setfilterVSCr(true);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVO(false);
    } else {
      setData(dataSinFil);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setfilterVO(false);
    }
  };
  const handleFIlterDebit = () => {
    const noObsequio = dataSinFil.filter((sale) => sale.saletype === "OBSEQUIO");
    if (data.length !== noObsequio.length) {
      setfilterVO(true);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setData(noObsequio);
    } else {
      setfilterVO(false);
      setfilterVSCr(false);
      setfilterVSCo(false);
      setfilterVPCr(false);
      setfilterVPCo(false);
      setData(dataSinFil);
    }
  };


//Factura Oportuno
  const handel_toinvoce = async () => {
    const factureAll = dataSinFil.reduce((result, sale) => {
      if (((sale.rfc_business_partner === "XAXX010101000" && sale.no_invoice === false) ||(sale.rfc_business_partner !== "XAXX010101000" && sale.no_invoice === true)) && sale.generate_debt_pos === true && sale.never_invoice === false && sale.pay_condition_pos === "CONTADO" && sale.saletype !== "NO VENTA" && sale.saletype !== "OTRO" && sale.saletype !== "PEDIDO_EMPLEADO" && sale.saletype !== "CONGELADOR" && sale.partner_type === "PROPIO") 
        {result.push(sale);}
      return result;
    }, []);
    const valid = factureAll.map((item) => {return item.complete;});
    const validFalseComplete = valid.filter((sale) => sale === false);
    const bonDevContadoAAPP = factureAll.map((item) => {return item.id;});
    if (validFalseComplete?.length > 0) {
      alert("Tienes una venta oportuno sin completar");
    } else {
      setmodalT(true);
      var dta = {sales: bonDevContadoAAPP};
      try {

        var rf_token= await refresh_token();
        if(rf_token === true){
        var result= await toinvoice_cashclosing(dta);
        console.log(result);
        alert("Exito ventas facturadas correctamente");
        setmodalT(false);
        handleReconsulta(true);
        }else{}
      } catch (err) {
        catchErrorModul("Corte de Caja => Factura Oportuno", JSON.stringify(dta), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));;
        alert("Error.," + JSON.stringify(err?.response?.data));
        setmodalT(false); 
      }
    }
  };

//Factura Oportuno
  const handel_toinvoce_fromdocument = async () => {
    const factureAll = dataSinFil.reduce((result, sale) => {
      if (sale.saletype !== "NO VENTA" && sale.saletype !== "OTRO" && sale.saletype !== "PEDIDO_EMPLEADO" && sale.saletype !== "CONGELADOR" && sale.partner_type === "PROPIO") 
        {result.push(sale);}
      return result;
    }, []);
    console.log(factureAll);
    const valid = factureAll.map((item) => {return item.complete;});
    console.log(valid);
    const validFalseComplete = valid.filter((sale) => sale === false);
    console.log(validFalseComplete);

    const invoice_cp = factureAll.map((item) => {return {folio_invoice:item.folio_invoice,ticket:item.ticket}});
    console.log(invoice_cp);

    if (validFalseComplete?.length > 0) {
      alert("Tienes una venta oportuno sin completar");
    } else {
      setmodalT(true);
      try {
        var rf_token= await refresh_token();
        if(rf_token === true){
          var result= await toinvoice_fromdocument(invoice_cp);
          console.log(result);
          alert("Éxito, las cartas porte se están generando.");
          setmodalT(false);
          handleReconsulta(true);
        }else{}
      } catch (err) {
        catchErrorModul("Corte de Caja => Generar Carta Porte", JSON.stringify(invoice_cp), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));;
        alert("Error.," + JSON.stringify(err?.response?.data));
        setmodalT(false); 
      }
    }
  };


  //Guardar Liquidación
  const handel_save_cashclosing = async () => {
    setmodalT(true);
    setData([]);
    try {
      var rf_token= await refresh_token();
      if(rf_token === true){
        const dt= await save_cashclosing(sessionStorage.getItem("dateiSale"),sessionStorage.getItem("datefSale"),sessionStorage.getItem("routeSale"),sessionStorage.getItem("selectVendro"),{});
        console.log(dt);
        setmodalT(false);
        alert("Liquidación guardada correctamente");
      }else{}
    } catch (err) {
      setmodalT(false);
        catchErrorModul("Corte de Caja => Guardar Liquidación", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response)); 
        alert("Error.," + JSON.stringify(err?.response?.data));
    }
  };

  //Cerrar Liquidación
  const handelCashclosing = async () => {
    const filterSaleType = dataSinFil.filter((d) => d.id_sale_type_fk === 1 || d.id_sale_type_fk === 2 || d.id_sale_type_fk === 3);
    const validtype=filterSaleType.filter((d)=>d.complete===false);
    if(validtype.length===0){
      setmodalT(true);
      setData([]);
      try {
        var rf_token= await refresh_token();
        if(rf_token === true){
          const dt= await close_cashclosing(sessionStorage.getItem("dateiSale"),sessionStorage.getItem("datefSale"),sessionStorage.getItem("routeSale"),{});
          console.log(dt);
          alert("Exito ventas cerradas");
          setmodalT(false);
          handleReconsulta(true);
        }else{}
      } catch (err) {
        setmodalT(false);
        catchErrorModul("Corte de Caja => Cerrar Liquidación", JSON.stringify({}), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        alert("Error.," + JSON.stringify(err?.response?.data));
      }

  }else{
    alert('No se puede cerrar la liquidación., Causa: hay ventas de tipo "PREVENTA, OBSEQUIO, VENTA DIRECTA", sin completar.')
  }
  
  };

  //Factura Venta
  const handel_generate_invoice = async (id_sale) => {
    try {
      var rf_token= await refresh_token();
      if(rf_token === true){
        const dt= await generate_invoice_cashclosing(id_sale,{});
        console.log(dt);
        alert("Exito venta facturada correctamente");
        handleReconsulta(true);
        setmodalT(false);
      }else{}
    } catch (err) {
      catchErrorModul("Corte de Caja", JSON.stringify({}), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      alert("Error.," + JSON.stringify(err?.response?.data));
      setmodalT(false);
    }
  };
  
  //Descargar PDF
  const handleDonwload  = async (typedocumentexmlorpdf, id_invoce, ticket) => {
    setmodalT(true);
    try {
      var rf_token= await refresh_token();
      if(rf_token === true){
        const data = await download_invoice(typedocumentexmlorpdf,null,id_invoce);
        var dt = data.data.file;
        var blob = base64toBlob(dt);
        const blobUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        var arr = ticket;
        downloadLink.download = arr + `.${typedocumentexmlorpdf}`;
        downloadLink.click();
        setmodalT(false);
      }else{}

    } catch (err) {
      setmodalT(false);
    }
  };

  //Cancelar Venta
  const handle_cancel_sale = async (id) => {
    setmodalT(true);
    try {
      var rf_token= await refresh_token();
      if(rf_token === true){
        await cancel_sale_cashclosing(id,sessionStorage.getItem("selectwarehouse"));
        handleReconsulta(true);
        setmodalT(false);
      }else{}
    } catch (err) {
      catchErrorModul("Corte de Caja => Cancelar Venta", JSON.stringify({id_warehouse_fk:id}), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      alert("Error.," +JSON.stringify(err?.response?.data));
      setmodalT(false);
    }
  };
  const handlechecked = (event) => {setfiltersColumsView(event.target.checked);};
  //Filtrar bonificaciones y devoluciones
  const handlecheckedbonusdev = (event) => {
    setfiltersbonusdevView(event.target.checked);
    if(event.target.checked===false){
      const datan=dataSinFil.filter((d)=>d.returns!==null);
      setData(datan);
    }else{
      setData(dataSinFil);
    }
  };


  

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: () => {
          return (
            <div>
              <span className="headerAction">.......</span>
              <img
                title="Recargar"
                src={RECARGA}
                onClick={()=>handleReconsulta(true)}
                alt="Recargar" 
              ></img>
            </div>
          );
        },
        Cell: ({ row }) => {
          const save_data=()=> {
            setticketS(row.original.returns);
            sessionStorage.setItem("TikectconsulReturn",JSON.stringify(row.original.returns));
          }
          return (
            <div>
              <a
                title="Consultar impuestos del concepto"
                {...row.getToggleRowExpandedProps()}
                style={{display: row.original.returns?.length > 0 ? "" : "none",}}
              >
                {row?.isExpanded ? (
                  <span title="Consultar detalles">🔼</span>
                ) : (
                  <span title="Consultar detalles" onClick={()=>save_data()}>
                    🔽
                  </span>
                )}
              </a>
              <img
                alt=""
                style={{
                  display:
                    row.original.generate_debt_pos === true 
                      ? row.original.folio_invoice === null 
                        ? row.original.saletype !== "NO VENTA" 
                          ? row.original.saletype !== "OTRO" 
                            ? row.original.saletype !== "PEDIDO_EMPLEADO"
                              ? row.original.saletype !== "CONGELADOR"
                                ? row.original.complete === true
                                  ? row.original.partner_type === "PROPIO"
                                    ? row.original.total !== row.original.returns?.map((sales) =>parseFloat(sales?.total)).reduce((p, c) => p + c, 0)
                                      ? ""
                                      : "none"
                                    : "none"
                                  : "none"
                                : "none"
                              : "none"
                            : "none"
                          : "none"
                        : "none"
                      : "none"
                }}
                src={INVOICE}
                className="withImg"
                title="Facturar"
                onClick={async () => {
                  if (
                    sessionStorage.getItem("ROLE_INVOICING") ==="ROLE_INVOICING") {
                    const result = await Confirm("¿Esta usted seguro de facturar esta venta?","Facturar Venta","Si","No");
                    if (result) {handel_generate_invoice(row.original.id);} else {}
                  } else {alert("No tienes permisos para facturar");}
                }}
              ></img>
              <img
                alt=""
                style={{
                  display:
                    row.original.folio_invoice === null
                      ? row.original.saletype !== "NO VENTA"
                        ? row.original.saletype !== "OTRO"
                          ? row.original.saletype !== "PEDIDO_EMPLEADO"
                            ? row.original.saletype !== "CONGELADOR"
                              ? row.original.complete === true
                                ? row.original.partner_type === "PROPIO"
                                  ? row.original.returns
                                      ?.map((sales) => parseFloat(sales?.total))
                                      .reduce((p, c) => p + c, 0) >=
                                    row.original.total
                                    ? ""
                                    : "none"
                                  : "none"
                                : "none"
                              : "none"
                            : "none"
                          : "none"
                        : "none"
                      : "none",
                }}
                src={CANCEL}
                className="withImg"
                title="Cancelada"
              ></img>

              <img
                alt=""
                style={{display: row.original.id_invoice_fk !== null ? "" : "none"}}
                src={PDF}
                title="Descargar PDF"
                onClick={async () => {
                  const result = await Confirm("¿Esta usted seguro de descargar esta factura?","Descargar Facrtura","Si","No");
                  if (result) {handleDonwload("pdf",row.original.id_invoice_fk,row.original.ticket);} else {}
                }}
              ></img>

              <img
                alt=""
                src={CONFIG}
                title="Configurar venta"
                onClick={async () => {
                  const result = await CustomDialog(
                    <PUT_CASHCLOSING downloadable={row.original.downloadable} special_folio_number={row.original.special_folio_number} no_invoice={row.original.no_invoice} never_invoice={row.original.never_invoice} paper={row.original.paper} id_sale={row.original.id}></PUT_CASHCLOSING>,
                    {
                      className: "cutBoxModal",
                      title: "Configurar venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>

              <img
              alt=""
                src={VIEW}
                title="Detalles de venta"
                onClick={async () => {
                  const result = await CustomDialog(
                    <CONSULT_DETAIL_SALE id_sale={row.original.id} typeSale={"SALE"}></CONSULT_DETAIL_SALE>,
                    {
                      className: "cutBoxModal2",
                      title: "Detalles de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  // eslint-disable-next-line no-lone-blocks
                  {
                    handleReconsulta(result);
                  }
                }}
              ></img>

              <img
                alt=""
                src={CANCELSALE}
                title="Cancelar Venta"
                className="imgcancelsale"
                onClick={async () => {
                  const result = await Confirm("¿Esta usted seguro de cancelar esta venta?","Cancelar venta","Si","No");
                  if (result) {handle_cancel_sale(row.original.id);} else {}
                }}
              ></img>
            </div>
          );
        },
      },

      {
        id: "tikect",
        show: viewTikect,
        Header: "Ticket",
        accessor: "ticket",
      },
      {
        id: "punto_de_venta",
        show: viewPV,
        Header: "Punto de venta",
        accessor: "name_pos",
      },
      {
        id: "socio_de_negocios",
        show: viewSN,
        Header: "Socio de Negocios",
        accessor: "name_business_partner",
        Filter: SelectColumnFilter,
      },
      {
        id: "fecha",
        show: viewF,
        Header: "Fecha",
        accessor: "date",
      },
      {
        id: "tipo",
        show: viewTp,
        Header: "Tipo",
        accessor: "saletype",
        Filter: SelectColumnFilter,
      },
      {
        id: "sub_total",
        show: viewSt,
        Header: "Sub Total",
        accessor: (d) => {
          return d.subtotal?.toFixed(2);
        },
      },
      {
        id: "descuentos",
        show: viewDesc,
        Header: "Descuentos",
        accessor: (d) => {
          return d.discount?.toFixed(2);
        },
      },
      {
        id: "impuestos",
        show: viewImp,
        Header: "Impuestos",
        accessor: (d) => {
          return d.tax?.toFixed(2);
        },
      },
      {
        id: "total",
        show: viewT,
        Header: "Total",
        accessor: (d) => {
          return d.total?.toFixed(2);
        },
      },

      {
        id: "adeudo",
        show: viewAd,
        Header: "Adeudo",
        accessor: (d) => {
          return d.debt?.toFixed(2);
        },
      },
      {
        id: "pagado",
        show: viewPgd,
        Header: "Pagado",
        accessor: (d) => {
          return d.payed?.toFixed(2);
        },
      },
      {
        id: "pago",
        show: viewPg,
        Header: "Pago",
        accessor: "payment_method",
      },
      {
        id: "credito",
        show: viewCd,
        Header: "Crédito",
        accessor: "pay_condition_pos",
      },

      {
        id: "vendedor",
        show: viewVd,
        Header: "Vendedor",
        accessor: "name_person_user",
      },
      {
        id: "completa",
        show: viewCp,
        Header: "Completa",
        Filter: SelectColumnFilter,
        accessor: (d) => {
          return d.complete === false ? "❌" : "✅";
        },
      },
      {
        id: "rfc",
        show: viewrfc,
        Header: "RFC",
        accessor: "rfc_business_partner",
      },
      {
        id: "factura",
        show: viewfc,
        Header: "Factura",
        accessor: "folio_invoice",
      },

      {
        id: "gen_deuda",
        show: viewGd,
        Header: "Gen. Deuda",
        accessor: (d) => {
          return d.generate_debt_pos === false ? "NO" : "SI";
        },
      },
      {
        id: "oportuno",
        show: viewOp,
        Header: "Oportuno",
        accessor: (d) => {
          return d.no_invoice === true ? "SI" : "NO";
        },
      },

      {
        id: "nunca facturar",
        show: viewNf,
        Header: "Nunca facturar",
        accessor: (d) => {
          return d.never_invoice === true ? "SI" : "NO";
        },
      },

      {
        id: "venta_cerrada",
        show: viewVc,
        Header: "Venta Cerrada",
        accessor: (d) => {
          return d.close === true ? "SI" : "NO";
        },
      },
      {
        Header: "TIpo de socio",
        accessor: "partner_type",
        Filter: SelectColumnFilter,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [viewTikect, viewPV, viewSN, viewF, viewTp, viewSt, viewDesc, viewImp, viewT, viewAd, viewPgd, viewPg, viewCd, viewVd, viewCp, viewfc, viewrfc, viewGd, viewOp, viewNf, viewVc, filterVPCo, filterVPCr, filterVSCo, filterVSCr,]
  );



  useEffect(() => {
    localStorage.setItem("viewTikect", viewTikect);
    localStorage.setItem("viewPV", viewPV);
    localStorage.setItem("viewSN", viewSN);
    localStorage.setItem("viewF", viewF);
    localStorage.setItem("viewTp", viewTp);
    localStorage.setItem("viewSt", viewSt);
    localStorage.setItem("viewDesc", viewDesc);
    localStorage.setItem("viewImp", viewImp);
    localStorage.setItem("viewT", viewT);
    localStorage.setItem("viewAd", viewAd);
    localStorage.setItem("viewPgd", viewPgd);
    localStorage.setItem("viewPg", viewPg);
    localStorage.setItem("viewCd", viewCd);
    localStorage.setItem("viewVd", viewVd);
    localStorage.setItem("viewCp", viewCp);
    localStorage.setItem("viewfc", viewfc);
    localStorage.setItem("viewrfc", viewrfc);
    localStorage.setItem("viewGd", viewGd);
    localStorage.setItem("viewOp", viewOp);
    localStorage.setItem("viewNf", viewNf);
    localStorage.setItem("viewVc", viewVc);
  }, [viewTikect, viewPV,viewSN,viewF,viewTp,viewSt,viewDesc,viewImp,viewT,viewAd,viewPgd,viewPg,viewCd,viewVd,viewCp,viewfc,viewrfc,viewGd,viewOp,viewNf,viewVc,filterVPCo,filterVPCr,filterVSCo,filterVSCr]);

  useEffect(() => {
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setviewTikect(viewTikect);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setticketS(ticketS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="">
      <div className="containeritems py-2">
        <nav aria-label="breadcrumb" className="navegacion">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a
                className="listBreadCrum"
                href="demo.herramientasinformaticas.com"
              >
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a className="listBreadCrum" href="/cashclosing">
                Corte de caja
              </a>
            </li>
            <li className="breadcrumb-item">Detalles de ventas</li>
          </ol>
        </nav>
      </div>

      <MODAL_TABLE open={true} message={menssage} modalGeneral={modalGeneral} modalT={modalT}></MODAL_TABLE>

      <div>
        <h1>Liquidación Ruta {sessionStorage.getItem("routeSale")}</h1>
      </div>
      <div className="TableReport TableGTotalCutBox">
        <div style={{ overflowX: "auto" }}>
         <SUMMARY_CASHCLOSING 
              modalT={modalT} //Activa Modal
              handleFIlterSalePrimaryC={handleFIlterSalePrimaryC}  //Filtra Ventas Primarias de Contado
              filterVPCo={filterVPCo} 
              vPContadoTotal={vPContadoTotal}
              vPSContadoDevolucion={vPSContadoDevolucion} 
              vPSContadoBonificacion={vPSContadoBonificacion} 
              vPContadoPagado={vPContadoPagado} 
              vPContadoAdeudo={vPContadoAdeudo}
              handleFIlterSalePrimaryCredit={handleFIlterSalePrimaryCredit} //Filtra Ventas Primarias de Crédito
              filterVPCr={filterVPCr} 
              vPCTotal={vPCTotal} 
              vPSDevolucion={vPSDevolucion} 
              vPSBonificacion={vPSBonificacion}
              vPCPagado={vPCPagado} 
              vPCAdeudo={vPCAdeudo} 
              handleFIlterSaleSecundaryC={handleFIlterSaleSecundaryC} //Filtra Ventas Secundarias de Contado
              filterVSCo={filterVSCo} 
              vSContadoTotal={vSContadoTotal} 
              vSSContadoDevolucion={vSSContadoDevolucion}
              vSSContadoBonificacion={vSSContadoBonificacion} 
              vSContadoPagado={vSContadoPagado} 
              vSContadoAdeudo={vSContadoAdeudo} 
              handleFIlterSaleSecundaryCredit={handleFIlterSaleSecundaryCredit}  //Filtra Ventas Secundarias de Crédito
              filterVSCr={filterVSCr} 
              vSCTotal={vSCTotal} 
              vSSDevolucion={vSSDevolucion} 
              vSSBonificacion={vSSBonificacion} 
              vSCPagado={vSCPagado} 
              vSCAdeudo={vSCAdeudo} 
              handleFIlterDebit={handleFIlterDebit}   //Filtra Ventas Obsequio
              filterVO={filterVO} 
              obTotal={obTotal} 
              obDevolucion={obDevolucion} 
              obBonificacion={obBonificacion} 
              obPagado={obPagado} 
              obAdeudo={obAdeudo}
        ></SUMMARY_CASHCLOSING> 
      
        </div>
      </div>

      <FILTER_CASHCLOSING 
          filtersColumsView={filtersColumsView} 
          viewTikect={viewTikect} 
          setviewTikect={setviewTikect} 
          viewPV={viewPV} 
          setviewPV={setviewPV} 
          viewSN={viewSN} 
          setviewSN={setviewSN} 
          viewF={viewF} 
          setviewF={setviewF} 
          viewTp={viewTp} 
          setviewTp={setviewTp} 
          viewSt={viewSt} 
          setviewSt={setviewSt}
          viewDesc={viewDesc} 
          setviewDesc={setviewDesc} 
          viewImp={viewImp} 
          setviewImp={setviewImp} 
          viewT={viewT} 
          setviewT={setviewT} 
          viewAd={viewAd} 
          setviewAd={setviewAd}
          viewPgd={viewPgd} 
          setviewPgd={setviewPgd} 
          viewPg={viewPg} 
          setviewPg={setviewPg} 
          viewCd={viewCd} 
          setviewCd={setviewCd} 
          viewVd={viewVd} 
          setviewVd={setviewVd}
          viewCp={viewCp} 
          setviewCp={setviewCp} 
          viewfc={viewfc} 
          setviewfc={setviewfc} 
          viewGd={viewGd} 
          setviewGd={setviewGd} 
          viewNf={viewNf} 
          setviewNf={setviewNf}
          viewVc={viewVc} 
          setviewVc={setviewVc} 
          viewOp={viewOp} 
          setviewOp={setviewOp} 
          viewrfc={viewrfc} 
          setviewrfc={setviewrfc}
        ></FILTER_CASHCLOSING>

      <div className="containerCutBox">
        <div className="expen-1 color-1">
          <h2>Ventas</h2>
        </div>

        <div className="expen-2 color-1">
          <button
            style={{ width: "100%",fontSize:"14px"}}
            title="GUARDAR LIQUIDACION"
            className="button"
            onClick={async () => {
              if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                const result = await Confirm("¿Esta usted seguro de cerrar la liquidación?","Guardar Liquidación","Si","No");
                if (result) {handel_save_cashclosing();} else {}
              } else {alert("No tienes permisos para guardar liquidación");}
            }}
          >
            GUARDAR LIQUIDACIÓN
          </button>
        </div>
        <div className="expen-3 color-1">
          <button
            style={{ width: "100%",fontSize:"14px"}}
            title="CERRAR LIQUIDACION"
            className="button"
            onClick={async () => {
              if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                const result = await Confirm("¿Esta usted seguro de cerrar la liquidación888?","Cerrar liquidación","Si","No");
                if (result) {handelCashclosing();} else {}
              } else {alert("No tienes permisos para cerrar liquidación");}
            }}
          >
            CERRAR LIQUIDACIÓN
          </button>
        </div>

        <div className="expen-4 color-1">
          <img
            alt=""
            title="Facturar Todo Publico En General"
            className="iconPublicFacture iconPublic"
            src={PUBLIC}
            onClick={async () => {
              if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                const result = await Confirm("¿Esta usted seguro de facturar todo los de publico en general?","Facturar publico en general","Si","No");
                if (result) {handel_toinvoce();} else {}
              } else {alert("No tienes permisos para facturar");}
            }}
          ></img>
        </div>

        <div className="expen-5 color-1">
        <img
            alt=""
            title="Generar Carta Porte"
            className="iconPublicFacture iconPublic"
            src={CP}
            onClick={async () => {
              if (sessionStorage.getItem("ROLE_INVOICING") === "ROLE_INVOICING") {
                const result = await Confirm("¿Esta usted seguro de generar la carta porte?","Generar Carta Porte","Si","No");
                if (result) {handel_toinvoce_fromdocument();} else {}
              } else {alert("No tienes permisos para facturar");}
            }}
          ></img>        </div>
       
        <div className="expen-7 color-1">
          <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} onChange={handlecheckedbonusdev} checked={filtersbonusdevView}/>} label={filtersbonusdevView === true ? "Bonificación/Devolución" : "Ocultar"}/>
        </div>  
        <div className="expen-8 color-1">
          <FormControlLabel control={<MaterialUISwitch sx={{ m: 1 }} onChange={handlechecked} checked={filtersColumsView}/>} label={filtersColumsView === true ? "Activar Filtros" : "Desactivar Filtros"}/>
        </div>
      </div>

      <div className="TableReport TableSaleCutBox">
        <div style={{ overflowX: "auto" }}>
          <TableContainer  
            exportEx={true}
            columns={columns}
            data={data}
            Gmodal={modalT}
            Gmodal2={modalT}
            pgd={viewPgd}
            imp={viewImp}
            desc={viewDesc}
            totalv={viewT}
            ad={viewAd}
            st={viewSt}
            TableStickyHeader={true}
            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <CONSULT_RETURN_CASHCLOSING verData={true} ticket={ticketS} handleReconsulta={handleReconsulta} />
                </div>
              );
            }}
          />
        </div>
      </div>
      <CONSULT_CASHCLOSING_WAREHOUSE></CONSULT_CASHCLOSING_WAREHOUSE> 
    </div>
  );
};

export default Consult_cut_box_sale;



