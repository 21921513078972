//PAQUETERIAS
import { useEffect, useState, useMemo, useRef} from "react";
import { StickyTable } from "react-sticky-table";
import { CustomDialog} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
import "./RecoveryPartner.css"
//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import RecoveryPoS from "./RecoveryPoS";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_pos_recover } from "../../services/businesspartners/businesspartners";
//IMAGENES/ICONOS
import RECOVERY from "../../imgComponents/reconsultaTable.png"
import MODAL_TABLE from "../../main_components/modal/modal_Table";


const TableRecoverPartner = () => {

const [data, setData] = useState([]);
const [open, setopen]=useState(false);  
var entrada=0;
const notificationSystemRef = useRef();
const [modalT, setmodalT] = useState(false);
const [menssage, setmenssage] = useState("");
const [modalGeneral, setmodalGeneral] = useState(false);

useEffect(()=>{
  handleSubmit();
},[]);

function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}


const handleSubmit =async () => {

  refresh_token ();
  
  if(entrada===0){
    setmenssage("Buscando Socios de Negocios...");
    entrada=entrada+1;
    setmodalGeneral(true);
    setmodalT(true);
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);
  
  try {
    var d = await get_pos_recover();
    setData(d === null ? [] : d.data.data);
  } catch (error) {
    setData([]);
    setopen(false)
    setmodalGeneral(false);
    setmodalT(false);
  } finally {
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);
  }
};

const columns = useMemo(
  () => [
    { 
    Header:"Acciones",

    Cell: ({ row }) => {
        return (
          <div>
            <img
              src={RECOVERY}
                     onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                   <RecoveryPoS alertas={alertas} handleSubmit={handleSubmit} row={row} reconsultar={reconsultar}></RecoveryPoS>
                 </div>,
               {
                 className: "modalpuntosventa",
                 title:"Recuperar Socio de Negocios",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           );
         }
        }
              className="centerText, cancel"
              title="Recuperar Socio de Negocios"
              alt=""
            ></img>   
          </div>
        );
      },
    },
     {
       Header: "Código Punto Venta",
       accessor: (d)=>{
        return d.point_of_sale.code;
       },
     },
     {
      Header: "Punto de Venta",
      accessor: (d)=>{
        return d.point_of_sale.name;
      }
     },
     {
      Header:"Código Socio",
      accessor: (d)=>{
        return d.business_partner.code;
      }
     },
    {
       Header: "Socio de Negocios",
       accessor: (d)=>{
        return d.business_partner.business_partner;
       },
    },
    {
      Header: "RFC",
      accessor: (d)=>{
        return d.business_partner.rfc;
      },
    },
    ],
    []
);
  return (
    <>

<div className="containeritems py-2">
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

<MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
  ></MODAL_TABLE>

<nav aria-label="breadcrumb" className="navegacion">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
            <a href="/http://localhost:3000/">Home</a>
        </li>
        <li className="breadcrumb-item">
          Recuperar Socio de Negocios
        </li>
      
      </ol>
        
    </nav>

</div>

      <div className="Tablerecoveryb">
          <div className="">
            <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>
           
          <>
          <br/>
          <div className="titletable">
          <h2>RECUPERAR SOCIO DE NEGOCIO</h2>
          </div>
             <StickyTable>
              <TableContainer
                paginado={"Socios de Negocios"}
                consultas={"Socios de Negocios consultados"}
                nametable={"Recuperar Socio de Negocios"}
                exportar={true}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                Gmodal={open}
              />
            </StickyTable>
         <div>
        </div>
          </>          
          </div>
        </div>
      </div>
    </>
  );
}

export default TableRecoverPartner;
