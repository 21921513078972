import { Component } from "react";
import CONSULT_CASHCLOSING from "../../components_cashclosing/cashclosing/consult_cashclosing";

export class SetBanc extends Component {
  

  render() {
    return (
      <div className="tc">
        <CONSULT_CASHCLOSING />
      </div>
    );
  }
}

export default SetBanc;
