//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable } from "react-sticky-table";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
//ESTILOS
import "../../components_cfdi/estilo.css";
import '../../componentSociosNegocios/componentSectors/bonuses.css';
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import "./routes.css";
import TextField from "@mui/material/TextField";
import { Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Brush, ReferenceLine, Line, ComposedChart } from 'recharts';
import { Checkbox } from "@material-ui/core";
import DetailSales from "./DetaIlSales";
import { CustomDialog } from "react-st-modal";
import TableGoal from "./componentgoals/TableGoal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_goals, get_sales_by_route_all, get_sales_by_route_ind } from "../../services/reports/reports";
import { get_route_list } from "../../services/businesspartners/businesspartners";
import TableContainer from "../../main_components/Table/TableContainer";


const TableRouteSalesReport = () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [datagrap, setdatagrap]=useState([]);
  const [datatotales, setdatatotales]=useState([]);
  const [datameta, setdatameta]=useState([]);

  const [open, setopen]=useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);  

  const [nameroute, setnameroute]=useState("vacio");
  const [fi, setfi]=useState(null);
  const [ff, setff]=useState(null);
  const [routedata, setroutedata]=useState(null);
  const [totales, settotales]=useState(null);
  const [counttendencia, setcounttendencia]=useState(0);

  const [my, setmy]=useState(null);

  const [actualizacionmeta, setactualizacionmeta]=useState(false);
  const [banderafiltro, setbanderafiltro]=useState(true);
  const [nuevafecha, setnuevafecha]=useState(false);

function alertas(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const handleSubmit = () => {
 
console.log(nameroute);
console.log(ff);

 if(fi===null || ff===null || fi==='' || ff===''){
  alertas("Falta asignar Rango de Fechas", false);
  return null
 }
 if(nameroute==="vacio"){
   setbanderafiltro(false);
   routeall(); 
 }else{
  setbanderafiltro(true);
  routeind();
 }
};


const routeind = async () => {

  setfiltro1(false);
  setfiltro2(false);
  setfiltro3(false);
  setfiltro4(false);
  setfiltro5(false);
  setfiltro6(false);
  setfiltro7(true);
  setfiltrometas(false);
  
  await refresh_token ();
  setmodalT(true);
  setopen(true);
  setData([]);
  try {
    var d = await get_sales_by_route_ind(nameroute, fi, ff);

    console.log(d.data.detalles_fletes);
    setData(d === null ? [] : d.data.detalles_fletes);    
    setdatagrap(d===null?[]:d.data.detalles_fletes);
    handletotales(d === null ? [] : d.data.detalles_fletes);
    //  settotales(d === null ? [] : d.data.detalles_fletes);
  } catch (error) {
    console.log(error);
    setData([]);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);
  } finally {
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);
  }
}
const routeall= async ()=>{
  
  var ttt = await tendencia(fi);
  var tt2 = await countDaysWithoutSundays(fi, ff);
  
  const formattedDate = ff.replace(/-/g, '/');
  const date = new Date(formattedDate);
  var año = date.getFullYear();
  var mes = date.getMonth()+1;
  setmy(año+"-"+(mes<10?"0"+mes:mes));
 
  setfiltro1(false);
  setfiltro2(false);
  setfiltro3(false);
  setfiltro4(false);
  setfiltro5(false);
  setfiltro6(false);
  setfiltro7(true);

  if(actualizacionmeta===true){

  }else{
    setmodalT(true);  
    setfiltrometas(false);
  }    
  await refresh_token ();
  setopen(true);
  setData([]);  

  try {
    var d = await get_sales_by_route_all(fi, ff);
    console.log(d.data);
    setData(d === null ? [] : d.data.colaboradores);
    setdatagrap(d===null ? []:d.data.colaboradores);
    //analisismetas(d === null ? [] : d.data);
    handletotales(d === null ? [] : d.data.colaboradores);
  } catch (error) {
    console.log(error);
    setData([]);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);  
  } finally {
    setactualizacionmeta(false);
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);  
  }
}

const tendencia = (fi)=> {
  
  const formattedDate = fi.replace(/-/g, '/');
  const date = new Date(formattedDate);
  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  let count = 0;
  for (let day = 1; day <= daysInMonth; day++) {
    const currentDate = new Date(year, month, day);
    if (currentDate.getDay() !== 0) {
      count++;
    }
  }
  return count; 
  //setcounttendencia(count);
}
function countDaysWithoutSundays(startDateStr, endDateStr) {
  const fi = startDateStr.replace(/-/g, '/');
  const ff = endDateStr.replace(/-/g, '/');
  const startDate = new Date(fi);
  const endDate = new Date(ff);
  let numDays = 0;
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    if (d.getDay() !== 0) { // 0 = Sunday
      numDays++;
    }
  }
  return numDays;
}

const handlemetas = async (year, month)=>{
  try {
    var d = await get_goals(year, month);
      return d===null?[]:d.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}


const [maxgrapmeta, setmaxgrapmeta]=useState(0.0);
const [maxgraptotal, setmaxgraptotal]=useState(0.0);
const [maxtendencia, setmaxtendencia]=useState(0.0);

const analisismetas=(d)=>{
  const result = d.colaboradores.map(item => {
   const metaItem = d.metas.find(meta => meta.id_colaborador === item.id);
    return metaItem ? { ...item, goal: metaItem.goal} : { ...item};
  });
  console.log(result);
  setdatagrap(result);
  const filtro1 = result.filter(item => !isNaN(item.goal));
  setmaxgrapmeta(Math.max(...filtro1.map((item) => item.goal)));  
  const filtro2 = result.filter(item => !isNaN(item.total));
  setmaxgraptotal(Math.max(...filtro2.map((item) => item.total)));
  const filtro3 = result.filter(item=> !isNaN(item.tendencia));
  setmaxtendencia(Math.max(...filtro3.map((item)=>item.tendencia)));
}



const [tabla, settabla]=useState(false);
const [grafica, setgrafica]=useState(true);

const handletabla = ()=>{
  settabla(true);
  setgrafica(false);
}
const handlegrafica = () =>{
  settabla(false);
  setgrafica(true);
}

const crudmetas=()=>{

  const result = CustomDialog(               
    <div>
      <TableGoal my={my} setdatameta={setdatameta} setfiltrometas={setfiltrometas} setactualizacionmeta={setactualizacionmeta}></TableGoal>
    </div>,
  {
    className: "modalTItle",
    title:"Metas",
    showCloseIcon: true,
    isCanClose:false,
  })
}


const handlefiltrometas= async ()=>{

  if(actualizacionmeta===true){
    routeall(); 
    setmodalT(true);
    setfiltrometas(!filtrometas);
    setTimeout(() => {
      setmodalT(false);       
    }, 2000);

  }else if(nuevafecha===true){

    const partes = my.split("-"); // Divide la cadena en dos partes: año y mes
    var year = parseInt(partes[0]); // Convierte el año en un número entero
    var month = parseInt(partes[1]); // Convierte el mes en un número entero
    var datam = await handlemetas(year, month);
    var ttt = await tendencia(fi);
    var tt2 = await countDaysWithoutSundays(fi, ff);  
    analisismetas(datam, data, ttt, tt2); 
    setmodalT(true);
    setTimeout(() => {
      setnuevafecha(false);
      setfiltrometas(true);
      setmodalT(false);    
    }, 2000);
  }else {
    console.log("entra opcion else");
    setfiltrometas(!filtrometas);
  }
}


const [filtro1, setfiltro1]=useState(false);
const [filtro2, setfiltro2]=useState(false);
const [filtro3, setfiltro3]=useState(false);
const [filtro4, setfiltro4]=useState(false);
const [filtro5, setfiltro5]=useState(false);
const [filtro6, setfiltro6]=useState(false);
const [filtro7, setfiltro7]=useState(true);
const [filtrometas, setfiltrometas]=useState(false);
const [filtrotendencia, setfiltrotendencia]=useState(false);


const handletotales=(datanew)=>{
  
  console.log(datanew);

  const result = datanew.reduce((acc, item) =>{
    acc.discount +=item.discount;

    acc.nofletes += item.nofletes;
    acc.drecorrida += item.drecorrida;
    acc.ganancia += item.ganancia;
    acc.total += item.total;
    acc.costo += item.costo;
    return acc;
  }, {
    nofletes:0, 
    drecorrida:0,
    ganancia:0,
    total:0,
    costo:0,
  });
  setdatatotales(result);
}
const handlefiltro1=(datanew)=>{
  var newArray = [];    
    const groupedByDate = datanew.reduce((acc, obj) => {
      const date = obj.fecha.split(" ")[0];
      if (!acc[date]) {
        acc[date] = {
          date: date,
          costo:0,
          drecorrida:0,
          ganancia:0,
          total:0,
        }
      }
      acc[date].costo += obj.costo;
      acc[date].drecorrida += obj.drecorrida;
      acc[date].ganancia += obj.ganancia;
      acc[date].total+= obj.total;
      return acc;
    }, {});
    
    for (let date in groupedByDate) {
    newArray.push({
      date: groupedByDate[date].date,
      costo: (groupedByDate[date].costo).toFixed(2),
      drecorrida: (groupedByDate[date].drecorrida).toFixed(2),
      ganancia: (groupedByDate[date].ganancia).toFixed(2),
      total: (groupedByDate[date].total).toFixed(2),
    });
  }
  setdatagrap(newArray);
  console.log(newArray);
}

const columns = useMemo(
  () => [
    {
      Header: "Fecha",
      accessor:"fecha",
    },
    {
      Header:"Socio",
      accessor:"socio"
    },
    {
      Header:"Latitud",
      accessor: "lat"
    },
    {
      Header:"Longitud",
      accessor:"lng"
    },
    {
      Header:"Distancia recorrida",
      accessor:"drecorrida"
    },
    {
      Header:"Costo",
      accessor:(d) => {
        return d.costo
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.costo)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.costo);
      }, 
    },
    {
      Header:"Ganancia",
      accessor:(d) => {
        return d.ganancia
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.ganancia)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.ganancia);
      }, 
    },
    {
      Header:"Total",
      accessor:(d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      }, 
    },
    ],
    []
);
const columns2 = useMemo(
  () => [
    {
      Header:"Colaborador",
      accessor:"nombre_colaborador"
    },
    {
      Header:"Número de Placas",
      accessor:"num_placas"
    },
    {
      Header:"Número de Fletes",
      accessor:"nofletes"
    },
    {
      Header:"Distancia Recorrida",
      accessor:"drecorrida"
    },
    {
      Header:"Costo",
      accessor:(d) => {
        return d.costo
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.costo)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.costo);
      }, 
    },
    {
      Header:"Ganancia",
      accessor:(d) => {
        return d.ganancia
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.ganancia)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.ganancia);
      }, 
    },
    {
      Header:"Total",
      accessor:(d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      }, 
    },
    
    ],
    []
);


return (
    <>
<div className="TableReportSaleRoute">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  <MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
    <>

    <div className="titletable">
          <h1>Reporte Ventas por Flete</h1>
    </div>
    <br></br>
    <div className="gridfiltros">
      <div className="fil1">
        <input
          type="text"
          className="fi"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha inicial"
        onChange={(event) => setfi(event.target.value)}
        ></input>
      </div>
      <div className="fil2">
        <input
          type="text"
          className="ff"
          onFocus={(e) => {
            e.currentTarget.type = "date";
            e.currentTarget.focus();
          }}
          placeholder="Fecha Final"
          onChange={(event) => setff(event.target.value)}
        ></input>
      </div>
      <div className="fil3">
      <TextField
          select
          required
          label="Colaborador"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnameroute(event.target.value)}
      >
         <option key={0} value={"vacio"} selected>
              {"Todos los colaboradores"}
          </option> 
         <option key={1} value={1}>
              {"Colaborador A"}
          </option> 
          <option key={2} value={2}>
              {"Colaborador B"}
          </option>
      </TextField>
      </div>
      <div className="fil4">
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>
        <button className="button" id="buttonpackageb" onClick={handletabla} type="button">
            Tabla
        </button>
        <button className="button" id="buttonpackageb" onClick={handlegrafica} type="button">
            Gráfica
        </button>
        {/* <button className="button" id="buttonpackageb" onClick={crudmetas} type="button">
            Metas
        </button> */}
      </div>
    </div>
      <div  className={banderafiltro===true?"tableg":"tableg2"} style={tabla===true?{"display":"block"}:{"display":"none"}}>            
        <StickyTable>
            <TableContainer
              paginado={"Ventas"}
              consultas={"Ventas Consultadas"}
              exportar={true}
              columns={banderafiltro===true?columns:columns2}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              banderafiltro={banderafiltro}
             
            />
          </StickyTable>
      </div>
      <div className="graphict" style={grafica===true?{"display":"block"}:{"display":"none"}}>

      <div className="filtrosgraphic">
        {
          banderafiltro===false?
          <div className="fg1">
          <label className="labelcheck">Número de Fletes
          </label>
            <Checkbox
              checked={filtro1}
              onChange={()=>setfiltro1(!filtro1)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
             <p>
              {
                datatotales?.nofletes>0? (datatotales?.nofletes)?.toFixed(2):0.0
              }
            </p>
        </div>:<> </>
        }
      
        <div className="fg2">
          <label className="labelcheck">Distancia Recorrida
          </label>
            <Checkbox
              checked={filtro2}
              onChange={()=>setfiltro2(!filtro2)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
             <p>
              {datatotales?.drecorrida>0? (datatotales?.drecorrida)?.toFixed(2):0.0} Km
            </p>
        </div>
        <div className="fg3">
          <label className="labelcheck">Costo
          </label>
            <Checkbox
              checked={filtro3}
              onChange={()=>setfiltro3(!filtro3)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.costo>0? (datatotales?.costo)?.toFixed(2):0.0))
              }
            </p>
        </div>
        <div className="fg4">
          <label className="labelcheck">Ganancia
          </label>
            <Checkbox
              checked={filtro4}
              onChange={()=>setfiltro4(!filtro4)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.ganancia>0? (datatotales?.ganancia)?.toFixed(2):0.0))
              }
            </p>
        </div>
        <div className="fg5">
          <label className="labelcheck">Total
          </label>
            <Checkbox
              checked={filtro5}
              onChange={()=>setfiltro5(!filtro5)}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <p>{Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
              }).format((datatotales?.total>0? (datatotales?.total)?.toFixed(2):0.0))
              }
            </p>
        </div>
        
       
        {/* {
        banderafiltro===false?
        <div className="fg6">
          <label className="labelcheck">Metas 
          </label>
            <Checkbox
              checked={filtrometas}
              onChange={handlefiltrometas}
              inputProps={{"aria-label": "primary checkbox"}}
              style={{"padding":"10px", "transform":"scale(1.7)"}}
            />
            <div className="check-input-meta">
          </div>    
        </div>:<></>
        }       */}
        <div className="fg8">
        </div>
      </div>

      <ResponsiveContainer width="95%" maxHeight={450} minHeight={500}>
        <ComposedChart
        data={datagrap}
        margin={{
          top: 20,
          right: 50,
          left: 50,
          bottom: 10
        }}
      > 
        <CartesianGrid strokeDasharray="3 3" />
        {
          banderafiltro===true?
          <>
            <XAxis dataKey="fecha"> 
              <Label value="fecha" offset={-10} position="insideBottom"/>
            </XAxis>
            <YAxis dataKey="total" label={{value:"Total $", angle:90}} 
              tickFormatter={(value) => `$${value.toLocaleString()}`}
              domain={[0, (Math.max(...datagrap?.map(item=>(item.total))))]}     
            />
            <Brush dataKey="date" height={30} stroke="#8884d8" />  
            <Tooltip
             formatter={(value) => `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
            />
          </>
          :
          <>
          <XAxis dataKey="nombre_colaborador"> 
            <Label value="route" offset={-10} position="insideBottom"/>
          </XAxis>
            <YAxis dataKey="total" label={{value:"Total $", angle:90}} 
             tickFormatter={(value) => `$${value.toLocaleString()}`}
             domain={
              filtrometas===true && filtrotendencia===true?(maxgrapmeta>maxtendencia)?[0,maxgrapmeta]:[0,maxtendencia]:
              filtrometas===true?[0,maxgrapmeta]:filtrotendencia===true?[0,maxtendencia]:[0,maxgraptotal]
            }
            />
            <Brush dataKey="route" height={30} stroke="#8884d8" />  
            <Tooltip
             // formatter={(value) => `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}`}
             formatter={(value, name, props) => {
              if (name === 'Meta' && props.payload.porcentaje !== undefined) {
                const formattedValue = value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 }); // formatear el valor a formato monetario
                const porcentaje = props.payload.porcentaje.toFixed(2); // obtener el valor de porcentaje y formatearlo a 2 decimales
                return `$${formattedValue} - Porcentaje Avance: ${porcentaje}%`; // retornar el texto personalizado
              }
              if(name==="Número de Fletes" || name==="Distancia Recorrida"){
                return value;
              }else{
                return  `$${value.toLocaleString('es-MX', { useGrouping: true, minimumFractionDigits: 0 })}` // utilizar el formatter por defecto para las demás barras
              }
            }}
            />
          </>
          
        }
       
        <Legend verticalAlign="top" height={50}/>
        <ReferenceLine y={0} stroke="#000" /> 
               
        {
          filtro1===true?<Bar dataKey="nofletes" stackId="a" name="Número de Fletes" fill="orange"/>:""
        }
        {
          filtro2===true?<Bar dataKey="drecorrida" stackId="b" name="Distancia Recorrida" fill="red"/>:""
        }
        {
          filtro3===true?<Bar dataKey="costo" stackId="e" name="Costo" fill="#7121AA"/>:""
        } 
        {
          filtro4===true?<Bar dataKey="ganancia" stackId="g" name="Ganancia" fill="#9C4701"/>:"" 
        }
        {
          filtro5===true?<Bar dataKey="total" stackId="h" name="Total" fill="#00CFC3"/>:"" 
        }
        {
          filtrometas===true? <Line type="monotone" name="Meta" dataKey="goal" stroke="red" strokeWidth={3}/>:<></>
        } 
      </ComposedChart>
      </ResponsiveContainer>
      </div>
      </>           
    </div>
   </div>
    </>
  );
}

export default TableRouteSalesReport;
