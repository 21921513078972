//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import Put_policy from "./Put_policy";
import SubTable from "../expense_report/Get_datail";
import Put_expense_extranjero from "../expense_extranjero/Put_Expense";
import TableDetailGrupExpensePolicyDetail from "./Consult_Detail_Grup_Policy_Detail";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../expense.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import ADD from "../../imgComponents/add.png";
import PDF from "../../imgComponents/pdf.png";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableDetailGrupExpensePolicy = ({idGrupExpense}) => {

  var date = new Date();

  var dia = `${(date.getDate())}`.padStart(2,'0');
  var mes = `${(date.getMonth()+1)}`.padStart(2,'0');
  var año = date.getFullYear();
  var mesantes = Number(mes)-1;
  if(mesantes==0){
    mesantes=`${(12)}`.padStart(2,'0');;
  }else{
    mesantes=`${(mesantes)}`.padStart(2,'0');;
  }
  const recuperarPPD= `${año}-${mes}-${dia}`
  const recuperarPPDantes= `${año}-${mesantes}-${dia}`

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [estadoAc, setestadoAc] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [idPolicy, setidPolicy] = useState("");
  const [codModificacble, setcodModificacble] = useState("");

  const [DetaiPlolicy, setDetaiPlolicy] = useState("none");
  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const handleSubmit = (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando grupo de gastos...");
    console.log(fi);
    console.log(ff);

    axios
      .get(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${sessionStorage.getItem("IdGrupExpense")}/policy`, 
      {
      
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
       
      })
      .then((response) => {
        const data =response?.data;      
        setData(data);
        setidPolicy(data[0].id);
        setcodModificacble(data[0]?.modificable);

        console.log(data[0].id);
        setDetaiPlolicy("");
        setOpen(false);
        setMenssage("");
        setestadoAc("ocupado")
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setOpen(false);
        setDetaiPlolicy("none")
        setMenssage("");
        setestadoAc("vacio");
      });
  };

  
  const handleReconsulta = (reconsultar)=>{

    if(reconsultar===true){
      handleSubmit();
    }
    else{
      console.log("no se reconsulto");
    }

  }
  useEffect(() => {
    handleSubmit();
  }, []);

 

  


  const columns = useMemo(
    
    () => [


      {
        id:"CrearPoliza",
        Header: ({valor, row}) => {


          useEffect(() => {
            setestadoAc(estadoAc);
          }, []);

          const handleSubmit2 = (idPolicy) => {
            var date = {};
        
            console.log(date);
            setMenssage("Creando póliza...");

             setOpen(true);
             let axiosConfig = {
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
             };
             axios
               .post(
                 `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${sessionStorage.getItem("IdGrupExpense")}/policy`,
                 date,
                 axiosConfig
               )
               .then((res) => {
                 setOpen(false);
                 alert("Poliza creado correctamente");
                 window.location="/expensesGrupDetail";
                })
               .catch((err) => {
                 setOpen(false);
                 var error=err?.response?.data?.errorMessage;
                 alert("Error.," + error);
               });
            
        
          };

            return(
              <img
                    title="Crear Poliza"
                    src={CREATE}
                    onClick={handleSubmit2}
                  ></img>
                );
         
        },
        Cell: ({ valor, row }) => {
          
          function Delete(result) {
            setMenssage("Eliminando Poliza...")
            setOpen(true);
            axios
              .delete(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${sessionStorage.getItem("IdGrupExpense")}/policy/${row.original.id}`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                alert("Poliza eliminada correctamente")
                setOpen(false);
                setMenssage("");
                setTimeout(handleReconsulta(result), 3000);
                
              })
              .catch((err) => {
                console.log("error", err);
                console.log("error", err?.response);
                var errorM=err?.response?.data?.errorMessage;
                setOpen(false);
                setMenssage("")
                console.log()
                alert("Error.,"+errorM); 
              });


          }


          
          return (
            <div>
              <img
                  title="Inyectar"
                  src={ADD}
                  onClick={async () => {
                  
                    const result = await CustomDialog(
                      
                        <Put_policy 
                        codCocept={row.original.concept}
                        codType={row.original.type}
                        codId={row.original.id}
                        codAction={"INJECTION"}
                        ></Put_policy>,
                      {
                        className: "modalTItle",
                        title:
                          "Inyectar poliza",
                        showCloseIcon: true,
                        isCanClose:false,
                      }
                    );
                    {handleReconsulta(result)}
                  
                }}
                ></img>
                <img
                  title="Editar"
                  src={SAVE}
                  onClick={async () => {
                  
                    const result = await CustomDialog(
                      
                        <Put_policy 
                        codCocept={row.original.concept}
                        codType={row.original.type}
                        codId={row.original.id}
                        codAction={"UPDATE"}

                        ></Put_policy>,
                      {
                        className: "modalTItle",
                        title:
                          "Editar poliza",
                        showCloseIcon: true,
                        isCanClose:false,
                      }
                    );
                    {handleReconsulta(result)}
                  
                }}
                ></img>


              <img
                title="Eliminar Poliza"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar esta poliza?",
                    "Eliminar Poliza",
                    "Si",
                    "No"
                  );
                  if (result) {
                    Delete(result);
                  } else {
                  }
                }}
              ></img>

            </div>
          );
        },
      },
     
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Fecha",
        accessor: "export_date",
      },
      {
        Header: "Estado",
        accessor: "status",
      },
      {
        Header: "Modificable",
        accessor: (d) => {
          return d.modificable === true ? "✅" : "❌";
        },
      }
	
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style2} >
              <div className="centermodaldescarga">
                <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                  <img src={Spinner}></img>
                </Typography>
                <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                  {menssage}
                </Typography>
              </div>
            </Box>
        </Modal>
        </div>

      <div><h1>CABECERA DE LA POLIZA</h1></div>
      <br></br>
        <div className="TableReport ExpenseNoDeducible">
        <div style={{overflowX:"auto"}}>
          <TableContainer
            visible={true}
            exportEx={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable verData={true}/>
               </div>
              );
            }}
          />
        </div>
      </div>
      <div style={{display:DetaiPlolicy}}>
      <TableDetailGrupExpensePolicyDetail idGrupExpense={idPolicy} codModificable={codModificacble}></TableDetailGrupExpensePolicyDetail>

      </div>
    </div>
  );
};

export default TableDetailGrupExpensePolicy;
