import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Agrupar from '../../imgComponents/agrupar.png';
import "../expense.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});



const Set_Grup_Expense = ({grupExpense}) => {
  const [open, setOpen] = useState(false);
  const dialog = useDialog();

  const handleSubmit = (event) => {
    event?.preventDefault();
    var data = {
      expense: grupExpense

      };

    console.log(data);

     setOpen(true);
     let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
     };
     axios
       .post(
         `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/group`,
         data,
         axiosConfig
       )
       .then((res) => {
         setOpen(false);
         dialog.close(true);
         window.location="/expensesReport";

        })
       .catch((err) => {
         setOpen(false);
         console.log(err);
         console.log(err?.response);
         console.log(err?.response?.data);
         dialog.close();
         var error=err?.response?.data?.errorMessage;
         alert("Error.," + error);
       });

  };

 
  useEffect(() => {
    handleSubmit();
  }, []);
  


  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Agrupando gastos..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        
      </div>
    </div>
  );
};

export default Set_Grup_Expense;
