//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import { StickyTable} from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import AlertResponse from "../../main_components/alerts/alertResponse";
import CreateSector from "./CreateSector";
import EditSector from "./EditSector";
import { detele_sector, get_sectors } from "../../services/businesspartners/businesspartners";
import { SelectColumnFilter } from "../../components_cfdi/filters";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./bonuses.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import Details from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";
import MODAL_TABLE from "../../main_components/modal/modal_Table";

const TableSectors = () => {

  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var entrada=0;
  const notificationSystemRef = useRef();  
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);


  useEffect(()=>{
    handleSubmit();
  },[]);



  function alertas(msj,status){

    if(status===true){
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={true}></AlertResponse>,
        level: 'success',
        position: 'br', 
        autoDismiss: 10, 
      });  
    }else{
      notificationSystemRef.current.addNotification({
        message:<AlertResponse msj={msj} view={false}></AlertResponse>,
        level: 'error',
        position: 'br', 
        autoDismiss: 60, 
      });
    }  
  }
  const reconsultar=(n)=>{
    if(n===true){
      setmodalT(true);  
    }else{
      setmodalT(false);
    } 
  }
  

//proceso de consultar todos los sectores
const handleSubmit = async() => {

  refresh_token ();
  if(entrada===0){
    setmenssage("Consultando Sectores...")
   entrada=entrada+1;
   setmodalGeneral(true);
   setmodalT(true);
  }else{
    setmodalT(true);
  }
  setData([]);
  setopen(true);

  try {
    var d = await get_sectors();
    setData(d === null ? [] : d.data.sectors);
  } catch (error) {
    setData([]);
    setopen(false)
    setmodalGeneral(false);
    setmodalT(false);
  } finally {
    setopen(false);
    setmodalGeneral(false);
    setmodalT(false);
  }
};
const columns = useMemo(
  () => [    
    {
      Header:()=>(
        <div>
          <img
            id="crearsector"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
               <CreateSector handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateSector>
             </div>,
           {
             className: "modalTItle",
             title:"Crear nuevo Sector",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Crear Sector"
            alt=""
          ></img>
        </div>
      ),     
      
      id: "Acciones",
      Cell: ({ row }) => {
      
      const DeleteSector = async () =>{  
      
      setmodalT(true);  
      await refresh_token ();
      setData([]);
      setopen(true);

      try {
        const dt = await detele_sector(row.original.id);
        console.log(dt);
        alertas("Sector Eliminado", true);
        handleSubmit();
      } catch (err) {
        console.log("error", err);
        setopen(false);
        setmodalT(false);
        if(err.response.status===409){
          alertas("Aún hay puntos de venta relacionados al Sector", false);
        }else{
          alertas("Error, no se pudo eliminar el sector", false);    
        }
        handleSubmit();
      }
      }
      function Detalles(){
        sessionStorage.setItem("id_sector",row.original.id);
        sessionStorage.setItem("sectorname",row.original.name_sector)
        sessionStorage.setItem("id_ruta", row.original.id_route_fk)
      
        window.location = "/consult_point_sale_sector";
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar el sector?','Eliminar Sector','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Sector"
              alt=""
            ></img>
             <img
              src={Details}
              onClick={Detalles}
              className="centerText, cancel"
              title="Puntos de venta del sector"
              alt=""
            ></img>
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                   <EditSector handleSubmit={handleSubmit} data={row} reconsultar={reconsultar} alertas={alertas}></EditSector>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Sector",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           );
         }
        }
              className="centerText, cancel"
              title="Editar Sector"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Número",
      accessor: "number_sector",
    },
    {
      Header: "Nombre",
      accessor: "name_sector",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Ruta",
      accessor: "route",
    }
    ],
    []
);
  return (
    <>
<NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

<MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
  ></MODAL_TABLE>


<div className="containeritems py-2">
<nav aria-label="breadcrumb" className="navegacion">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
            <a href="od2.fricongelados.com">Home</a>
        </li>
        <li className="breadcrumb-item">
          Consultar Sectores
        </li>
      </ol>
    </nav>
</div>  


      <div className="TableSectors">      
          <div className="">
      
          <>
          <div className="titletable">
          <h1>Datos de sectores</h1>
          </div>
         
          <StickyTable>
            <TableContainer
              paginado={"Sectores"}
              consultas={"Sectores Consultados"}
              nametable={"Sectores"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            />
          </StickyTable>
          </>           
          </div>
        </div>
    </>
  );
}

export default TableSectors;
