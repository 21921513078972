
import axios from "axios";

//Este método tiene como objetivo proporcionar el número 
//total de datos a consultar basándonos en el paginado solicitado.
export async function numberData(url, yy) {
  //Se recibe como parámetro una variable url
  try {
    //Se ejecuta una peticion get y se guarda como promesa la respuesta
    const response = await axios.get(`${url}/number`, {
      params: {year: yy},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    const data = response.data.count;
    return data;//Se retorna la respuesta en caso de éxito
  } catch (error) {
    console.error(error);
    return null; //Se retorna un null en caso de fallo
  }
}







