
import TableUpdateBusnes from '../../componentsUpdateBusnes/TableUpdateBusnes';
import { Component } from "react";


export class UpdateBusnes extends Component {
  

  render() {

 
      
      return (    
      
      <div className="tc" >
        <TableUpdateBusnes></TableUpdateBusnes>

      </div>
          );
       }     
}



export default UpdateBusnes;
