import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Modal_Table from "../../main_components/modal/modal_Table";

import "../expense.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const Put_expense_no_deducible = ({
  codCocept,
  codDescripcion,
  codIdPayment,
  codImporte,
  codAccount,
  codidConcept,
  id_expense,
}) => {
  const [open, setOpen] = useState(false);
  const [Concepto, setConcepto] = useState([]);
  const [ConceptoUpdate, setConceptoUpdate] = useState(codCocept);

  const [Descripcion, setDescripcion] = useState(codDescripcion);
  const [importe, setImporte] = useState(codImporte);
  const [pro_servData, setpro_servData] = useState([]);
  const [accounts, setAccounts] = useState(codAccount);
  const [idaccounts, setidAccounts] = useState(codidConcept);
  const [accountspayment, setaccountspayment] = useState(codIdPayment);
  const [accountsList, setaccountsList] = useState([]);
  const [modalT, setmodalT] = useState(false);

  
  const [selectaccountsList, setselectaccountsList] =
    React.useState(codIdPayment);

  const handelClave_prod_serv = (event) => {
    if (event?.target?.value === "SELECCIONE UNA OPCIÓN") {
    } else {
      const result = pro_servData.filter(
        (item) => item.id == event?.target?.value
      );
      console.log(result);
      console.log(pro_servData);
      console.log(event?.target?.value);
      setConcepto(result[0]?.id);
    }
  };

  const handelDescripcion = (event) => {
    setDescripcion(event?.target?.value);
  };

  const handelUnidad = (event) => {
    setImporte(event?.target?.value);
  };

  const handelAccount = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
      setaccountspayment(event?.target?.value);
    }
  };

  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const dialog = useDialog();

  const handleOnSearch = (string, results) => {};

  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {
    setConcepto(item);
    setidAccounts(item.id_account);
    setAccounts(item.account);
    setConceptoUpdate(item.concept);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };

  var data = {
    expense: {
      id_account: idaccounts,

      id_payment: Number(accountspayment),

      description: Descripcion,

      amount: Number(importe),
    },
  };

  console.log(data);

  const handleSubmit = (event) => {
    event?.preventDefault();
    var data = {
      expense: {
        id_account: idaccounts,
  
        id_payment: Number(accountspayment),
  
        description: Descripcion,
  
        amount: Number(importe),
      },
    };

    console.log(data);

    setmodalT(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .put(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${id_expense}`,
        data,
        axiosConfig
      )
      .then((res) => {
        setmodalT(false);
        dialog.close(true);
      })
      .catch((err) => {
        setmodalT(false);
        var error= err?.response?.data?.errorMessage;
        alert("Error.," + error);
      });
  };

  const handleprod_concept_list = (event) => {
    event?.preventDefault();
    const url = `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/concept-list`;
    axios
      .get(url, {
        params: {
          foreign: 0,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setpro_servData(data);
      })
      .catch((err) => {
        setpro_servData([]);
      });
  };

  const handleAccountsList = (event) => {
    event?.preventDefault();
    const url = `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/prod/paymentaccounts`;
    axios
      .get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        const dataStatic = {
          id: "90909090909090",
          payment_method: "SELECCIONE UNA OPCIÓN",
        };
        data[data.length] = dataStatic;
        setaccountsList(data);
      })
      .catch((err) => {
        setaccountsList([]);
      });
  };

  useEffect(() => {
    handleprod_concept_list();
    handleAccountsList();
  }, []);

  return (
    <div>
      <div>
        <Modal_Table modalT={modalT}></Modal_Table>
      </div>
      <div>
        <form>
          <Box>
            <div style={{ width: "90%", margin: "0 auto" }}>
              <ReactSearchAutocomplete
                items={pro_servData}
                fuseOptions={{ keys: ["concept"] }}
                resultStringKeyName={"concept"}
                onSearch={handleOnSearch}
                onHover={handleOnHover}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                onClear={handleOnClear}
                showIcon={true}
                placeholder="Buscar concepto..."
                styling={{
                  height: "34px",
                  border: "1px solid darkgreen",
                  borderRadius: "4px",
                  backgroundColor: "white",
                  boxShadow: "none",
                  hoverBackgroundColor: "lightgreen",
                  color: "black",
                  iconColor: "black",
                  lineColor: "black",
                  placeholderColor: "black",
                  clearIconMargin: "3px 8px 0 0",
                  zIndex: 10,
                }}
              />
            </div>
          </Box>

          <Box className="expen-7">
            <ValidationTextField
                label="Concepto"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ m: 2, width: "50%" }}
                value={ConceptoUpdate}
                InputLabelProps={{ shrink: true }}
              />
              <ValidationTextField
                label="Cuenta"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ m: 2, width: "34%" }}
                value={accounts}
                InputLabelProps={{ shrink: true }}
              />
             
            
            </Box>

          
          

          <Box>
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>

          <Box>
            <ValidationTextField
              label="Importe"
              variant="outlined"
              placeholder="Importe"
              type="number"
              sx={{ m: 2, width: "89%" }}
              onChange={handelUnidad}
              onBlur={handelImportOnBurl}
              value={importe}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          <Box>
            <TextField
              select
              label="Cuenta de pago"
              value={selectaccountsList}
              sx={{ m: 1.5, width: "89%" }}
              onChange={handelAccount}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {accountsList.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </Box>

          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Actualizar
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default Put_expense_no_deducible;
