import axios from "axios";
import config from "../../config";
const urlinc = `${config?.baseURLINT}`;
const urldb = `${config?.baseURLDB}`;

//Consultar Lineas de Productos
export const get_producst_line = async () => {
  const url = `${urlinc}/od/products_line`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear Linea de Procutos
export const create_products_line = async (dt) => {
  const url = `${urlinc}/od/products_line`;
  try {
    const response = await axios.post(url, dt, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Actualizar Linea de Procutos
export const update_products_line = async (dt, id) => {
  const url = `${urlinc}/od/products_line/${id}`;
  try {
    const response = await axios.put(url, dt, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar Lineas de Productos
export const delete_producst_line = async (id) => {
  const url = `${urlinc}/od/products_line/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar Productos
export const get_products = async (id,isvalid) => {
  const url = `${urlinc}/od/products`;
  try {
    const response = await axios.get(url, {
      params:{product_line:id,deleted:isvalid},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar impurstos del producto
export const get_products_tax = async (id) => {
  const url = `${urlinc}/od/products/${id}/tax_config`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error); 
  }
};

//Consultar marcas de los proudctos
export const get_products_brands = async (id,isvalid) => {
  const url = `${urldb}/prod/dashboard1/products/brands`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar movimientos
export const get_movements = async (fi,ff) => {
  const url = `${urlinc}/od/warehouses/movements`;
  try {
    const response = await axios.get(url, {
      params:{di:fi,df:ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar almacenes
export const get_warehouse = async () => {
  const url = `${urlinc}/od/products/warehouses`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Consultar productos existentes 
export const get_warehouse_existence = async (id) => {
  const url = `${urlinc}/od/warehouses/${id}/existence`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//Crear movimiento 
export const create_warehouse_movements = async (dt) => {
  const url = `${urlinc}/od/warehouses/movements`;
  try {
    const response = await axios.post(url, dt, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};//Crear movimiento 
export const get_detil_warehouse = async (id) => {
  const url = `${urlinc}/od/warehouses/movements/${id}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};