import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { get_sectors, post_pos_sector } from "../../services/businesspartners/businesspartners";
import { catchErrorModul } from "../../main_components/catchErr/catchError";

function AddPointSaleSector(props) {

const dialog = useDialog();
const [ordensector, setordensector] = useState(0);
const [sector, setsector] = useState(0);
const [datas, setDatas] = useState([]);
const [bandera, setbandera]=useState(true);

useEffect(()=>{
  handleSubmit();
},[]);

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

const addpointsalesector= async () =>{

if(sector===0 || ordensector === 0){
  alert("Datos faltantes");
}else{

refresh_token ();
setbandera(false);
  
var data = {
  "idPointSale":props.row.original.id,
  "orderSector":ordensector
}

  try {
    const dt = await post_pos_sector(sector, data);
    console.log(dt);
    alert("Punto de venta agregado a sector");
    props.handleSubmit();
    dialog.close();
  } catch (err) {  
    catchErrorModul("Puntos de venta sin sector", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
    console.log(err);
    setbandera(true);
  }
 }
}

const handleSubmit = async () => {
  try {
      var d = await get_sectors();
      const data = d===null ? [] : d.data.sectors;
      const datasec = data.filter(item=>item.id_route_fk===props.row.original.id_route_fk)
      setDatas(datasec);
      console.log(datas);
    } catch (error) {
      console.log(error);
    } finally {
  }
};

  return (
    <div>
      <div className="addpointsalecss">
          <Box>
            <TextField
              label="Punto de Venta"
              value={props.row.original.name}
              sx={{ m: 1.5, width: "90%"}}
              disabled
            ></TextField>
           <TextField
              label="Orden del sector"
              type={"number"}
              disabled={!bandera}
              required
              sx={{ m: 1.5, width: "30%"}}
              onChange={(event) => setordensector(event.target.value)}
              InputLabelProps={{ shrink: true }}

            ></TextField>

            <TextField
              select
              label="Sector"
              disabled={!bandera}
              defaultValue={""}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "56%"}}
              onChange={(event) => setsector(event.target.value)}  
            >
              <option
                   key={0}
                   value={0}
                   selected
                 >
                  {"Seleccione Sector"}
                 </option>
            {
              datas.map(item=>(
                <option
                   key={item.id}
                   value={item.id}
                 >
                  {
                    item.number_sector+"-"+item.name_sector
                  }
                 </option>
              ))
            }
             </TextField>
          </Box>
          <br></br>
          <br></br>
          <Box>
            {
              bandera===true?
          <Button
            style={{"backgroundColor":"green"}}
            variant="contained"
            sx={{ m: 1.5, width: "40ch" }}
            onClick={addpointsalesector}
          >Agregar Punto de Venta a Sector</Button>:
          <Button
          style={{"backgroundColor":"green", "height":"35px"}}
          variant="contained"
          sx={{ m: 1.5, width: "40ch" }}
        ><Spinner/></Button>
            }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default AddPointSaleSector;
