var date = new Date();
var dia = `${date.getDate()}`.padStart(2, "0");
var mes = `${date.getMonth() + 1}`.padStart(2, "0");
var año = date.getFullYear();

var mesantes = Number(mes) - 1;
var añoantes = año;
if (mesantes ===0) {
  mesantes = 12;
  añoantes = añoantes - 1;
}

var ultimoDiaMesAnterior;
if (mesantes ===2) {
  if ((añoantes % 4 ===0 && añoantes % 100 !== 0) || añoantes % 400 ===0) {
    ultimoDiaMesAnterior = 29;
  } else {
    ultimoDiaMesAnterior = 28;
  }
} else {
  ultimoDiaMesAnterior = new Date(añoantes, mesantes, 0).getDate();
}


var diaantes = Math.min(dia, ultimoDiaMesAnterior);
var mesantesStr = `${mesantes}`.padStart(2, "0");
var diaantesStr = `${diaantes}`.padStart(2, "0");

export const date_current = `${año}-${mes}-${dia}`;
export const previous_date=`${añoantes}-${mesantesStr}-${diaantesStr}`;