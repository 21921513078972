//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
//COMPONENTES
import TableContainer from "../../../Table_Container_Cashclosing";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { SelectColumnFilter } from "../../../../components_cfdi/filters";
import { get_warehouses } from "../../../../services/cashclosing/cashclosing";
//ESTILOS
import "../../../cashclosing.css";
//IMAGENES/ICONOS


const CONSULT_CASHCLOSING_WAREHOUSE = ({Warehoseid}) => {
  const [data, setData] = useState([]);
  const [modalT, setmodalT] = useState(true);
  //Consulta inventario
  const handleSubmit = async () => {
    
    setData([]);
    setmodalT(true);

    try {
      const dt= await get_warehouses(sessionStorage.getItem("selectwarehouse"),sessionStorage.getItem("datawarehose"));
      const data = dt.data.sort((a, b) =>a.quantity < b.quantity ? 1 : a.quantity > b.quantity ? -1 : 0);
          setData(data?.filter((d)=>d.quantity!==0));
          setmodalT(false);
    } catch (err) {
      console.log("error", err);
      setData([]);
      setmodalT(false);

    }
  };
   
  const handleShort=()=>{if(data[0]?.quantity<=data[data.length].quantity){setData(data.sort((a, b) => a.quantity - b.quantity));}else{setData(data.sort((a, b) => b.quantity - a.quantity));}}

  useEffect(() => {
    handleSubmit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const columns = useMemo(
    () => [
      {
        id: "code",
        key:"code",
        Header: ()=>{return (<p onClick={handleShort}> </p>)},
        accessor: (d)=>{if(d.quantity<0){return(<div style={{color:"red",fontWeight:"bold" }}>{d.code}</div>);}else{return(<div>{d.code}</div>);}},
        Filter: SelectColumnFilter,
      },
      {
        id: "quantity",
        Header: "Cantidad",
        key:"quantity",
        accessor: (d)=>{if(d.quantity<0){return(<div style={{color:"red",fontWeight:"bold" }}>{d.quantity}</div>);}else{return(<div>{d.quantity}</div>);}},
      },
      {
        id: "description",
        Header: "Descripción",
        key:"description",
        accessor: (d)=>{if(d.quantity<0){return(<div style={{color:"red",fontWeight:"bold"}}>{d.description}</div>);}else{return(<div>{d.description}</div>);}},
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className="">
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>

      <div>
        <h1>Inventario asignado al usuario</h1>
      </div>
      <div className="TableReport TableSaleCutBox">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            key={data?.code}
            inventario={true}
            exportEx={true}
            columns={columns}
            data={data}
            Gmodal={modalT}
            TableStickyHeader={true}
            style={{ overflowx: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default CONSULT_CASHCLOSING_WAREHOUSE;



