//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Dashboard";
import SubTable from "./Get_datail_Sales";
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token } from "../../main_components/tokens/tokenrefresh.jsx";

//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
//IMAGENES/ICONOS

import Spinner from "../../imgComponents/S.gif";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardDetailSales = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const [selectRutas, setselectRutas] = useState("all");


  const handleSubmit = (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    console.log(fi);
    console.log(ff);
    refresh_token ();
    setData([]);

    setTimeout(() => {
      axios
      .get(
        `https://e26bmbbxf1.execute-api.us-east-1.amazonaws.com/demo/dashboard`,
       
        {
          params: {
            di:fi,
            df:ff
          },
          
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {

        const data = response.data;

        setData(data);

        setOpen(false);
        setMenssage("");
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setOpen(false);

        setMenssage("");
      });
    }, 2000);

  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };


  const columns = useMemo(
    () => [
       {
         id: "expander",
         Header: "Acciones",
         Cell: ({ row }) => {
           function guardardato() {
             sessionStorage.setItem("id_socio", row.original.id_socio);
           }

           function Denegado() {
             alert("No tienes el rol necesario para ver los detalles del gasto");
           }
          
             return (
               <a
                 title="Ver detalles"
                 {...row.getToggleRowExpandedProps()}
               >
                 {row?.isExpanded ? (
                   <span title="Ver detalles">🔼</span>
                 ) : (
                   <span
                     title="Ver detalles"
                     onClick={guardardato}
                   >
                     🔽
                   </span>
                 )}
               </a>
             );
         },
       },

       {
        Header: "Socio",
        accessor: "socio",
      },
      {
        Header: "N. Fletes",
        accessor: "fletes",
      },
      {
        Header: "KM",
        accessor: "drecorrida",
     
      },
      {
        Header: "Costo",
        accessor: "costo",

      },
      {
        Header: "Ganancia",
        accessor: "ganancia",
      },

      {
        Header: "total",
        accessor: "total",

      },
    ],
    []
  );

  return (
    <div className="">
      <div>
      <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>

      
      <div className="containerExpenseReport2">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5 topSelect">
          <TextField
            select
            label="Rutas"
            value={selectRutas}
            sx={{ width: "100%" }}
            onChange={handleRutas}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {Rutas.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </TextField>
        </div>

        <div className="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="TableReport tableDashboard TableSalesDashboard">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            Gtotal={!open}
            salesD={true}
            campSUbtable={true}
            Gmodal={open}

            style={{ overflowx: 10 }}
            renderRowSubComponent={(row) => {
              return (
                <div style={{ padding: "20px" }}>
                  <SubTable 
                  verData={true}
                  datei={fi}
                  datef={ff}
                  route={selectRutas} 
                  />
                </div>
              );
            }}
           
          />
        </div>
      </div>
    </div>
  );
};

export default NewDashboardDetailSales;
