import axios from "axios";
import config from "../../config";
const urlcc = `${config?.baseURLClC}`;
const urlcp = `${config?.baseURLIVC}`;

//Consultar rutas 
export const get_routes = async (fi, ff) => {
  const url = `${urlcc}/od/cashclosing/routes`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar corte de caja
export const get_cashclosing = async (fi, ff) => {
  const url = `${urlcc}/od/cashclosing`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi,
        df: ff,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Actualizar corte de caja
export const put_cashclosing = async (id_cashclosing, fi, ff, route, id) => {
  const url = `${urlcc}/od/cashclosing/${id_cashclosing}?di=${fi}&df=${ff}&route=${route}&user=${id}`;
  try {
    const response = await axios.put(
      url,
      {},
      {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar corte de caja
export const delete_cashclosing = async (id) => {
  const url = `${urlcc}/od/cashclosing/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar ventas del corte de caja
export const get_sale_cashclosing = async (fi, ff, route) => {
  const url = `${urlcc}/od/cashclosing/sales?`;
  try {
    const response = await axios.get(url, {
      params: {
        di: fi,
        df: ff,
        route: route,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};




//Consultar detalles de la venta
export const get_detail_sale_cashclosing = async (id_sale) => {
  const url = `${urlcc}/od/cashclosing/sales/${id_sale}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Cerrar liquidación
export const put_sale_cashclosing = async (id,dta) => {
  const url = `${urlcc}/od/cashclosing/sales/${id}`;
  try {
    const response = await axios.put(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//Consultar detalles de la venta (devolución/bonificación)
export const get_detail_sale_return_cashclosing = async (id_sale) => {
  const url = `${urlcc}/od/cashclosing/sales/returns/${id_sale}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Cancelar Venta
export const cancel_sale_cashclosing = async (id, id_warehouse_fk) => {
  const url = `${urlcc}/od/cashclosing/sales/${id}/cancel`;
  try {
    const response = await axios.post(
      url,
      {
        id_warehouse_fk: id_warehouse_fk,
      },
      {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      }
    );
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Generar Factura Oportuno
export const toinvoice_cashclosing = async (dta) => {
  const url = `${urlcc}/od/cashclosing/sales/toinvoice`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Generar Factura
export const generate_invoice_cashclosing = async (id, dta) => {
  const url = `${urlcc}/od/cashclosing/sales/${id}`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Generar Nota de Crédito
export const generate_invoice_return_cashclosing = async (
  id_sale_return,
  dta
) => {
  const url = `${urlcc}/od/cashclosing/sales/toinvoice/${id_sale_return}`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Guardar liquidación
export const save_cashclosing = async (fi, ff, route, user, dta) => {
  const url = `${urlcc}/od/cashclosing?di=${fi}&df=${ff}&route=${route}&user=${user}`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Cerrar liquidación
export const close_cashclosing = async (fi, ff, route, dta) => {
  const url = `${urlcc}/od/cashclosing/sales?di=${fi}&df=${ff}&route=${route}`;
  try {
    const response = await axios.put(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Consultar almacen
export const get_warehouses = async (warehouses, warehose_id) => {
  const url = `${urlcc}/od/cashclosing/warehouses/${warehouses}`;
  try {
    const response = await axios.get(url, {
      params: {
        cashclosing: warehose_id === "" ? null : warehose_id,
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Generar Carta Porte
export const toinvoice_fromdocument = async (dta) => {
  const url = `${urlcp}/od/invoices/cartaporte/fromdocument`;
  try {
    const response = await axios.post(url, dta, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar bonificación/devolución
export const delete_bon_dev = async (id) => {
  const url = `${urlcc}/od/cashclosing/sales/returns/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

