//PAQUETERIAS
import { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
//COMPONENTES
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_route_list, post_sector } from "../../services/businesspartners/businesspartners";

function CreateSector(props) {
  const dialog = useDialog();
  const notificationSystemRef = useRef();
  const [routes, setroutes] = useState();
  const [numsector, setnumsector] = useState(0);
  const [namesector, setnamesector] = useState("");
  const [route, setroute] = useState(null);
  const [bandera, setbandera]=useState(true);

  const [vnumsector, setvnumsector]=useState(false);
  const [vnamesector, setvnamesector]=useState(false);
  const [vroute, setvroute]=useState(false);

useEffect(()=>{
  handleroute();
},[]);

//ALERTAS PERSONALIZADAS
function alertascreate(msj,status){
  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'tr', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'tr', 
      autoDismiss: 60, 
    });
  }  
}
//ANIMACION DE CARGA DE BOTON
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

//consultar todas las rutas
const handleroute = async ()=>{
  try {
    var d = await get_route_list();
    setroutes(d === null ? [] : d.data);
  } catch (error) {
    console.log("error", error);
  } finally {
  } 
}

//crear un nuevo sector
const createnewsector = async () =>{

  console.log(namesector);
  console.log(numsector);
  console.log(route);

if(namesector==="" || numsector===0 || route===null || route===0 ){
  alertascreate("Datos faltantes", false);  
  namesector===""?setvnamesector(true):setvnamesector(false);
  numsector===0?setvnumsector(true):setvnumsector(false);
  route===null || route===0?setvroute(true):setvroute(false);
}else{
  refresh_token ();
  props.reconsultar(true);
  setbandera(false);

var data = {
  "numberSector":numsector,
  "nameSector":namesector,
  "idRoute":route
}

  try {
    const dt = await post_sector(data);
    console.log(dt);
    props.alertas("Sector creado con éxito", true);
    props.reconsultar(false);
    dialog.close();
    props.handleSubmit();
  } catch (err) {  
    console.log(err);
    catchErrorModul("Sectores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response)); 
    if(err.response.status===500){
      alertascreate("Error al crear el Sector", false);
      setbandera(true);
      props.reconsultar(false);
    }
    if(err.response.status===409){
      alertascreate("El número de sector ya fue utilizado");
      setbandera(true);
      props.reconsultar(false);
    }
  }
;}
} 
  return (
    <div>
      <div className="addpointsalecss">
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  
          <Box>
            <TextField
              label="Nombre"
              required
              disabled={!bandera}
              value={namesector}
              style={vnamesector===true?{
                "border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onBlur={
                namesector===null || namesector===""?()=>setvnamesector(true):()=>setvnamesector(false)
              }
              onSelect={()=>setvnamesector(false)}
              sx={{ m: 1.5, width: "70%" }}
              onChange={(event) => setnamesector(event.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}

            ></TextField>
            <br/>
            <TextField
              label="Número"
              type={"number"}
              required
              style={vnumsector===true?{
                "border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onBlur={
                numsector===0 || numsector===""?()=>setvnumsector(true):()=>setvnumsector(false)
              }
              onSelect={()=>setvnumsector(false)}
              disabled={!bandera}
              sx={{ m: 1.5, width: "25%" }}
              onChange={(event) => setnumsector(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            <TextField
              select
              label="Ruta"
              required
              style={vroute===true?{
                "border":"2px solid red", "borderRadius":"5px"}:{}
              }
              disabled={!bandera}
              defaultValue={""}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "41%" }}
              onChange={(event) => setroute(parseInt(event.target.value))}  
              onBlur={
                route===null || route===0?()=>setvroute(true):()=>setvroute(false)
              }
              onSelect={()=>setvroute(false)}
            >
              <option key={0} value={0} selected>
                  {"Seleccione ruta"}
                 </option>
               {
                routes?.parametersList?.map((option) => (
                <option
                   key={option.idRoute}
                   value={option.idRoute}
                 >
                  {option.route}
                 </option>
              ))
            } 
              
             </TextField>
          </Box>
          <br></br>
          <br></br>
          <Box>
            {bandera===true?
            <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "20ch" }}
            onClick={createnewsector}
          >Crear Sector
          </Button>:
          <Button
            variant="contained"
            desabled
            style={{"backgroundColor":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "20ch" }}
            onClick={createnewsector}
          ><Spinner></Spinner></Button>          
          }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateSector;
