import TableDashboardCliente from '../../componentsNewDashboard/newDashboard';
import { Component } from "react";



export class NewDashboard extends Component {
 

  

  render() {

    
      return (    
      
      <div className="tc">
      <TableDashboardCliente/> 

      </div>
          );
       }     
}

export default NewDashboard;


