import { Component } from "react";
import RegisterGeneral from "../../../componentSociosNegocios/componentOfSale/RegisterGeneral";

export class SetRegisterGeneral extends Component {



  render() {

      return (    
      
      <div className="tc" >
        <RegisterGeneral/>
      </div>
          );
       }     
}

export default SetRegisterGeneral;


