import  { Component } from "react";

import TableDiscount from "../../../componentSociosNegocios/componentDescuentos/TableDiscount";
export class SetDiscounts extends Component {
 
  render() {

      
      
      return (    
      
      <div className="tc" >
        <TableDiscount/>
      </div>
          );
       }     
}

export default SetDiscounts;


