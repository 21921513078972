//PAQUETERIAS
import React, { useEffect, useState, useMemo ,useRef } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import Set_expense from "./Set_Expense";
import SubTable from "../expense_report/Get_datail";
import Put_expense_extranjero from "./Put_Expense";
import Modal_Table from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";


//ESTILOS
import "../../components_cfdi/estilo.css";
import "../expense.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import ADD from "../../imgComponents/add.png";
import PDF from "../../imgComponents/pdf.png";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableExpense_extanjero = () => {

  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes=  añoantes-1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  
  

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${añoantes}-${mesantes}-${dia}`;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");
  const [modalT, setmodalT] = useState(false);
  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");
  const notificationSystemRef = useRef();

  const handleSubmit = (menssageAlert) => {
    setmodalT(true);
    setmodalGeneral(false)
    setMenssage("Consultando gastos...");
    console.log(fi);
    console.log(ff);

    axios
      .get(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses`, 
      {
        params: {
          di:fi,
          df:ff,
          origin:"EXTRANJERO",
       
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
       
      })
      .then((response) => {
        const data = response.data;
        const data2 = data.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));




        console.log(data2);

        setData(data2);

        setmodalT(false);
        setMenssage("");
        if(menssageAlert===undefined){
          console.log("sin alaerta")
        }else{
          notificationSystemRef.current.addNotification({
            message: (
              <AlertResponse
                msj={menssageAlert}
                view={true}
              ></AlertResponse>
            ),
            level: "success",
            position: "br",
            autoDismiss: 10,
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setmodalT(false);

        setMenssage("")

      });
  };

  
  const handleReconsulta = (reconsultar,a) => {
    if (reconsultar === true) {
      handleSubmit(a);
    } else {
      console.log("no se reconsulto");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);



  const columns = useMemo(
    
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {

          return(
          <img
                title="Crear Gasto"
                src={CREATE}
                onClick={async () => {
                  
                  const result = await CustomDialog(
                    
                      <Set_expense ></Set_expense>,
                    {
                      className: "modalTItle",
                      title:
                      "Crear Gasto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                  handleReconsulta(result,"Gasto creado correctamente");
                
              }}
              ></img>
            )
        },
        Cell: ({ row }) => {

          function guardardato(){
            sessionStorage.setItem("id_expense_subtable",row.original.id);
          }

          function Denegado(){
            alert("No tienes el rol necesario para ver los detalles del gasto");
          }

          if(sessionStorage.getItem("PERMISOVERDETALLESGASTO")==="ROLE_EXPENSE_ADMINISTRATIVOS"){

          return(
          <a title="Consultar impuestos del concepto" {...row.getToggleRowExpandedProps()} >
            {row?.isExpanded ? <span title="Consultar detalles del gasto" >🔼</span>: <span title="Consultar detalles del gasto" onClick={guardardato}>🔽</span>}
          </a>
          )
          }else{
            return(
            <span title="Consultar detalles del gasto" onClick={Denegado}>🔽</span>
            );
          }
          },
      },
      
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function downloadPDF(typedocumentexmlorpdf) {
            setmodalT(true);
            setmodalGeneral(true);

            
            setMenssage("Descargando pdf...");
            const uri =`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${row.original.id}`

            axios
              .get(uri, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                const data = response.data;
                  var blob = base64toBlob(data.file);
                  const blobUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  const fileName = row.original.file_name + ".pdf";
                  downloadLink.href = blobUrl;
                  let arr = row.original.file_name.split('/');

                  downloadLink.download = arr[4]+".pdf";
                  downloadLink.click();
                  setTimeout(() => {
                    setmodalT(false);
                    setmodalGeneral(false);

                  }, 2000);
              
              })
              .catch((err) => {
                console.log("error", err);
                setmodalT(false);
                setmodalGeneral(false);

              });
          }

          function base64toBlob(base64Data) {
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize;
              const end = Math.min(begin + sliceSize, bytesLength);

              const bytes = new Array(end - begin);
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: "application/pdf" });
          }

          
          function DeleteTax(result) {
            setMenssage("Eliminando gasto...")
            setmodalT(true);
            axios
              .delete(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/${row.original.id}`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                setmodalT(false);
                setMenssage("");
                handleReconsulta(result,"Gasto eliminado correctamente");
                
              })
              .catch((err) => {
                console.log("error", err);
                setmodalT(false);
                setMenssage("")
                console.log()
                alert("Error.,"+ err.response.data.errorMessage); 
              });


          }

          return (
            <div>

                <img
                  title="Descargar pdf"
                  src={PDF}
                  onClick={() => downloadPDF("pdf")}
                  className=""
                ></img>

              <img
                title="Editar Gasto"
                src={SAVE}
                onClick={async () => {
                  if(row.original.modifiable === true){
                    const result = await CustomDialog(
                      <Put_expense_extranjero 
                      codCocept={row.original.concept}
                      codDescripcion={row.original.description}
                      codDate={row.original.date}
                      codImporte={row.original.ammount}
                      codAccount={row.original.account}
                      codIdPayment={row.original.paiement_account}
                      id_expense={row.original.id}
                      codidConcept={row.original.id_account}
                      ></Put_expense_extranjero>,
                    {
                      className: "modalTItle",
                      title:
                        "Editar Gasto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                  handleReconsulta(result,"Gasto actualizado correctamente");
                }else{
                    alert("Este gasto ya no se puede modificar.")
                  }
                  
                  
                
              }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Gasto"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Gasto?",
                    "Eliminar Gasto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "No.",
        accessor: "spend_folio_number",
      },
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      },
      {
        Header: "Editable",
        accessor: (d) => {
          return d.modifiable === true ? "✅" : "❌";
        },
      },
      {
        Header: "Cuenta",
        accessor: "account",
      },
	
    ],
    []
  );

  return (
    <div className="">
      <Modal_Table message={menssage} modalGeneral={modalGeneral} modalT={modalT}></Modal_Table>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>


      <div><h1>CONSULTAR GASTOS DEL EXTRANJERO</h1></div>
      <br></br>
      

      <div class="containerExpense">
            <div class="expen-1 color-1">
            <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
            </div>

            <div class="expen-2 color-2">
            <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
            </div>


            <div class="expen-3 color-5">

            <Button  
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit} >
                  Buscar
                </Button>
              
            </div>
          </div>




     

          <div className="TableReport ExpenseNoDeducible">
        <div style={{overflowX:"auto"}}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable verData={true}/>
               </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableExpense_extanjero;
