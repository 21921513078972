import { useEffect, useMemo, useState, useRef} from 'react'
import MapContainer from './MapContainer';
import { refresh_token  } from '../../main_components/tokens/tokenrefresh';
import TextField from "@mui/material/TextField";
import TableContainer from './TableContainer';
import { StickyTable } from "react-sticky-table";
import "./salesreal.css";
import marker1 from "../../imgComponents/marker1.png";
import marker2 from "../../imgComponents/marker2.png";
import marker3 from "../../imgComponents/marker3.png";
import marker4 from "../../imgComponents/marker4.png";
import marker5 from "../../imgComponents/marker5.png";
import lupa from "../../imgComponents/lupa.png";
import {CustomDialog} from "react-st-modal";
import DetailsSalesReal from './DetailsSalesReal';
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";

import { get_route_list } from '../../services/businesspartners/businesspartners';
import MODAL_TABLE from '../../main_components/modal/modal_Table';
import { get_sales_in_real_time } from '../../services/reports/reports';

const SalesReal = () => {


useEffect(()=>{
  handleroute();
},[]);

const [markers, setmarkers]=useState([]);
const [markers2, setmarkers2]=useState([]);
const [data, setdata]=useState([]);
const [fecha, setfecha]=useState(null);
const [nameroute, setnameroute]=useState(null);
const [bandera, setbandera]=useState(false);

const [routedata, setroutedata]=useState([]);
const [open, setopen]=useState(false);
const notificationSystemRef = useRef(); 
const [modalT, setmodalT] = useState(false);


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const handleroute =async () => {

  await refresh_token ();
  try {
    var d = await get_route_list();
    setroutedata(d === null ? [] : d)
  } catch (error) {
    console.log(error);
    alertas("Error al obtener información de rutas", false);
  } finally {
  }
}
const handleSubmit = async () => {

  await refresh_token ();
  setmodalT(true);
  setopen(true);
  setdata([]);
  try {
    var d = await get_sales_in_real_time(nameroute, fecha);
    const jsonordenado = d === null ? [] : d.data.points_of_sale.sort((a, b) => {
      const aDate = new Date(a.sales[0].date);
      const bDate = new Date(b.sales[0].date);
      return aDate - bDate;
    });
    const jsn = jsonordenado.flatMap(item => {
      return item.sales.map(sale => {
        return {
          ...item,
          sales: [sale],
        }
      })
    });

    const jsonew = jsn.map((item, index) => {
      return { ...item, ordenvisita: (index+1)};
    });
    //console.log(jsonew); 
    newjson(d === null ? [] : jsonew);
    setdata(d === null ? [] :jsonew);
  } catch (error) {
    console.log(error);
    setopen(false);
    setdata([]);
    setmodalT(false);
    console.log("error al obtener data de rutas");
  } finally {
    setbandera(true);
    setmodalT(false);
    setopen(false);

  }
}
function newjson(n){
  //console.log(n);


  const puntosventa = n.map(obj => {
    return { 
      code: obj.code,
      name: obj.name,
      lat: obj.lat, 
      lng: obj.lng, 
      ordenvisita: obj.ordenvisita,
      tipo:1,
    };
  });
  const allSales = n.reduce((acc, obj) => {
  return acc.concat(obj.sales.map(item =>
      ({ 
        ticket: item.ticket,
        name_pos: item.name_pos,
        lat: item.lat, 
        lng: item.lng, 
        code_pos: item.code_pos,
        ordenvisita: obj.ordenvisita,
        scan: item.scan,
        total: item.total,
        total_returns: item.total_returns,
        id_sale_type_fk: item.id_sale_type_fk,
        saletype: item.saletype,
        tipo: item.saletype==="NO VENTA" || item.saletype=== "OTRO"?3:(obj.cabinets.length===0?5:(item.scan===null||""?4:2)),
        cabinets: obj.cabinets,
        details: item.details,
      })));
  }, []);

const result = puntosventa.concat(allSales);

console.log(puntosventa);
console.log(allSales);
console.log(result); 

setmarkers(result);
setmarkers2(result);
 
}
const columns = useMemo(
  () => [
    {
      Header:"Acciones",
      id:"Acciones",
      Cell: ({row }) => {
          return (
            <div>
              <img
                src={lupa}
                onClick={
                  async () => {
                    const result = await CustomDialog(               
                   <div>
                      <DetailsSalesReal row={row}></DetailsSalesReal>
                   </div>,
                 {
                   className: "modalpuntosventa",
                   title:"Detalles de la Venta",
                   showCloseIcon: true,
                   isCanClose:false,
                 }
             ); 
           }
          }
                className="centerText, cancel"
                title="Detalles de la Venta"
              ></img>
            </div>
          );
        },

    },
    {
      Header:"Visita",
      accessor:"ordenvisita"
    },
    {
       Header:"Código",
       accessor:"code"
    },
    {
      Header:"Fecha",
      accessor: (d)=>{
        return d?.sales[0]?.date
      }
    },
    {
      Header:"Punto de Venta",
      accessor:"name"
     },
    {
       Header:"Tickets",
       accessor: (d)=>{
        return d?.sales[0]?.ticket
       }
    },
    {
       Header:"Congelador",
       accessor: (d)=>{
      var congeladores="";
        for(var i=0; i<d.cabinets.length;i++){
          congeladores=congeladores + d.cabinets[i].economic_number+" ";
        }
      return congeladores;
       }
    },
    {
       Header:"Con. Escaneado",
       accessor: (d)=>{
        var escaneado="";
        for(var i=0; i<d.sales.length;i++){
          escaneado=escaneado + d.sales[i].scan+" ";
        }
        return escaneado;
       }
    },
    {
      Header:"Tipo",
      accessor: (d)=>{
        if(d.sales[0]?.saletype==="NO VENTA" || d.sales[0]?.saletype==="OTRO"){
          return "NOTA";
        }else if(d.cabinets.length===0){
          return "SIN CONGELADOR";
        }else if(d.sales[0].scan===null||""){
          return "SIN ESCANEO";
        }else{
          return "VENTA";
        }
      },
    },
    {
      Header:"Marker",
      accessor:(d)=>{
        if(d.sales[0]?.saletype==="NO VENTA" || d.sales[0]?.saletype==="OTRO"){
          return <img src={marker3}></img>;
        }else if(d.cabinets.length===0){
          return <img src={marker5}></img>
        }else if(d.sales[0].scan===null||""){
          return <img src={marker4}></img>
        }else{
          return <img src={marker2}></img>
        }
      }
    }
    ],
    []
);
  return (
    <div>
       <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
            <MODAL_TABLE
              open={true}
              message={""}
              modalGeneral={false}
              modalT={modalT}
            ></MODAL_TABLE>  
      <div>
        <h2>Reporte Ventas en Tiempo Real</h2>
      </div>
      <div className='grid_real_sales'>
        <div className='realsales1'>
           <input
              type="text"
              className="fecha1"
              onFocus={(e) => {
                e.currentTarget.type = "date";
                e.currentTarget.focus();
              }}
              placeholder="Fecha"
            onChange={(event) => setfecha(event.target.value)}
            ></input>
        </div>
        <div className='realsales2'>
        <TextField
          select
          required
          label="Ruta"
          InputLabelProps={{shrink: true,}}
          SelectProps={{native: true,}}
          sx={{ m: 1.5, width: "100%" }}
          onChange={(event) => setnameroute(event.target.value)}
      >
          <option key={0} value={"vacio"} selected>
              {"Seleccione Ruta"}
          </option>
          {
            routedata?.data?.parametersList?.map((item)=>(
              <option key={item.idRoute} value={item.route}>
                {item.route}
              </option>
            ))
          }
      </TextField>
        </div>
        <div className='realsales3'>
        <button className="button" id="buttonpackageb" onClick={handleSubmit} type="button">
            Buscar
        </button>
        </div>
      </div>
      <div className='tableventastreal'>
      <table>
        <tr>
          <td>
            <img src={marker1}></img>
            <p>Punto de Venta</p>
          </td>
          <td>
            <img src={marker2}></img>
            <p>Ventas</p>
          </td>
          <td>
            <img src={marker3}></img>
            <p>Notas</p>
          </td>
          <td>
            <img src={marker4}></img>
            <p>Sin escaneo</p>
          </td>
          <td>
            <img src={marker5}></img>
            <p>Sin congelador</p>
          </td>
        </tr>
      </table>
  </div>
      {
        bandera===true?<MapContainer markers={markers}></MapContainer>:""
      }

  <div className="TableSalesTimeReal">
    <div className="">       
      <>
      <div className="titletable">
         </div>
           <StickyTable>
            <TableContainer
              paginado={"Ventas"}
              consultas={"Ventas consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
              markers={markers}
              setmarkers={setmarkers}
              markers2={markers2}
            />
          </StickyTable>
          </>   
       
          </div>
        </div>


    </div>
  );
}


export default SalesReal