//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Dashboard";
import SubTable from "../sales/Get_datail_Sales";
import Rutas from "../../dataDahsboard/routes.json";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh.jsx";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../dashboar.css";
//IMAGENES/ICONOS

import Spinner from "../../imgComponents/S.gif";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  opacity: ".1 !important",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewDashboardClient = () => {
  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();

  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${año}-${mesantes}-${dia}`;
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTotal, setopenTotal] = useState(true);
  const [menssage, setMenssage] = useState("");
  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [selectRutas, setselectRutas] = useState("all");
  const [totalcustomer, settotalcustomer] = useState(0);
  const [totalwithsale, settotalwithsale] = useState(0);
  const [totalwithoutsale, settotalwithoutsale] = useState(0);
  const [totalfreezers, settotalfreezers] = useState(0);

  const handleSubmit = (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando...");
    console.log(fi);
    console.log(ff);
    setopenTotal(false);
    setData([]);
    refresh_token ();
    setTimeout(() => {
      axios
        .get(
          `https://e26bmbbxf1.execute-api.us-east-1.amazonaws.com/demo/dashboard`,
         
          {
            params: {
              di:fi,
              df:ff
            },
            
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data;
          setData(data)
          const data2 = data.filter((dash) => dash.customer !== "Grand Total");
          var cust = data2
            .filter((sale) => !isNaN(parseFloat(sale.customers)))
            .map((sale) => parseFloat(sale.customers))
            .reduce((previous, current) => previous + current, 0);

            var withs = data2
            .filter((sale) => !isNaN(parseFloat(sale.withsale)))
            .map((sale) => parseFloat(sale.withsale))
            .reduce((previous, current) => previous + current, 0);

            var witho = data2
            .filter((sale) => !isNaN(parseFloat(sale.withoutsale)))
            .map((sale) => parseFloat(sale.withoutsale))
            .reduce((previous, current) => previous + current, 0);
            var freez = data2
            .filter((sale) => !isNaN(parseFloat(sale.freezers)))
            .map((sale) => parseFloat(sale.freezers))
            .reduce((previous, current) => previous + current, 0);

          const ndata = data2.map((d) => {
            console.log(cust);
            var json = {
              customer: d.customer,
              sales: d.sales,
              participation: d.participation,
              customers: d.customers,
              pcustomers:(((d.customers) /(cust))*(100)).toFixed(2),
              withsale: d.withsale,
              pwithsale: (((d.withsale) /(withs))*(100)).toFixed(2),
              withoutsale: d.withoutsale,
              pwithoutsale: (((d.withoutsale) /(witho))*(100)).toFixed(2),
              freezers: d.freezers,
              pfreezers: (((d.freezers) /(freez))*(100)).toFixed(2),
              cost: d.cost,
              profit: d.profit,
            };

            return json;
          });
          handleCacular()
          console.log(
            data
              .filter((sale) => !isNaN(parseFloat(sale.customers)))
              .map((sale) => parseFloat(sale.customers))
              .reduce((previous, current) => previous + current, 0)
          );

          setopenTotal(true);
          setOpen(false);
          setMenssage("");
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setOpen(false);
          setopenTotal(true);
          setMenssage("");
        });
    }, 2000);
  };


  const handleCacular = (a,b) => {

  };

  const handleRutas = (event) => {
    setselectRutas(event.target.value);
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  console.log(sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT"));

  console.log(
    sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT") ===
      "ROLE_REPORT_SALES_MANAGEMENT"
      ? true
      : false
  );

  const columns = useMemo(


    () => [

      
      {
        Header: "Socio",
        accessor: "socio",
      },
      {
        Header: "N. Fletes",
        accessor: "fletes",
      },
      {
        Header: "KM",
        accessor: "drecorrida",
     
      },
      {
        Header: "Costo",
        accessor: "costo",

      },
      {
        Header: "Ganancia",
        accessor: "ganancia",
      },

      {
        Header: "total",
        accessor: "total",

      },
     
      
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          className="nModalNew"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga nModalNew">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              ></Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              ></Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="containerExpenseReport2">
        <div className="expen-1 color-1">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha inicial"
            onChange={(event) => setfi(event.target.value)}
          ></input>
        </div>

        <div className="expen-2 color-2">
          <input
            type="text"
            className="fExpense"
            onFocus={(e) => {
              e.currentTarget.type = "date";
              e.currentTarget.focus();
            }}
            placeholder="Fecha Final"
            onChange={(event) => setff(event.target.value)}
          ></input>
        </div>

        <div className="expen-3 color-5 topSelect">
          {/* <TextField
            select
            label="Ruta"
            value={selectRutas}
            sx={{ width: "50%" }}
            onChange={handleRutas}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              native: true,
            }}
          >
            {Rutas.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </TextField> */}
        </div>

        <div className="expen-4 color-5">
          <Button
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </div>
      </div>

      <div className="TableReport tableDashboard">
        <div style={{ overflowX: "auto" }}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            clients={true}
            Gtotal={openTotal}
            Gmodal={open}
            porcentaje={true}
            style={{ overflowx: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewDashboardClient;
