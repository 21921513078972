//PAQUETERIAS
import { useEffect, useState, useRef} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
//COMPONENTES
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
import AlertResponse from "../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_route_list, put_sector } from "../../services/businesspartners/businesspartners";

function EditSector(props) {
  const dialog = useDialog();
  const notificationSystemRef = useRef();
  const [routes, setroutes] = useState();
  const[numsector, setnumsector] = useState(props.data.original.number_sector);
  const[namesector, setnamesector] = useState(props.data.original.name_sector);
  const[route, setroute] = useState(props.data.original.id_route_fk);
  const[bandera, setbandera]=useState(true);

  useEffect(()=>{
    handleroute();
  },[]);

//animacion de boton de carga
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}
//alertas personalizadas
function alertascreate(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'tr', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'tr', 
      autoDismiss: 60, 
    });
  }  
}
  
//consulta todas las rutas
const handleroute = async () => {
  try {
    var d = await get_route_list();
    setroutes(d === null ? [] : d.data);
  } catch (error) {
    console.log("error", error);
  } finally {
  }   
}

const updatesector = async () => {

    if(namesector===props.data.original.name_sector && numsector===props.data.original.number_sector && route===props.data.original.id_route_fk){
      alertascreate("Ninguna cambio realizado", false);
    }
    else{
      console.log("entra")
      props.reconsultar(true);
      refresh_token ();
      setbandera(false);
      var data = {
      "numberSector":numsector,
      "nameSector":namesector,
      "idRoute":route
    }    
    try {
      const dt = await put_sector(data, props.data.original.id);
      console.log(dt);
      props.alertas("Sector actualizado con éxito", true);
      dialog.close();
      props.handleSubmit();
    } catch (err) {  
      console.log(err);
      catchErrorModul("Sectores", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          if(err?.response?.status === 500){
            alertascreate("Error al actualizar, vuelva a intentar", false);
            setbandera(true);
            props.reconsultar(false);
          }else{ 
            alertascreate(err?.response?.data?.errorMessage, false);
            setbandera(true);
            props.reconsultar(false);
          }
    }
  ;}    
}
  return (
    <div>
      <div className="addpointsalecss">
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
  
          <Box>
            <TextField
              label="Nombre"
              value={namesector}
              disabled={!bandera}
              sx={{ m: 1.5, width: "47ch" }}
              onChange={(event) => setnamesector(event.target.value.toUpperCase())}
              InputLabelProps={{ shrink: true }}

            ></TextField>
            <br/>
            <TextField
              label="Número"
              type={"number"}
              disabled={!bandera}
              value={numsector}
              sx={{ m: 1.5, width: "20ch" }}
              onChange={(event) => setnumsector(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            <TextField
              select
              disabled={!bandera}
              label="Ruta"
              defaultValue={""}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              sx={{ m: 1.5, width: "25ch" }}
              onChange={(event) => setroute(event.target.value)}  
            >
             {
                routes?.parametersList?.map((option) => (
                
                props.data.original.route===option.route?
                <option
                   key={option.idRoute}
                   value={option.idRoute}
                   selected
                 >
                  {option.route}
                 </option>:
                 <option
                 key={option.idRoute}
                 value={option.idRoute}
               >
                {option.route}
               </option>

              ))
            }  
             </TextField>
            
          </Box>
          <br></br>
          <br></br>
          <Box>
            {
              bandera===true?
              <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={updatesector}
            >
              Guardar Cambios
            </Button>:
            <Button
            variant="contained"
            style={{"backgroundColor":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={updatesector}
          ><Spinner/></Button>
          }  
          <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default EditSector;
 