//PAQUETERIAS
import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
//COMPONENTES
//IMAGENES
import Spinner from "../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
//ESTILOS
const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const ObjetoImp = [
  { value: "01", label: "No objeto de impuesto" },
  { value: "02", label: "Sí objeto de impuesto" },
  { value: "03", label: "Sí objeto del impuesto y no obligado al desglose" },

];


const Put_concept = ({Concepton,Clave_prod_servn,clave_unidadn,Descripcionn,No_identificacionn,Objeto_impn,Peso_en_kgn,Unidadn,Valor_unitarion,id_concept}) => {
  const [open, setOpen] = React.useState(false);
  const [Concepto, setConcepto] = React.useState(Concepton);
  const [Clave_prod_serv, setClave_prod_serv] = React.useState(Clave_prod_servn);
  const [Clave_unidad, setClave_unidad] = React.useState(clave_unidadn);
  const [Descripcion, setDescripcion] = React.useState(Descripcionn);
  const [No_identificacion, setNo_identificacion] = React.useState(No_identificacionn);
  const [Objeto_imp, setObjeto_imp] = React.useState(Objeto_impn);
  const [Peso_en_kg, setPeso_en_kg] = React.useState(Peso_en_kgn);
  const [Unidad, setUnidad] = React.useState(Unidadn);
  const [Valor_unitario, setValor_unitario] = React.useState(Valor_unitarion);
  const [unidadData, setunidadData] = React.useState([]);
  const [pro_servData, setpro_servData] = React.useState([]);
  const dialog = useDialog(); 
  const [Clave_prod_serv_input, setClave_prod_serv_input] = React.useState(true);
  const [Clave_unidad_input, setClave_unidad_input] = React.useState(true);

  const handelConcepto = (event) => { setConcepto(event?.target?.value);};
  const handelClave_prod_serv = (event) => { setClave_prod_serv(event?.target?.value); if(event?.target?.value==="OTRO"){setClave_prod_serv_input(false)}};
  const handelClave_unidad = (event) => { setClave_unidad(event?.target?.value); 
    if(event?.target?.value==="OTRO"){setClave_unidad_input(false); setUnidad("");}
    else{
      const result = unidadData.filter(cuni => cuni.clave_unidad === event?.target?.value);
      console.log(result);
      setUnidad(result[0]?.unidad);
    }};
  const handelDescripcion = (event) => { setDescripcion(event?.target?.value); };
  const handelNo_identificacion = (event) => { setNo_identificacion(event?.target?.value); };
  const handelObjeto_imp = (event) => { setObjeto_imp(event?.target?.value); };
  const handelPeso_en_kg = (event) => { setPeso_en_kg(event?.target?.value); };
  const handelUnidad = (event) => {setUnidad(event?.target?.value);
  };
  const handelValor_unitario = (event) => { setValor_unitario(event?.target?.value); };
  const handelClave_prod_serv_input = (event) => { setClave_prod_serv(event?.target?.value);};

  const handelClave_unidad_input = (event) => {setClave_unidad(event?.target?.value);}

  const handleSubmit = (event) => {
    event?.preventDefault();
    var data={
      concept: Concepto,
      clave_prod_serv: Clave_prod_serv,
      clave_unidad: Clave_unidad ,
      descripcion: Descripcion,
      no_identificacion: No_identificacion, 
      objeto_imp: Objeto_imp, 
      peso_en_kg: Number(Peso_en_kg),  
      unidad: Unidad,
      valor_unitario: Number(Valor_unitario),
    };  
    
    setOpen(true);
    let axiosConfig = {headers: { Authorization: sessionStorage.getItem("getIdTokenRefresh"), "access-token": sessionStorage.getItem("getAccesTokenRefresh"),"Content-Type": "application/json",}};
    axios
    .put(`https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/concepts/${id_concept}`, data, axiosConfig)
    .then((res) => { 
      setOpen(false); 
      alert('Se actualizo correctamente el concepto'); 
      window.location="/consult_concept"; })
    .catch((err) => { 
      catchErrorModul("Conceptos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      setOpen(false); alert("Error.,"+ err.response.data.errorMessage);
     })
  
  
  };
  const handleUnidades = (event) => {
    event?.preventDefault();
    axios
      .get(`https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/sat_catalogue/units`,{headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setunidadData(data);
        const result = data?.filter(cni => cni.clave_unidad === clave_unidadn);
        if(result.length===0){
          setClave_unidad_input(false);
        }
      })
      .catch((err) => {
        setunidadData([]);

      });
  };

  const handleprod_serv = (event) => {
    event?.preventDefault();
    axios
      .get(`https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/sat_catalogue/prod_serv`,{headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        setpro_servData(data);

        const result = data?.filter(cps => cps.clave_prod_serv === Clave_prod_servn);
        if(result.length===0){
          setClave_prod_serv_input(false);
        }

      })
      .catch((err) => {
        setpro_servData([]);

      });
  };
  
  
  useEffect(() => {    
    handleprod_serv();
    handleUnidades();
  }, []);

  return (
    <div>
        <div>
            <Modal
              open={open}
              onClose={open}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
                <Box sx={style2} >
                  <div className="centermodaldescarga">
                    <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                      <img src={Spinner}></img>
                    </Typography>
                    <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                      {"Creando configuración..."}
                    </Typography>
                    </div>
                  </Box>
             </Modal>
        </div>
      <div>
      <Box>
            <ValidationTextField
              label="Concepto"
              variant="outlined"
              placeholder="Concepto"
              sx={{ m: 2, width: "89%" }}
              onChange={handelConcepto}
              value={Concepto}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

            <div style={{display:((Clave_prod_serv_input)===true?"":"none") }}>
            <Box>

            <TextField
              select
              label="Clave producto/servicio"
              value={Clave_prod_serv}
              sx={{ m: 1.5, width: "41.5%" }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {pro_servData.map((option) => (
                <option
                  key={option.clave_prod_serv}
                  value={option.clave_prod_serv}
                  selected={option.clave_prod_serv}
                >
                  {option.clave_prod_serv + " " + "(" + option.descripcion + ")"}
                </option>
              ))}
            </TextField>


            <ValidationTextField
              label="N. Identificación"
              variant="outlined"
              placeholder="N. Identificación"
              sx={{ m: 2, width: "42%" }}
              onChange={handelNo_identificacion}
              value={No_identificacion}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

            </div>

            
            <Box>


          <div style={{display:((Clave_prod_serv_input)===true?"none":"")}}>
          <ValidationTextField
              label="Clave producto/servicio"
              variant="outlined"
              placeholder="Clave producto/servicio"
              sx={{ m: 2, width: "42%" }}
              onChange={handelClave_prod_serv_input}
              value={Clave_prod_serv}
              InputLabelProps={{ shrink: true }}
            />

            <ValidationTextField
              label="N. Identificación"
              variant="outlined"
              placeholder="N. Identificación"
              sx={{ m: 2, width: "42%" }}
              onChange={handelNo_identificacion}
              value={No_identificacion}
              InputLabelProps={{ shrink: true }}
            />
            </div>
            </Box>


          <div style={{display:((Clave_unidad_input)===true?"":"none") }}>
          <Box>

            <TextField
              select
              label="Clave Unidad"
              value={Clave_unidad}
              sx={{ m: 1.5, width: "41.5%" }}
              onChange={handelClave_unidad}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {unidadData.map((option) => (
                <option
                  key={option.clave_unidad}
                  value={option.clave_unidad}
                  selected={option.clave_unidad}
                >
                  {option.clave_unidad + " " + "(" + option.unidad + ")"}
                </option>
              ))}
            </TextField>
            <ValidationTextField
              label="Unidad"
              variant="outlined"
              placeholder="Unidad"
              sx={{ m: 2, width: "42%" }}
              value={Unidad}
              InputLabelProps={{ shrink: true }}
            />
            </Box>

            </div>

            <div style={{display:((Clave_unidad_input)===true?"none":"") }}>
            <Box>

            <ValidationTextField
              label="Clave Unidad"
              variant="outlined"
              placeholder="Unidad"
              sx={{ m: 2, width: "42%" }}
              onChange={handelClave_unidad_input}
              value={Clave_unidad}
              InputLabelProps={{ shrink: true }}
            />

            <ValidationTextField
              label="Unidad"
              variant="outlined"
              placeholder="Unidad"
              sx={{ m: 2, width: "42%" }}
              onChange={handelUnidad}
              value={Unidad}
              InputLabelProps={{ shrink: true }}
            />
            </Box>

            </div>

           

            





          <Box>
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>


          <Box>

            <TextField
              select
              label="Objeto Impuesto"
              value={Objeto_imp}
              sx={{ m: 1.5, width: "26%" }}
              onChange={handelObjeto_imp}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {ObjetoImp.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.label}
                >
                  {option.label}
                </option>
              ))}
            </TextField>


            <ValidationTextField
              label="Peso en KG"
              variant="outlined"
              placeholder="Peso en KG"
              sx={{ m: 2, width: "26%" }}
              onChange={handelPeso_en_kg}
              value={Peso_en_kg}
              InputLabelProps={{ shrink: true }}
            />

            <ValidationTextField
              label="Valor Unitario"
              variant="outlined"
              placeholder="Valor Unitario"
              sx={{ m: 2, width: "26%" }}
              onChange={handelValor_unitario}
              value={Valor_unitario}
              InputLabelProps={{ shrink: true }}
            />
          </Box>


          <br></br>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Actualizar
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>

          </Box>
      </div>
    </div>
    
  );
}
export default Put_concept;
