import axios from "axios";
import { numberData} from "./numberData";
import config from "../../config";

export async function pagination(divnum, origen, yy) {
  var promises = [];
  const urlbp = `${config?.baseURLBPTEST}`;
  const urlreport =  `${config?.baseURLREPORT}`;
  console.log(urlbp);

  var url = "";
  var urlnumber = "";

  switch (origen) {
    case "bp":
      url = `${urlbp}/test/businesspartners?role=FACTURA&`;
      urlnumber = `${urlbp}/test/businesspartners`;
      break;
    case "sumary":
      url = `${urlreport}/prod/annual-summary-by-pos?`;
      urlnumber = `${urlreport}/prod/annual-summary-by-pos`;
      break;
    case "pointofsales":
      url = `${urlbp}/development/pointsofsale?`;
      urlnumber = `${urlbp}/development/pointsofsale`;
      break;
    case "pr":
      url = `${urlbp}`;
      break;
    case "ot":
      url = `${urlbp}`;
      break;
    case "cabinets":
      url = `https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com/development/cabinets?`;
      urlnumber = `https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com/development/cabinets`;
      break;

    default:
      url = urlbp;
      break;
  }
  
  var totalData = numberData(urlnumber, yy);
 
  try {
    const resultado = await totalData;
    const miVariable = resultado;
    console.log(miVariable);
    const offsetInt = Math.floor(miVariable===null?0:miVariable / divnum);
    const lim = offsetInt * divnum;
    const limitF = null?0:miVariable - lim;
    const limitFinal = limitF < 0 ? lim : limitF;
    console.log(offsetInt);
    console.log(lim);
    console.log(limitF);
    console.log(limitFinal);
    var cont = divnum - 1;


    
      for (let i = 0; i <= cont; i++) {
        promises.push(
          axios.get(
            `${url}offset=${i === 0 ? 0 : offsetInt * i}&limit=${
              i === cont ? offsetInt * (i + 1) + limitFinal : offsetInt * (i + 1)
            }`,
            {
              params: {year: yy},
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
            }
          )
        );
      }
   
    const results = await Promise.all(promises);
    console.log(results);
    const ndata = results.flatMap((result) => result?.data || []);
    console.log(ndata);
    return ndata;
  } catch (error) {
    console.log(error);
    return null;
  }
}
