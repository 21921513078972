//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog, Confirm } from "react-st-modal";

//COMPONENTES
import TableContainer from "../../main_components/Table/TableContainer";
import { get_producst_line } from "../../services/inventory/inventory";
import CREATE_UPDATE from "./tools/create_update";
import { delete_producst_line } from "../../services/inventory/inventory";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import VIEW from "../../imgComponents/lupa.png";

import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";

const PRODUCTS_LINE = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando impuestos...");
    try {
      const dt = await get_producst_line();
      setData(dt.data);
      setOpen(false);
    } catch (err) {
      setData([]);
      setMenssage("");
    }
  };

  const handle_products = async (id) => {
    sessionStorage.setItem("id_product_line", id);
    window.location = "/products";
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() {
            window.location = "/consult_configure_taxes";
          }
          const handle_delete_producst_line = async (id) => {
            try {
              setMenssage("Eliminando lista de producto...");
              setOpen(true);
              await delete_producst_line(id);
            } catch (err) {
              setOpen(false);
              setMenssage("");
              console.log();
              alert("Error.," + JSON.stringify(err.response.data));
            }
          };

          return (
            <div>
              <img
                alt=""
                title="Actualizar"
                src={SAVE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <CREATE_UPDATE
                      name_line={row.original.name_line}
                      code={row.original.code}
                      description={row.original.description}
                      enable={row.original.enable}
                      method={"UPDATE"}
                      id={row.original.id}
                      name_supplier={row.original.business_partner}
                      id_supp={row.original.id_business_partner_provider_fk}
                    ></CREATE_UPDATE>,
                    {
                      className: "modalTItle",
                      title: "Seleccione la configuracion del impuesto",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="centerText, taxes"
              ></img>
              <img
                alt=""
                title="Ver productos"
                src={VIEW}
                className="centerText, taxes"
                onClick={() => handle_products(row.original.id)}
              ></img>
               <img
                alt=""
                title="Eliminar Linea de productos"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Impuesto?",
                    "Eliminar Impuesto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    handle_delete_producst_line(row.original.id);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Nombre de la linea",
        accessor: "name_line",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Proveedor",
        accessor: "business_partner",
      },
      {
        Header: "Activo",
        accessor: (d) => {
          return d.enable === true ? "Si" : "NO";
        },
      },
    ],
    []
  );

  return (
    <div className="">
      <div></div>

      <div>
        <h1>CONSULTAR LINEA DE PRODUCTOS</h1>
      </div>
      <br></br>

      <div className="TableTaxes">
        <div className="">
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
          />
        </div>
      </div>
    </div>
  );
};

export default PRODUCTS_LINE;
