import UpdateBP from "../../componentsUpdateBusnes/UpdateBP";
import  { Component } from "react";

export class UpdateBusnes02 extends Component {
 

  render() {
   
    return (
      <div className="tc" >
        <UpdateBP />
      </div>
    );
  }
}

export default UpdateBusnes02;
