import  { Component } from "react";
import TablePointOfSale from "../../../componentSociosNegocios/componentOfSale/TablePointOfSale";


export class setPointsOfSale extends Component {
  

  render() {

      
      return (    
      
      <div className="tc" >
        <TablePointOfSale></TablePointOfSale>
      </div>
          );
       }     
}

export default setPointsOfSale;


