import TableGrupExpense from '../../components_Expenses/grup_expense/Consult_Grup_Expense';
import  { Component } from "react";


export class SetConsulGrupExpense extends Component {
 
  render() {


      return (    
      
      <div className="tc">
      <TableGrupExpense></TableGrupExpense>

      </div>
          );
       }     
}

export default SetConsulGrupExpense;
