import { Component } from "react";
import TableRecoverPartner from "../../../componentSociosNegocios/componentRecoverPartner/TableRecoverPartner";
export class SetRecoverPartner extends Component {
  render() {
    return (
      <div className="tc">
        <TableRecoverPartner />
      </div>
    );
  }
}

export default SetRecoverPartner;
