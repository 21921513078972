import { Component } from "react";
import TablePointOfSaleWS from "../../../componentSociosNegocios/Componentpointsofsalewithoutsector/TablePointOfSaleWS";


export class setPointsOfSaleWS extends Component {
 
  render() {

      return (    
      
      <div className="tc" >
        <TablePointOfSaleWS></TablePointOfSaleWS>
      </div>
          );
       }     
}

export default setPointsOfSaleWS;


