// eslint-disable-next-line import/no-anonymous-default-export
export default {
    baseURLBP : "https://8qpng1m0fj.execute-api.us-east-1.amazonaws.com",
    baseURLIVC : "https://rfpx9okh43.execute-api.us-east-1.amazonaws.com",
    baseURLCONF: "https://lxwr6pudd9.execute-api.us-east-1.amazonaws.com",
    baseURLREPORT: "https://e26bmbbxf1.execute-api.us-east-1.amazonaws.com",
    baseURLHR: "https://yqqevspzyi.execute-api.us-east-1.amazonaws.com",
    baseURLClC : "https://b7z3hke4a8.execute-api.us-east-1.amazonaws.com",
    baseURLAss: "https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com",
    baseURLINT: "https://m5c1a5ucj2.execute-api.us-east-1.amazonaws.com",
    baseURLPVD:"https://h93zxd4wrb.execute-api.us-east-1.amazonaws.com",
    baseURLDB:"https://zaqp4ihdn9.execute-api.us-east-1.amazonaws.com",
    baseURLBPTEST:"https://lhtfgusul2.execute-api.us-east-1.amazonaws.com",
    baseurlTetstPOnint: "https://lhtfgusul2.execute-api.us-east-1.amazonaws.com"
}



