import {useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { put_warehouse } from "../../services/configuration/configuration";

function EditWarehouse(props) {

const dialog = useDialog();
const [bandera, setbandera]=useState(true);
const [codew, setcodew]=useState(props.row.original.code);
const [namew, setnamew]=useState(props.row.original.warehouse_name);
const [descw, setdescw]=useState(props.row.original.description);
const [typew, settypew]=useState(props.row.original.type);
const [allow_display, setallow_display]=useState(props.row.original.allow_display);
const [subtype, setsubtype]=useState(props.row.original.subtype);
 
//actualizar almacen
const updateW = async () => {

props.reconsultar(true);
setbandera(false);
await refresh_token ();
  var data = {
    "warehouse_name": namew, //String = Nombre del almacén
    "code": codew,//String = Clave que tendrá el almacén
    "description": descw, //String = Descripción del almacén
    "type": typew,//String = Tipo de almacén "CONGELADORES" | "PRODUCTO"
    "allow_display": allow_display===true?true:(allow_display==="false"?false:true),//Boolean = Habilitar su impresión en consultas
    "subtype": subtype===0?null:subtype //String = Subtipo de almacén "REPARTO" | null
}

  try {
    const dt = await put_warehouse(props.row.original.id, data);
    console.log(dt);
    props.alertas("Almacén actualizado con éxito", true);
    props.reconsultar(false);
    dialog.close();
    props.handleSubmit();
  } catch (err) {  
    console.log(err);
    catchErrorModul("Almacén", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
    props.alertas("No se pudo actualizar el almacén", false);
    props.reconsultar(false);
    setbandera(true);
  };

}
 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


return (
    <div>
      <div className="addrulesSE">
          <div className="gridwarehouse1">
            <div className="wh1">
            <TextField
              label="Código"
              disabled={!bandera}
              value={codew}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcodew(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="wh2">
            <TextField
              label="Nombre Almacén"
              disabled={!bandera}
              value={namew}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setnamew(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridwarehouse2">
          <div className="wh3">
          <TextField
              label="Descripción"
              disabled={!bandera}
              value={descw}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setdescw(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridwarehouse3">
            
          <div className="wh4">
          <TextField
              select
              disabled={!bandera}
              value={allow_display}
              label="Permitir Visualización"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setallow_display(event.target.value)}  
            >
              <option key={1} value={true} selected>
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
          </div>
          <div className="wh5">
          <TextField
              select
              label="Tipo"
              value={typew}
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settypew(event.target.value)}  
            >
              <option key={1} value={"PRODUCTO"} selected >
                  {"PRODUCTO"}
              </option>
              <option key={2} value={"CONGELADORES"}>
                  {"CONGELADORES"}
              </option>
            </TextField>
          </div>
          <div className="wh6">
          <TextField
              select
              disabled={!bandera}
              label="Subtipo"
              value={subtype}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsubtype(event.target.value)}  
            >
              <option key={1} value={0} selected >
                  {"Seleccione"}
              </option>
              <option key={2} value={"REPARTO"}>
                  {"REPARTO"}
              </option>
            </TextField>
          </div>
          </div>
         <br></br>
          <br></br>
          <Box>
            {
              bandera===true?              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={updateW}
          >
            Actualizar Almacén
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
    </div>
  );
}
export default EditWarehouse;
