import { useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {useDialog} from "react-st-modal";
import { catchErrorModul } from "../main_components/catchErr/catchError";
import { refresh_token  } from "../main_components/tokens/tokenrefresh";

function SetBonuses() {

  const dialog = useDialog();

  const[numpack, setnumpack] = useState(0);
  const[namepack, setnamepack] = useState("");
  function handelonCLik(){

  if (numpack === 0 || namepack === "") {
      alert("Datos vacios");
    } else {
      refresh_token ();
      console.log(numpack);
      console.log(namepack);
      var data = {
        numberBP: numpack,
        nameBP: namepack,
      };
      var url =
        "https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/bonification_packages";
      var axiosConfig = {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      };
      axios
        .post(url, data, axiosConfig)
        .then((response) => {

          console.log(response);
       
          sessionStorage.setItem("idpack",response.data.bonificationPackageID);
          sessionStorage.setItem("name_pack",namepack);
          sessionStorage.setItem("num_pack",numpack);

          console.log(sessionStorage.getItem("idpack"));
          console.log(sessionStorage.getItem("name_pack"));
          console.log(sessionStorage.getItem("num_pack"));
          window.location = "/sale_add_bonuses";  
        })
        .catch((err) => {
          
          catchErrorModul("Bonificaciones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
       
          console.log("error", err);
          alert("Paquete ya existente");
        });
    }
  }

  return (
    <div>
      <div>
          <Box>
            <TextField
              label="Nombre"
              required
              sx={{ m: 1.5, width: "25ch" }}
              onChange={(event) => setnamepack(event.target.value)}
              InputLabelProps={{ shrink: true }}

            ></TextField>
            <TextField
              label="Número"
              type={"number"}
              required
              sx={{ m: 1.5, width: "25ch" }}
              onChange={(event) => setnumpack(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
          </Box>
          <br></br>
          <br></br>
          <Box>
            <Button
              style={{"background-color":"green"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={handelonCLik}
            >
              Crear
            </Button>
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
              cancelar
            </Button>
          </Box>
      </div>
    </div>
  );
}

export default SetBonuses;
