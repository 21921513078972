//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import TableContainer from "../../components_cashclosing/Table_Container_Cashclosing";
import SubTable from "./subTable";
import Set_vehicle from "./set_vehicle";
import Pet_vehicle from "./put_vehicle";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "./tableclientes.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import CREATE from "../../imgComponents/create.png";

const Consult_vehicle = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando Vehículos...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const URL_BASE = "https://ua5rm92fs2.execute-api.us-east-1.amazonaws.com";

  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData([]);
    setIsLoding(true);
    setTimeout(() => {
      setmodalGeneral(false);

      axios
        .get(`${URL_BASE}/development/vehicles`, {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          setmodalT(false);

          setData(data);
          setIsLoding(false);
          if (msjAler?.length > 1) {
            notificationSystemRef.current.addNotification({
              message: (
                <AlertResponse msj={msjAler} view={true}></AlertResponse>
              ),
              level: "success",
              position: "br",
              autoDismiss: 10,
            });
          }
        })
        .catch((err) => {
          var errroM = err?.response?.data?.errorMessage;
          setData([]);
          notificationSystemRef.current.addNotification({
            message: (
              <AlertResponse
                msj={
                  errroM === undefined
                    ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                    : errroM
                }
                view={false}
              ></AlertResponse>
            ),
            level: "error",
            position: "br",
            autoDismiss: 60,
          });
          setmodalT(false);
        });
    }, 2000);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [
    
      {
        Header:()=>{
          return (
            <div>
              <p style={{color:"white"}} >Acciones</p>
              <img
                title="Crear vehículo"
                src={CREATE}
                className="cursorPointer"
                alt=""
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_vehicle
                    ></Set_vehicle>,
                    {
                      className: "modalSetBussnes",  

                      title: "Crear vehículo",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "vehículo creado correctamente"
                    );
                  }
                }}
              ></img>
            </div>
          );
        },
        id: "Acciones",

        Cell: ({ row }) => {
          function DeleteBusnes() {
            setmenssage("Eliminando vehículo");
            setmodalGeneral(true);
            let axiosConfig = {
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
            };
            setIsLoding(true);
            setmensajeDescarga("Eliminando");
            axios
              .delete(`${URL_BASE}/development/vehicles/${row.original.id}`, axiosConfig)
              .then(() => {
                handleReconsulta(true, "Vehículo eliminado correctamente");
              })

              .catch((err) => {
                var errroM = err?.response?.data?.errorMessage;
                notificationSystemRef.current.addNotification({
                  message: (
                    <AlertResponse
                      msj={
                        errroM === undefined
                          ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                          : errroM
                      }
                      view={false}
                    ></AlertResponse>
                  ),
                  level: "error",
                  position: "br",
                  autoDismiss: 60,
                });
              });
          }
         
          return (
            <div>
              <img
                title="Actualizar Vehículo"
                src={SAVE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    // eslint-disable-next-line react/jsx-pascal-case
                    <Pet_vehicle
                      vehicular_config={row.original.vehicular_config}
                      license_plate={row.original.license_plate}
                      asegura_resp_civil={row.original.asegura_resp_civil}
                      poliza_resp_civil={row.original.poliza_resp_civil}
                      id_model_fk={row.original.id_model_fk}
                      description={row.original.description}
                      asegura_med_ambiente={row.original.asegura_med_ambiente}
                      poliza_med_ambiente={row.original.poliza_med_ambiente}
                      asegura_carga={row.original.asegura_carga}
                      poliza_carga={row.original.poliza_carga}
                      prima_seguro={row.original.prima_seguro}
                      id_route_fk={row.original.id_route_fk}
                      id_vehicle={row.original.id}
                      year_model={row.original.year_model}
                      id_brand_model_fk={row.original.id_brand_model_fk}
                      name_brand={row.original.brand}
                    ></Pet_vehicle>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar vehículo",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "Vehículo actualizado correctamente"
                    );
                  }
                }}
              ></img>

              <img
                src={DELETE}
                alt=""
                className="cursorPointer"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este vehículo?",
                    "Eliminar vehículo",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteBusnes();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Ruta",
        accessor: "route",
      },
      {
        Header: "Marca",
        accessor: "brand",
      },
      {
        Header: "Modelo",
        accessor: "model",
      },
      {
        Header: "Descripción",
        accessor: "description",
      },
      {
        Header: "Configuración Vehícular",
        accessor: "vehicular_config",
      },
      {
        Header: "Placa",
        accessor: "license_plate",
      },
      {
        Header: "Año del Modelo",
        accessor: "year_model",
      },
      {
        Header: "Aseguradora Responsabilidad Civil",
        accessor: "asegura_resp_civil",
      },
      {
        Header: "Poliza Responsabilidad Civil",
        accessor: "poliza_resp_civil",
      },
      {
        Header: "Aseguradora Medio Ambiente",
        accessor: "asegura_med_ambiente",
      },
      {
        Header: "Poliza Medio Ambiente",
        accessor: "poliza_med_ambiente",
      },
      {
        Header: "Aseguradora de Carga",
        accessor: "asegura_carga",
      },
      {
        Header: "Poliza de Carga",
        accessor: "poliza_carga",
      },
      {
        Header: "Prima de Seguro",
        accessor: "prima_seguro",
      },
      {
        Header: "Fecha de registro",
        accessor: "date_register",
      },
    ],
    []
  );




  

  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div className="">
          <div className="TableCOnsultaClientes">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
      </div>
    </div>
  );
};

export default Consult_vehicle;
