//PAQUETERIAS
import { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { StickyTable } from "react-sticky-table";
import {CustomDialog, Confirm} from "react-st-modal";
import NotificationSystem from 'react-notification-system';
import MODAL_TABLE from "../main_components/modal/modal_Table";
//COMPONENTES

import AlertResponse from "../main_components/alerts/alertResponse";
import TableContainer from "../componentSociosNegocios/TableContainer"
//ESTILOS
import "../components_cfdi/estilo.css";
import '../componentSociosNegocios/componentSectors/bonuses.css';
import "../components_cfdi/modal.css";
import "./accountinga.css";
//IMAGENES/ICONOS
import Delete from "../imgComponents/borrar.png";
import CREATE from "../imgComponents/create.png";
import Edit from "../imgComponents/save.png";
import { refresh_token  } from "../main_components/tokens/tokenrefresh";

import CreateAcounting from "./CreateAcounting";
import EditAccounting from "./EditAccounting";

const TableAccountingAccount= () => {

  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen]=useState(false);
  var consulta=0;
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  
  useEffect(()=>{
    handleSubmit();
  },[]);


function alertas(msj,status){

  if(status===true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}
const reconsultar=(n)=>{
  if(n===true){
    setmodalT(true);  
  }else{
    setmodalT(false);
  } 
}
//proceso de consultar todas las cuentas contables
const handleSubmit = () => {

  console.log("entra a consultar todas las cuentas contables");
  refresh_token ();
  
  if(consulta===0){
    consulta=consulta+1;
    setmenssage("Consultando Cuentas Contables...");
    setmodalGeneral(true);
    setmodalT(true);
  }else{
    setmodalT(true);
  }
  setopen(true);
  setData([]);

    setTimeout(() => {
      axios
        .get(
          `https://r1lwievqy2.execute-api.us-east-1.amazonaws.com/development/accounting-account`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.acc_accounts;
          setData(data);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);
          setopen(false);
          setmodalGeneral(false);
          setmodalT(false);
          });
    }, 2000);
};

const columns = useMemo(
  () => [
    {
      Header:()=>(
        <div>
          <img
            id="crearCCl"
            src={CREATE}
            onClick={async () => {
              const result = await CustomDialog(               
             <div>
                <CreateAcounting handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></CreateAcounting>
             </div>,
           {
             className: "modalTItle",
             title:"Agregar cuenta contable",
             showCloseIcon: true,
             isCanClose:false,
           }
       );
     }}
            title="Agregar cuenta contable"
            className="centerText, cancel"
             
          ></img>
        </div>
      ),
      
      id: "Acciones",
      Cell: ({ valor, row }) => {
      function DeleteSector(){  
      
      setmodalT(true);
      refresh_token ();
      setopen(true);
      setData([]);

      setTimeout(() => {
        var url = `https://r1lwievqy2.execute-api.us-east-1.amazonaws.com/development/accounting-account/${row.original.id}`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
        axios
        .delete(url,axiosConfig)
        .then((response) => {
          console.log(response);
          alertas("Cuenta Contable Eliminada", true);
          handleSubmit();
        })
        .catch((err) => {
          console.log("error", err);
          setopen(false);
          alertas("Error, no se pudo eliminar la cuenta contable", false);
          handleSubmit();
        });
       }, 2000);
      }
        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                  const result = await Confirm('¿Esta usted seguro de eliminar la cuenta contable?','Eliminar Cuenta Contable','Si','No');
                  if(result){
                    DeleteSector()
                    }
                  }            
                }
              className="centerText, cancel"
              title="Eliminar Cuenta Contable"
            ></img>
              &nbsp;
            <img
              src={Edit}
              onClick={
                async () => {
                  const result = await CustomDialog(               
                 <div>
                    <EditAccounting row={row} handleSubmit={handleSubmit} alertas={alertas} reconsultar={reconsultar}></EditAccounting>
                 </div>,
               {
                 className: "modalTItle",
                 title:"Editar Cuenta Contable",
                 showCloseIcon: true,
                 isCanClose:false,
               }
           ); 
         }
        }
              className="centerText, cancel"
              title="Editar Cuenta Contable"
            ></img>
          </div>
        );
      },
    },
    {
      Header:"Código",
      accessor: "code"
    },
    {
      Header:"Nombre",
      accessor:"name"
    },
    {
      Header:"Concepto",
      accessor:"expense_concept"
    },
    {
      Header:"Efecto",
      accessor:"efect"
    },
    {
      Header:"Tipo",
      accessor:"type"
    }
    ],
    []
);
  return (
    <>
<div className="TableAccounting">
  <div className="">         
  <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
<MODAL_TABLE
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></MODAL_TABLE>
          <>
          <div className="titletable">
            <br/>
          <h1>Datos de Cuentas Contables</h1>
          </div>
          <StickyTable>
            <TableContainer
              paginado={"Cuentas Contables"}
              consultas={"Cuentas Contables Consultadas"}
              exportar={true}
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
              Gmodal={open}
            
            />
          </StickyTable>
          </>   
          </div>
        </div>
    </>
  );
}

export default TableAccountingAccount;
