//PAQUETERIAS
import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";

import { useDialog } from "react-st-modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupSharpIcon from "@mui/icons-material/GroupSharp";
import Checkbox from "@mui/material/Checkbox";

//COMPONENTES
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { create_products_line } from "../../../services/inventory/inventory";
import { update_products_line } from "../../../services/inventory/inventory";
import { get_businesspartners_supplier } from "../../../services/businesspartners/businesspartners";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import MODAL_TABLE from "../../../main_components/modal/modal_Table";
import NotificationSystem from "react-notification-system";
import AlertResponse from "../../../main_components/alerts/alertResponse";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { get_producst_line } from "../../../services/inventory/inventory";
import { get_products_brands } from "../../../services/inventory/inventory";
import uinits from "../../../dataComponets/inventory/unit.json";
import type_product from "../../../dataComponets/inventory/type.json";
import uinits2 from "../../../dataComponets/inventory/unit2.json";
import dms from "../../../dataComponets/inventory/dms.json";
//IMAGENES
//ESTILOS
import "./products.css";

const op1 = [
  { value: "CLIENTE", label: "CLIENTE" },
  { value: "PROVEEDOR", label: "PROVEEDOR" },
  { value: "AMBOS", label: "AMBOS" },
];

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE = (props) => {
  const [code, setcode] = React.useState("");
  const [product_name, setproduct_name] = React.useState("");
  const [description, setdescription] = React.useState("");
  const [barcode, setbarcode] = React.useState("");
  const [type, settype] = React.useState("CONGELADOS");
  const [product_line_data, setproduct_line_data] = React.useState("CLIENTE");
  const [enable, setenable] = React.useState(true);
  const [only_promo, setonly_promo] = React.useState(false);
  const [cost, setcost] = React.useState(0);
  const [pieces, setpieces] = React.useState(1);
  const [clave_prod_serv, setclave_prod_serv] = React.useState(0.1);
  const [clave_unidad, setclave_unidad] = React.useState(0.1);
  const [clave_unidad_2, setclave_unidad_2] = React.useState(0.1);
  const [weight, setweight] = React.useState(0.1);
  const [iva0, setiva0] = React.useState("");
  const [iva16, setiva16] = React.useState("");
  const [ieps8, setieps8] = React.useState("");

  const [minimum_sales_unit, setminimum_sales_unit] = React.useState("PIEZA");
  const [minimum_sales_unit2, setminimum_sales_unit2] = React.useState("PIEZA");
  const [unit, setunit] = React.useState("LITROS");
  const [unit_dms, setunit_dms] = React.useState("ZCU");
  const [unit_conversion, setunit_conversion] = React.useState(1);

  const [validate_code, setvalidate_code] = React.useState(true);
  const [validate_product_name, setvalidate_product_name] =
    React.useState(true);
  const [validate_description, setvalidate_description] = React.useState(true);
  const [validate_barcode, setvalidate_barcode] = React.useState(true);
  const [validate_type, setvalidate_type] = React.useState(true);
  const [validate_cost, setvalidate_cost] = React.useState(true);
  const [validate_weight, setvalidate_weight] = React.useState(true);
  const [validate_unit_conversion, setvalidate_unit_conversion] = React.useState(true);
  const [validate_pieces, setvalidate_pieces] = React.useState(true);
  const [validate_clave_prod_serv, setvalidate_clave_prod_serv] = React.useState(true);
  const [validate_clave_unidad, setvalidate_clave_unidad] = React.useState(true);
  const [validate_clave_unidad2, setvalidate_clave_unidad2] = React.useState(true);

  const [rfc, setrfc] = useState("");

  const [product_line, setproduct_line] = useState([]);
  const [product_brands, setproduct_brands] = useState([]);
  const [brand, setbrand] = useState(1);


  const dialog = useDialog();
  const [modalT, setmodalT] = useState(false);
  const notificationSystemRef = useRef();

  const [value, setValue] = React.useState(0);
  const [detail_product, setdetail_product] = React.useState("");
  const [detailSale, setdetailSale] = React.useState("none");
  const [fiscal, setfiscal] = React.useState("none");
  const [product, setproduct] = React.useState("none");
  const [noSale, setnoSale] = React.useState("none");
  const [checkedGeneral, setcheckedGeneral] = React.useState(true);

  const handle_detail_product = () => {
    setdetail_product("");
    setdetailSale("none");
    setfiscal("none");
    setproduct("none");
    setnoSale("none");
  };

  const handle_sales_unit = () => {
    if (code !== "" && product_name !== "" && barcode !== "" && description !== "" && cost > 0) {
      setdetail_product("none");
      setdetailSale("none");
      setfiscal("none");
      setproduct("none");
      setnoSale("");
    } else {
      code === "" ? setvalidate_code(false) : setvalidate_code(true);
      product_name === "" ? setvalidate_product_name(false) : setvalidate_product_name(true);
      description === "" ? setvalidate_description(false) : setvalidate_description(true);
      barcode === "" ? setvalidate_barcode(false) : setvalidate_barcode(true);
    }
  };

  const handle_fiscal = () => {
    if (
      code !== "" && product_name !== "" && barcode !== "" && description !== "" && cost > 0 && weight>0 &&pieces>0 &&unit_conversion>0) {
      setdetail_product("none");
      setdetailSale("none");
      setfiscal("");
      setproduct("none");
      setnoSale("none");
    } else {
      code === "" ? setvalidate_code(false) : setvalidate_code(true);
      product_name === ""? setvalidate_product_name(false) : setvalidate_product_name(true);
      description === "" ? setvalidate_description(false) : setvalidate_description(true);
      barcode === "" ? setvalidate_barcode(false) : setvalidate_barcode(true);
      weight === "" ? setvalidate_weight(false) : setvalidate_weight(true);
      pieces === "" ? setvalidate_pieces(false) : setvalidate_pieces(true);
      unit_conversion === "" ? setvalidate_unit_conversion(false) : setvalidate_unit_conversion(true);

    }
  };

  useEffect(() => {
    setrfc(rfc);
  }, []);

  useEffect(() => {
    setdetail_product(detail_product);
    setdetailSale(detailSale);
    setfiscal(fiscal);
    setproduct(product);
    setnoSale(noSale);
  }, [setdetail_product, setdetailSale, setfiscal, setproduct, setnoSale]);

  const handlechecked = (event) => {
    setcheckedGeneral(event.target.checked);
    console.log(event.target.checked);
  };

  const consult_get_producst_line = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        console.log("ejecuta");
        setTimeout(async () => {
          console.log("ejecuta 1.5 segundos despues");
          const dt = await get_producst_line();
          setproduct_line(dt.data);
        }, 1500);
      } else {
      }
    } catch (err) {
      setproduct_line([]);
    }
  };


  const consult_get_products_brands = async (event) => {
    event?.preventDefault();
    try {
      var rf_token = await refresh_token();
      if (rf_token === true) {
        console.log("ejecuta");
        setTimeout(async () => {
          console.log("ejecuta 1.5 segundos despues");
          const dt = await get_products_brands();
          setproduct_brands(dt.data);
        }, 1500);
      } else {
      }
    } catch (err) {
      setproduct_brands([]);
    }
  };

  



  useEffect(() => {
    consult_get_producst_line();
    consult_get_products_brands();
  }, []);
  return (
    <div className="">
      <NotificationSystem ref={notificationSystemRef} />

      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      <BottomNavigation
        className=""
        showlabel
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <div>
          <BottomNavigationAction
            className="btonwith"
            showlabel
            label="Datos generales"
            icon={<GroupSharpIcon onClick={handle_detail_product} />}
            onClick={handle_detail_product}
          />
        </div>

        <div>
          <BottomNavigationAction
            showlabel
            sx={{ width: "50%" }}
            label="Dirección"
            icon={<LocationOnIcon onClick={handle_sales_unit} />}
            onClick={handle_sales_unit}
          />
        </div>

        <div>
          <BottomNavigationAction
            className="btonwith"
            showlabel
            label="Datos generales"
            icon={<GroupSharpIcon onClick={handle_fiscal} />}
            onClick={handle_fiscal}
          />
        </div>
      </BottomNavigation>

      <div style={{ display: detail_product }}>
        <div className="containerProducts">
          <div className="bp-1">
            <ValidationTextField
              label="Código"
              required
              placeholder="Código"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              value={code}
              onChange={(e) => setcode(e.target.value)}
              onBlur={() =>
                code === "" ? setvalidate_code(false) : setvalidate_code(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_code === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-2">
            <ValidationTextField
              label="Nombre del producto"
              required
              placeholder="Nombre del producto"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              value={product_name}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setproduct_name(value.toUpperCase());
                } else {
                  setproduct_name(value);
                }
              }}
              onBlur={() =>
                code === ""
                  ? setvalidate_product_name(false)
                  : setvalidate_product_name(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_product_name === false ? "Completa este campo" : ""}
            </p>
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Descripción"
              required
              placeholder="Descripción"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={description}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setdescription(value.toUpperCase());
                } else {
                  setdescription(value);
                }
              }}
              onBlur={() =>
                code === ""
                  ? setvalidate_description(false)
                  : setvalidate_description(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_description === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-4">
            <ValidationTextField
              label="Código de Barras"
              placeholder="Código de Barras"
              variant="outlined"
              sx={{ m: 2, width: "100%" }}
              defaultValue="Success"
              name="capital_regime"
              value={barcode}
              onChange={(e) => {
                const value = e.target.value;
                if (typeof value === "string") {
                  setbarcode(value.toUpperCase());
                } else {
                  setbarcode(value);
                }
              }}
              onBlur={() =>
                code === ""
                  ? setvalidate_barcode(false)
                  : setvalidate_barcode(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_barcode === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-5">
            <TextField
              id="tipo"
              select
              label="Tipo"
              value={type}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(e) => settype(e.target.value)}
              size="small"
              name="Tipo"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {type_product.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-6">
            <TextField
              id="Lineadeproducto"
              select
              label="Linea de producto"
              value={props.name_line}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Linea de producto"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {product_line.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name_line}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-12">
            <TextField
              id="marca"
              select
              label="Marca"
              value={brand}
              onChange={(e)=>setbrand(e.target.value)}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Marca"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {product_brands.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.brand}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-7">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={enable}
                  onChange={(e) => setenable(e.target.checked)}
                />
              }
              label="Disponible"
              labelPlacement="bottom"
            />
          </div>

          <div className="bp-8">
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={only_promo}
                  onChange={(e) => setonly_promo(e.target.checked)}
                />
              }
              label="Promo ONLY"
              labelPlacement="bottom"
            />
          </div>
          <div className="bp-9">
            <ValidationTextField
              label="Precio Costo "
              placeholder="Precio Costo"
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="first_last_name"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              value={cost}
              onChange={(e) => setcost(e.target.value)}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_cost(false)
                  : setvalidate_cost(true)
              }
            />
              <br />
            <p className="validate_data">
              {validate_cost === false ? "El Precio debe ser mayor a '0'" : ""}
            </p>
          </div>
          <div className="bp-10">
            <Button
              onClick={handle_sales_unit}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              SIGUIENTE
            </Button>
          </div>

          <div className="bp-11">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
      {/* sección 2 */}

      <div style={{ display: noSale }}>
        <div className="containerProducts02">
          <div className="bp-1">
            <TextField
              id="unidaddeventa"
              select
              label="Unidad de Venta"
              value={minimum_sales_unit}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Unidad de Venta"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setminimum_sales_unit(e.target.value)}
            >
              {uinits.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-2">
            <TextField
              id="Unidad Minima de Bonificación"
              select
              label="Unidad Minima de Bonificación"
              value={minimum_sales_unit2}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Linea de producto"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setminimum_sales_unit2(e.target.value)}
            >
              {uinits.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Piezas"
              required
              placeholder="Piezas	"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={pieces.toFixed(0)}
              type="number"
              onChange={(e) => setpieces(Number(e.target.value))}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_pieces(false)
                  : setvalidate_pieces(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_pieces === false ? "El numero de piezas debe ser mayor a '0'" : ""}
            </p>
          </div>

          <div className="bp-9">
            <ValidationTextField
              label="Peso"
              required
              placeholder="Peso	"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              value={weight}
              type="number"
              onChange={(e) => setweight(Number(e.target.value))}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_weight(false)
                  : setvalidate_weight(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
            <br />
            <p className="validate_data">
              {validate_weight === false ? "El peso debe ser mayor a '0'" : ""}
            </p>
          </div>

        

          <div className="bp-4">
            <TextField
              id="medicion"
              select
              label="Medida"
              value={unit}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Medida"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setunit(e.target.value)}
            >
              {uinits2.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-5">
            <TextField
              id="unidaddemedidadns"
              select
              label="Uidad de Medida DMS"
              value={unit_dms}
              sx={{ m: 1.5, width: "100%" }}
              size="small"
              name="Linea de producto"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
              onChange={(e) => setunit_dms(e.target.value)}
            >
              {dms.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>

          <div className="bp-6">
            <ValidationTextField
              label="Conversión de Media"
              placeholder="Conversión de Medida"
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              sx={{ m: 2, width: "100%" }}
              name="Conversión"
              InputLabelProps={{
                shrink: true,
              }}
              type="number"
              value={unit_conversion}
              onChange={(e) => setunit_conversion(e.target.value)}
              onBlur={(e) =>
                e.target.value <= 0
                  ? setvalidate_unit_conversion(false)
                  : setvalidate_unit_conversion(true)
              }
            />
              <p className="validate_data">
              {validate_unit_conversion === false ? "La conversión debe ser mayor a '0'" : ""}
            </p>

          </div>

   



          <div className="bp-7">
            <Button
              onClick={handle_sales_unit}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              SIGUIENTE
            </Button>
          </div>

          <div className="bp-8">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>

      {/* sección 3 */}

      <div style={{ display: fiscal }}>
        <div className="containerProducts03">
          <div className="bp-1">
            <ValidationTextField
              label="Clave Producto Servicio (SAT)"
              required
              placeholder="Clave Producto Servicio (SAT)"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              inputProps={{ maxLength: 8, minLength:8 }}
              value={clave_prod_serv}
              onChange={(e) => setclave_prod_serv(e.target.value)}
              onBlur={() =>
                clave_prod_serv.length !==8  ? setvalidate_clave_prod_serv(false) : setvalidate_clave_prod_serv(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_clave_prod_serv === false ? "La longitud de la valve debe contener 8 caracteres" : ""}
            </p>
          </div>

          <div className="bp-2">
            <ValidationTextField
              label="Calve Unidad(SAT)"
              required
              placeholder="Clave Unidad(SAT)"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              value={clave_unidad}
              inputProps={{ maxLength: 20}}
              onChange={(e) => setclave_unidad(e.target.value)}
              onBlur={() =>
                clave_unidad === "" ? setvalidate_clave_unidad(false) : setvalidate_clave_unidad(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_clave_unidad === false ? "Completa este campo" : ""}
            </p>
          </div>
          <div className="bp-3">
            <ValidationTextField
              label="Clave Unidad por Corrugado (SAT)"
              required
              placeholder="Clave Unidad por Corrugado (SAT)"
              sx={{ m: 2, width: "100%" }}
              variant="outlined"
              defaultValue="Success"
              id="validation-outlined-input"
              name="code"
              value={clave_unidad_2}
              inputProps={{ maxLength: 20}}

              onChange={(e) => setclave_unidad_2(e.target.value)}
              onBlur={() =>
                clave_unidad_2 === "" ? setvalidate_clave_unidad2(false) : setvalidate_clave_unidad2(true)
              }
              InputLabelProps={{
                shrink: true,
              }}
            />

            <br />
            <p className="validate_data">
              {validate_clave_unidad2 === false ? "Completa este campo" : ""}
            </p>
          </div>

          <div className="bp-4">
          <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={iva0}
                  onChange={(e) => {
                    setiva0(e.target.checked);
                    setiva16(!e.target.value)}}
                />
              }
              label="IVA 0%"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={ieps8}
                  onChange={(e) => setieps8(e.target.checked)}
                />
              }
              label="IEPS 8%"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox
                  checked={iva16}
                  onChange={(e) => {
                    setiva16(e.target.checked);
                    setiva0(!e.target.value)}}
                />
              }
              label="IVA 16%"
              labelPlacement="bottom"
            />
          </div>

          
          <div className="bp-7">
            <Button
              onClick={handle_sales_unit}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "green" }}
            >
              CREAR
            </Button>
          </div>

          <div className="bp-8">
            <Button
              onClick={() => {
                dialog.close();
              }}
              sx={{ m: 2, width: "100%" }}
              variant="contained"
              style={{ background: "red" }}
            >
              CANCELAR
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CREATE_UPDATE;
