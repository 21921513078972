//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { StickyTable } from "react-sticky-table";
import { CustomDialog, Confirm } from "react-st-modal";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
//COMPONENTES
import { MaterialUISwitch } from "../../componentsUpdateBusnes/UpdateBP";

//ESTILOS

import "../../components_cfdi/estilo.css";
import "../componentSectors/bonuses.css";
import "../../components_cfdi/modal.css";

//IMAGENES/ICONOS
import Delete from "../../imgComponents/borrar.png";
import Details from "../../imgComponents/lupa.png";
import CREATE from "../../imgComponents/create.png";
import Edit from "../../imgComponents/save.png";

import DetailsPointSale from "./DetailsPointsOfSale";
import CreatePointOfSale from "./CreatePointOfSale";
import EditPointsOfSale from "./EditPointsOfSale";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";

import NotificationSystem from "react-notification-system";
import AlertResponse from "../../main_components/alerts/alertResponse";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import { pagination } from "../../main_components/pagination/pagination";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TableContainer from "../../main_components/Table/TableContainer";
import { delete_points_of_sale } from "../../services/businesspartners/businesspartners";
const TablePointOfSale = () => {
  const notificationSystemRef = useRef();
  const [data, setData] = useState([]);
  const [open, setopen] = useState(false);
  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [filtersColumsView, setfiltersColumsView] = useState(true);


  const [viewcode, setviewcode] = useState(
    localStorage.getItem("viewcode") === null
      ? true
      : localStorage.getItem("viewcode") === "true"
      ? true
      : false
  );
  const [viewouter_code, setviewouter_code] = useState(
    localStorage.getItem("viewouter_code") === null
      ? false
      : localStorage.getItem("viewouter_code") === "true"
      ? true
      : false
  );
  const [viewspecial_number_folio, setviewspecial_number_folio] = useState(
    localStorage.getItem("viewspecial_number_folio") === null
      ? false
      : localStorage.getItem("viewspecial_number_folio") === "true"
      ? true
      : false
  );
  
  const [viewname, setviewname] = useState(
    localStorage.getItem("viewname") === null
      ? true
      : localStorage.getItem("viewname") === "true"
      ? true
      : false
  );

  const [viewbusinessPartner, setviewbusinessPartner] = useState(
    localStorage.getItem("viewbusinessPartner") === null
      ? true
      : localStorage.getItem("viewbusinessPartner") === "true"
      ? true
      : false
  );
  const [viewrfc, setviewrfc] = useState(
    localStorage.getItem("viewrfc") === null
      ? true
      : localStorage.getItem("viewrfc") === "true"
      ? true
      : false
  );
  const [viewchannel, setviewchannel] = useState(
    localStorage.getItem("viewchsetviewvisit_daysannel") === null
      ? true
      : localStorage.getItem("viewchannel") === "true"
      ? true
      : false
  );
  const [viewsubchannel, setviewsubchannel] = useState(
    localStorage.getItem("viewsubchannel") === null
      ? true
      : localStorage.getItem("viewsubchannel") === "true"
      ? true
      : false
  );
  const [viewroute, setviewroute] = useState(
    localStorage.getItem("viewroute") === null
      ? true
      : localStorage.getItem("viewroute") === "true"
      ? true
      : false
  );
  const [viewconditionPay, setviewconditionPay] = useState(
    localStorage.getItem("viewconditionPay") === null
      ? true
      : localStorage.getItem("viewconditionPay") === "true"
      ? true
      : false
  );
  const [viewgenerates_debt, setviewgenerates_debt] = useState(
    localStorage.getItem("viewgenerates_debt") === null
      ? true
      : localStorage.getItem("viewgenerates_debt") === "true"
      ? true
      : false
  );
  const [viewfrequency, setviewfrequency] = useState(
    localStorage.getItem("viewfrequency") === null
      ? true
      : localStorage.getItem("viewfrequency") === "true"
      ? true
      : false
  );
  const [viewdate_register, setviewdate_register] = useState(
    localStorage.getItem("viewdate_register") === null
      ? true
      : localStorage.getItem("viewdate_register") === "true"
      ? true
      : false
  );
  const [viewlat, setviewlat] = useState(
    localStorage.getItem("viewlat") === null
      ? false
      : localStorage.getItem("viewlat") === "true"
      ? true
      : false
  );
  const [viewlng, setviewlng] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );

  const [viewvisit_days, setviewvisit_days] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewprice_list, setviewprice_list] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewclassification, setviewclassification] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewnumber_oxxo_store, setviewnumber_oxxo_store] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewemail, setviewemail] = useState(
    localStorage.getItem("viewemail") === null
      ? false
      : localStorage.getItem("viewemail") === "true"
      ? true
      : false
  );
  const [viewphone, setviewphone] = useState(
    localStorage.getItem("viewlng") === null
      ? false
      : localStorage.getItem("viewlng") === "true"
      ? true
      : false
  );
  const [viewstreet, setviewstreet] = useState(
    localStorage.getItem("viewstreet") === null
      ? false
      : localStorage.getItem("viewstreet") === "true"
      ? true
      : false
  );
  const [viewoutdoor_number, setviewoutdoor_number] = useState(
    localStorage.getItem("viewoutdoor_number") === null
      ? false
      : localStorage.getItem("viewoutdoor_number") === "true"
      ? true
      : false
  );
  const [viewinside_number, setviewinside_number] = useState(
    localStorage.getItem("viewinside_number") === null
      ? false
      : localStorage.getItem("viewinside_number") === "true"
      ? true
      : false
  );
  const [viewneighborhood, setviewneighborhood] = useState(
    localStorage.getItem("viewneighborhood") === null
      ? false
      : localStorage.getItem("viewneighborhood") === "true"
      ? true
      : false
  );
  const [viewzip_code, setviewzip_code] = useState(
    localStorage.getItem("viewzip_code") === null
      ? false
      : localStorage.getItem("viewzip_code") === "true"
      ? true
      : false
  );
  const [viewcity, setviewcity] = useState(
    localStorage.getItem("viewcity") === null
      ? false
      : localStorage.getItem("viewcity") === "true"
      ? true
      : false
  );
  const [viewtown, setviewtown] = useState(
    localStorage.getItem("viewtown") === null
      ? false
      : localStorage.getItem("viewtown") === "true"
      ? true
      : false
  );
  const [viewstate, setviewstate] = useState(
    localStorage.getItem("viewstate") === null
      ? false
      : localStorage.getItem("viewstate") === "true"
      ? true
      : false
  );
  const [viewcountry, setviewcountry] = useState(
    localStorage.getItem("viewcountry") === null
      ? false
      : localStorage.getItem("viewcountry") === "true"
      ? true
      : false
  );
  const [viewreference, setviewreference] = useState(
    localStorage.getItem("viewreference") === null
      ? false
      : localStorage.getItem("viewreference") === "true"
      ? true
      : false
  );
  const [viewaddress_type, setviewaddress_type] = useState(
    localStorage.getItem("viewaddress_type") === null
      ? false
      : localStorage.getItem("viewaddress_type") === "true"
      ? true
      : false
  );

  const [viewunilever, setviewunilever] = useState(
    localStorage.getItem("viewunilever") === null
      ? false
      : localStorage.getItem("viewunilever") === "true"
      ? true
      : false
  );
  const [viewpropio, setviewpropio] = useState(
    localStorage.getItem("viewpropio") === null
      ? false
      : localStorage.getItem("viewpropio") === "true"
      ? true
      : false
  );
  useEffect(() => {
    localStorage.setItem("viewcode", viewcode);
    localStorage.setItem("viewname", viewname);
    localStorage.setItem("viewbusinessPartner", viewbusinessPartner);
    localStorage.setItem("viewrfc", viewrfc);
    localStorage.setItem("viewchannel", viewchannel);
    localStorage.setItem("viewsubchannel", viewsubchannel);
    localStorage.setItem("viewroute", viewroute);
    localStorage.setItem("viewfrequency", viewfrequency);
    localStorage.setItem("viewconditionPay", viewconditionPay);
    localStorage.setItem("viewgenerates_debt", viewgenerates_debt);
    localStorage.setItem("viewdate_register", viewdate_register);
    localStorage.setItem("viewlat", viewlat);
    localStorage.setItem("viewlng", viewlng);
    localStorage.setItem("viewvisit_days", viewvisit_days);
    localStorage.setItem("viewprice_list", viewprice_list);
    localStorage.setItem("viewclassification", viewclassification);
    localStorage.setItem("viewnumber_oxxo_store", viewnumber_oxxo_store);
    localStorage.setItem("viewemail", viewemail);
    localStorage.setItem("viewphone", viewphone);
    localStorage.setItem("viewstreet", viewstreet);
    localStorage.setItem("viewoutdoor_number", viewoutdoor_number);
    localStorage.setItem("viewinside_number", viewinside_number);
    localStorage.setItem("viewneighborhood", viewneighborhood);
    localStorage.setItem("viewzip_code", viewzip_code);
    localStorage.setItem("viewcity", viewcity);
    localStorage.setItem("viewtown", viewtown);
    localStorage.setItem("viewstate", viewstate);
    localStorage.setItem("viewcountry", viewcountry);
    localStorage.setItem("viewreference", viewreference);
    localStorage.setItem("viewaddress_type", viewaddress_type);
    localStorage.setItem("viewunilever", viewunilever);
    localStorage.setItem("viewpropio", viewpropio);
    localStorage.setItem("viewouter_code", viewouter_code);
    localStorage.setItem("viewspecial_number_folio", viewspecial_number_folio);



  }, [
    viewcode,
    viewname,
    viewbusinessPartner,
    viewrfc,
    viewchannel,
    viewsubchannel,
    viewroute,
    viewfrequency,
    viewconditionPay,
    viewgenerates_debt,
    viewdate_register,
    viewlat,
    viewlng,
    viewvisit_days,
    viewprice_list,
    viewclassification,
    viewnumber_oxxo_store,
    viewemail,
    viewphone,
    viewstreet,
    viewoutdoor_number,
    viewinside_number,
    viewneighborhood,
    viewzip_code,
    viewcity,
    viewtown,
    viewstate,
    viewcountry,
    viewreference,
    viewaddress_type,
    viewunilever,
    viewpropio,
    viewouter_code,
    viewspecial_number_folio
  ]);

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 10,
      });
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 60,
      });
    }
  }
  const reconsultar = (n) => {
    if (n === true) {
      setmodalT(true);
    } else {
      setmodalT(false);
    }
  };

  const handleSubmit = async () => {
    setData([]);
    setopen(true);
    setmenssage("Buscando Puntos de Venta");
    setmodalGeneral(true);
    setmodalT(true);
    try {
      // refresh_token ();
      // var prueba = await pagination(3, "pointofsales",null);
      // setData(prueba === null ? [] : prueba);
      axios
      .get(
        `https://lhtfgusul2.execute-api.us-east-1.amazonaws.com/dev/pointofsale`,
        {
          
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
       
        setData(data);
        setopen(false);
        setmodalGeneral(false);
        setmodalT(false);
      })
      .catch((err) => {
        console.log("error", err);
        setopen(false);
        setmodalGeneral(false);
        setmodalT(false);
      });
    } catch (error) {
      console.error(error);
      setopen(false);
      setmodalGeneral(false);
      setmodalT(false);
    } finally {
      setopen(false);
      setmodalGeneral(false);
      setmodalT(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const handlechecked = (event) => {
    setfiltersColumsView(event.target.checked);
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div>
            <img
              id="crearsector"
              src={CREATE}
              onClick={async () => {
                const result = await CustomDialog(
                  <div>
                    <CreatePointOfSale
                      handleSubmit={handleSubmit}
                      reconsultar={reconsultar}
                      alertas={alertas}
                    />
                  </div>,
                  {
                    className: "modalpuntosventa",
                    title: "Crear Nuevo Punto de Venta",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
              title="Agregar punto de venta"
              alt=""
            ></img>
          </div>
        ),

        id: "Acciones",

        Cell: ({ row }) => {
          const DeleteBusines = async ()  => {
            setmodalT(true);
            refresh_token ();
            setData([]);
            setopen(true);

            try {
              const dt = await delete_points_of_sale(row.original.id);
              console.log(dt);
              alertas("Punto de Venta Eliminado", true);
              handleSubmit();
            } catch (err) {
              console.log("error", err);
              alertas("Error, vuelva a intentarlo", false);
              handleSubmit();
            }
          }

          return (
            <div>
              <img
                src={Delete}
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar el punto de venta?",
                    "Eliminar Punto de Venta",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteBusines();
                  }
                }}
                className="centerText, cancel"
                title="Eliminar punto de venta"
                alt=""
              ></img>
              <img
                src={Details}
                onClick={async () => {
                  const result = await CustomDialog(
                    <div>
                      <DetailsPointSale row={row} />
                    </div>,
                    {
                      className: "modalpuntosventa",
                      title: "Detalles de punto de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="centerText, cancel"
                title="Detalles de punto de venta"
                alt=""
              ></img>
              <img
                src={Edit}
                onClick={async () => {
                  const result = await CustomDialog(
                    <div>
                      <EditPointsOfSale
                        handleSubmit={handleSubmit}
                        row={row}
                        alertas={alertas}
                        reconsultar={reconsultar}
                      />
                    </div>,
                    {
                      className: "modalpuntosventa",
                      title: "Editar punto de venta",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
                className="centerText, cancel"
                title="Editar punto de venta"
                alt=""
              ></img>
            </div>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code",
        show: viewcode,
        id: "code",
      },
      {
        Header: "Código Unilever",
        accessor: "outer_code",
        show: viewouter_code,
        id: "outer_code",
      },
      {
        Header: "Folio Especial",
        accessor: "special_number_folio",
        show: viewspecial_number_folio,
        id: "special_number_folio",
      },


      
      {
        Header: "Nombre",
        accessor: "name",
        show: viewname,
        id: "name",
      },
      {
        Header: "Socio de Negocios",
        accessor: "businessPartner",
        show: viewbusinessPartner,
        id: "businessPartner",
      },
      {
        Header: "RFC",
        accessor: "rfc",
        show: viewrfc,
        id: "rfc",
      },
      {
        Header: "Canal",
        accessor: "channel",
        show: viewchannel,
        id: "channel",
      },
      {
        Header: "Subcanal",
        accessor: "subchannel",
        show: viewsubchannel,
        id: "subchannel",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Ruta",
        accessor: "route",
        show: viewroute,
        id: "route",
      },
      {
        Header: "Pago",
        accessor: "conditionPay",
        show: viewconditionPay,
        id: "conditionPay",
      },

      {
        Header: "Deuda",
        accessor: (d) => {
          return d.generates_debt === true ? "SI" : "NO";
        },
        show: viewgenerates_debt,
        id: "generates_debt",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Frecuencia",
        accessor: "frequency",
        show: viewfrequency,
        id: "frequency",
      },
      {
        Header: "Alta",
        accessor: "date_register",
        show: viewdate_register,
        id: "date_register",
      },

      {
        Header: "Días de visita",
        accessor: "visit_days",
        show: viewvisit_days,
        id: "visit_days",
      },

      {
        Header: "Lista de precios",
        accessor: "price_list",
        show: viewprice_list,
        id: "price_list",
      },

      {
        Header: "Clasificación",
        accessor: "classification",
        show: viewclassification,
        id: "classification",
      },

      {
        Header: "Número de Tienda",
        accessor: "number_oxxo_store",
        show: viewnumber_oxxo_store,
        id: "number_oxxo_store",
      },

      {
        Header: "Lat",
        accessor: "lat",
        show: viewlat,
        id: "lat",
      },
      {
        Header: "Lng",
        accessor: "lng",
        show: viewlng,
        id: "lng",
      },

      {
        Header: "E-Mail",
        accessor: "email",
        show: viewemail,
        id: "email",
      },

      {
        Header: "Número Telefónico",
        accessor: "phone",
        show: viewphone,
        id: "phone",
      },
      {
        Header: "Calle",
        accessor: (d) => {
          return d.address?.street;
        },
        show: viewstreet,
        id: "street",
      },
      {
        Header: "Número Exterior",
        accessor: (d) => {
          return d.address?.outdoor_number;
        },
        show: viewoutdoor_number,
        id: "outdoor_number",
      },
      {
        Header: "Número Interior",
        accessor: (d) => {
          return d.address?.inside_number;
        },
        show: viewinside_number,
        id: "inside_number",
      },
      {
        Header: "Colonia",
        accessor: (d) => {
          return d.address?.neighborhood;
        },
        show: viewneighborhood,
        id: "neighborhood",
      },
      {
        Header: "Código postal",
        accessor: (d) => {
          return d.address?.zip_code;
        },
        show: viewzip_code,
        id: "zip_code",
      },
      {
        Header: "Ciudad",
        accessor: (d) => {
          return d.address?.city;
        },
        show: viewcity,
        id: "city",
      },
      {
        Header: "Municipio",
        accessor: (d) => {
          return d.address?.town;
        },
        show: viewtown,
        id: "town",
      },
      {
        Header: "Estado",
        accessor: (d) => {
          return d.address?.state;
        },
        show: viewstate,
        id: "state",
      },
      {
        Header: "País",
        accessor: (d) => {
          return d.address?.country;
        },
        show: viewcountry,
        id: "country",
      },
      {
        Header: "Referencia",
        accessor: (d) => {
          return d.address?.reference;
        },
        show: viewreference,
        id: "reference",
      },
      {
        Header: "Tipo de dirección",
        accessor: (d) => {
          return d.address?.address_type;
        },
        show: viewaddress_type,
        id: "address_type",
      },
      {
        Header: "Número de Congeladores Unilever",
        accessor: (d) => {
          var ndata=d.cabinets[0].unilever?.split(',');
          var numberdata=ndata.length;
          if(numberdata===1){

            if(ndata[0]===""){
              numberdata=0;
            }
          }

          return numberdata;
        },
        show: viewunilever,
        id: "numberUnilever",
      },
      {
        Header: "Congeladores Unilever",
        accessor: (d) => {
          return d.cabinets[0].unilever;
        },
        show: viewunilever,
        id: "Unilever",
      },
      {
        Header: "Número de Congeladores Propios",
        accessor: (d) => {
          var ndata=d?.cabinets[1].propio?.split(',');
          var numberdata=ndata?.length;
          if(numberdata===1){

            if(ndata[0]===""){
              numberdata=0;
            }
          }

          return numberdata;
        },
        show: viewunilever,
        id: "numberPropios",
      },

      {
        Header: "Congeladores Propios",
        accessor: (d) => {
          return d.cabinets[1].propio;
        },
        show: viewpropio,
        id: "Propio",
      },

      
    ],
    [
      viewcode,
      viewname,
      viewbusinessPartner,
      viewrfc,
      viewchannel,
      viewsubchannel,
      viewroute,
      viewfrequency,
      viewconditionPay,
      viewgenerates_debt,
      viewdate_register,
      viewlat,
      viewlng,
      viewvisit_days,
      viewprice_list,
      viewclassification,
      viewnumber_oxxo_store,
      viewemail,
      viewphone,
      viewstreet,
      viewoutdoor_number,
      viewinside_number,
      viewneighborhood,
      viewzip_code,
      viewcity,
      viewtown,
      viewstate,
      viewcountry,
      viewreference,
      viewaddress_type,
      viewunilever,
      viewpropio,
      viewouter_code,
      viewspecial_number_folio,
    ]
  );
  return (
    <>
      <div className="containeritems py-2">
        <nav aria-label="breadcrumb" className="navegacion">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">Consultar Puntos de Venta</li>

            <li className="breadcrumb-item">
              <a href="/consult_point_of_sale_without_sector">
                Consultar Puntos de Venta sin Sector
              </a>
            </li>
          </ol>
        </nav>
      </div>

      <div className="TablePointOfSales">
        <div className="">
          <div style={{ width: "98%", height: "100%", paddingLeft: "20px" }}>
            <NotificationSystem
              ref={notificationSystemRef}
            ></NotificationSystem>
            <MODAL_TABLE
              open={true}
              message={menssage}
              modalGeneral={modalGeneral}
              modalT={modalT}
            ></MODAL_TABLE>
            <>
              <div className="titletable">
                <h1>Puntos de venta </h1>
              </div>
              <br></br>
              <div
                className="contanerFIltersTableSelect"
                style={{ display: filtersColumsView === true ? "none" : "" }}
              >
                <div className="filter-1 color-1">
                  <h2>Filtros Personalizados</h2>
                </div>
                <div className="filter-2 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewcode}
                        onChange={(e) => {
                          setviewcode(e.target.checked);
                        }}
                      />
                    }
                    label="Código"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-3 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewname}
                        onChange={(e) => {
                          setviewname(e.target.checked);
                        }}
                      />
                    }
                    label="Puntos de venta"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-4 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewbusinessPartner}
                        onChange={(e) => {
                          setviewbusinessPartner(e.target.checked);
                        }}
                      />
                    }
                    label="Socio de negocios"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-5 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewrfc}
                        onChange={(e) => {
                          setviewrfc(e.target.checked);
                        }}
                      />
                    }
                    label="RFC"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-6 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewchannel}
                        onChange={(e) => {
                          setviewchannel(e.target.checked);
                        }}
                      />
                    }
                    label="Canal"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-7 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewsubchannel}
                        onChange={(e) => {
                          setviewsubchannel(e.target.checked);
                        }}
                      />
                    }
                    label="Sub Canal"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-8 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewroute}
                        onChange={(e) => {
                          setviewroute(e.target.checked);
                        }}
                      />
                    }
                    label="Ruta"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-9 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewconditionPay}
                        onChange={(e) => {
                          setviewconditionPay(e.target.checked);
                        }}
                      />
                    }
                    label="Pago"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-10 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewgenerates_debt}
                        onChange={(e) => {
                          setviewgenerates_debt(e.target.checked);
                        }}
                      />
                    }
                    label="Deuda"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-11 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewfrequency}
                        onChange={(e) => {
                          setviewfrequency(e.target.checked);
                        }}
                      />
                    }
                    label="Frecuencia"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-12 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewdate_register}
                        onChange={(e) => {
                          setviewdate_register(e.target.checked);
                        }}
                      />
                    }
                    label="Alta"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-13 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewlat}
                        onChange={(e) => {
                          setviewlat(e.target.checked);
                        }}
                      />
                    }
                    label="Lat"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-14 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewlng}
                        onChange={(e) => {
                          setviewlng(e.target.checked);
                        }}
                      />
                    }
                    label="Lng"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-15 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewvisit_days}
                        onChange={(e) => {
                          setviewvisit_days(e.target.checked);
                        }}
                      />
                    }
                    label="Días de visita"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-16 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewprice_list}
                        onChange={(e) => {
                          setviewprice_list(e.target.checked);
                        }}
                      />
                    }
                    label="Lista de precios"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-17 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewclassification}
                        onChange={(e) => {
                          setviewclassification(e.target.checked);
                        }}
                      />
                    }
                    label="Clasificación"
                    labelPlacement="bottom"
                  />
                </div>

                <div className="filter-18 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewnumber_oxxo_store}
                        onChange={(e) => {
                          setviewnumber_oxxo_store(e.target.checked);
                        }}
                      />
                    }
                    label="Número de tienda"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-19 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewemail}
                        onChange={(e) => {
                          setviewemail(e.target.checked);
                        }}
                      />
                    }
                    label="E-Mail"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-20 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewphone}
                        onChange={(e) => {
                          setviewphone(e.target.checked);
                        }}
                      />
                    }
                    label="Teléfono"
                    labelPlacement="bottom"
                  />
                </div>      
    
                <div className="filter-21 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewstreet}
                        onChange={(e) => {
                          setviewstreet(e.target.checked);
                        }}
                      />
                    }
                    label="Calle"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-22 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewoutdoor_number}
                        onChange={(e) => {
                          setviewoutdoor_number(e.target.checked);
                        }}
                      />
                    }
                    label="Número Exterior"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-23 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewinside_number}
                        onChange={(e) => {
                          setviewinside_number(e.target.checked);
                        }}
                      />
                    }
                    label="Número Interior"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-24 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewneighborhood}
                        onChange={(e) => {
                          setviewneighborhood(e.target.checked);
                        }}
                      />
                    }
                    label="Colonia"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-25 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewzip_code}
                        onChange={(e) => {
                          setviewzip_code(e.target.checked);
                        }}
                      />
                    }
                    label="Código"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-26 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewcity}
                        onChange={(e) => {
                          setviewcity(e.target.checked);
                        }}
                      />
                    }
                    label="Ciudad"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-27 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewtown}
                        onChange={(e) => {
                          setviewtown(e.target.checked);
                        }}
                      />
                    }
                    label="Municipio"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-28 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewstate}
                        onChange={(e) => {
                          setviewstate(e.target.checked);
                        }}
                      />
                    }
                    label="Estado"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-29 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewcountry}
                        onChange={(e) => {
                          setviewcountry(e.target.checked);
                        }}
                      />
                    }
                    label="País"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-30 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewreference}
                        onChange={(e) => {
                          setviewreference(e.target.checked);
                        }}
                      />
                    }
                    label="Referencia"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-31 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewaddress_type}
                        onChange={(e) => {
                          setviewaddress_type(e.target.checked);
                        }}
                      />
                    }
                    label="Tipo de dirección"
                    labelPlacement="bottom"
                  />
                </div>

                <div className="filter-32 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewunilever}
                        onChange={(e) => {
                          setviewunilever(e.target.checked);
                        }}
                      />
                    }
                    label="Congeladores unilever"
                    labelPlacement="bottom"
                  />
                </div>

                <div className="filter-33 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewpropio}
                        onChange={(e) => {
                          setviewpropio(e.target.checked);
                        }}
                      />
                    }
                    label="Congeladores propios"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-34 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewouter_code}
                        onChange={(e) => {
                          setviewouter_code(e.target.checked);
                        }}
                      />
                    }
                    label="Código Unilever"
                    labelPlacement="bottom"
                  />
                </div>
                <div className="filter-35 color-1">
                  <FormControlLabel
                    value="bottom"
                    control={
                      <Checkbox
                        checked={viewspecial_number_folio}
                        onChange={(e) => {
                          setviewspecial_number_folio(e.target.checked);
                        }}
                      />
                    }
                    label="Folio Especial"
                    labelPlacement="bottom"
                  />
                </div>
                
              </div>

              <div className="containerCutBox">
                <div className="expen-8 color-1">
                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 1 }}
                        onChange={handlechecked}
                        checked={filtersColumsView}
                      />
                    }
                    label={
                      filtersColumsView === true
                        ? "Activar Filtros"
                        : "Desactivar Filtros"
                    }
                  />
                </div>
              </div>
              
              <br></br>
        
              <StickyTable>
                <TableContainer
                  paginado={"Puntos de Venta"}
                  consultas={"Puntos de Venta Consultados"}
                  nametable={"Puntos de Venta"}
                  exportar={true}
                  columns={columns}
                  data={data}
                  style={{ overflowx: 10 }}
                  updateMyData={updateMyData}
                  Gmodal={open}
                />
              </StickyTable>
              <div></div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default TablePointOfSale;
