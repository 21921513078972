import axios from "axios";
import config from "../../config";

const urlreport = `${config?.baseURLREPORT}`;


//////////////////// C R U D   D E   M E T A S /////////////////////

//consultar todas las metas
export const get_goals = async (yy, mm) => {
  const url = `${urlreport}/prod/goals`;
  try {
    const response = await axios.get(url, {
      params:{year: yy ,month: mm
      },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Eliminar meta
export const delete_goal = async (id) => {
  const url = `${urlreport}/prod/goals/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//Crear META
export const post_goals = async (data) => {
  const url = `${urlreport}/prod/goals`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//ACTUALIZAR META
export const put_goals = async (id, data) => {
  const url = `${urlreport}/prod/goals/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//////////////////// C R U D   D E   S E M A F O R O ////////////////////////


//consultar semaforos
export const get_semaphore = async () => {
  const url = `${urlreport}/prod/semaphore`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//ELIMINAR SEMAFORO
export const delete_semaphore = async (id) => {
  const url = `${urlreport}/prod/semaphore/${id}`;
  try {
    const response = await axios.delete(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//CREAR SEMAFORO
export const post_semaphore = async (data) => {
  const url = `${urlreport}/prod/semaphore`;
  try {
    const response = await axios.post(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//ACTUALIZAR SEMAFORO
export const put_semaphore = async (id, data) => {
  const url = `${urlreport}/prod/semaphore/${id}`;
  try {
    const response = await axios.put(url, data, {
      headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

////////////// R E P O R T E     G P S ///////////////////////////

//CONSULTAR DATA REPORTE GPS
export const get_report_gps = async () => {
  const url = `${urlreport}/demo/reporte-gps`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

///////////// REPORTE DE VENTAS POR RUTA //////////////////////////
//una ruta en especifico
export const get_sales_by_route_ind = async (nameroute, fi, ff) => {
  const url = `${urlreport}/demo/general-routes-report/${nameroute}`;
  try {
    const response = await axios.get(url, {
      params:{route: nameroute,date: fi, finalDate: ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//todas las rutas
export const get_sales_by_route_all = async (fi, ff) => {
  const url = `${urlreport}/demo/general-routes-report`;
  try {
    const response = await axios.get(url, {
      params:{date: fi, finalDate: ff},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};
//consulta sumario anual
export const get_sumary_annual = async (yy) => {
  const url = `${urlreport}/prod/annual-summary-by-pos`;
  try {
    const response = await axios.get(url, {
      params: { year: yy },
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


///////////////////REPORTE DE VENTAS EN TIEMPO REAL ///////////////7777

export const get_sales_in_real_time = async (nameroute, fecha) => {
  const url = `${urlreport}/prod/sales-in-real-time`;
  try {
    const response = await axios.get(url, {
      params: {route: nameroute, date: fecha},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};


//sumaria semanal

export const get_sumary_weektly = async (date,weeks) => {
  const url = `${urlreport}/demo/weektly-summary`;
  try {
    const response = await axios.get(url, {
      params: {date: date, weeks: weeks},
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    });
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

