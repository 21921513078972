import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { post_roles } from "../../services/configuration/configuration";

function CreateRole(props) {
 
const dialog = useDialog();
const[nameRol, setnameRol] = useState(null);
const[descRol, setdesRol]= useState(null);
const[vnamerol, setvnamerol]=useState(false);
const[vdesrol, setvdesrol]=useState(false);

const [bandera, setbandera]=useState(true);
  
//crear un nuevo Rol
const createnewdiscount = async () => {

if(nameRol==null || descRol==null){
  props.alertas("Parámetros faltantes", false);
  nameRol===null?setvnamerol(true):setvnamerol(false);
  descRol===null?setvdesrol(true):setvdesrol(false);
}else{

props.reconsultar(true);
setbandera(false);
await refresh_token ();
  var data = {
    "name_role": nameRol,
    "description": descRol
}
try {
  const dt = await post_roles(data);
  console.log(dt);
  props.alertas("Rol creado con éxito", true);
  props.reconsultar(false);
  dialog.close();
  props.handleSubmit();
} catch (err) {  
  console.log(err);
  catchErrorModul("Roles", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
  setbandera(true);
  props.reconsultar(false);
  if(err.response.status===500){
    props.alertas("No se pudo crear el Rol, vuelva a intertarlo", false);
  }
  if(err.response.status===409){
    props.alertas("Rol ya existente", false);
  }
  if(err.response.status===400){
    props.alertas("Parámetros incompletos", false);
  }
};
  }
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
          <Box>
             <TextField
              label="Nombre del Rol"
              required
              sx={{ m: 1.5, width: "90%" }}
              value={nameRol}
              style={
                vnamerol===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvnamerol(false)}
              onBlur={
                nameRol==null?()=>setvnamerol(true):()=>setvnamerol(false)
              }
              onChange={(event) => setnameRol(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            <TextField
              label="Descripción del Rol"
              required
              sx={{ m: 1.5, width: "90%" }}
              style={
                vdesrol===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvdesrol(false)}
              onBlur={
                descRol==null?()=>setvdesrol(true):()=>setvdesrol(false)
              }
              onChange={(event) => setdesRol(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <br></br>
          <br></br>
          <Box>
            {
              bandera===true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={createnewdiscount}
          >
            Crear Rol
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateRole;
