import { useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { post_brands } from "../../../services/assets/assets";

function CreateBrandF(props) {

const dialog = useDialog();
const[nameBrand, setnameBrand] = useState(null);
const[typeB, settypeB]= useState("CONGELADOR");
const[vnameBrand, setvnameBrand]=useState(false);
const [bandera, setbandera]=useState(true);
  
//crear un nuevo Rol
const createnewdiscount = async () => {
console.log(typeB);
if(nameBrand===null || nameBrand===""){
  props.alertas("Parámetros faltantes", false);
  nameBrand===null || nameBrand===""?setvnameBrand(true):setvnameBrand(false);
}else{
props.reconsultar(true);
setbandera(false);
await refresh_token ();
  var data = {
    "name":  nameBrand,//String = Nombre de la marca
    "type": typeB//String = CONGELADOR | VEHÍCULO
}
    try {
      const dt = await post_brands(data);
      console.log(dt);
      props.alertas("Marca creada con éxito", true);
      props.reconsultar(false);
      dialog.close();
      props.handleSubmit();
      
    } catch (err) {  
      console.log(err);
      setbandera(true);
      props.reconsultar(false);
      catchErrorModul("Marcas", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
      
      if(err.response.status===500){
        props.alertas("No se pudo crear la Marca, vuelva a intertarlo", false);
      }else{
        props.alertas("Error al crear la Marca", false);
      }
    };
  }
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
          <div className="gridmarca1">
            <div className="marca1">
            <TextField
              label="Nombre de la Marca"
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              value={nameBrand}
              style={
                vnameBrand===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvnameBrand(false)}
              onBlur={
                nameBrand==="" || nameBrand===null?()=>setvnameBrand(true):()=>setvnameBrand(false)
              }
              onChange={(event) => setnameBrand(event.target.value.toUpperCase().slice(0,40))}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            </div>
          </div>
          <div className="gridmarca2">
              <div className="marca2">
                  <TextField
                  select
                  label="Tipo"
                  disabled={!bandera}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => settypeB(event.target.value)}  
                 >
                  <option key={1} value={"CONGELADOR"}>
                      {"CONGELADOR"}
                  </option>
                  <option key={2} value={"VEHÍCULO"}>
                      {"VEHÍCULO"}
                  </option>
             </TextField>
              </div>
          </div>
             
            
          
          <br></br>
          <br></br>
          <Box>
            {
              bandera===true?
              
            <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={createnewdiscount}
          >
            Crear Marca
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"backgroundColor":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateBrandF;
