import { useExportData } from "react-table-plugins";
import React, { useEffect, useState, useMemo, Fragment } from "react";
import { CSVLink } from "react-csv";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import exportE from "../imgComponents/export.png";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, Input, CustomInput } from "reactstrap";
import { Filter, DefaultColumnFilter } from "../components_cfdi/filters";

import "./dashboarModal.css";

const TableContainer = ({
  columns,
  data,
  renderRowSubComponent,
  exportEx,
  visible,
  Gtotal,
  campSUbtable,
  GtotalBonus,
  GtotalP,
  GtotalNsales,
  Gmodal,
  porcentaje,
  clients,
  salesD

}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { hiddenColumns: columns
        .filter(col => col.show === false)
        .map(col => col.id), pageIndex: 0, pageSize: 10 },
      getExportFileBlob,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,

    useExportData,
    useRowSelect
  );

  const [difT, setdifT] = useState([]);

  const [totalescustomer, settotalescustomer] = useState(0);
  const [totalessales, settotalessales] = useState(0);
  const [totalessalesBonus, settotalessalesBonus] = useState(0);

  const [totalescustomerPor, settotalescustomerPor] = useState(0);
  const [totaleswithsalePor, settotaleswithsalePor] = useState(0);
  const [totaleswithoutsalePor, settotaleswithoutsalePor] = useState(0);
  const [totalesfreezersPor, settotalesfreezersPor] = useState(0);

  const [totalescustomers, settotalescustomers] = useState(0);
  const [totaleswithsale, settotaleswithsale] = useState(0);
  const [totaleswithoutsale, settotaleswithoutsale] = useState(0);
  const [totalesfreezers, settotalesfreezers] = useState(0);
  const [totalescost, settotalescost] = useState(0);
  const [totalesprofit, settotalesprofit] = useState(0);
  const [totalreturns, settotalreturns] = useState(0);
  const [totalbonus, settotalbonus] = useState(0);
  const [totalreal_sales, settotalreal_sales] = useState(0);
  const [totalcustomers_returns, settotalcustomers_returns] = useState(0);

  const [totalproduct, settotalproduct] = useState(0);
  const [totalquantity, settotalquantity] = useState(0);
  const [totalquantity_secondary_sale, settotalquantity_secondary_sale] = useState(0);
  const [totalquantity_primary_sale, settotalquantity_primary_sale] = useState(0);
  const [totalprimary_sale, settotalprimary_sale] = useState(0);
  const [setprofit_primary_sale, setsetprofit_primary_sale] = useState(0);
  const [totalprofit_secondary_sale, settotalprofit_secondary_sale] = useState(0);
  const [totalcustomers_bonus, settotalcustomers_bonus] = useState(0);
  const [totalmarket, settotalmarket] = useState(0);
  const [totalescustomersP, settotalescustomersP] = useState(0);
  const [totalcustomersNsales, settotalcustomersNsales] = useState(0);
  const [totalwithsale, settotalwithsale] = useState(0);
  const [totalwithoutsale, settotalwithoutsale] = useState(0);
  const [totalfreezers, settotalfreezers] = useState(0);
  const [totalesparticipation, settotalesparticipation] = useState(0);
  const [totalesparticipationBonus, settotalesparticipationBonus] = useState(0);

  
  useEffect(() => {
    console.log(data);
    console.log(page);
    for (let i = 0; i < data.length; i++) {
      difT[i] = {
        cost: page[i]?.original?.cost,
        customer: page[i]?.original?.customer,
        customers: page[i]?.original?.customers,
        pcustomers: page[i]?.original?.pcustomers,
        freezers: page[i]?.original?.freezers,
        pfreezers: page[i]?.original?.pfreezers,
        participation: page[i]?.original?.participation,
        profit: page[i]?.original?.profit,
        sales: page[i]?.original?.sales,
        withoutsale: page[i]?.original?.withoutsale,
        pwithoutsale: page[i]?.original?.pwithoutsale,
        withsale: page[i]?.original?.withsale,
        pwithsale: page[i]?.original?.pwithsale,
        returns:page[i]?.original?.returns,
        bonus:page[i]?.original?.bonus,
        real_sales:page[i]?.original?.real_sales,
        customers_returns:page[i]?.original?.customers_returns,
        customers_bonus:page[i]?.original?.customers_bonus,
        product:page[i]?.original?.product,
        quantity:page[i]?.original?.quantity,
        quantity_secondary_sale:page[i]?.original?.quantity_secondary_sale,
        quantity_primary_sale:page[i]?.original?.quantity_primary_sale,
        primary_sale:page[i]?.original?.primary_sale,
        profit_primary_sale:page[i]?.original?.profit_primary_sale,
        profit_secondary_sale:page[i]?.original?.profit_secondary_sale,
        market:page[i]?.original?.market,
        customersP: page[i]?.original?.customers,
        customersNsales: page[i]?.original?.customers,

        costo: page[i]?.original?.costo,
        drecorrida: page[i]?.original?.drecorrida,
        ganancia: page[i]?.original?.ganancia,
        fletes: page[i]?.original?.fletes,
        socio: page[i]?.original?.socio,
        total: page[i]?.original?.total,



    
      };
    }

    const sumarClientes = data.filter((elem) => {
      console.log(elem);
      return difT?.some((term) =>
        elem?.socio?.includes(term?.socio)
      );
    });
    const sumarbonificaciones = data.filter((elem) => {
      console.log(elem);
      return difT?.some((term) =>
        elem?.customer?.includes(term?.customer)
      );
    });
    const sumarProductos = difT.filter((item) => item.product !== undefined);
    const sumarNosales = difT.filter((item) => item.withsale !== undefined);

    console.log(sumarbonificaciones);

    const sumarTotal = sumarClientes
      .map((total) => parseFloat(total?.total))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarGanancia = sumarClientes
      .map((total) => parseFloat(total?.ganancia))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarparticipationBonus = sumarClientes
      .map((total) => parseFloat(total?.drecorrida))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarcost = sumarClientes
      .map((total) => parseFloat(total?.cost))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarcustomer = sumarClientes
      .map((total) => parseFloat(total?.costo))
      .reduce((previous, current) => {
       
        return previous + current;
      }, 0);
      const sumarcustomerPor = sumarClientes
      .map((total) => parseFloat(total?.fletes))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarwithsalePor = sumarClientes
      .map((total) => parseFloat(total?.pwithsale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarwithoutsalePOr = sumarClientes
      .map((total) => parseFloat(total?.pwithoutsale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarFreezersPor = sumarClientes
      .map((total) => parseFloat(total?.pfreezers))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
   
      const sumarfreezers = sumarClientes
      .map((total) => parseFloat(total?.freezers))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarparticipation = sumarClientes
      .map((total) => parseFloat(total?.participation))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
     
      const sumarprofit = sumarClientes
      .map((total) => parseFloat(total?.profit))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarwithoutsale= sumarClientes
      .map((total) => parseFloat(total?.withoutsale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarwithsale = sumarClientes
      .map((total) => parseFloat(total?.withsale))
      .reduce((previous, current) => {
        console.log(previous);
        console.log(current);
        console.log(previous + current)

        return previous + current;
      }, 0);

      const sumarreturns= sumarbonificaciones
      .map((total) => parseFloat(total?.returns))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarbonus= sumarbonificaciones
      .map((total) => parseFloat(total?.bonus))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarreal_sales= sumarbonificaciones
      .map((total) => parseFloat(total?.real_sales))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarcustomers_returns= sumarbonificaciones
      .map((total) => parseFloat(total?.customers_returns))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarcustomers_bonus= sumarbonificaciones
      .map((total) => parseFloat(total?.customers_bonus))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

    
      const sumarquantity= sumarProductos
      .map((total) => parseFloat(total?.quantity))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarquantity_secondary_sale= sumarProductos
      .map((total) => parseFloat(total?.quantity_secondary_sale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarquantity_primary_sale= sumarProductos
      .map((total) => parseFloat(total?.quantity_primary_sale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarprimary_sale= sumarProductos
      .map((total) => parseFloat(total?.primary_sale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarprofit_primary_sale= sumarProductos
      .map((total) => parseFloat(total?.profit_primary_sale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarprofit_secondary_sale= sumarProductos
      .map((total) => parseFloat(total?.profit_secondary_sale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarmarket= sumarProductos
      .map((total) => parseFloat(total?.market))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);


      const sumarcustomersP= sumarProductos
      .map((total) => parseFloat(total?.customersP))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarcustomersNsales= sumarNosales
      .map((total) => parseFloat(total?.customersNsales))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);

      const sumarwithsaleE= sumarNosales
      .map((total) => parseFloat(total?.withsale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarWithoutsale= sumarNosales
      .map((total) => parseFloat(total?.withoutsale))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      const sumarFreezers= sumarNosales
      .map((total) => parseFloat(total?.freezers))
      .reduce((previous, current) => {
        return previous + current;
      }, 0);
      
    settotalcustomersNsales(sumarcustomersNsales);
    settotalwithsale(sumarwithsaleE);
    settotalwithoutsale(sumarWithoutsale);
    settotalfreezers(sumarFreezers);
    settotalescustomersP(sumarcustomersP)  
    settotalquantity(sumarquantity);
    settotalquantity_secondary_sale(sumarquantity_secondary_sale);
    settotalquantity_primary_sale(sumarquantity_primary_sale);
    settotalprimary_sale(sumarprimary_sale);
    setsetprofit_primary_sale(sumarprofit_primary_sale);
    settotalprofit_secondary_sale(sumarprofit_secondary_sale);
    settotalmarket(sumarmarket);

    settotalescustomers(sumarcustomer);

    settotalescustomerPor(sumarcustomerPor);
    settotaleswithsalePor(sumarwithoutsalePOr);
    settotaleswithoutsalePor(sumarwithoutsalePOr);
    settotalesfreezersPor(sumarFreezersPor);

    settotalessales(sumarTotal);
    settotalesparticipation(sumarparticipation);
    console.log(sumarwithsale);
    settotaleswithsale(sumarwithsale);
    settotaleswithoutsale(sumarwithoutsale);
    settotalesfreezers(sumarfreezers);
    settotalescost(sumarcost);
    settotalesprofit(sumarprofit);
    
    settotalreal_sales(sumarreal_sales);
    
   

    //inicio variables bonificaciones
    settotalessalesBonus(sumarGanancia);
    settotalreturns(sumarreturns);
    settotalbonus(sumarbonus);
    settotalcustomers_returns(sumarcustomers_returns);
    settotalcustomers_bonus(sumarcustomers_bonus);
    settotalesparticipationBonus(sumarparticipationBonus);

    //fin variables bonificaciones

  }, [page,data]);

   



  function getExportFileBlob({ columns, data, fileType, fileName }) {
    console.log(page);
    if (fileType === "csv") {
      
      const cabeceras = columns.map((col) => col.exportValue);
      var longitud = data.slice(0,page.length); 
    
      for(var i=0; i<longitud.length;i++){
         for(var j=0;j<longitud[i].length;j++){
          if(typeof longitud[i][j]==='string' && longitud[i][j].endsWith("%")){
            longitud[i][j]=Number(longitud[i][j].replace('%',''));
          }
          if(longitud[i][j][0]==="$"){
            longitud[i][j]=(longitud[i][j].slice(1));
           }
          }
       }
       
      console.log(longitud);
     
      //afecta la tabla de clientes y detalles de Venta
      if(Gtotal===true){
        var nuevoArray = [
          ["Gran Total", totalessales,
            totalesparticipation.toFixed(2)>100?100:totalesparticipation.toFixed(2),
            totalescustomers.toFixed(2),
            salesD===true?null:totalescustomerPor.toFixed(2)>100?100:totalescustomerPor.toFixed(2),
            totaleswithsale.toFixed(2), 
            salesD===true?null:totaleswithsalePor.toFixed(2)>100?100:totaleswithsalePor.toFixed(2),  
            totaleswithoutsale.toFixed(2),
            salesD===true?null:totaleswithoutsalePor.toFixed(2)>100?100:totaleswithoutsalePor.toFixed(2),
            totalesfreezers.toFixed(2),
            salesD===true?null:totalesfreezersPor.toFixed(2)>100?100:totalesfreezersPor.toFixed(2),
            ]];

            var ndata=nuevoArray.filter((d)=>d!==null);
        var resultado = longitud.concat(ndata);
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data: resultado});
        return new Blob([csvString], { type: "text/csv" });
      }
     


      //afecta la tabla de detalles de bonificacion
      if(GtotalBonus===true){ 
        var nuevoArray=[["Gran Total", totalessales, totalreturns, totalbonus, totalreal_sales,
        totalesparticipation.toFixed(2)>100?100:totalesparticipation.toFixed(2), totalcustomers_returns.toFixed(2), totalcustomers_bonus.toFixed(2)]]
        var resultado = longitud.concat(nuevoArray);
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data: resultado});
        return new Blob([csvString], { type: "text/csv" });
      }
      //afecta la tabla de productos
      if(GtotalP===true){        
         var nuevoArray = [["Gran Total", totalquantity.toFixed(2), totalquantity_secondary_sale.toFixed(2), totalquantity_primary_sale.toFixed(2), "", "",totalprimary_sale.toFixed(2)]];
         var resultado = longitud.concat(nuevoArray);
         const headerNames = columns.map((col) => col.exportValue);
         const csvString = Papa.unparse({ fields: headerNames, data: resultado});
         return new Blob([csvString], { type: "text/csv" });
      }
      //afecta la tabla de puntos de venta sin venta
      if(GtotalNsales===true){
        var nuevoArray = [["Gran Total",totalcustomersNsales.toFixed(2), totalwithsale.toFixed(2), totalwithoutsale.toFixed(2), totalfreezers.toFixed(2)]];
        var resultado = longitud.concat(nuevoArray);
        const headerNames = columns.map((col) => col.exportValue);
        const csvString = Papa.unparse({ fields: headerNames, data: resultado});
        return new Blob([csvString], { type: "text/csv" });
      }    
    } else if (fileType === "xlsx") {
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj; function getExportFileBlob({ columns, data, fileType, fileName }) {
          if (fileType === "csv") {
      
            
            const cabeceras = columns.map((col) => col.exportValue);
            var longitud = data.slice(0,page.length); 
          
            for(var i=0; i<longitud.length;i++){
               for(var j=0;j<longitud[i].length;j++){
                if(typeof longitud[i][j]==='string' && longitud[i][j].endsWith("%")){
                  longitud[i][j]=Number(longitud[i][j].replace('%',''));
                }
                if(longitud[i][j][0]==="$"){
                  longitud[i][j]=(longitud[i][j].slice(1));
                 }
                }
             }
             
            console.log(longitud);
           
            //afecta la tabla de clientes y detalles de Venta
            if(Gtotal===true){
              var nuevoArray = [
                ["Gran Total", totalessales,
                  totalesparticipation.toFixed(2)>100?100:totalesparticipation.toFixed(2),
                  totalescustomers.toFixed(2), 
                  totalescustomerPor.toFixed(2)>100?100:totalescustomerPor.toFixed(2),
                  totaleswithsale.toFixed(2), 
                  totaleswithsalePor.toFixed(2)>100?100:totaleswithsalePor.toFixed(2),  
                  totaleswithoutsale.toFixed(2),
                  totaleswithoutsalePor.toFixed(2)>100?100:totaleswithoutsalePor.toFixed(2),
                  totalesfreezers.toFixed(2),
                  totalesfreezersPor.toFixed(2)>100?100:totalesfreezersPor.toFixed(2),
                  ]];
              var resultado = longitud.concat(nuevoArray);
              const headerNames = columns.map((col) => col.exportValue);
              const csvString = Papa.unparse({ fields: headerNames, data: resultado});
              return new Blob([csvString], { type: "text/csv" });
            }
            //afecta la tabla de detalles de bonificacion
            if(GtotalBonus===true){ 
              var nuevoArray=[["Gran Total", totalessales, totalreturns, totalbonus, totalreal_sales,
              totalesparticipation.toFixed(2)>100?100:totalesparticipation.toFixed(2), totalcustomers_returns.toFixed(2), totalcustomers_bonus.toFixed(2)]]
              var resultado = longitud.concat(nuevoArray);
              const headerNames = columns.map((col) => col.exportValue);
              const csvString = Papa.unparse({ fields: headerNames, data: resultado});
              return new Blob([csvString], { type: "text/csv" });
            }
            //afecta la tabla de productos
            if(GtotalP===true){        
               var nuevoArray = [["Gran Total", totalquantity.toFixed(2), totalquantity_secondary_sale.toFixed(2), totalquantity_primary_sale.toFixed(2), "", "",totalprimary_sale.toFixed(2)]];
               var resultado = longitud.concat(nuevoArray);
               const headerNames = columns.map((col) => col.exportValue);
               const csvString = Papa.unparse({ fields: headerNames, data: resultado});
               return new Blob([csvString], { type: "text/csv" });
            }
            //afecta la tabla de puntos de venta sin venta
            if(GtotalNsales===true){
              var nuevoArray = [["Gran Total",totalcustomersNsales.toFixed(2), totalwithsale.toFixed(2), totalwithoutsale.toFixed(2), totalfreezers.toFixed(2)]];
              var resultado = longitud.concat(nuevoArray);
              const headerNames = columns.map((col) => col.exportValue);
              const csvString = Papa.unparse({ fields: headerNames, data: resultado});
              return new Blob([csvString], { type: "text/csv" });
            }    
          } else if (fileType === "xlsx") {
            const header = columns.map((c) => c.exportValue);
            const compatibleData = data.map((row) => {
              const obj = {};
              header.forEach((col, index) => {
                obj[col] = row[index];
              });
              return obj;
            });
            let wb = XLSX.utils.book_new();
            let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
              header,
            });
            XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
            XLSX.writeFile(wb, `${fileName}.xlsx`);
            return false;
          }
      
      
          return false;
        }
      });
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
      return false;
    }


    return false;
  }

  
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.  
    isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <>
    
      <div
        style={{ display: exportEx === true ? "" : "none" }}
        className="containerExport"
      >
        <div className="expen-6 ">
        
          <img
            src={exportE}
            className=" "
            id=""
            title="Exportar"
            onClick={() => {
                exportData("csv", false);
            }}
          ></img>
        </div>
      </div>

      <Fragment>
      <div  className={data?.length<=10?"":"tableFixHeadDashboard"}>

        <Table bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()} title="Ordenar">
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                    <Filter column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>

         

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                  {row.isExpanded && (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {renderRowSubComponent(row)}
                      </td>
                    </tr>
                  )}
                </Fragment>

              );
            })}
          </tbody>

   
          <tbody style={{ display: Gtotal === true ? "" : "none" }}>
            <tr>
              <td style={{ display: campSUbtable === true ? "" : "none" }}></td>
              <td>Gran Total</td>
              <td>{totalescustomerPor.toFixed(2)}</td>
              <td>{totalesparticipationBonus.toFixed(2)}</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalescustomers)}</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalessalesBonus)}</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalessales)}</td>

              
            </tr>
          </tbody>

          <tbody style={{ display: GtotalBonus === true ? "" : "none" }}>
            <tr>
              <td style={{ display: campSUbtable === true ? "" : "none" }}></td>
              <td>Gran Total</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalessalesBonus)}</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalreturns)}</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalbonus)}</td>
              <td>{ Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(totalreal_sales)}</td>
              <td>{totalesparticipationBonus.toFixed(2)>100?100+"%":totalesparticipationBonus.toFixed(2)+"%"}</td>
              <td>{totalcustomers_returns.toFixed(2)}</td>
              <td>{totalcustomers_bonus.toFixed(2)}</td>
            </tr>
          </tbody>



          <tbody style={{ display: GtotalP === true ? "" : "none" }}>
            <tr>
              <td style={{ display: campSUbtable === true ? "" : "none" }}></td>
              <td>Gran Total</td>
              <td>{totalquantity.toFixed(2)}</td>
              <td>{totalquantity_secondary_sale.toFixed(2)}</td>
              <td>{totalquantity_primary_sale.toFixed(2)}</td>
              <td></td>
              <td></td>
              <td>{totalprimary_sale.toFixed(2)}</td>
              <td style={{display:sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?"":"none"}}>{totalprofit_secondary_sale.toFixed(2)}</td>
              <td style={{display:sessionStorage.getItem("ROLE_REPORT_SALES_MANAGEMENT")==="ROLE_REPORT_SALES_MANAGEMENT"?"":"none"}}>{setprofit_primary_sale.toFixed(2)}</td>
            </tr>
          </tbody>


          <tbody style={{ display: GtotalNsales === true ? "" : "none" }}>
            <tr>
              <td style={{ display: campSUbtable === true ? "" : "none" }}></td>
              <td>Gran Total</td>
              <td>{totalcustomersNsales.toFixed(2)}</td>
              <td>{totalwithsale.toFixed(2)}</td>
              <td>{totalwithoutsale.toFixed(2)}</td>
              <td>{totalfreezers.toFixed(2)}</td>
              </tr>
          </tbody>

        </Table>
        </div>

        <div className="sk-fading-circle" style={{ display: Gmodal === true ? "" : "none" }}>
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>

        {/* <p>Factura seleccionada: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              'selectedFlatRows[].original': selectedFlatRows.map(
                d => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre> */}

        <div style={{ display: visible === true ? "none" : "" }}>
          <Row
            style={{ maxWidth: 1300, margin: "0 auto", textAlign: "center" }}
          >
            <Col md={3}>
              <Button
                color="primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </Button>
              <Button
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </Col>
            <Col md={2} style={{ marginTop: 7 }}>
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageOptions.length}
              </strong>
            </Col>

            <Col md={4}>
              <CustomInput
                id="select"
                type="select"
                onChange={onChangeInSelect}
              >
                {[
                  10,
                  Math.round(data.length / 4),
                  Math.round(data.length / 2),
                  data.length,
                ].map((pageSize) => (
                  <option key={pageSize.id} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </CustomInput>
            </Col>
            <Col md={3}>
              <Button
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage}
              >
                {">"}
              </Button>
              <Button
                color="primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </Button>
            </Col>
          </Row>
          <h3 className="facturasConsultasTotales">
            Consultados: {data.length}
          </h3>
        </div>
      </Fragment>
    </>
  );
};

export default TableContainer;
