//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import { SelectColumnFilter } from "../components_cfdi/filters";
import TableContainer from "./Table_Container_Tax";
import Set_tax from "./Set_Taxes";
import SubTable from "./Get_Configure_Tax";
import Set_configure from "./Set_Configure_Tax";

//ESTILOS
import "../components_cfdi/estilo.css";
import "./TableTax.css";
import "../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../imgComponents/save.png";
import DELETE from "../imgComponents/borrar.png";
import Spinner from "../imgComponents/S.gif";
import CREATE from "../imgComponents/create.png";
import VER from "../imgComponents/visualizar.png";
import { refresh_token  } from "../main_components/tokens/tokenrefresh";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableTaxes = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);

  const handleSubmit = (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando impuestos...")
    axios
      .get(`https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/taxes?`, {headers: {Authorization: sessionStorage.getItem("getIdTokenRefresh"),"access-token": sessionStorage.getItem("getAccesTokenRefresh"),"Content-Type": "application/json"},})
      .then((response) => {
        const data = response.data;
        setData(data);
        setOpen(false);
        setMenssage("")
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setMenssage("")

      });
  };

  

  useEffect(() => {
    handleSubmit();
  }, []);


  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {

          return(
          <img
                title="Crear Impuesto"
                src={CREATE}
                className="cancel"
                onClick={async () => {
                  
                  const result = await CustomDialog(
                    
                      <Set_tax ></Set_tax>,
                    {
                      className: "modalTItle",
                      title:
                        "Seleccione la configuracion del impuesto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                
              }}
              ></img>
            )
        },
        Cell: ({ row }) => {

          function guardardato(){
            sessionStorage.setItem("id_tax_subtable",row.original.id);
          }

          return(
          <a title="Configuración de impuestos" {...row.getToggleRowExpandedProps()} >
            {row?.isExpanded ? <span title="Configuración del impuesto" >🔼</span>: <span title="Configuración del impuesto" onClick={guardardato}>🔽</span>}
          </a>
          )
          },
      },
      
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function Redireccion() { 
            window.location="/consult_configure_taxes"
          }
          function DeleteTax() {
            setMenssage("Eliminando impuesto...");
            refresh_token ();
            setOpen(true);
            axios
              .delete(`https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/taxes/${row.original.id}`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                alert("Impuesto eliminado correctamente")
                setOpen(false);
                window.location="/consult_taxes"
                setMenssage("")
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
                setMenssage("")
                console.log()
                alert("Error.,"+ err.response.data.errorMessage); 
              });


          }

          return (
            <div>
              <img
                title="Consultar configuraciones"
                src={VER}
                onClick={Redireccion}
                className="centerText, taxes"
              ></img>

              <img
                title="Asignar configuración"
                src={SAVE}
                onClick={async () => {
                  
                  const result = await CustomDialog(
                    
                      <Set_configure id_tax={row.original.id} ></Set_configure>,
                    {
                      className: "modalTItle",
                      title:
                        "Seleccione la configuracion del impuesto",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                
                 }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar impuesto"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este Impuesto?",
                    "Eliminar Impuesto",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Código",
        accessor: "c_impuesto",
      },
      {
        Header: "Impuesto",
        accessor: "tax_name",
      },
      {
        Header: "Traslado",
        accessor: (d) => {
          return d.transfer===true?"Si":"NO"
            
        },
      },
      {
        Header: "Retención",
        accessor: (d) => {
          return d.retention===true?"Si":"NO"
            
        },
      }
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          onClose={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style2} >
              <div className="centermodaldescarga">
                <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                  <img src={Spinner}></img>
                </Typography>
                <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                  {menssage}
                </Typography>
              </div>
            </Box>
        </Modal>
        </div>

      <div><h1>CONSULTAR IMPUESTOS</h1></div>
      <br></br>

      <div className="TableTaxes">
        <div className="">
          <TableContainer
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable/>
               </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableTaxes;
