import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { post_semaphore } from "../../services/reports/reports";


function CreateSemaphore(props) {
   
const dialog = useDialog();
const[bandera, setbandera]=useState(true);
const [color, setcolor]=useState(null);
const [max, setmax]=useState(0.0);
const [min, setmin]=useState(0.0);

const createnew = async () => {
if(color===null){
  props.alertas("No ha seleccionado color", false);
  return null;
}

// if(min>max){;
//   props.alertas("La cantidad máxima no puede ser menor a la cantidad miníma", false);
//   return null
// }

props.reconsultar(true);
setbandera(false);
await refresh_token ();
  var data = {
    "color_hex":color, //String = Indica el color en hexadecimal	
    "max": max,//Float = Indica el maximo valor
    "minimum": min,//Float = Indica el minimo valor
}
  try {
    const dt = await post_semaphore(data);
    console.log(dt);
    props.alertas("Semáforo creado con éxito", true);
    props.reconsultar(false);
    dialog.close();
    props.handleSubmit();
    
  } catch (err) {  
    console.log(err);
    catchErrorModul("Semáforo", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
    console.log("error post", err);
    setbandera(true);
    props.reconsultar(false);
  };
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
        <div className="grid-semaphore">
          <div className="semaphore1">
            <TextField
              label="Color"
              type="color"
              value={color}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcolor(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
           </div>
         
          <div className="semaphore2">
          <TextField
              label="Cantidad Mínima"
              type={"number"}
              value={min}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setmin(event.target.value.slice(0,9))}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>   
          <div className="semaphore3">
          <TextField
              label="Cantidad Máxima"
              type={"number"}
              value={max}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setmax(event.target.value.slice(0,9))}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>   
             
        </div> 
        <br></br>
        <br></br>
          <Box>
            {
              bandera===true?
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "30ch" }}
            onClick={createnew}
          >
            Crear Semaforo
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "30ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateSemaphore;
