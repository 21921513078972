import { Component } from "react";
import TableUpdateAddSales from "../../componentBonificaciones/componentVentasBonificaciones/TableUpdateAddSales";

export class SetSalesAddBonuses extends Component {
 
  render() {

      return (    
      
      <div className="tc" >
        <TableUpdateAddSales/> 
      </div>
          );
       }     
}

export default SetSalesAddBonuses;


