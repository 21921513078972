//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import { CustomDialog} from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import { StickyTable } from "react-sticky-table";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//COMPONENTES
import TableContainer from "../../../main_components/Table/TableContainer";
import { SelectColumnFilter} from "../../filters";
import ReenvioCorreo from "./report_email/send_invoice";
import SubTable from "./detail/detail_invoce";
import CANCEL_INVOICE from "../cancel_invoice/cancel_invoice";
import { base64toBlob } from "../../../main_components/donwloadfuncion/download";
import { get_invoices } from "../../../services/invoices/invoice";
import { download_invoice } from "../../../services/invoices/invoice";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { get_status } from "../../../services/invoices/invoice";
//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";

//IMAGENES
import Spinner from "../../../imgComponents/S.gif";
import tache from "../../../imgComponents/tache.png";
import tacheCANCEL from "../../../imgComponents/cancelado.png";
import PDF from "../../../imgComponents/pdf.png";
import PDFCANCEL from "../../../imgComponents/pdfcancel.png";
import XML from "../../../imgComponents/xml.png";
import XMLCANCEL from "../../../imgComponents/xmlcancel.png";
import EMAIL from "../../../imgComponents/correoElectronico.png";
import REPORT from "../../../imgComponents/invoice_cp.png";


const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Vppd = ()=> {
  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [isLoding, setIsLoding] = useState(false);
  const [open, setOpen] = useState(false);
  const [reconsult, setreconsult] = useState(false);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsLoding(true);
    setData([]);
    try {
      var dt = await get_invoices(fi, ff, rfc, sdn, tipodoc, cancel,"",true);
      setData(dt.data.slice().sort((a, b) => a.invoice_date < b.invoice_date));
      setIsLoding(false); 
    } catch (err) {
      setData([]);
      setIsLoding(false);
    }   
  };

  useEffect(() => { 
    handleSubmit(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handle_status =async(id,uuid)=>{
    setmensajeDescarga("Verificando estado de la factura");
    setOpen(true);
    setreconsult(true)

    try {
      const dt= await get_status(id,uuid);
      sessionStorage.setItem( "faCancel",dt?.data?.cancellation_request_id);
      alert("Estado actualizado");
      setOpen(false);
      setmensajeDescarga("");
      setreconsult(false)

    } catch (err) {
      alert(JSON.stringify(err.response.data));
      setreconsult(false)

    }
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span>{isAllRowsExpanded ? "🔼" : "🔽"}</span>
        ),
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("dataSuntablefiltro", row.original.uuid_sat);
            sessionStorage.setItem("dataSuntablefiltroID", row.original.id);
            console.log(row.original.folio);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          
          async function download(typedocumentexmlorpdf,id_invoce,fileName_document) {
            setmensajeDescarga("Descargando");
            setOpen(true);
            try {
              const data = await download_invoice(
                typedocumentexmlorpdf,
                fileName_document,
                id_invoce
              );
              var dt = data.data.file;
              var blob = base64toBlob(dt);
              const blobUrl = URL.createObjectURL(blob);
              const downloadLink = document.createElement("a");
              downloadLink.href = blobUrl;
              let arr = row.original.filename.split("/");
              downloadLink.download = arr[4] + `.${typedocumentexmlorpdf}`;
              downloadLink.click();
              setmensajeDescarga("");
              setOpen(false);
            } catch (err) {
              setOpen(false);
            }
          }

          return (
            <div>
              <div className="contanerActionImg">
                <div className="fat-1">
                  <img
                  alt=""
                    style={{display: row.original.cancel === false ? "none" : ""}}
                    src={tacheCANCEL}
                    title="REVERIFICAR ESTADO DE LA FACTURA"
                    onClick={() => handle_status(row.original.id,row.original.uuid_sat)}
                  ></img>

                  <img
                  alt=""
                    src={tache}
                    style={{display: row.original.cancel === false ? "" : "none"}}
                    className="cancelIMG"
                    title="Cancelar Factura"
                    onClick={async () => {
                      if (
                        sessionStorage.getItem("PERMISOCANCEL") ===
                        "ROLE_CANCEL_INVOICE"
                      ) {
                        sessionStorage.setItem("rfcBusqueda",row.original.rfc_business_partner);
                        sessionStorage.setItem("rowUuid",row.original.uuid_sat);
                        sessionStorage.setItem("id_cancelF", row.original.id);
                        handle_status(row.original.id,row.original.uuid_sat);
                        
                        await CustomDialog(
                          <CANCEL_INVOICE
                            idCancel={row.original.id}
                          ></CANCEL_INVOICE>,
                          {
                            className: "modalCancelR",
                            title:"Seleccione el motivo por el cual desea cancelar la factura",
                            showCloseIcon: true,
                          }
                        );
                      } else {
                        alert("No tienes permisos para cancelar");
                      }
                    }}
                  ></img>
                </div>
                <div className="fat-2">
                  <img
                  alt=""
                    src={XML}
                    title="Descargar XML"
                    className="cancelIMG2"
                    onClick={() =>
                      download("xml", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-3">
                  <img
                  alt=""
                    src={PDF}
                    title="Descargar PDF"
                    className="cancelIMG2"
                    onClick={() =>
                      download("pdf", row.original.id, row.original.filename)
                    }
                  ></img>
                </div>

                <div className="fat-5">
                  <img
                    alt=""
                    src={XMLCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "xml",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar XML Cancel"
                  ></img>
                </div>

                <div className="fat-6">
                  <img
                    alt=""
                    src={PDFCANCEL}
                    style={{
                      display: row.original.cancel === false ? "none" : "",
                    }}
                    onClick={() =>
                      download(
                        "pdf",
                        row.original.id,
                        row.original.filename_cancel
                      )
                    }
                    title="Descargar PDF Cancel"
                  ></img>
                </div>
                <div className="fat-4">
                  <img
                    alt=""
                    src={EMAIL}
                    className="cancelIMG2"
                    title="ENVIAR CORREO"
                    onClick={async () => {
                      sessionStorage.setItem(
                        "FACTURAMANDAR",
                        row.original.uuid_sat
                      );

                       await CustomDialog(
                        <ReenvioCorreo
                          report={false}
                          id={row.original.id}
                          uuid={row.original.uuid_sat}
                        ></ReenvioCorreo>,
                        {
                          className: "modalTItle",
                          title:
                            'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                          showCloseIcon: true,
                        }
                      );
                    }}
                  ></img>
                </div>
              </div>
            </div>
          );
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Fecha",
        accessor: "invoice_date",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        id: "seriee",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 1000,
        Header: "Cliente",
        accessor: "name_business_partner",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "RFC",
        accessor: "rfc_business_partner",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Forma de pago",
        accessor: "forma_pago",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Monto de pago",
        accessor: (d) => {
          return d.amount_paid
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_paid)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_paid);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Pagado",
        accessor: (d) => {
          return d.paid === true ? "SI" : "NO";
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Monto de deuda",
        accessor: (d) => {
          return d.amount_of_debt
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_of_debt)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.amount_of_debt);
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Parcialidad",
        accessor: "installment",
      },

      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel === true ? "CANCELADO" : "VIGENTE";
        },
      },
    ],
    []
  );

  return (
    <div className="TableConsultarTodo">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {mensajeDescarga}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="">
        {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={mensajeDescarga || "Buscando..."}
          />
        ) : (
          <div className="">
            <div className="contenerControllerCFDI">
              <div className="bp-1f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>
              <div className="bp-2f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
              <div className="bp-3f">
                <select
                  id="canceladas-si-no"
                  onChange={(event) => setCancel(event.target.value)}
                >
                  <option>Estado de la factura</option>
                  <option value={true}>CANCELADO</option>
                  <option value={false}>VIGENTE</option>
                  <option value={""}>TODOS</option>
                </select>
              </div>
              <div className="bp-4f">
                <input
                  type="text"
                  placeholder="Busqueda por RFC"
                  className="filtroRFC"
                  onChange={(event) => setRFC(event.target.value)}
                ></input>
              </div>
              <div className="bp-5f">
                <input
                  type="text"
                  placeholder="Busqueda por socio de negocios"
                  onChange={(event) => setSDN(event.target.value)}
                ></input>
              </div>
              <div className="bp-6f">
                <select onChange={(event) => setTipoDoc(event.target.value)}>
                  <option>Tipo de documento</option>
                  <option value={"FACTURA"}>FACTURA</option>
                  <option value={"NOTA DE CREDITO"}>NOTA DE CRÉDITO</option>
                  <option value={"TRASLADO"}>CARTA PORTE</option>
                  <option value={"PAGO"}>PAGO</option>
                  <option value={"null"}>TODOS</option>
                </select>
              </div>

              <div className="bp-7f">
                <button className="button" onClick={handleSubmit} type="button">
                  Buscar
                </button>
              </div>
              <div className="bp-8f">
              <img
                    alt=""
                    src={REPORT}
                    className="reportInvoice"
                    title="GENERAR REPORTE OPORTUNO"
                    onClick={async () => {
                      await CustomDialog(
                        <ReenvioCorreo report={true}
                        ></ReenvioCorreo>,
                        {
                          className: "modalTItle",
                          title:
                            'Nota: SEPARA LOS CORREOS ELECTRÓNICOS A TRAVÉS DE UNA "," SI DESEAS ENVIAR MÁS DE UN CORREO ELECTRÓNICO',
                          showCloseIcon: true,
                        }
                      );
                    }}
                  ></img>
              </div>
            </div>
            <StickyTable>
              <TableContainer
                nametable={`Facturas del ${fi} al ${ff}`}
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                consultas={"Facturas"}
                exportar={true} 
                renderRowSubComponent={(row) => {
                  return (
                    <div style={{ padding: "20px" }}>
                      <SubTable
                        reconsult={reconsult}
                        data={data}
                        columns={columns}
                        defaultPageSize={3}
                        showPagination={false}
                      />
                    </div>
                  );
                }}
              />
            </StickyTable>
          </div>
        )}
      </div>
    </div>
  );
};

export default Vppd;
