import TableConsultPolicy from "../../components_Expenses/curd_policy/Consult_Policy";
import { Component } from "react";


export class SetConsulPolicy extends Component {


  render() {


      return (    
      
      <div className="tc">
      <TableConsultPolicy></TableConsultPolicy>

      </div>
          );
       }     
}

export default SetConsulPolicy;
