//PAQUETERIAS
import React, { useState } from "react";
import {useDialog } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import Button from "@mui/material/Button";

//COMPONENTES
import { send_invoice } from "../../../../services/invoices/invoice";
import { general_public } from "../../../../services/invoices/invoice";

//ESTILOS
import "../../../estilo.css";
import "../../../modal.css";

//IMAGENES
import Spinner from "../../../../imgComponents/S.gif";
import { catchErrorModul } from "../../../../main_components/catchErr/catchError";

const ReenvioCorreo = (props) => {
  const dialog = useDialog();
  const [isLoding, setIsLoding] = useState(false);
  const [email, setEmail] = React.useState("");
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const handleChangeeEmail = (event) => {setEmail(event.target.value);};

  const send_email = async () => {
    setIsLoding(true);
    try {
      await send_invoice(props.id, props.uuid, email);
      alert("Envío exitoso");
      dialog.close();
      setIsLoding(false);
    } catch (err) {
      var dtErr = {email: email,uuid: props.uuid,id: props.id};
      alert(JSON.stringify(err?.response?.data));
      catchErrorModul("Envíar Facturas",JSON.stringify(dtErr),JSON.stringify( err?.response));
      setIsLoding(false);
    }
  };

  const handle_report =async ()=>{
    var dt = {emails: email,};
    setIsLoding(true);
    try {
      await general_public(fi,ff,dt);
      alert("El reporte será procesado.\nSe le enviará a su correo electrónico cuando esté disponible.");
      dialog.close();
      setIsLoding(false);
    } catch (err) {
      alert(JSON.stringify(err?.response?.data));
      catchErrorModul("Envíar Facturas",JSON.stringify(dt),JSON.stringify( err?.response));
      setIsLoding(false);
    }
  }

  return (
    <div>
      {isLoding ? (
        <LoadingScreen
          className="LoadinScreen"
          loading={true}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          logoSrc={Spinner}
          text={"Enviando..."}
        />
      ) : (
        <div>

          <div className="contenerControllerReport" style={{display:props.report===true?"":"none"}}>
          <div className="bp-1f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
              </div>
              <div className="bp-2f">
                <input
                  type="text"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
              
          </div>
          <textarea
            placeholder="Correos electronicos"
            style={{ width: "90%", height: 44 }}
            onChange={handleChangeeEmail}
          ></textarea>

          <Button
            style={{display:props.report===false?"":"none"}}
            sx={{ m: 2, width: "20ch" }}
            variant="contained"
            onClick={send_email}
          >
            Enviar
          </Button>

          <Button
            style={{display:props.report===true?"":"none"}}
            sx={{ m: 2, width: "20ch" }}
            variant="contained"
            onClick={handle_report}
          >
            Enviar Reporte
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReenvioCorreo;
