
import { Auth } from 'aws-amplify';
export async function refresh_token (){
  
    try {
      const tokenId = sessionStorage.getItem("getIdTokenRefresh");
      const tokenAcces = sessionStorage.getItem("getAccesTokenRefresh");
  
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(JSON.parse(atob(tokenId.split('.')[1])).exp);
      const expirationDateAcces = new Date(0);
      expirationDateAcces.setUTCSeconds(JSON.parse(atob(tokenAcces.split('.')[1])).exp);
  
      const currentTime = new Date();
      const timeToRefresh = (expirationDate - currentTime) / 1000 / 60; 
      const timeToRefresh2 = (expirationDateAcces - expirationDate) / 1000 / 60; 
      console.log("session:"+timeToRefresh2)
      console.log("session:"+timeToRefresh)
      console.log(expirationDateAcces);
      console.log(currentTime);

      
      if (timeToRefresh <= 15) {
        console.log(`Actualizando tokens...`);
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const { refreshToken } = cognitoUser.getSignInUserSession();
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          console.log(session)
          sessionStorage.setItem("getIdTokenRefresh",session.idToken.jwtToken)
          sessionStorage.setItem("getAccesTokenRefresh",session.accessToken.jwtToken);
  
          const expirationDaten = new Date(0);
          expirationDaten.setUTCSeconds(JSON.parse(atob(session.idToken.jwtToken.split('.')[1])).exp);
         const timeToRefreshn = (expirationDaten - currentTime) / 1000 / 60; 
  
          console.log(timeToRefreshn);
        });
        
        return true;
       
      } else {
        console.log(`Token tiempo de vidad : ${timeToRefresh} minutos.`);
        
        if (expirationDateAcces < currentTime) {
          try {
            await Auth.signOut();
            alert('Sesión terminada.');
            window.location.href="/";
    
          } catch (error) {
            console.log('Error al cerrar sesión:', error);
          }        
          console.log("Session Terminda.");
          return false;

        }else{
          return true;

        }
      }
    } catch (error) {
      console.log(`Error: ${error}`);
    }

}
