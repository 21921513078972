//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import Set_expense from "../expense_extranjero/Set_Expense";
import SubTable from "../expense_report/Get_datail";
import Put_expense_extranjero from "../expense_extranjero/Put_Expense";
import TableDetailGrupExpense from "./Consult_Detail_Grup";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../expense.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import ADD from "../../imgComponents/add.png";
import PDF from "../../imgComponents/pdf.png";
import VISUALIZAR from "../../imgComponents/lupa.png"
const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableGrupExpense = () => {

  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes=  añoantes-1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  
  

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${añoantes}-${mesantes}-${dia}`;

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState("");

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState("");

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");

  const handleSubmit = (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Consultando grupo de gastos...");
    console.log(fi);
    console.log(ff);

    axios
      .get(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses`, 
      {
        params: {
          di:fi,
          df:ff,       
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
       
      })
      .then((response) => {
        const data = response?.data;
        const data2 = data?.sort((a, b) => (a.id < b.id ? 1 : a.id > b.id ? -1 : 0));




        console.log(data2);

        setData(data2);

        setOpen(false);
        setMenssage("")
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setOpen(false);

        setMenssage("")

      });
  };

  
  const handleReconsulta = (reconsultar)=>{

    if(reconsultar===true){
      handleSubmit();
    }
    else{
      console.log("no se reconsulto");
    }

  }
  useEffect(() => {
    handleSubmit();
  }, []);



  const columns = useMemo(
    
    () => [
      
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {

          function ConsultDetailGrupExpense(){
            if(sessionStorage.getItem("PERMISOPOLICY")==="ROLE_POLICY"){
            sessionStorage.setItem("IdGrupExpense",row.original?.id);
            sessionStorage.setItem("nameConceptExpense",row.original?.concept)
            window.location="/expensesGrupDetail";
          }else{
            alert("No cuentas con el rol necesario para visualizar los detalles de grupo., Solicita el rol con tu supervisor.")
          }

          }

          function DeleteTax(result) {
            setMenssage("Eliminando Grupo...")
            setOpen(true);
            axios
              .delete(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${row.original.id}`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                alert("Grupo eliminado correctamente")
                setOpen(false);
                setMenssage("");
                setTimeout(handleReconsulta(result), 3000);
                
              })
              .catch((err) => {
                console.log("error", err);
                console.log("error", err?.response);
                var errorM=err?.response?.data?.errorMessage;
                setOpen(false);
                setMenssage("")
                console.log()
                alert("Error.,"+errorM); 
              });


          }

          return (
            <div>
              
              <img
                title="Ver detalles"
                src={VISUALIZAR}
                onClick={ConsultDetailGrupExpense}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Grupo de gastos"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este grupo de gastos?",
                    "Eliminar Grupo de gastos",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "No.",
        accessor: "id",
      },
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Pago",
        accessor: "payment",
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
      {
        Header: "Usuario",
        accessor: "user_name",
      }
	
    ],
    []
  );

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style2} >
              <div className="centermodaldescarga">
                <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                  <img src={Spinner}></img>
                </Typography>
                <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                  {menssage}
                </Typography>
              </div>
            </Box>
        </Modal>
        </div>

      <div><h1>GRUPO DE GASTOS</h1></div>
      <br></br>
      
      <div class="containerExpenseReport2">

            <div class="expen-1 color-1">
            <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>
            </div>

            <div class="expen-2 color-2">
            <input
                  type="text"
                  className="fExpense"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
            </div>


            <div class="expen-3 color-5">

            <Button  
            sx={{ width: "100%" }}
            variant="contained"
            onClick={handleSubmit} >
                  Buscar
                </Button>
              
            </div>
          </div>




     

          <div className="TableReport GrupExpense">
        <div style={{overflowX:"auto"}}>
          <TableContainer
            exportEx={false}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable verData={true}/>
               </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TableGrupExpense;
