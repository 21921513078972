//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { CustomDialog, Confirm } from "react-st-modal";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
//COMPONENTES

import TableContainer from "../table_movements";
import { get_warehouse_existence } from "../../../services/inventory/inventory";
import { create_warehouse_movements } from "../../../services/inventory/inventory";
//ESTILOS
import "./movements.css";
//IMAGENES/ICONOS
import CREATE from "../../../imgComponents/create.png";

import SET_MOVEMENTS from "../tools/create_movements";

export const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
const WAREHOUSE_MOVEMENTS = () => {
  const [dataTable, setDataTable] = useState([]);
  const [dataTableOriginal, setdataTableOriginal] = useState([]);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [reference, setreference] = useState("");
  const [note, setnote] = useState("");
  const [validate_reference, setvalidate_reference] = useState(true);
  const [open, setOpen] = useState(true);
  const [menssage, setMenssage] = useState(true);
  const [originalData] = useState(dataTable);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [quantity, setquantity] = useState(0);
  const [origin, setorigin] = useState(0);
  const [destination, setdestination] = useState(0);

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setOpen(true);
    setMenssage("Productos...");
    try {
      const dt = await get_warehouse_existence(
        sessionStorage.getItem("origin")
      );
      const dt2 = await get_warehouse_existence(
        sessionStorage.getItem("destination")
      );
      setData(dt.data);
      setData2(dt2.data);
      const ndatadestination = dt2.data.map((e) => ({
        destination: e.quantity,
      }));
      if (sessionStorage.getItem("origin") === "-1") {
        const ndata = dt2.data.map((e, index) => ({
          code: e.code,
          id: e.id_products_fk,
          description: e.description,
          origin: 0,
          quantity: "",
          destination: e.quantity,
        }));

        console.log(ndata);
        setDataTable(ndata);
        setdataTableOriginal(ndata)
      } else {
        const ndata = dt.data.map((e, index) => ({
          code: e.code,
          id: e.id_products_fk,
          description: e.description,
          origin: e.quantity,
          quantity: "",
          destination: ndatadestination[index].destination,
        }));
        setDataTable(ndata);
        setdataTableOriginal(ndata)

      }

      setOpen(false);
    } catch (err) {
      setDataTable([]);
      setMenssage("");
    }
  };

  const handle_move_all_product = () => {
  

    const ndata = data.map((e, index) => ({
      code: e.code,
      description: e.description,
      origin: e.quantity,
      quantity: e.quantity,
      destination: e.quantity + data2[index]?.quantity,
    }));

    console.log(ndata);
    setDataTable(ndata);
  };

  const handle_create_movement = async () => {
    const ndt = dataTable.filter(d => d.quantity && d.quantity !== 0).map(({ id, quantity }) => ({ id, quantity }));

    var env_data = {
      origin: sessionStorage.getItem("origin"),
      destination: sessionStorage.getItem("destination"),
      note: note,
      reference: reference,
      movement: ndt,
    };

    try {
      await create_warehouse_movements(env_data);
    } catch (err) {}
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "",
        Cell: ({ row }) => {
          return row.index + 1;
        },
      },

      {
        id: "code",
        Header: "Código",
        Cell: ({ row }) => {
          return row.original.code;
        },
      },
      {
        id: "description",
        Header: "Descripción",
        Cell: ({ row }) => {
          return row.original.description;
        },
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        id: "Origen",
        Header: "Origen",
        Cell: ({ row }) => {
          return row.original.origin;
        },
      },
      {
        id: "destination",
        Header: "Destino",
        Cell: ({ row }) => {
          return row.original.destination;
        },
      },
    ],
    [dataTable]
  );

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setDataTable((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          console.log(columnId);
          return {
            ...old[rowIndex],
            [columnId]: value,
            destination:
              value === "" ? dataTableOriginal[rowIndex].destination : dataTableOriginal[rowIndex].destination + Number(value),
          };
        }
        return row;
      })
    );
  };
  React.useEffect(() => {
    setSkipPageReset(false);
    var ntest = dataTable.filter((d) => d.quantity !== "");
    console.log(ntest);
    setquantity(
      ntest
        .map((a) => Number(a.quantity))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setorigin(
      dataTable
        .map((a) => Number(a.origin))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
    setdestination(
      dataTable
        .map((a) => Number(a.destination))
        .reduce((previous, current) => {
          return previous + current;
        }, 0)
    );
  }, [dataTable]);

  const resetData = () => dataTable(originalData);

  return (
    <div className="">
      <div>
        <h1>CONSULTAR LINEA DE PRODUCTOS</h1>
      </div>
      <br></br>
      <div className="containerMovements">
        <div className="pdc-1 color-1">
          <ValidationTextField
            label="Referencia"
            placeholder="Código de Barras"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={reference}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setreference(value.toUpperCase());
              } else {
                setreference(value);
              }
            }}
            
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
         
        </div>

        <div className="pdc-2 color-2">
          <ValidationTextField
            label="Referencia"
            placeholder="Código de Barras"
            variant="outlined"
            sx={{ m: 2, width: "90%" }}
            defaultValue="Success"
            name="capital_regime"
            value={note}
            onChange={(e) => {
              const value = e.target.value;
              if (typeof value === "string") {
                setnote(value.toUpperCase());
              } else {
                setnote(value);
              }
            }}
           
            InputLabelProps={{
              shrink: true,
            }}
          />
          <br />
          
        </div>

        <div className="pdc-3">
          <Button
            sx={{ width: "100%" }}
            style={{
              display: sessionStorage.getItem("origin") === "-1" ? "none" : "",
            }}
            variant="contained"
            onClick={() => handle_move_all_product()}
          >
            Mover Todo el Producto
          </Button>
        </div>
        <div className="pdc-4">
          <Button
            sx={{ width: "100%" }}
            style={{
              display: sessionStorage.getItem("origin") === "-1" ? "none" : "",
            }}
            variant="contained"
            onClick={() => handle_create_movement()}
          >
            Cerrar Movimiento
          </Button>
        </div>
      </div>

      <div className="TableTaxes">
        <div className="">
          <TableContainer
            columns={columns}
            data={dataTable}
            style={{ overflowx: 10 }}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            quantity={quantity}
            origin={origin}
            destination={destination}
          />
        </div>
      </div>
    </div>
  );
};

export default WAREHOUSE_MOVEMENTS;
