import React, { Fragment,useEffect,useState} from 'react';
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from 'react-table';
import { Table, Row, Col, Button, Input, CustomInput } from 'reactstrap';
import { Filter, DefaultColumnFilter } from '../../components_cfdi/filters';
import { text } from '@fortawesome/fontawesome-svg-core';

import "./tools/movements.css"
// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)
  const onChange = e => {
    console.log(index)
    var nda= JSON.parse(sessionStorage.getItem("warehouseProducts"));
    console.log(nda[index]);
    if(sessionStorage.getItem("origin")==="-1"){
      setValue(e.target.value)
    }else{

    
    if(e.target.value>0){
      if(e.target.value<=nda[index].origin){
        setValue(Number(e.target.value))
  
      }else{
        setValue(Number(e.target.value?.substr(0, e.target.value.length - 1)));
      }
     
    } else{
      setValue("")
    }
  }
    
    
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value)
  }

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

    return <input disabled={id==="quantity"?false:true} type={id==="quantity"?"number":"text"} className='inputDataEdit' value={value} onChange={onChange} onBlur={onBlur}/>

}
// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
}


const TableContainer = ({ columns, data, renderRowSubComponent, updateMyData,exportEx, visible,quantity,origin,destination}) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: (data && data.length) || 1000 },
      getExportFileBlob,
      updateMyData
    },
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect,


  );

  // const IndeterminateCheckbox = React.forwardRef(
  //   ({ indeterminate, ...rest }, ref) => {
  //     console.log(selectedFlatRows);
  //     const defaultRef = React.useRef()
  //     const resolvedRef = ref || defaultRef
  
  //     React.useEffect(() => {
  //       resolvedRef.current.indeterminate = indeterminate
  //     }, [resolvedRef, indeterminate])
  
  //     return (
  //       <>
  //         <input className='seleccionar' type="checkbox" ref={resolvedRef} {...rest} ></input>
  //       </>
  //     )
  //   }
  // )

  console.log(data);

  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
      // XLSX example
  
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });
  
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
  
      // Returning false as downloading of file is already taken care of
      return false;
    }
    //PDF example
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);
  
      return false;
    }
  
    // Other formats goes here
    return false;
  }
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };


  useEffect(() => {
    console.log("+++++++++");
    const map1 = page.map(x => x.original);
    sessionStorage.setItem("warehouseProducts",JSON.stringify(map1));

  }, [page]);



  return (
    <>
      

    <Fragment>
    <div className="tableFixHead">

      <Table bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>{quantity}</td>
            <td>{origin}</td>
            <td>{destination}</td>

          </tr>
       
        </tfoot>
        
      </Table>
     
      
      </div>


<h3 className='facturasConsultasTotales'>Productos: {data.length}</h3>

      
    </Fragment>
    </>
  );
};

export default TableContainer;
