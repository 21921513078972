import Set_bussines_p from "../../form_business_partner/set_bussines_p";
import { Component } from "react";


export class Set_bussines_P extends Component {
  


  render() {

   
      return (    
      
      <div className="tc" >
      <Set_bussines_p/> 

      </div>
          );
       }     
}

export default Set_bussines_P;


