import  { Component } from "react";
import TableBonificaciones from "../../componentBonificaciones/TableBonificaciones";
export class SetTableBonuses extends Component {
  



  render() {
      return (    
      
      <div className="tc" >
        <TableBonificaciones/> 
      </div>
          );
       }     
}

export default SetTableBonuses;


