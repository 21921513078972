//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable } from "react-sticky-table";
import LoadingScreen from "react-loading-screen";
//COMPONENTES
import TableContainer from "./TableContainerAddSales";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./estilostable.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Spinner from "../../componentsPago/S.gif";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import Select from "react-select";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";


const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#ffffff" : "#000000",
    backgroundColor: state.isSelected ? "#000000" : "#ffffff",
  }),
};
const TableUpdateAddSales = () => {
  const [data, setData] = useState([]);
  const [originalData, setoriginalData] = useState([]);

  const [searchData, setsearchData] = useState([]);

  const fecha = new Date();

  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes = añoantes - 1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  const recuperarPPD = `${año}-${mes}-${dia}`;
  const recuperarPPDantes = `${añoantes}-${mesantes}-${dia}`;
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    console.log(selected);
    filterArray(selected);
  };

  function filterArray(f) {
    if (f?.length > 0) {
      const filterArr = originalData.filter((elem) => {
        console.log(elem);
        return f?.some((term) =>
          elem?.name?.trim().includes(term.value.trim())
        );
      });
      console.log(filterArr);
      setData(filterArr);
    } else {
      setData(originalData);
    }
  }
  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");
  const [bandera, setbandera] = useState(false);
  const [cont, setcont] = useState(0);
  const [contpackage, setcontpackage] = useState(0);
  const [difT, setDifT] = React.useState([{ id: "eliminar" }]);
  const [difT2, setDift2] = useState([{ id: "eliminarEsto" }]);
  const [reiniciaA, setreiniciaA] = useState(false);

  //proceso de consultar ventas para generar el paquete de bonificaciones
  useEffect(() => {
    numeros();
  }, []);
  const handleSubmit = () => {
    const [y, m, d] = fi.split("-");
    const [y2, m2, d2] = ff.split("-");

    if (y.length == 4) {
      const rr = [d, m, y].join("/");
      setfi(rr);
      console.log("if del lengh=4");
      console.log(fi);
    }
    if (y2.length == 4) {
      const rrr = [d2, m2, y2].join("/");
      setff(rrr);
      console.log("if del leng=4");
      console.log(ff);
    }

    var fechainicial = fi;
    var fechafinal = ff;

    const [year, month, day] = fi.split("-");
    const [year2, month2, day2] = ff.split("-");

    if (year.length == 10) {
      console.log("entra 1");
      const [dd, mm, yy] = fi.split("/");
      fechainicial = [yy, mm, dd].join("-");
    }
    if (year2.length == 10) {
      console.log("entra 2");
      const [dd2, mm2, yy2] = ff.split("/");
      fechafinal = [yy2, mm2, dd2].join("-");
    }

    console.log(fechainicial);
    console.log(fechafinal);
    //console.log(fi);
    //console.log(ff);

    console.log("entra a consultar ventas");
    if (fi == "") {
      alert("Falta fecha inicial");
    } else {
      refresh_token ();
      setIsLoading(true);
      setMensajeBusqueda("Buscando Ventas");
      axios
        .get(
          "https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/sales/without_bon_pack",
          {
            params: {
              startDate: fechainicial,
              finalDate: fechafinal,
            },
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data.sales;
          setoriginalData(data);
          const searchData = data.map((d) => {
            const json = {
              value: d.name,
              label: d.name,
            };
            return json;
          });
          console.log(searchData);
          setsearchData(searchData);
          setData(data);

          setreiniciaA(true);

          setTimeout(() => {
            setIsLoading(false);

          }, 1000);
        })
        .catch((err) => {
          console.log("error", err);
          setData([]);

          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    }
  };
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    console.log("entra a updatemydata");
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const itemStyle = {
    padding: "2px",
    border: "1px solid silver",
    color: "Black",
    width: "120px",
    height: "20px",
  };
  const itemStyle2 = {
    padding: "2px",
    border: "1px solid silver",
    color: "Red",
    width: "90px",
    height: "20px",
  };
  const textStyle = {
    ...itemStyle,
    padding: "3px 2px",
    "font-size": "12px",
  };
  const textStyle2 = {
    ...itemStyle,
    padding: "3px 2px",
    "font-size": "10px",
    "border-color": "red",
  };
  //console.log(difT);
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      //cuando se selecciona uno por uno
      React.useEffect(() => {
        //console.log("entra");
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: 20 + "px" }}
            title="Seleccionar"
          />
        </>
      );
    }
  );
  function filtrar() {
    setbandera(false);

    console.log(difT);

    if (difT[0].id === "eliminar") {
      alert("Ninguna venta seleccionada");
    } else {
      //Se quitan las ventas deseleccionadas
      const arreglo = difT.filter((paper) => paper.select !== "noselect");
      console.log("arreglo sin ventas seleccionadas");
      console.log(arreglo);

      //Arreglo que tiene las ventas seleccionadas pero sin paper
      const arreglo2 = arreglo.filter((paper) => paper.paper == "");
      console.log("arreglo que tiene ventas seleccionadas pero sin paper");
      console.log(arreglo2);

      const arreglo3 = arreglo.filter((paper) => paper.paper == null);
      console.log("arreglo que tiene ventas seleccionadas pero sin paper");
      console.log(arreglo3);

      if (arreglo.length == 0) {
        alert("Ninguna venta seleccionada");
      } else {
        if (arreglo2.length > 0) {
          setbandera(true);
          var suma = arreglo2.length;
          if (arreglo3.length > 0) {
            suma = suma + arreglo3.length;
            alert(
              "Error en el campo comentarios en: " +
                suma +
                " ventas\nVerificar venta con ticket: " +
                arreglo2[0].ticket
            );
          } else {
            alert(
              "Error en el campo comentarios en: " +
                suma +
                " ventas\nVerificar venta con ticket: " +
                arreglo2[0].ticket
            );
          }
        } else {
          if (arreglo3.length > 0) {
            setbandera(true);
            var suma = arreglo3.length;
            if (arreglo2.length > 0) {
              suma = suma + arreglo2.length;
              alert(
                "Error en el campo comentarios en: " +
                  suma +
                  " ventas\nVerificar venta con ticket: " +
                  arreglo3[0].ticket
              );
            } else {
              alert(
                "Error en el campo comentarios en: " +
                  suma +
                  " ventas\nVerificar venta con ticket: " +
                  arreglo3[0].ticket
              );
            }
          } else {
            setbandera(false);
            //alert("se agregan ventas");
            agregarventas(arreglo);
            console.log(arreglo);
          }
        }
      }
    }
  }
  function reiniciar() {
    handleSubmit();
    numeros();
  }
  function Terminar() {
    window.location = "/consult_report_bonuses";
  }
  function numeros() {
    refresh_token ();
    console.log("entra a consultar numero de ventas en el paquete");
    axios
      .get(
        `https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/bonification_packages/${sessionStorage.getItem(
          "idpack"
        )}/sales`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data.sales.length);
        setcontpackage(response.data.sales.length);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  function agregarventas(arreglo) {

    refresh_token ();
    console.log("metodo de agregar ventas");
    console.log(arreglo);
    console.log(sessionStorage.getItem("idpack"));

    if (arreglo.length == 0) {
      alert("Datos vacios");
    }
    if (sessionStorage.getItem("idpack") == 0) {
      alert("No se puede generar el paquete");
    } else {
      var data = {
        sales: arreglo,
      };
      var url = `https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/bonification_packages/${sessionStorage.getItem(
        "idpack"
      )}`;
      var axiosConfig = {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      };

      setIsLoading(true);
      setMensajeBusqueda("Agregando ventas");

      axios
        .post(url, data, axiosConfig)
        .then((response) => {
          console.log(response);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);

          alert("Ventas agregadas con éxito");
          reiniciar();
          //window.location = "/consult_report_bonuses";
        })
        .catch((err) => {
          console.log("error post", err);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
    }
  }
  

  const columns = useMemo(
    () => [
      {
        Header: "Fecha",
        accessor: "date",
      },
      {
        Filter: () => {       
          return (
            <div>
               <Select
                styles={{color:"black"}}
                value={selectedOptions}
                onChange={handleChange}
                options={searchData}
                isMulti
                placeholder=""
              /> 
            </div>
          );
        },
        Header: "Razón Social",
        accessor: "name",
      },
      {
        Filter: SelectColumnFilter,
        Header: "Socio de Negocios",
        accessor: "name_business_partner",
      },
      {
        Header: "Código Externo",
        accessor: "outer_code",
      },
      {
        Header: "Número Tienda",
        accessor: "number_oxxo_store",
      },
      {
        Header: "Ticket",
        accessor: "ticket",
      },
      {
        Header: "Folio Especial",
        accessor: "special_folio_number",
      },
      {
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
      {
        id: "selection",
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <>
            <div>
              Comentarios&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                title="Seleccionar todas las ventas"
                className="NoInput"
              />
            </div>
          </>
        ),
        Cell: ({ row, value, onChange }) => {
          const [text, setText] = React.useState(value ?? "");
          var rowDate = row.getToggleRowSelectedProps();

          if (rowDate.checked === true) {
            difT[row.index] = {
              id_campo: row.index,
              id: row.original.id,
              paper: row.original.paper,
              ticket: row.original.ticket,
              select: "select",
              alert: "noalert",
            };
          } else {
            difT[row.index] = {
              id_campo: row.index,
              id: row.original.id,
              paper: data[row.index]?.paper,
              ticket: row.original.ticket,
              select: "noselect",
              alert: "noalert",
            };
          }

          //contador
          if (difT.id === "eliminar") {
            setcont(0);
          } else {
            setcont(difT.filter((item) => item.select == "select").length);
          }

          function folio() {
            //console.log(difT);
            if (row.isSelected === true) {
              setText(row.original.paper);

              if (difT[row.index]?.length != 0) {
                difT[row.index] = {
                  id_campo: null,
                  id: row.original.id,
                  paper: data[row.index]?.paper,
                  ticket: row.original.ticket,
                };
              }
              row.original.paper = difT2[row.index].paper;
            } else {
              //entra cuando se selecciona una casilla
              //contador

              setText("");
              difT[row.index] = {
                id_campo: row.index,
                id: row.original.id,
                paper: row.original.paper,
                ticket: row.original.ticket,
              };
              //console.log("entra");
              setDifT(difT);
              console.log(difT);
            }
          }
          useEffect(() => {
            const handlerestar = () => {
              setDifT(difT);
            };
            handlerestar();
          });
          useEffect(() => {
            const handlerestar = () => {
              setbandera(bandera);
            };
            handlerestar();
          });
          React.useEffect(() => {
            setText(value);
          }, [value]);
          const handleInputChange = (e) => {
            setText(e.target.value);
            row.original.paper = e.target.value;
          };
          const handleInputChange2 = (e) => {
            setText("");
          };

          const handleSaveClick = () => {
            setDifT(
              Number(text) < row.original.amount_of_debt
                ? Number(row.original.amount_of_debt - text).toFixed(2)
                : 0
            );

            if (Number(text) > row.original.amount_of_debt) {
              setText(0);
              if (difT[row.index]?.length != 0) {
                difT[row.index] = {
                  id_campo: row.index,
                  id: row.original.id,
                  paper: row.original.paper,
                  ticket: row.original.ticket,
                };
              } else {
                setDifT([
                  ...difT,
                  {
                    id_campo: row.index,
                    id: row.original.id,
                    paper: row.original.paper,
                    ticket: row.original.ticket,
                  },
                ]);
              }
            } else {
              if (difT[row.index]?.length != 0) {
                difT[row.index] = {
                  id_campo: row.index,
                  id: row.original.id,
                  paper: text,
                  ticket: row.original.ticket,
                  alert: "noalert",
                };
              } else {
                setDifT([
                  ...difT,
                  {
                    id_campo: row.index,
                    id: row.original.id,
                    paper: text,
                    ticket: row.original.ticket,
                    alert: "noalert",
                  },
                ]);
              }
              if (onChange) {
                onChange(text);
              }
            }
          };

          return (
            <div className="">
              <table>
                <tr>
                  <td>
                    {row.isSelected === true ? (
                      <input
                        type={"text"}
                        value={row.original.paper}
                        style={
                          //textStyle
                          difT[row.index].alert === "alert"
                            ? textStyle2
                            : textStyle
                        }
                        onChange={handleInputChange}
                        onClick={handleInputChange2}
                        onBlur={handleSaveClick}
                      />
                    ) : (
                      <input
                        type={"text"}
                        style={textStyle}
                        value={
                          row.original.paper == null ? "" : row.original.paper
                        }
                        disabled
                      />
                    )}
                  </td>
                  <td>
                    {" "}
                    <IndeterminateCheckbox
                      className="NoInput"
                      onChange={folio}
                      onClick={folio}
                      {...row.getToggleRowSelectedProps()}
                    />
                  </td>
                </tr>
              </table>
            </div>
          );
        },
      },
    ],
    [data]
  );
  return (
    <>
      <div className="containeritems py-2">
        <nav aria-label="breadcrumb" className="navegacion">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a className="listBreadCrum" href="/http://localhost:3000/">
                Home
              </a>
            </li>
            <li className="breadcrumb-item">
              <a className="listBreadCrum" href="/consult_report_bonuses">
                Reporte de Bonificaciones
              </a>
            </li>
            <li className="breadcrumb-item">Agregar Ventas</li>
          </ol>
        </nav>
      </div>

      <div className="TableAddSale">
        {/* <div className="buttonback">
    
  </div> */}
        <div className="">
          {isLoading ? (
            <LoadingScreen
              className="LoadinScreen"
              loading={true}
              bgColor="#f1f1f1"
              spinnerColor="#9ee5f8"
              textColor="#676767"
              logoSrc={Spinner}
              text={MensajeBusqueda || "Buscando..."}
            />
          ) : (
            <StickyTable>
              <div className="datospackage">
                <div className="package_info">
                  <p>Nombre Paquete: {sessionStorage.getItem("name_pack")}</p>
                  <p>Número paquete: {sessionStorage.getItem("num_pack")}</p>
                </div>
              </div>

              <div className="contanerFIltersTableSelectBonus">
                <div className="filter-1 color-1">
                  <input
                    type="text"
                    className="fi"
                    value={fi}
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha inicial"
                    onChange={(event) => setfi(event.target.value)}
                  ></input>
                </div>
                <div className="filter-2 color-1">
                  <input
                    type="text"
                    value={ff}
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                      e.currentTarget.focus();
                    }}
                    placeholder="Fecha Final"
                    onChange={(event) => setff(event.target.value)}
                  ></input>
                </div>

                <div className="filter-3 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    onClick={handleSubmit}
                    type="button"
                  >
                    Buscar
                  </button>
                </div>

                <div className="filter-4 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    style={{ width: "140px" }}
                    onClick={filtrar}
                  >
                    Agregar Ventas
                  </button>
                </div>

                <div className="filter-5 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    style={{ width: "150px" }}
                    onClick={Terminar}
                  >
                    Terminar Paquete
                  </button>
                </div>

                <div className="filter-6 color-1">
                  <button
                    className="button"
                    id="buttonpackageb"
                    style={{ width: "150px" }}
                    onClick={Terminar}
                  >
                    Terminar Paquete
                  </button>
                </div>

                <div className="filter-7 color-1">
                  <tr>
                    <td>
                      <p>Ventas en Paquete:&nbsp;{contpackage}</p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "white" }}>
                      <p>Ventas seleccionadas:&nbsp;{cont}</p>
                    </td>
                  </tr>
                </div>
              </div>

              <div className="contentbotons">&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div id="countsale"></div>
             
              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                updateMyData={updateMyData}
              />
            </StickyTable>
          )}
        </div>
      </div>
    </>
  );
};
export default TableUpdateAddSales;



