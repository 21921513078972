import { Component } from "react";
import TablePointSaleSector from "../../../componentSociosNegocios/componentPointsSale/TablePointSaleSector";
export class SetPointSaleSector extends Component {

  render() {


      return (    
      
      <div className="tc" >
        <TablePointSaleSector/>
      </div>
          );
       }     
}

export default SetPointSaleSector;


