import CfdiTable from "../../components_cfdi/invoice/consult_invoice/consult_invoice";
import { Component } from "react";

export class SetConsultaCFDI extends Component {
 

  render() {
    return (
      <div>
        <CfdiTable></CfdiTable>
      </div>
    );
  }
}

export default SetConsultaCFDI;

