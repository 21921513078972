import React, { Component } from 'react'
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';


export class ConfirmSignUp extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       Code : '',
       Username:'',
    }

    this.confirmSignUp = this.confirmSignUp.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);

  }

  async resendConfirmationCode(){
    try {
      await Auth.resendSignUp(this.state.Username);
      // console.log('code resent successfully');
    } catch (err) {
        // console.log('error resending code: ', err);
    }
  }

  handleFormSubmission(evt) {
    evt.preventDefault();
    this.confirmSignUp();
  }

  async confirmSignUp() {
    const username = this.state.Username;
    const code = this.state.Code;

    try {
      await Auth.confirmSignUp(username, code);
      this.props.SetAuthState(AuthState.SignIn)
    } catch (error) {
        // console.log('error confirming sign up', error);
    }
  }
  
  render() {
    return (
      <div className="colorfondo">
        <div className="parent clearfix">
          <div className="bg-illustration">
          </div>

          <div className="login">
            <div className="container">
              <br></br>
              <h2 className="titleOD2">Verifica tu correo</h2>
                      <div className="form-group">
                        <input className="form-control text-center" type="text" placeholder="Username" onChange={(e) => this.setState({Username: e.target.value})} ></input>

                      </div>

                      <div className="form-group">
                        <input className="text-center form-control" type="text" placeholder="Ingresa codigo de verificación" onChange={(e) => this.setState({Code: e.target.value})}></input>
                      </div>
                      
                      <div className="form-group">
                        <a className="form-control btn btn-primary"  onClick={this.handleFormSubmission} >Verificar Cuenta</a>
                      </div>
                      <div className="form-group">
                        <a className="form-control btn btn-primary" onClick={() => this.resendConfirmationCode}>Reenviar Codigo</a>

                      </div> 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmSignUp;
