import React, { Fragment, useEffect, useState } from 'react';
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "../../componentsNewDashboard/dashboarModal.css";


import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from 'react-table';
import { Table, Row, Col, Button,CustomInput } from 'reactstrap';
import { Filter, DefaultColumnFilter } from '../../components_cfdi/filters';
import Spinner from "../../imgComponents/S.gif";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


import CSV from "../../imgComponents/export.png"

const style2 = {
  position: 'absolute' ,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%', 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TableContainer = ({ paginado, consultas, exportar, columns, data, renderRowSubComponent, Gmodal, markers, setmarkers, markers2}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    exportData,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter},
      initialState: { 
        hiddenColumns: columns
        .filter((col) => col.show === false)
        .map((col) => col.id),
        pageIndex: 0,
        pageSize: 10,
      },
      getExportFileBlob,      
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useExportData,
    useRowSelect,
  );
  
  const [filterChanged, setFilterChanged] = useState(false);

  // función que se ejecuta cuando hay un cambio en la tabla
  const handleTableChange = (state) => {
    setFilterChanged(true);
  };

  useEffect(() => {
    if (filterChanged) {
      const newArray = page.map(obj => obj.original.ordenvisita);
      const filteredArray = markers2.filter(obj => newArray.includes(obj.ordenvisita));
      const sortedArray = filteredArray.sort((a, b) => a.ordenvisita - b.ordenvisita);
      setmarkers(sortedArray);
      setFilterChanged(false);
      console.log(sortedArray);
    }
  }, [markers, page, setmarkers, filterChanged]);

  

  // Set our editable cell renderer as the default Cell renderer
 
  function getExportFileBlob({ columns, data, fileType, fileName }) {
    if (fileType === "csv") {
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: "text/csv" });
    } else if (fileType === "xlsx") {
  
      const header = columns.map((c) => c.exportValue);
      const compatibleData = data.map((row) => {
        const obj = {};
        header.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });
  
      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
      XLSX.writeFile(wb, `${fileName}.xlsx`);
  
      return false;
    }
    if (fileType === "pdf") {
      const headerNames = columns.map((column) => column.exportValue);
      const doc = new JsPDF();
      doc.autoTable({
        head: [headerNames],
        body: data,
        margin: { top: 20 },
        styles: {
          minCellHeight: 9,
          halign: "left",
          valign: "center",
          fontSize: 11,
        },
      });
      doc.save(`${fileName}.pdf`);
  
      return false;
    }
  
    return false;
  }
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

const [open, setOpen] = React.useState(false);

return (
    <>
<div>
      <Modal
        open={open}
        onClose={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2} >
          <div className="centermodaldescarga">
          <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
          <img src={Spinner}></img>
          </Typography>
          <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>
            
            Exportando...
          </Typography>
          </div>
        </Box>
      </Modal>
    </div>
{ exportar==true?

<div className='containerExport'>

        <div className="expen-6 ">
<img
    src={CSV}  
    onClick={() => {
      setOpen(true);
      if(sessionStorage.getItem("exportPermis")==="ROLE_EXPORT_INVOICE"){
        exportData("csv", false);
        setOpen(false);
      }else{
        alert("No tienes permisos para exportar");
      }
    }}
    title="Exportar CSV"
  ></img>
  </div>

</div>:" "
}
    <Fragment>
    <div  className={data?.length<=1?"":""}>

      <Table bordered hover {...getTableProps()}
       onChange={handleTableChange}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()} title={"Ordenar"}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>

<div className="sk-fading-circle" style={{ display: Gmodal === true ? "" : "none" }}>
  <div className="sk-circle1 sk-circle"></div>
  <div className="sk-circle2 sk-circle"></div>
  <div className="sk-circle3 sk-circle"></div>
  <div className="sk-circle4 sk-circle"></div>
  <div className="sk-circle5 sk-circle"></div>
  <div className="sk-circle6 sk-circle"></div>
  <div className="sk-circle7 sk-circle"></div>
  <div className="sk-circle8 sk-circle"></div>
  <div className="sk-circle9 sk-circle"></div>
  <div className="sk-circle10 sk-circle"></div>
  <div className="sk-circle11 sk-circle"></div>
  <div className="sk-circle12 sk-circle"></div>
</div>
      <Row style={{ maxWidth: 1300, margin: '0 auto', textAlign: 'center' }}>
        <Col md={3}>
          <Button
            color='primary'
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            {'<<'}
          </Button>
          <Button
            color='primary'
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {'<'}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Pagina{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>
        </Col>
        
        <Col md={4}>
        <CustomInput
            id="select"
            type='select'
            onChange={onChangeInSelect}
          >
          {
            data.length<10?
            [ data.length,
              Math.round(data.length/4),
              Math.round(data.length/2),
            ]
            .map((pageSize) => (
              <option  value={pageSize}>
                {paginado} {pageSize}
              </option>
            )):
            [ 10,
              Math.round(data.length/4),
              Math.round(data.length/2),
              data.length
            ]
            .map((pageSize) => (
              <option  value={pageSize}>
                {paginado} {pageSize}
              </option>
          ))}
          </CustomInput>
        </Col>
        <Col md={3}>
          <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
            {'>'}
          </Button>
          <Button
            color='primary'
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {'>>'}
          </Button>
        </Col>
      </Row>
      {
        console.log(page)
      }
      <h3 className='facturasConsultasTotales'>{consultas} {page.length} de {data.length}</h3>
    </Fragment>
    </>
  );
};

export default TableContainer;
