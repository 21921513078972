import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Modal_Table from "../../main_components/modal/modal_Table";

import "../expense.css";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const ObjetoImp = [
  { value: "01", label: "No objeto de impuesto" },
  { value: "02", label: "Sí objeto de impuesto" },
  { value: "03", label: "Sí objeto del impuesto y no obligado al desglose" },
];

const typeFactorTC = [
  { value: "Tasa", label: "Tasa" },
  { value: "Cuota", label: "Cuota" },
];

const Set_expense = () => {
  const [open, setOpen] = useState(false);
  const [Descripcion, setDescripcion] = useState("");
  const [importe, setImporte] = useState(0);
  const [pro_servData, setpro_servData] = useState([]);
  const [accountsList, setaccountsList] = useState([]);
  const [selectaccountsList, setselectaccountsList] =
    React.useState("90909090909090");
  const [ff, setff] = useState(true);
  const [accounts, setAccounts] = useState("90909090909090");
  const [account, setAccount] = useState("");
  const [Concepto, setConcepto] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [modalT, setmodalT] = useState(false);

  

  const dialog = useDialog();

  const handelAccount = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
    }
  };

  const handelClave_prod_serv = (event) => {
    if (event?.target?.value === "9090909090909") {
    } else {
      const result = pro_servData.filter(
        (item) => item.id_account == event?.target?.value
      );
      console.log(result);
      console.log(pro_servData);
      console.log(event?.target?.value);
      setAccounts(result[0]?.id_account);
      setAccount(result[0]?.account);

      setConcepto(result[0]?.concept);
    }
  };

  const handelDescripcion = (event) => {
    setDescripcion(event?.target?.value);
  };

  const handelUnidad = (event) => {
    setImporte(event?.target?.value);
  };
  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const fileTypes = ["PDF"];

  const handleChange = (file) => {
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile(result);
        setFileName(file);
      }
    });
  };

  var data = {
    expense: {
      concept: Concepto,

      id_payment: Number(selectaccountsList),

      account: account,

      description: Descripcion,

      date: ff,

      amount: Number(importe),

      origin: 1,
    },

    file: {
      content: file?.replace("data:application/pdf;base64,", ""),

      type: "pdf",
    },
  };

  console.log(data);

  const handleSubmit = (event) => {
    event?.preventDefault();
    var data = {
      expense: {
        concept: Concepto,

        id_payment: Number(selectaccountsList),

        account: account,

        description: Descripcion,

        date: ff,

        amount: Number(importe),

        origin: 1,
      },

      file: {
        content: file?.replace("data:application/pdf;base64,", ""),

        type: "pdf",
      },
    };

    console.log(data);

    setmodalT(true);
    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses`,
        data,
        axiosConfig
      )
      .then((res) => {
        setmodalT(false);
        dialog.close(true);
      })
      .catch((err) => {
        setmodalT(false);
        var error=err?.response?.data?.errorMessage;
        alert("Error.," + error);
      });
  };

  const handleprod_concept_list = (event) => {
    event?.preventDefault();
    axios
      .get(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expenses/concept-list`,
        {
          params: {
            foreign: 0,
          },
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        const dataStatic = {
          id_account: "90909090909090",
          concept: "SELECCIONE UNA OPCIÓN",
          account: "SELECCIONE UNA OPCIÓN",
        };
        data[data.length] = dataStatic;
        setpro_servData(data);
      })
      .catch((err) => {
        setpro_servData([]);
      });
  };

  const handleAccountsList = (event) => {
    event?.preventDefault();
    const url = `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/prod/paymentaccounts`;
    axios
      .get(url, {
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;
        const dataStatic = {
          id: "90909090909090",
          payment_method: "SELECCIONE UNA OPCIÓN",
        };
        data[data.length] = dataStatic;
        setaccountsList(data);
      })
      .catch((err) => {
        setaccountsList([]);
      });
  };

  useEffect(() => {
    handleprod_concept_list();
    handleAccountsList();
  }, []);

  return (
    <div>
      <Modal_Table modalT={modalT}></Modal_Table>
      <div>
        <form>
          <Box>
            <TextField
              select
              label="Concepto"
              value={accounts}
              sx={{ m: 1.5, width: "89%" }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {pro_servData.map((option) => (
                <option
                  key={option.id_account}
                  value={option.id_account}
                  selected={option.id_account}
                >
                  {option.concept}
                </option>
              ))}
            </TextField>
          </Box>

          <Box>
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>

          <div className="generarExpense">
            <div className="expen-6">
              <input
                type="text"
                className="fExpense2"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder="Fecha"
                onChange={(event) => setff(event.target.value)}
              ></input>
            </div>
            <div className="expen-7">
              <ValidationTextField
                label="Importe"
                type="number"
                variant="outlined"
                placeholder="Importe"
                sx={{ m: 2, width: "94%" }}
                onChange={handelUnidad}
                onBlur={handelImportOnBurl}
                value={importe}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <Box>
            <TextField
              select
              label="Cuenta de pago"
              value={selectaccountsList}
              sx={{ m: 1.5, width: "89%" }}
              onChange={handelAccount}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {accountsList.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </Box>

          <br></br>

          <div className="container">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              placeholder="123-45-678"
            >
              <div className="drangAndDropExpense">
                <p>Arrastra o selecciona el PDF a enviar</p>
              </div>
            </FileUploader>
            <div className="upload-area">
              {fileName && <p className="filename">{fileName.name}</p>}
            </div>
            <br />
          </div>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Crear
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default Set_expense;
