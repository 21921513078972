import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_route_users, get_route_warehouse, get_types_of_sales, post_route } from "../../services/configuration/configuration";
import { get_employess } from "../../services/humanResources/humanResources";

function CreateRoutes(props) {

useEffect(()=>{
    consultroute();  
    consulttypesale();
    consultusers();
    consultempleados();
},[]);

const dialog = useDialog();

const [route, setroute]=useState(null);
const [desroute, setdesroute]=useState(null);
const [coderoute, setcoderoute]=useState(null);
const [firstuser, setfirstuser]=useState(null);
const [seconduser, setseconduser]=useState(null);
const [firstw, setfirstw]=useState(null);
const [secondw, setsecondw]=useState(null);
const [typesale, settypesale]=useState(null);
const [days, setdays]=useState(null);
const [codeRouteDMS, setcodeRouteDMS]=useState(null);
const [frec, setfrec]=useState(true);
const [sendms, setsendms]=useState(false);
const [id_employee_assigned, setid_employee_assigned]=useState(null);
const [id_second_employee_assigned, setid_second_employee_assigned]=useState(null);


const [vroute, setvroute]=useState(false);
const [vdesroute,setvdesroute]=useState(false);
const [vcoderoute, setvcoderoute]=useState(false);
const [vfuser, setvfuser]=useState(false);
const [vsuser, setvsuser]=useState(false);
const [vfw, setvfw]=useState(false);
const [vsw, setvsw]=useState(false);
const [vtype, setvtype]=useState(false);
const [vdays, setvdays]=useState(false);
const [vdms, setvdms]=useState(false);
const [vemploy1, setvemploy1]=useState(false);
const [vemploy2, setvemploy2]=useState(false);



const [bandera, setbandera]=useState(true);
const [datawarehouse, setdatawarehouse]=useState([]);
const [datatypesale, setdatatypesale]=useState([]);
const [datausers, setdatausers]=useState([]);
const [dataemployes, setdataemployes]=useState([]);

//consultar usuarios
const consultusers = async () => {
  await refresh_token();
  try {
    var d = await get_route_users();
    setdatausers(d === null ? [] : d.data.sales_employess);
  } catch (error) {
    console.log(error);
  } finally {
  }
}
const consulttypesale = async () => {
  await refresh_token();
  try {
    var d = await get_types_of_sales();
    setdatatypesale(d === null ? [] : d.data.sale_types);
  } catch (error) {
    console.log(error);
  } finally {
  }
}
const consultroute = async () =>{
  await refresh_token();
  try {
    var d = await get_route_warehouse();
    setdatawarehouse(d === null ? [] : d.data.warehouses);
  } catch (error) {
    console.log(error);
  } finally {
  }
} 
const consultempleados = async () => {
  await refresh_token();
  try {
    var d = await get_employess();
    setdataemployes(d === null ? [] : d.data);
  } catch (error) {
    console.log(error);
  } finally {
  }
}

//crear nueva ruta
const createnewRuta = async () => {

  route==null?setvroute(true):setvroute(false);
  desroute==null?setvdesroute(true):setvdesroute(false);
  coderoute==null?setvcoderoute(true):setvcoderoute(false);
  firstuser==null?setvfuser(true):setvfuser(false);
  seconduser==null?setvsuser(true):setvsuser(false);
  firstw==null?setvfw(true):setvfw(false);
  secondw==null?setvsw(true):setvsw(false);
  typesale==null?setvtype(true):setvtype(false);
  days==null?setvdays(true):setvdays(false);
  codeRouteDMS==null?setvdms(true):setvdms(false);
  id_employee_assigned===null?setvemploy1(true):setvemploy1(false);
  id_second_employee_assigned===null?setvemploy2(true):setvemploy2(false);

  if(route==null || desroute==null || coderoute==null || firstuser==null || seconduser==null
    || firstw==null || secondw==null || typesale==null || days==null || codeRouteDMS==null
    || id_employee_assigned==null || id_second_employee_assigned==null){
      props.alertas("Parametros faltantes", false);
  }else{
    
    if(firstuser===seconduser){
      props.alertas("No se puede tener el mismo usuario asignado en prevendedor y repartidor", false);
      setvfuser(true);
      setvsuser(true);
      return null;
    }

    if(firstw===secondw){
        props.alertas("No se puede tener el mismo almacén para la Ruta y Repartidor ", false)
        setvfw(true);
        setvsw(true);
        return null;
    }

    if(id_employee_assigned === id_second_employee_assigned){
      props.alertas("No se puede tener el mismo empleado asignado en prevendedor y repartidor", false)
      setvemploy1(true);
      setvemploy2(true);
      return null;
    }

        props.reconsultar(true);
        setbandera(false);
        await refresh_token ();
          var data = {
            "route":route, //String = Número de ruta
            "id_warehouse": firstw,//int = ID del primer almacén asignado
            "id_sale_type": typesale,//int = ID del tipo de venta
            "description": desroute,//String = Descripción breve de la ruta
            "code": coderoute,//Sting = Código de la ruta
            "send_to_dms" : sendms===true?true:(sendms==="true"?true:false),//Boolean | NULL  = Enviar a DMS
            "id_user_assigned": firstuser,//int = ID del primer usuario registrado
            "id_second_user_assigned": seconduser,//int = ID del segundo usuario registrado
            "id_second_warehouse":secondw, //int = ID del segundo almacén asignado
            "days_for_delivery":days, //int = Días para entregar
            "code_dms": codeRouteDMS,//String = Código DMS
            "frequently_update": frec===false?false:(frec==="false"?false:true),//Boolean = Actualización frecuente
            "id_employee_assigned": id_employee_assigned,
            "id_second_employee_assigned":id_second_employee_assigned,
          }
        try {
          const dt = await post_route(data);
          console.log(dt);
          props.alertas("Ruta registrada con éxito", true);
          props.reconsultar(false);
          dialog.close();
          props.handleSubmit();
        } catch (err) {  
          console.log(err);
          catchErrorModul("Rutas", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
          console.log("error post", err);
          props.alertas("No se pudo registrar la ruta", false);
          props.reconsultar(false);
          setbandera(true);
        };
      }
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


return (
    <div>
      <div className="addrulesSE">
          <div className="gridroutes">
            <div className="gr1">
            <TextField
              label="Ruta"
              required
              value={route}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              style={
                vroute===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvroute(false)}
              onBlur={
                route==null?()=>setvroute(true):()=>setvroute(false)
              }
              onChange={(event) => setroute(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="gr2">
            <TextField
              label="Descripción de la Ruta ('Area de Influencia)"
              value={desroute}
              required
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              style={
                vdesroute===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvdesroute(false)}
              onBlur={
                desroute==null?()=>setvdesroute(true):()=>setvdesroute(false)
              }
              onChange={(event) => setdesroute(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="gr3">
          <TextField
              label="Código de la Ruta"
              required
              value={coderoute}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              style={
                vcoderoute===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvcoderoute(false)}
              onBlur={
                coderoute==null?()=>setvcoderoute(true):()=>setvcoderoute(false)
              }
              onChange={(event) => setcoderoute(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridroutes2">
          <div className="gr4">
          <TextField
              select
              required
              label="Usuario Asignado (Vendedor o Prevendedor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfirstuser(event.target.value)}  
              style={
                vfuser===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvfuser(false)}
              onBlur={
                firstuser==null?()=>setvfuser(true):()=>setvfuser(false)
              }
            
            >
              <option key={0} value={0} selected >
                  {"Seleccione Usuario"}
              </option>
              {
                datausers.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.username}
                  </option>
                ))
              }
              
            </TextField>
          </div>
          <div className="gr5">
          <TextField
              select
              required
              label="Segundo Usuario Asignado (Repartidor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setseconduser(event.target.value)}  
              style={
                vsuser===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvsuser(false)}
              onBlur={
                seconduser==null?()=>setvsuser(true):()=>setvsuser(false)
              }
            >
              <option key={0} value={0} selected >
                  {"Seleccione Usuario"}
              </option>
              {
                datausers.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.username}
                  </option>
                ))
              }
            </TextField>
          </div>
          </div>



          <div className="gridroutes2">
          <div className="gr4">
          <TextField
              select
              required
              label="Empleado Asignado (Vendedor o Prevendedor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setid_employee_assigned(event.target.value)}  
              style={
                vemploy1===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvemploy1(false)}
              onBlur={
                id_employee_assigned==null?()=>setvemploy1(true):()=>setvemploy1(false)
              }
            
            >
              <option key={0} value={0} selected >
                  {"Seleccione Empleado"}
              </option>
              {
                dataemployes.map((item)=>(
                  <option key={item.id} value={item.id}>
                     {item.name + " " + item.first_last_name+" "+item.second_last_name}
                  </option>
                ))
              }
              
            </TextField>
          </div>
          <div className="gr5">
          <TextField
              select
              required
              label="Segundo Empleado Asignado (Repartidor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setid_second_employee_assigned(event.target.value)}  
              style={
                vemploy2===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvemploy2(false)}
              onBlur={
                id_second_employee_assigned==null?()=>setvemploy2(true):()=>setvemploy2(false)
              }
            >
              <option key={0} value={0} selected >
                  {"Seleccione Empleado"}
              </option>
              {
                dataemployes.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.name + " " + item.first_last_name+" "+item.second_last_name}
                  </option>
                ))
              }
            </TextField>
          </div>
          </div>

          <div className="gridroutes3">
          <div className="gr6">
          <TextField
              select
              required
              label="Almacen Asignado (El Almacén de la Ruta o Cámara)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfirstw(event.target.value)}
              style={
                vfw===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvfw(false)}
              onBlur={
                firstw==null?()=>setvfw(true):()=>setvfw(false)
              }  
              
            >
              <option key={0} value={0} selected >
                  {"Seleccione Almacén"}
              </option>
              {
                datawarehouse.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.warehouse_name}
                  </option>
                ))
              }
            </TextField>
          </div>
          <div className="gr7">
          <TextField
              select
              required
              label="Segundo Almacén Asignado (El Almacén del Repartidor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsecondw(event.target.value)}  
              style={
                vsw===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvsw(false)}
              onBlur={
                secondw==null?()=>setvsw(true):()=>setvsw(false)
              }
            >
              <option key={0} value={0} selected >
                  {"Seleccione Almacén"}
              </option>
              {
                datawarehouse.map((item)=>(
                  <option key={item.id} value={item.id} >
                    {item.warehouse_name}
                  </option>
                ))
              }
            </TextField>
          </div>
          </div>
          <div className="gridroutes4">
          <div className="gr8">
          <TextField
              select
              required
              disabled={!bandera}
              label="Tipo de Venta"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settypesale(event.target.value)}  
              style={
                vtype===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvtype(false)}
              onBlur={
                typesale==null?()=>setvtype(true):()=>setvtype(false)
              }
            >
              <option key={0} value={0} selected>
                  {"Seleccione"}
              </option>
            {
              datatypesale.map((item)=>(
                <option key={item.id} value={item.id}>
                  {item.type}
              </option>
              ))
            }
            
            </TextField>
          </div>
          <div className="gr9">
          <TextField
              label="Días para la Entrega"
              required
              value={days}
              type={"number"}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setdays(event.target.value.slice(0,3))}  
              InputLabelProps={{ shrink: true }}
              style={
                vdays===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvdays(false)}
              onBlur={
                days==null?()=>setvdays(true):()=>setvdays(false)
              }
            ></TextField>
            </div>
            <div className="gr10">
          <TextField
              label="Código de Ruta para DMS"
              required
              value={codeRouteDMS}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcodeRouteDMS(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
              style={
                vdms===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvdms(false)}
              onBlur={
                codeRouteDMS==null?()=>setvdms(true):()=>setvdms(false)
              }
            ></TextField>
            </div>
          </div>
          <div className="gridroutes5">
          <div className="gr11">
            <TextField
              select
              disabled={!bandera}
              label="Actualización Frecuente"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfrec(event.target.value)}  
            >
              <option key={1} value={true} selected>
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>     
            </div>  
            <div className="gr12">
            <TextField
              select
              disabled={!bandera}
              value={sendms}
              label="Envíar a DMS"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsendms(event.target.value)}  
            >
              <option key={1} value={true} selected>
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>     
            </div>  

          </div>

         <br></br>
          <br></br>
          <Box>
            {
              bandera===true?              
          <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={createnewRuta}
          >Crear Ruta
          </Button>:
          <Button
            variant="contained"
            disabled
            style={{"backgroundColor":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
    </div>
  );
}
export default CreateRoutes;
