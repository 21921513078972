import { useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../main_components/catchErr/catchError";

function CreateAcounting(props) {

const dialog = useDialog();
const [code, setcode]=useState(null);
const [name, setname]=useState(null);
const [concep, setconcep]=useState(null);
const [type, settype]=useState("CARGO");
const [efect, setefect]=useState("CARGO");
const [vcode, setvcode]=useState(false);
const [vname, setvname]=useState(false);
const [bandera, setbandera]=useState(true);

//crear un nuevo Rol
function createnewaccounting(){

if(code===null || code===""|| name===null || name===""){
  props.alertas("Parámetros faltantes", false);
  code===null || code===""?setvcode(true):setvcode(false);
  name===null || name===""?setvname(true):setvname(false);
}else{
  props.reconsultar(true);
  setbandera(false);
  refresh_token ();
  setTimeout(() => {
    var data = {
      "code":code,
      "name":name,
      "expense_concept":concep,
      "type":type,
      "efect":efect
  }
  var url = `https://r1lwievqy2.execute-api.us-east-1.amazonaws.com/development/accounting-account`;
  var axiosConfig = {
    headers: {
      Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
      "Content-Type": "application/json",
    },
  }
  axios
  .post(url, data,axiosConfig)
  .then((response) => {
    console.log(response);
    props.reconsultar(false);
    props.alertas("Cuenta contable creada con éxito", true);
    dialog.close();
    props.handleSubmit();
  })
  .catch((err) => {
    console.log("error post", err);
    catchErrorModul("Cuentas Contables", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
    
    if(err.response.status===500){
      props.alertas("No se pudo crear la cuenta contable, vuelva a intertarlo", false);
      setbandera(true);
    }
    if(err.response.status===400){
      props.alertas("Error en parámetros enviados", false);
      setbandera(true);
    }
    props.reconsultar(false);
    });
  }, 2000);
}
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


  return (
    <div>
      <div className="addpointsalecss">
        <div className="gridcc">
          <div className="cc1">
          <TextField
              label="Código"
              value={code}
              required
              sx={{ m: 1.5, width: "100%" }}
              style={
                vcode===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvcode(false)}
              onBlur={
                (code===null||code==="")?()=>setvcode(true):()=>setvcode(false)
              }  
              onChange={(event)=>setcode(event.target.value)}
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
          <div className="cc2">
          <TextField
              label="Nombre"
              required
              value={name}
              sx={{ m: 1.5, width: "100%" }}
              style={
                vname===true?{"border":"2px solid red", "border-radius":"5px"}:{}
              }
              onSelect={()=>setvname(false)}
              onBlur={
                (name===null||name==="")?()=>setvname(true):()=>setvname(false)
              }  
              onChange={(event) => setname(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <div className="gridcc2">
          <div className="cc3">
            <TextField
              label="Concepto"
              value={concep}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setconcep(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <div className="gridcc3">
          <div className="cc4">
            <TextField
              select
              label="Tipo"
              defaultValue={""}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => settype(event.target.value)}  
            >
              <option key={2} value={"CARGO"}>{"CARGO"}</option>
              <option key={3} value={"ABONO"}>{"ABONO"}</option>
              <option key={4} value={"IMPUESTO"}>{"IMPUESTO"}</option>
          </TextField>
          </div>
          <div className="cc5">
          <TextField
              select
              label="Efecto"
              defaultValue={""}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setefect(event.target.value)}  
            >
              <option key={2} value={"CARGO"}>{"CARGO"}</option>
              <option key={3} value={"ABONO"}>{"ABONO"}</option>
          </TextField>
          </div>
          
        </div>
          
          <br></br>
          <br></br>
          <Box>
          {bandera===true?
            <Button
              variant="contained"
              style={{"background-color":"green"}}
              sx={{ m: 1.5, width: "35ch" }}
              onClick={createnewaccounting}
            >
              Crear Cuenta Contable
            </Button>:
            <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "35ch" }}
            onClick={createnewaccounting}
          >
           <Spinner></Spinner>
          </Button>
          }
            
            <Button
              style={{"background-color":"red"}}
              disabled={!bandera}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default CreateAcounting;
