//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable } from "react-sticky-table";
import LoadingScreen from "react-loading-screen";
import {Confirm} from "react-st-modal";
//COMPONENTES
import TableContainer from "./TableContainerAddSales";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "./estilostableedit.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import Spinner from "../../componentsPago/S.gif";
import Delete from "../../imgComponents/borrar.png";
import { SelectColumnFilter } from "../../components_cfdi/filters";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";

const TableEditAddSales = () => {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [MensajeBusqueda, setMensajeBusqueda] = React.useState("");

  useEffect(()=>{
    handleSubmit();
  },[]);

//proceso de consultar ventas del paquete de bonificaciones
const handleSubmit = () => {
    console.log("entra a consultar ventas de un paquete de bonificaciones");
    setIsLoading(true);
    refresh_token ();
    setMensajeBusqueda("Buscando ventas del paquete");
      axios
        .get(
          `https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/bonification_packages/${sessionStorage.getItem("id_pack_edit")}/sales`,
          {
            headers: {
              Authorization: sessionStorage.getItem("getIdTokenRefresh"),
              "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response.data.sales);
          const data = response.data.sales;
          setData(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        })
        .catch((err) => {
          console.log("error", err);
          alert("el paquete aún no tiene ventas asignadas");
          window.location="/consult_report_bonuses";
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        });
};

const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    console.log("entra a updatemydata")
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
};

  const itemStyle = {
    padding: "2px",
    border: "1px solid silver",
    color: "Black",
    width: "120px",
    height: "25px",
  };

  const textStyle = {
    ...itemStyle,
    padding: "3px 2px",
    "font-size":"12px"
  };

  const[cont, setcont]=useState(0);
  const [difT, setDifT] = React.useState([{ id: "elminarEsto" }]);
  const [difT2, setDift2] = useState([{id:"eliminarEsto"}]);
//console.log(difT);

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <>
          <input
            type="checkbox"
            ref={resolvedRef}
            {...rest}
            style={{ width: 20 + "px" }}
          />
        </>
      );
    }
);
function filtrar(){


    console.log(difT);

    //Se quitan las ventas deseleccionadas
    const arreglo = (difT.filter(paper=>paper.id_campo!==null));
    console.log(arreglo);
    
    //Arreglo que tiene las ventas seleccionadas pero sin paper
    const arreglo2 = (arreglo.filter(paper=>paper.id_campo==='vacio'))
     
    if(arreglo.length==0){
      alert("Ninguna venta seleccionada");
    }else{

      if(arreglo2.length>0){
        alert("Campos vacios en: "+arreglo2.length+" ventas");
      }
      else{
        //alert("se agregan ventas");
        actualizarpaper(arreglo);
        console.log(arreglo);
      }

    }
}
function actualizarpaper(arreglo){


  // console.log("entrea en actualizar paper")
  // console.log(arreglo);

  refresh_token ();
  setIsLoading(true);
  setMensajeBusqueda("Actualizando reporte");

  var data = {
    sales: arreglo
  }
  var url = `https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/bonification_packages/${sessionStorage.getItem("id_pack_edit")}/sales`;
  var axiosConfig = {
    headers: {
      Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
      "Content-Type": "application/json",
    },
  }
  axios
  .put(url, data, axiosConfig)
  .then((response) => {
    console.log(response);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    window.location ="/consult_report_bonuses";
  })
  .catch((err) => {
    console.log("error", err);
    catchErrorModul("Bonificaciones", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
       
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });



}


const columns = useMemo(
  () => [
    {
      Header: "Acciones",
      id: "Acciones",

      Cell: ({ valor, row }) => {
        
      function DeleteSales(){    

      refresh_token ();
      //alert(row.original.id);
      setIsLoading(true);
      setMensajeBusqueda("Eliminando venta");
        var url = `https://xlkjajemk3.execute-api.us-east-1.amazonaws.com/od/bonification_packages/${sessionStorage.getItem("id_pack_edit")}/sales/${row.original.id}`;
        var axiosConfig = {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
        axios
        .delete(url,axiosConfig)
        .then((response) => {
          
          console.log(response);
          setTimeout(() => {
            setMensajeBusqueda("Elimando Venta");  
          }, 4000);

          if(response.status === 201){
            setMensajeBusqueda("Paquete Eliminado por no contener ventas");         
            window.location = "/consult_report_bonuses"
          }else{
            if(response.status === 200){
              setMensajeBusqueda("Venta Eliminada");
            }            
            setTimeout(() => {
               setIsLoading(false);
             }, 1000);
             handleSubmit();
          }
        })
        .catch((err) => {
          console.log("error", err);
          alert("Ups, algo salio mal");
           setTimeout(() => {
             setIsLoading(false);
           }, 1000);
        });
      }

        return (
          <div>
            <img
              src={Delete}
              onClick={async() =>{
                const result = await Confirm('¿Esta usted seguro de eliminar la venta?','Eliminar venta','Si','No');
                if(result){
                  DeleteSales()
                  }
                }
              }
              className="centerText, cancel"
              title="Eliminar venta"
              alt=""
            ></img>
          </div>
        );
      },
    },
    {
      Header: "Fecha",
      accessor: "date",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Razón Social",
      accessor: "name",
    },
    {
      Filter: SelectColumnFilter,
      Header: "Socio de negocios",
      accessor: "name_business_partner",
    },
    {
      Header: "Código Externo",
      accessor: "outer_code",
    },
    {
      Header: "Número Tienda",
      accessor: "number_oxxo_store",
    },
    {
      Header: "Ticket",
      accessor: "ticket",
    },
    {
      Header: "Folio Especial",
      accessor: "special_folio_number",
    },
    {
      Header: "Total",
      accessor: (d) => {
        return d.total
          ? Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total)
          : Intl.NumberFormat("es-MX", {
              style: "currency",
              currency: "MXN",
            }).format(d.total);
      },   
    },
    {
      id: "selection",
      Header: () => (
        <div>
          Comentarios&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      ),
      Cell: ({ row, value, onChange }) => {
        const [mode, setMode] = React.useState("read");
        const [text, setText] = React.useState(value ?? "");

function folio() {
  
  console.log(difT);

    if (row.isSelected === true) {

      //entra cuando se deselecciona una casilla
        setcont((difT.filter(paper=>paper.id_campo!==null).length-1));
        setText(row.original.paper);

      row.original.paper = difT2[row.index].paper

        if (difT[row.index]?.length!==0) {
              difT[row.index] = {
                  id_campo: null,
                  id: row.original.id,
                  paper: difT2[row.index].paper
              };
        } 
      } else {
          //entra cuando se selecciona una casilla
          //contador
          if(difT.id==="eliminar"){
            setcont(1);
          }
          else{
            setcont(difT.filter(paper=>paper.id_campo!==null).length+1);
          }
         setText("");
              difT[row.index] = {
                id_campo: row.index,
                id: row.original.id,
                paper: row.original.paper,
              };
              difT2[row.index]={
                id_campo: row.index,
                id: row.original.id,
                paper: row.original.paper,
              }

              //console.log("entra");
              setDifT(difT);
              setDift2(difT2);
              console.log(difT);
              console.log(difT2);
            }
          }

          useEffect(() => {
            const handlerestar = () => {
              setDifT(difT);
            };
            handlerestar();
          });
          React.useEffect(() => {
            setText(value);
          }, [value]);

         // console.log (mode);
      
            const handleInputChange = (e) => {
              setText(e.target.value);
              row.original.paper = e.target.value;
            };
            const handleInputChange2 = (e) => {
              setText("");
            };

            const handleSaveClick = () => {
              setMode("read");

              setDifT(
                Number(text) < row.original.amount_of_debt
                  ? Number(row.original.amount_of_debt - text).toFixed(2)
                  : 0
              );

              if (Number(text) > row.original.amount_of_debt) {
                setText(0);
                if (difT[row.index]?.length !== 0) {
                  difT[row.index] = {
                  id_campo: row.index,
                  id: row.original.id,
                  paper: row.original.paper,
                  };
                } else {
                  setDifT([
                    ...difT,
                    {
                      id_campo: row.index,
                      id: row.original.id,
                      paper: row.original.paper,
                    },
                  ]);
                }
              } else {
                if (difT[row.index]?.length !== 0) {
                  difT[row.index] = {
                  id_campo: row.index,
                  id: row.original.id,
                  paper: text,
                  };
                } else {
                  setDifT([
                    ...difT,
                    {
                      id_campo: row.index,
                      id: row.original.id,
                      paper: text,
                    },
                  ]);
                }
                if (onChange) {
                  onChange(text);
                }
              }
            };
            return (
              <div className="">
                <table>
                  <tr>
                    <td> 
                    {row.isSelected===true?
                    
                    <input type={"text"} 
                      value={row.original.paper}
                      style={textStyle}
                      onChange={handleInputChange}
                      onClick={handleInputChange2}
                      onBlur={handleSaveClick}
                    />:
                    <input type={"text"}
                      style={textStyle}
                      value={row.original.paper}
                      disabled/>
                    }
                    </td>
                    <td>
                      {" "}
                      <IndeterminateCheckbox
                        className="NoInput"
                        onChange={folio}
                        onClick={folio}
                        {...row.getToggleRowSelectedProps()}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            );
          }
        },
    ],
    []
);
  return (
    <>
    <div className="containeritems py-2">
  <nav aria-label="breadcrumb" className="navegacion">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
            <a className="listBreadCrum" href="/http://localhost:3000/">Home</a>
        </li>
        <li className="breadcrumb-item">
          <a className="listBreadCrum" href="/consult_report_bonuses">Reporte de Bonificaciones</a>
        </li>
        <li className="breadcrumb-item">
          Editar Paquete
        </li>
      </ol>
    </nav>
</div>

    <div className="TableEditSales">

      <div className="">      
            {isLoading ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={MensajeBusqueda || "Buscando..."}
          />
        ) : (
             <StickyTable>
          <div className="contenidoedit">          
            <div className="contentbotons">   
                  
              <div className="package_data"> 
                  <p>Nombre Paquete: {sessionStorage.getItem("name_pack_edit")}</p>
                  <p>Número paquete: {sessionStorage.getItem("num_pack_edit")}</p>
              <div/>
              
              <button
                  className="button"
                  id="buttonpackageb"
                  style={{width:"140px"}}
                  onClick={filtrar}    
                  >
                    Guardar Cambios
                  </button> 
              </div>
            </div>   
            </div>

            <div id="countsaledit">      
                  <p>Ventas Editadas:&nbsp;{cont}</p>
            </div>

              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                updateMyData={updateMyData}
              />
            </StickyTable>
            )}

          </div>
        </div>
     
    </>
  );
};

export default TableEditAddSales;
