import TableDetailGrupExpense from '../../components_Expenses/grup_expense/Consult_Detail_Grup';
import { Component } from "react";


export class SetConsulDetailGrupExpense extends Component {
 

  render() {


      return (    
      
      <div className="tc">
      <TableDetailGrupExpense></TableDetailGrupExpense>

      </div>
          );
       }     
}

export default SetConsulDetailGrupExpense;
