//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import { StickyTable, Row, Cell } from "react-sticky-table";
import NotificationSystem from "react-notification-system";

//COMPONENTES
import { SelectColumnFilter } from "../../components_cfdi/filters";
import TableContainer from "../../components_cashclosing/Table_Container_Cashclosing";
import SubTable from "./subTable";
import Set_driver from "./set_driver";
import Put_driver from "./put_driver";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import AlertResponse from "../../main_components/alerts/alertResponse";
//ESTILOS
import "./tableclientes.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import CODEQR from "../../imgComponents/codigoqr.png";
import SUCCESSFULLY from "../../imgComponents/successfully.png";

var style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },

    success: {
      // Applied only to the success notification item
      color: "red",
    },
  },
};

const Consult_drivers = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");
  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando conductores...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");
  const URL_BASE = "https://yqqevspzyi.execute-api.us-east-1.amazonaws.com";

  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData([]);
    setIsLoding(true);
    setTimeout(() => {
      setmodalGeneral(false);

      axios
        .get(`${URL_BASE}/od/drivers`, {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;
          setmodalT(false);

          setData(data);
          setIsLoding(false);
          if (msjAler?.length > 1) {
            notificationSystemRef.current.addNotification({
              message: (
                <AlertResponse msj={msjAler} view={true}></AlertResponse>
              ),
              level: "success",
              position: "br",
              autoDismiss: 10,
            });
          }
        })
        .catch((err) => {
          var errroM = err?.response?.data?.errorMessage;
          setData([]);
          notificationSystemRef.current.addNotification({
            message: (
              <AlertResponse
                msj={
                  errroM === undefined
                    ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                    : errroM
                }
                view={false}
              ></AlertResponse>
            ),
            level: "error",
            position: "br",
            autoDismiss: 60,
          });
          setmodalT(false);
        });
    }, 2000);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };
  const columns = useMemo(
    () => [
    
      {
        Header:()=>{
          return (
            <div>
              <p style={{color:"white"}} >Acciones</p>
              <img
                title="Crear conductor"
                src={CREATE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Set_driver
                    ></Set_driver>,
                    {
                      className: "modalSetBussnes",
                      title: "Crear conductor",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "Conductor creado correctamente"
                    );
                  }
                }}
              ></img>
            </div>
          );
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function DeleteBusnes() {
            setmenssage("Eliminando conductor");
            setmodalGeneral(true);
            let axiosConfig = {
              headers: {
                Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                "Content-Type": "application/json",
              },
            };
            setIsLoding(true);
            setmensajeDescarga("Eliminando");
            axios
              .delete(`${URL_BASE}/od/drivers/${row.original.id}`, axiosConfig)
              .then((res) => {
                handleReconsulta(true, "Conductor eliminado correctamente");
              })

              .catch((err) => {
                var errroM = err?.response?.data?.errorMessage;
                notificationSystemRef.current.addNotification({
                  message: (
                    <AlertResponse
                      msj={
                        errroM === undefined
                          ? "Error 401:No autorizado intentelo nuevamente o recarge la pagina nuevamente oprimiendo la tecla 'CTRL + F5'"
                          : errroM
                      }
                      view={false}
                    ></AlertResponse>
                  ),
                  level: "error",
                  position: "br",
                  autoDismiss: 60,
                });
              });
          }

          return (
            <div>
              <img
                title="Actualizar conductor"
                src={SAVE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await CustomDialog(
                    <Put_driver
                      License_num={row.original.license_num}
                      Num_reg_id_trib={row.original.num_reg_id_trib}
                      Id={row.original.id}
                      Rfc={row.original.rfc}
                      Type={row.original.type}
                      Transport_parts={row.original.transport_parts}
                      Fiscal_residence={row.original.fiscal_residence}
                      Ruta={row.original.id_route_fk}
                      Driver_name={row.original.driver_name}
                    ></Put_driver>,
                    {
                      className: "modalSetBussnes",
                      title: "Actualizar conductor",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(
                      result,
                      "Conductor actualizado correctamente"
                    );
                  }
                }}
              ></img>

              <img
                src={DELETE}
                className="cursorPointer"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este conductor?",
                    "Eliminar conductor",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteBusnes();
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Ruta",
        accessor: "route",
      },
      
      {
        Header: "Conductor",
        accessor: "driver_name",
      },
      
      {
        Header: "RFC",
        accessor: "rfc",
      },

      {
        Header: "Número de licencia",
        accessor: "license_num",
      },
      {
        Header: "Tipo",
        accessor: "type",
      },
      {
        Header: "Piezas de transporte",
        accessor: "transport_parts",
      },
      {
        Header: "Num_Reg_Id_Trib",
        accessor: "num_reg_id_trib",
      },

      {
        Header: "Residencia Fiscal",
        accessor: "fiscal_residence",
      },
     
    ],
    []
  );

  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <MODAL_TABLE
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></MODAL_TABLE>
      <div className="">
          <div className="TableCOnsultaClientes">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
      </div>
    </div>
  );
};

export default Consult_drivers;
