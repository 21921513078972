import {useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { put_roles } from "../../services/configuration/configuration";

function EditRole(props) {

console.log(props.row)

const dialog = useDialog();
const[nameRol, setnameRol] = useState(props.row.original.name_role);
const[descRol, setdesRol]= useState(props.row.original.description);
const [band, setband]=useState(true);


//Actualizar Rol
const updaterole = async() =>{
  
var data = {
  "name_role": nameRol,
  "description": descRol
}
props.reconsultar(true);
setband(false);
await refresh_token ();

try {
  const dt = await put_roles(props.row.original.id, data);
  console.log(dt);
  props.alertas("Rol actualizado con éxito", true);
  props.reconsultar(false);
  dialog.close();
  props.handleSubmit();
} catch (err) {  
  console.log(err);
  catchErrorModul("Roles", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
  console.log("error post", err);
  props.alertas("No se pudo actualizar el Rol, vuelva a intertarlo", false);
  props.reconsultar(false);
  setband(true);
};
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
          <Box>
          <TextField
              label="Nombre del Rol"
              required
              value={nameRol}
              sx={{ m: 1.5, width: "90%" }}
              onChange={(event) => setnameRol(event.target.value)}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            <TextField
              label="Descripción del Rol"
              required
              value={descRol}
              sx={{ m: 1.5, width: "90%" }}
              onChange={(event) => setdesRol(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </Box>
          <br></br>
          <br></br>
          <Box>
            {
              band===true?
              <Button
              variant="contained"
              style={{"background-color":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={updaterole}
            >
              Actualizar Rol
            </Button>:
            <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button> 
            }
            
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!band}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}
export default EditRole;
