//yarn add react-widgets
//yarn add react-google-maps
import { useCallback, useEffect, useMemo, useState } from 'react';
import {withScriptjs,withGoogleMap,GoogleMap,Marker,Polygon, InfoWindow} from "react-google-maps";
import {TextField } from '@material-ui/core';
import "./ReporteGMaps.css";
import TableContainer from '../../componentSociosNegocios/TableContainer';
import { StickyTable} from "react-sticky-table";
import Tablaimg from "../../imgComponents/tabla.png";
import Mapaimg from "../../imgComponents/direccion.png";
import { refresh_token } from '../../main_components/tokens/tokenrefresh';
import { get_route_list, get_sectors } from '../../services/businesspartners/businesspartners';
import { get_report_gps } from '../../services/reports/reports';
import datamaps from "../../data/data_demo.json";


function MapContainer(props) {

const [polygon, setPolygon] = useState(null);
  
const vertices = useCallback((e) => {
    setPolygon(polygon ? [...polygon, e.latLng] : [e.latLng]);
}, [polygon]);
  
  const [coordenadas] = useState({ lat: 18.848893, lng: -97.073271});   
  const [selectedMarker, setSelectedMarker] = useState(null);
  // Actualiza el Polygon cuando se actualiza `verticespoligonodata` en las props
  
  useEffect(() => {
    if (!props.bandera2) {
      // Reset the polygon state when props.bandera2 becomes false
      setPolygon(null);
    }
  }, [props.bandera2]);

  return (
    <GoogleMap
    defaultZoom={10}
    defaultCenter={coordenadas}
    onClick={props.bandera===true?vertices:null}
  >
  {
   props.datamap?.map((item)=>
       (
         <Marker

         key={item.id}
         position={{lat: item.lat, lng: item.lng}}
         title={item?.socio}
        
         icon = {{
           path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
           fillColor: item.color,
           fillOpacity: 1,
           rotation: 0,
           strokeWeight: 1,
           strokeColor: "#ffffff",
           scale: 2, 
          }}
          onClick={() => setSelectedMarker(item)}
        
     >
     </Marker>
     ))   
}
{selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div className='inforwindowpos'>
           <h3>{selectedMarker?.code} - {selectedMarker?.name}</h3>
           <p>DÍA DE VISITA: {selectedMarker?.visit_days}</p>
           <p>SECTOR: {selectedMarker?.number_sector} - {selectedMarker?.name_sector}</p>          
          </div>
        </InfoWindow>
      )}
 
    {props.bandera2===true && polygon && (
          <Polygon
            paths={polygon}
            options={{
              fillColor: '#ff0000',
              fillOpacity: 0.35,
              strokeColor: '#ff0000',
              strokeOpacity: 1,
              strokeWeight: 2,
            }}
            onClick={() => {
               props.poligonomapa();
               props.setverticespoligono(polygon.map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })));
               props.setbandera(false);
               console.log(polygon.map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() })));

            }}
          />
        )}
        
</GoogleMap>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(MapContainer));


export const ReporteGMaps = () => {

  const [cont, setcont] = useState(0);
  const [mapatabla, setmapatabla]=useState(true);

  //data de consultas
  const [data, setData] = useState([]);
  const [dataroute, setdataroute] = useState(0);  
  const [datamap, setdatamap] = useState([]);
  const [sectors, setdatasector] = useState(0);
  const [filsectors, setfilsectors] = useState([]);
  //select 
  const [route, setroute] = useState("all");
  const [date, setdate] = useState("vacio");
  const [sectorselect, setsectorselect] = useState("vacio");
  const [test, settest] = useState(false);
  const [test2, settest2] = useState(false);
  const [seepoly, setseepoly]=useState(false);
  const [datafiltro1, setdatafiltro1]=useState([]);
  const [verticespoligono, setverticespoligono]=useState([]);
  //bandera que dibuja el poligono
  const [bandera, setbandera]=useState(false);
  //bandera que elimina el poligono
  const [bandera2, setbandera2]=useState(false);

const polygon = verticespoligono;


const [newjson, setnewjson]=useState([]);
const [Colaborador, setcolaborador]=useState("vacio");


function filtrar2(){
  if(newjson.length===0){
    alert("No ha seleccionado un archivo CSv");
  }else{
    unir();
  }
}
function unir(){
  const jsonunido = [...datafiltro1, ...newjson];
  var sinRepetidos = jsonunido.filter((item, index) => jsonunido.indexOf(item) === index);
  setdatamap(sinRepetidos);

 }

function dibujarpoligono(){
  setverticespoligono([]);
  setbandera(true);
  setbandera2(true);
}
function cancelarpoligono(){
  setseepoly(false);
  setbandera(false);
  setbandera2(false);
  unir();
}
function ocultarpoligono(){
  setseepoly(false);
  setbandera(false);
  setbandera2(false);
  unir();
}
function poligonomapa(){
  setseepoly(true);
}
function conjuntos(){
  console.log("conjuntos");
  const filteredCoordinates = datamap.filter((item) => {
     return isPointInPolygon([item.lat, item.lng], polygon);
  });
  console.log(filteredCoordinates);   
  setdatamap(filteredCoordinates);
}

function isPointInPolygon(point, polygon) {
  let isInside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat, yi = polygon[i].lng;
    const xj = polygon[j].lat, yj = polygon[j].lng;

    const intersect = ((yi >= point[1]) !== (yj >= point[1]))
      && (point[0] <= (xj - xi) * (point[1] - yi) / (yj - yi) + xi);
    if (intersect) {
      isInside = !isInside;
    }
  }
  return isInside;
}

//lectura de csv a json
const handleFileUpload = (event) => {

  const file = event.target.files[0];
  if (!file) return;

  const reader = new FileReader();
  reader.readAsText(file);

  reader.onload = (event) => {
    const csv = event.target.result;
    const rows = csv.split('\n');
    const header = rows.shift().split(',');
    header[header.length-1] = header[header.length-1].replace('\r', '');
    console.log(header);

    const jsonData = rows.map((row) => {
      const obj = {};
      const values = row.split(',');
      header.forEach((headerValue, index) => {
        
        if (headerValue === "lat" || headerValue === "lng") {
          obj[headerValue] = Number(values[index]?.trim());
        }
        else {
          obj[headerValue] = values[index]?.trim();
        }
      });
      obj.color_route = "#5E5E5E";
      return obj;
    });
    setnewjson(jsonData);
    console.log(jsonData);
  };
};

const filtrar = async () => {

if(Colaborador==="vacio"){
  alert("No ha seleccionado colaborador");
}else{
  var datafiltrada = datamaps?.colaboradores.filter(item=>item.id==Colaborador);
  setdatamap(datafiltrada[0]?.detalles_fletes);
  setdatafiltro1(datafiltrada[0]?.detalles_fletes);
}
  console.log(datamap);
}
useEffect(() => {
  const contador = () => {
      setcont(datamap.length);
  };
  contador();
});


const columns = useMemo(
  () => [
    {
      Header:"Socio",
      accessor: "socio"
    }, 
    {
      Header:"Fecha",
      accessor:"fecha"
    },  
    {
      Header:"Latitud",
      accessor: "lat"
    },
    {
      Header:"Longitud",
      accessor:"lng"
    },
    {
      Header:"Distancia Recorrida",
      accessor:"drecorrida"
    }
    ],
    []
);

return (  
  <>

  <div className='botonsfilt'>
    <div className='ff1'>
    <TextField
       select
       label="Colaborador"
       defaultValue={""}
       InputLabelProps={{shrink: true}}
       SelectProps={{native: true,}}
       sx={{ m: 1.5, width: "100%"}}
       onChange={(event) => setcolaborador(event.target.value)}
     >      
        <option key={100} value={"vacio"}>
           {"Seleccione Colaborador"}
        </option>
        <option key={1} value={1}>
           {"Colaborador 1"}
        </option>
        <option key={2} value={2}>
           {"Colaborador 2"}
        </option>
      </TextField>
    </div>
    
    <div className="ff2">
        <button onClick={filtrar}>
          Filtrar
        </button>
      </div>
      <div className='ff4'>
      <input type="file" onChange={handleFileUpload}/>
      </div>
      <div className='ff5'>
      <button onClick={filtrar2}>
          Dibujar CSV
        </button>
      </div>
      <div className='ff7'>
      {
        mapatabla===true? 
        <img
          src={Tablaimg}
         
          onClick={()=>setmapatabla(false)}
          className="centerText"
          title="Mostrar Tabla"
          alt=''
        ></img>:
        <img
          src={Mapaimg}
          onClick={()=>setmapatabla(true)}
          className="centerText"
          title="Mostrar Mapa"
          alt=''
        ></img>
      }
     
      </div>
      <div className='ff8'>
      {seepoly===false?
       
       bandera===true?
        <button onClick={cancelarpoligono} style={{"backgroundColor":"red"}}>
          Cancelar
        </button>:
        <button onClick={dibujarpoligono}>
          Dibujar Poligono
        </button>:
        <button onClick={ocultarpoligono}>
          Ocultar Poligono
        </button>
      }
        
      </div>
      <div className='ff9'>
      {
        seepoly===true?
        <button type="submit" onClick={conjuntos}>
          Agrupar
        </button>:""
      }
      
      </div>     
    </div>
      

    <div className='consultposgps'>
      <p>Fletes realizados:{cont}</p>
    </div>


<div className='contenido'>

<div id="map" style={mapatabla===true?{"display":"block"}:{"display":"none"}}>
<div className='simbologia'  style={test===true?{display:"block"}:{display:"none"}}>      
</div>

<div className='simbologiadia'  style={test2===true?{display:"block"}:{display:"none"}}>      
</div>


  <div style={{ width: '100%', height: '100%', margin: "0 auto" }}>
      <WrappedMap 
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh51zw12N4kb7yDY4pp-6gM7xVaFterc`}
        loadingElement={<div style={{ height: '100%'}} />}
        containerElement={<div style={{ height: '100%' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        datamap={datamap}
        setverticespoligono={setverticespoligono}
        poligonomapa={poligonomapa}
        bandera={bandera}
        setbandera={setbandera}
        bandera2={bandera2}
      />
    </div> 
</div>
<div className='tablaRgps' style={mapatabla===true?{"display":"none"}:{"display":"block"}}>
    <StickyTable>
      <TableContainer
        paginado={"Puntos de venta"}
        consultas={"Puntos de venta"}
        exportar={true}
        columns={columns}
        data={datamap}
        style={{ overflowx: 10 }}
      />
    </StickyTable>
</div>  
</div>
</>
  
  
  )
}