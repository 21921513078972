//Iconos material-ui
import {
  NoteAdd,
  Payment,
  FindInPage,
  BrandingWatermark,
  LocalShipping,
  Group,
  AirlineSeatReclineNormal,
  LocationOn,
  BarChart,
  InsertChartOutlined,
  Description,
  Public,
  Assignment,
  PieChart,
  ListAlt,
  AssignmentReturn,
  LocalAtm,
  TrendingDown,
  SupervisorAccount,
  PersonAdd,
  Redeem,
  DragIndicator,
  MonetizationOn,
  AccountBalance,
  EmojiPeople,
  GroupAdd,
  Receipt,
  ShoppingCart,
  Business,
  Timeline,
  Category,
} from "@material-ui/icons";
import {
  ContentPasteGo,
  FactCheck,
  ManageAccounts,
  PersonSearch,
  PriceChange,
  RequestQuote,
  PriceCheck,
  ReceiptLong,
  Calculate,
  Summarize,
  AutoAwesomeMosaic,
  Plagiarism,
  PointOfSale,
  TravelExplore,
  Discount,
  ContentPaste,
  Warehouse,
  Groups2,
  CurrencyExchange,
} from "@mui/icons-material";

import * as style from './style';
import * as icon from './Iconos'

export const arrStorage = ( roles = [], userId) => {
  
  const arr = [

  //ACTIVOS
  {
    name: "Marcas y Modelos",
    ruta: "consult_brands",
    restriccion: roles.ROLE_ASSETS,
    icono: <BrandingWatermark style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}/>,
    forStorage: localStorage.getItem(`${userId}consult_brands`),
    parent: "ACTIVOS",
    module_restriccion: [roles.ROLE_ASSETS],
  },
  {
    name: "Vehículos",
    ruta: "consult_vehicle",
    restriccion: roles.ROLE_ASSETS,
    icono: <LocalShipping  style={style.styleFavorite("#1695CD", "#2863CB", "#1337DB")}/>,
    forStorage: localStorage.getItem(`${userId}consult_vehicle`),
    parent: "ACTIVOS",
    icon_parent: <MonetizationOn style={style.iconStyle} sx={{ fontSize: 30 }}/>,
    module_restriccion: [roles.ROLE_ASSETS],
  },
  //ADDENDAS
  {
    name: "Addenda",
    ruta: "Addenda",
    restriccion: roles.ROLE_ADDENDAS,
    icono: <ManageAccounts style={style.styleFavorite("#EC5A4B", "#E88177", "#B32315")}/>,
    forStorage: localStorage.getItem(`${userId}Addenda`),
    parent: "ADDENDA",
    module_restriccion: [roles.ROLE_ADDENDAS],
  },
  //BANCOS
  {
    name: "Corte de Caja",
    ruta: "cashclosing",
    restriccion: roles.ROLE_BANK,
    icono: <PriceCheck style={style.styleFavorite("#4BA575", "#378059", "#1E5337")}/>,
    forStorage: localStorage.getItem(`${userId}cashclosing`),
    parent: "BANCO",
    module_restriccion: [roles.ROLE_BANK],
  },
  //COLABORADORES
  {
    name: "Empleados",
    ruta: "consult_employees",
    restriccion: roles.ROLE_HUMAN_RESOURCES,
    icono: <Group style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}/>,
    forStorage: localStorage.getItem(`${userId}consult_employees`),
    parent: "COLABORADORES",
    module_restriccion: [roles.ROLE_HUMAN_RESOURCES],
  },
  {
    name: "Conductores",
    ruta: "consult_driver",
    restriccion: roles.ROLE_HUMAN_RESOURCES,
    icono: <AirlineSeatReclineNormal style={style.styleFavorite("#A5702C", "#E79023", "#B66600")}/>,
    forStorage: localStorage.getItem(`${userId}consult_driver`),
    parent: "COLABORADORES",
    module_restriccion: [roles.ROLE_HUMAN_RESOURCES],
  },
  //FINANZAS
  {
    name: "Cuentas Contables",
    ruta: "consult_accounting_account",
    restriccion: roles.ROLE_FINANCE,
    icono: <Calculate style={style.styleFavorite("#E9E934", "#E8E827", "#E8BA0F")}/>,
    forStorage: localStorage.getItem(`${userId}consult_accounting_account`),
    parent: "FINANZAS",
    module_restriccion: [roles.ROLE_FINANCE],
  },

  //REPORTES
  {
    name: "Detalles de Venta",
    ruta: "NewDashboard",
    restriccion: roles.ROLE_SALES,
    icono: <ReceiptLong style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}/>,
    forStorage: localStorage.getItem(`${userId}NewDashboard`),
    parent: "REPORTES",
    module_restriccion: [roles.ROLE_SALES],
  },
  {
    name: "Mapa de Rutas",
    ruta: "rutas",
    restriccion: roles.ROLE_SALES,
    icono: <LocationOn  style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}/>,
    forStorage: localStorage.getItem(`${userId}rutas`),
    parent: "REPORTES",
    module_restriccion: [roles.ROLE_SALES],
  },
  {
    name: "Reporte de Ventas por Ruta",
    ruta: "consult_route_sales_report",
    restriccion: roles.ROLE_SALES,
    icono: <Summarize style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}/>,
    forStorage: localStorage.getItem(`${userId}consult_route_sales_report`),
    parent: "REPORTES",
    module_restriccion: [roles.ROLE_SALES],
  },
  {
    name: "Reporte de Ventas en Tiempo Real",
    ruta: "Consult_report_sale_real",
    restriccion: roles.ROLE_SALES,
    icono: <BarChart style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}/>,
    forStorage: localStorage.getItem(`${userId}Consult_report_sale_real`),
    parent: "REPORTES",
    module_restriccion: [roles.ROLE_SALES],
  },
  {
    name: "Reporte Sumaria Anual",
    ruta: "consult_report_sumary",
    restriccion: roles.ROLE_BANK,
    icono: <InsertChartOutlined  style={style.styleFavorite("#CCF0ED", "#8CB7B4", "#6F9391")}/>,
    forStorage: localStorage.getItem(`${userId}consult_report_sumary`),
    parent: "REPORTES",
    module_restriccion: [roles.ROLE_SALES],
  },

  //COMPRAS
  {
    name: "Consultar Documentos Enviados",
    ruta: "consultaFacturasProvedores",
    restriccion: roles.ROLE_BUYS,
    icono: <Description style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}consultaFacturasProvedores`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Enviar Documentos",
    ruta: "validador",
    restriccion: roles.ROLE_BUYS,
    icono: <AssignmentReturn style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}validador`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Gastos no Deducibles",
    ruta: "expenses",
    restriccion: roles.ROLE_BUYS,
    icono: <PriceChange style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}expenses`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Gastos del Extranjero",
    ruta: "expensesExtranjero",
    restriccion: roles.ROLE_BUYS,
    icono: <Public  style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}expensesExtranjero`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Reporte de Gastos",
    ruta: "expensesReport",
    restriccion: roles.ROLE_BUYS,
    icono: <Assignment style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}expensesReport`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Reporte de Gastos Agrupados",
    ruta: "expensesGrup",
    restriccion: roles.ROLE_BUYS,
    icono: <PieChart style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}expensesGrup`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Pólizas",
    ruta: "policy",
    restriccion: roles.ROLE_POLICY,
    icono: <ListAlt style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}policy`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },

  {
    name: "Catálogo de Gastos",
    ruta: "consult_expense_concept",
    restriccion: roles.ROLE_EXPENSE_ADMINISTRATIVOS,
    icono: <AutoAwesomeMosaic style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}consult_expense_concept`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  {
    name: "Regla de Gastos",
    ruta: "consult_rules_sending_expenses",
    restriccion: roles.ROLE_EXPENSE_ADMINISTRATIVOS,
    icono:  <LocalAtm style={style.styleFavorite("#DF2C52", "#ED2A2A", "#C51A1A")}/>,
    forStorage: localStorage.getItem(`${userId}consult_rules_sending_expenses`),
    parent: "COMPRAS",
    module_restriccion: [roles.ROLE_BUYS, roles.ROLE_EXPENSE],
  },
  //FACTURAS
  {
    name: "Carta Porte",
    ruta: "cartaporte4.0",
    restriccion: roles.ROLE_CARTA_PORTE,
    icono: <ContentPasteGo style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}cartaporte4.0`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
        roles.ROLE_CARTA_PORTE, 
        roles.ROLE_COMPLEMENTO_PAGO],
  },
  {
    name: "Consultar Conceptos",
    ruta: "consult_concept",
    restriccion: roles.ROLE_INVOICING,
    icono: <FindInPage style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}consult_concept`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],
  },
  {
    name: "Consultar Facturas",
    ruta: "consultaCFDI",
    restriccion: roles.ROLE_INVOICING,
    icono: <FactCheck style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}consultaCFDI`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],
  },
  {
    name: "Consultar Facturas PPD",
    ruta: "FacturasPPD",
    restriccion: roles.ROLE_INVOICING,
    icono: <Plagiarism style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}FacturasPPD`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],
  },

  {
    name: "Consultar Impuestos",
    ruta: "consult_taxes",
    restriccion: roles.ROLE_INVOICING,
    icono: <CurrencyExchange style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}consult_taxes`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],
  },

  {
    name: "Consultar Nómina",
    ruta: "nomina",
    restriccion: roles.ROLE_NOMINA,
    icono: <Payment style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}nomina`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],  
  },
  {
    name: "Realizar Factura",
    ruta: "GenerarFactura",
    restriccion: roles.ROLE_INVOICING,
    icono: <NoteAdd style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}GenerarFactura`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],
  },
  {
    name: "Realizar Complemento de Pago",
    ruta: "pago",
    restriccion: roles.ROLE_COMPLEMENTO_PAGO,
    icono: <RequestQuote  style={style.styleFavorite("#FF4003", "#FF6433", "#CD3200")}/>,
    forStorage: localStorage.getItem(`${userId}pago`),
    parent: "FACTURAS",
    module_restriccion: [roles.ROLE_INVOICING, 
      roles.ROLE_CARTA_PORTE, 
      roles.ROLE_COMPLEMENTO_PAGO],
  },
  //SOCIO DE NEGOCIOS
  {
    name: "Consultar Descuento",
    ruta: "consult_discounts",
    restriccion: roles.ROLE_BUSINESS_PARTNER_DISCOUNT,
    icono: <TrendingDown style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}consult_discounts`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
  },
  {
    name: "Consultar Promociones",
    ruta: "consult_promotions",
    restriccion: roles.ROLE_BUSINESS_PARTNER_PROMOTIONS,
    icono: <Discount style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}consult_promotions`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
  },
  {
    name: "Consultar puntos de ventas",
    ruta: "consult_point_of_sale",
    restriccion: roles.ROLE_BUSINESS_PARTNER_POINT_OF_SALE,
    icono: <PointOfSale style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}consult_point_of_sale`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
  },
  {
    name: "Consultar Sectores",
    ruta: "consult_sectors",
    restriccion: roles.ROLE_BUSINESS_PARTNER_SECTOR,
    icono: <TravelExplore  style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}consult_sectors`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
  },
  {
    name: "Consultar Socio de Negocios",
    ruta: "updateBP",
    restriccion: roles.ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER,
    icono: <PersonSearch style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}updateBP`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER,],
  },
  {
    name: "Recuperar Socio de Negocios",
    ruta: "consult_recover_partner",
    restriccion: roles.ROLE_BUSINESS_PARTNER_RECOVER,
    icono: <SupervisorAccount style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}consult_recover_partner`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
  },
  {
    name: "Registro General y Punto de Venta",
    ruta: "register_general",
    restriccion: roles.ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION,
    icono: <PersonAdd style={style.styleFavorite("#373B44", "#9B23A3", "#F3904F")}/>,
    forStorage: localStorage.getItem(`${userId}register_general`),
    parent: "SOCIO DE NEGOCIOS",
    module_restriccion: [roles.ROLE_BUSINESS_PARTNER],
  },
  //UNILEVER
  {
    name: "Reporte de bonificaciones",
    ruta: "consult_report_bonuses",
    restriccion: roles.ROLE_UNILEVER,
    icono: <Redeem style={style.styleFavorite("#0072C6","#0F1AC8","#1E2A3A")}/>,
    forStorage: localStorage.getItem(`${userId}consult_report_bonuses`),
    parent: "UNILEVER",
    module_restriccion: [roles.ROLE_UNILEVER],
  },

  //CONFIGURACION
  {
    name: "Almacén",
    ruta: "TableWarehouse",
    restriccion: roles.ROLE_CONFIGURATION, 
    icono: <Warehouse style={style.styleFavorite("#535962","#818181","#393E47")}/>,
    forStorage: localStorage.getItem(`${userId}TableWarehouse`),
    parent: "CONFIGURACIÓN",
    module_restriccion: [roles.ROLE_CONFIGURATION],
  },
  {
    name: "Empresa",
    ruta: "edit_company",
    restriccion: roles.ROLE_CONFIGURATION, 
    icono: <Business style={style.styleFavorite("#535962","#818181","#393E47")}/> ,
    forStorage: localStorage.getItem(`${userId}edit_company`),
    parent: "CONFIGURACIÓN",
    module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
  },
  {
    name: "Roles",
    ruta: "consult_roles",
    restriccion: roles.ROLE_ADMIN, 
    icono: <Category style={style.styleFavorite("#535962","#818181","#393E47")}/> ,
    forStorage: localStorage.getItem(`${userId}Consult_route`),
    parent: "CONFIGURACIÓN",
    module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
  },
  {
    name: "Rutas",
    ruta: "Consult_route",
    restriccion: roles.ROLE_CONFIGURATION, 
    icono: <Timeline style={style.styleFavorite("#535962","#818181","#393E47")}/> ,
    forStorage: localStorage.getItem(`${userId}Consult_route`),
    parent: "CONFIGURACIÓN",
    module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
  },
  {
    name: "Usuarios",
    ruta: "consult_users",
    restriccion: roles.ROLE_CONFIGURATION, 
    icono: <Groups2 style={style.styleFavorite("#535962","#818181","#393E47")}/>,
    forStorage: localStorage.getItem(`${userId}consult_users`),
    parent: "CONFIGURACIÓN",
    module_restriccion: [roles.ROLE_CONFIGURATION, roles.ROLE_ADMIN],
  },

];
return arr;
}
