import TableTaxes  from '../../componentsTaxes/Consult_Tax';
import { Component } from "react";


export class SetConsultaTaxes extends Component {
 

  render() {

      
      return (    
      
      <div className="tc">
      <TableTaxes></TableTaxes>

      </div>
          );
       }     
}

export default SetConsultaTaxes;
