import React, { useEffect, useState, useMemo } from "react";
import TableContainer from "../main_components/Table/TableContainer";
import "../components_cfdi/estilo.css";
import "../components_cfdi/table.css";
import "../components_cfdi/modal.css";
import Spinner from "../imgComponents/S.gif";
import {
  SelectColumnFilter,
  ColumnFilter,
  ColumnFilter2,
  SelectColumnFilter2,
} from "../components_cfdi/filters";
import axios from "axios";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import { StickyTable, Row, Cell } from "react-sticky-table";
import tache from "../imgComponents/borrar.png";
import PDF from "../imgComponents/pdf.png";
import XML from "../imgComponents/xml.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SubTable from "../components_cfdi/invoice/consult_invoice/detail/detail_invoce";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ConsultarFacturasProvedores = () => {
  const [mensajeDescarga, setmensajeDescarga] = useState("");

  const [data, setData] = useState([]);
  const [ff, setff] = useState("");
  const [fi, setfi] = useState("");
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [cancel, setCancel] = useState(null);
  const [tipodoc, setTipoDoc] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [documentType, setdocumentType] = useState("");

  const [descargapdf, setdescargapdf] = useState("");
  const [base64, setbase64] = useState("");

  const [uuidCorreo, setuuidCorreo] = React.useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = React.useState("");

  const [isLoding, setIsLoding] = useState(true);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const handleChangee = (event) => {
    setCurrency(event.target.value);
  };

  const buttonHandler = () => {
    if (fi <= ff) {
      setIsLoading((current) => !current);
    } else {
      alert("La fecha inicial debe ser menor que la final");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoding(true);
    const uri ="https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/supplier-invoices";

    axios
      .get(uri, {
        params: {
          di: fi || "",
          df: ff || "",
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response.data;

        setData(data);
        setTimeout(() => {
          setIsLoding(false);
          setff("");
          setfi("");
          setRFC("");
          setSDN("");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);

        if (err == "Error: Network Error") {
          console.log("No autorizado");
        }

        setData([]);

        console.log("----------------------------");
        console.log("HOLA AQUI VA OTRA COSA");
        console.log("AXIOS ERROR: ", err);
        console.log("----------------------------");

        setTimeout(() => {
          setIsLoding(false);
        }, 1000);
      });
  };

  useEffect(() => {
    const fetchCities = () => {
      var hoy = new Date();
      if (hoy.getMonth() + 1 < 10) {
        var mess = hoy.getMonth() + 1;
        var mesEnv = "0" + mess;
      } else {
        var mesEnv2 = hoy.getMonth() + 1;
      }
      if (hoy.getDate() + 1 < 10) {
        var diaa = hoy.getDate();
        var diaEnv = "0" + diaa;
      } else {
        var diaEnv2 = hoy.getDate();
      }

      if (hoy.getDate() + 1 < 10) {
        var diaa = hoy.getDate() - 1;
        var diaEnv01 = "0" + diaa;
      } else {
        var diaEnv02 = hoy.getDate() - 1;
      }

      var fecha =
        hoy.getFullYear() +
        "-" +
        (mesEnv || mesEnv2) +
        "-" +
        (diaEnv || diaEnv2);
      var fecha2 =
        hoy.getFullYear() +
        "-" +
        (mesEnv || mesEnv2) +
        "-" +
        (diaEnv01 || diaEnv02);

        const uri ="https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/supplier-invoices";

      axios
        .get(uri, {
          params: {
            di: "",
            df: "",
          },
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const data = response.data;

          setData(data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    fetchCities();
    setTimeout(() => {
      setIsLoding(false);
    }, 1000);

    if (isLoading === true) {
      console.log("entrandodato: " + isLoading);
    }
  }, [isLoading]);

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          function eliminarFactura() {
            setOpen(true);

            const uri =`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/supplier-invoices/${row.original.id}`

            axios
              .delete(uri, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem(
                    "getAccesTokenRefresh"
                  ),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                const data = response.data;

                setOpen(false);
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
              });
          }

          function downloadPDF(typedocumentexmlorpdf) {
            setOpen(true);

            const uri =`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/supplier-invoices/${row.original.id}`

            axios
              .get(uri, {
                params: {
                  filename: row.original.filename,
                  documentType: typedocumentexmlorpdf,
                },
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem(
                    "getAccesTokenRefresh"
                  ),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                const data = response.data;

                if (typedocumentexmlorpdf === "xml") {
                  var blob = base64toBlobxml(data.file);

                  const blobUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  const fileName = row.original.folio + ".xml";
                  downloadLink.href = blobUrl;
                  let arr = row.original.filename.split("/");
                  console.log(arr[4] + ".xml");

                  downloadLink.download = arr[4] + ".xml";
                  downloadLink.click();
                  setTimeout(() => {
                    setmensajeDescarga("");

                    setOpen(false);
                  }, 2000);
                } else {
                  var blob = base64toBlob(data.file);
                  const blobUrl = URL.createObjectURL(blob);
                  const downloadLink = document.createElement("a");
                  const fileName = row.original.folio + ".pdf";
                  downloadLink.href = blobUrl;
                  let arr = row.original.filename.split("/");

                  downloadLink.download = arr[4] + ".pdf";
                  downloadLink.click();
                  setTimeout(() => {
                    setmensajeDescarga("");

                    setOpen(false);
                  }, 2000);
                }
              })
              .catch((err) => {
                console.log("error", err);
                setOpen(false);
              });
          }

          function base64toBlob(base64Data) {
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize;
              const end = Math.min(begin + sliceSize, bytesLength);

              const bytes = new Array(end - begin);
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: "application/pdf" });
          }

          function base64toBlobxml(base64Data) {
            const sliceSize = 1024;
            const byteCharacters = atob(base64Data);
            const bytesLength = byteCharacters.length;
            const slicesCount = Math.ceil(bytesLength / sliceSize);
            const byteArrays = new Array(slicesCount);

            for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
              const begin = sliceIndex * sliceSize;
              const end = Math.min(begin + sliceSize, bytesLength);

              const bytes = new Array(end - begin);
              for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
              }
              byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: "application/xml" });
          }

          if (row.original.cancel == false) {
            return (
              <div>
                <div className="img-cancel">
                  <img
                    src={tache}
                    className="centerText, cancel"
                    onClick={async () => {
                      const result = await Confirm(
                        "¿Esta usted seguro de eliminar esta factura?",
                        "Eliminar factura de proveedor",
                        "Si",
                        "No"
                      );

                      if (result) {
                        eliminarFactura("xml");
                      } else {
                      }
                    }}
                  ></img>
                  <img
                    src={XML}
                    onClick={() => downloadPDF("xml")}
                    className="centerText, cancel"
                  ></img>
                  <img
                    src={PDF}
                    onClick={() => downloadPDF("pdf")}
                    className="centerText, cancel"
                  ></img>
                </div>
              </div>
            );
          }
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Fecha",
        accessor: "invoice_date",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        id: "seriee",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        id: "cancel",
        Header: "Folio",
        accessor: "folio",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 1000,
        Header: "Cliente",
        accessor: "name_business_partner",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "RFC",
        accessor: "rfc_business_partner",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Subtotal",
        accessor: (d) => {
          return d.subtotal
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.subtotal);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Descuento",
        accessor: (d) => {
          return d.discount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.discount);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Impuesto",
        accessor: (d) => {
          return d.tax
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.tax);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Método de pago",
        accessor: "payment_method",
        id: "payment",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,

        Header: "Tipo de comprobante",
        accessor: "tipodecomprobante",
        Filter: SelectColumnFilter,
        filter: "equals",
      },

      {
        Filter: SelectColumnFilter,
        filter: "equals",
        Header: "Estado",
        accessor: (d) => {
          return d.cancel == true ? "CANCELADO" : "VIGENTE";
        },
      },

      {
        Header: "Usuario",
        accessor: "username",
      },
    ],
    []
  );

  return (
    <div className="TableConsultarTodo">
      <div>
        <Modal
          open={open}
          onClose={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                Descargando factura...
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>

      <div className="">
        {isLoding ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={mensajeDescarga || "Buscando..."}
            value={true}
          />
        ) : (
          <div className="">
            <StickyTable>
              <div className="div1c">
                <input
                  type="text"
                  className="fi04"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha inicial"
                  onChange={(event) => setfi(event.target.value)}
                ></input>

                <input
                  type="text"
                  className="fi04"
                  onFocus={(e) => {
                    e.currentTarget.type = "date";
                    e.currentTarget.focus();
                  }}
                  placeholder="Fecha Final"
                  onChange={(event) => setff(event.target.value)}
                ></input>
              </div>
              <div className="div2c"></div>

              <div className="buscarfacturas">
                <button className="button" onClick={handleSubmit} type="button">
                  Buscar
                </button>
              </div>

              <TableContainer
                columns={columns}
                data={data}
                style={{ overflowx: 10 }}
                renderRowSubComponent={(row) => {
                  return (
                    <div style={{ padding: "20px" }}>
                      <SubTable
                        data={data}
                        columns={columns}
                        defaultPageSize={3}
                        showPagination={false}
                        renderRowSubComponent={(row) => {
                          return (
                            <div style={{ padding: "20px" }}>algo más....</div>
                          );
                        }}
                      />
                    </div>
                  );
                }}
              />
            </StickyTable>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsultarFacturasProvedores;
