import{ Component } from "react";
import { ReporteGMaps } from "../../componentsNewDashboard/ComponentRoutes/ReporteGMaps";
export class SetReportRoutes extends Component {
  

  render() {

      
      return (    
      
      <div className="tc" >
        <ReporteGMaps/> 
      </div>
          );
       }     
}

export default SetReportRoutes;


