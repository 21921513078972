//Paqueterias
import { useState, useEffect, useMemo } from "react";
//Componentes
import TableContainer from "../../../SubTableContainer";
import MODAL_TABLE from "../../../../main_components/modal/modal_Table";
import { get_detail_sale_cashclosing } from "../../../../services/cashclosing/cashclosing";
import { get_detail_sale_return_cashclosing } from "../../../../services/cashclosing/cashclosing";
//Estilos
import "../../../cashclosing.css";

const CONSULT_DETAIL_SALE = ({id_sale,typeSale}) => {
  const [modalT] = useState(false);
  const [vendor, setvendor] = useState([]);
  const [data, setData] = useState([]);
  
  const handle_detail_sale = async () => {
    try {
      const dt= await get_detail_sale_cashclosing(id_sale);
      setData(dt.data)
    } catch (err) {
      setData([])
    }
  };

  const handle_detail_return_sale = async() => {
    try {
      const dt= await get_detail_sale_return_cashclosing(id_sale);
      setData(dt.data)
    } catch (err) {
      setData([])
    }
  };

  useEffect(() => {
    if(typeSale==="SALE"){
      handle_detail_sale();
    }else{
      handle_detail_return_sale();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setvendor(vendor);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const columns = useMemo(
    () => [
     
      {
        Header: "Linea",
        accessor: "row",
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Nombre del producto",
        accessor: "product_name",
      },
      {
        Header: "Precio",
        accessor: "price",
      },

      {
        Header: "Cantidad",
        accessor: "quantity",
      },
      {
        Header: "Sub Total",
        accessor: "subtotal",
      },
      {
        Header: "Descuento",
        accessor: "discount",
      },
      {
        Header: "Impuesto",
        accessor: "tax",
      },
      {
        Header: "Total",
        accessor: "total",
      },
      {
        Header: "Fecha de registro",
        accessor: "date_register",
      },

    ],
    []
  );



  return (
    <div>
      <div>
      <MODAL_TABLE
        open={true}
        message={""}
        modalGeneral={false}
        modalT={modalT}
      ></MODAL_TABLE>
      </div>
      <div>
      <div className="ContainerTableCutBox2">
        <div className="TableReport TableCutBoxx" style={{ overflowX: "auto",overflowY:"auto" }}>
          <TableContainer
            exportEx={true}
            columns={columns}
            data={data}
            style={{ overflowx: 10 }}
          />
        </div>
      </div>
      </div>
    </div>
  );
};

export default CONSULT_DETAIL_SALE;
