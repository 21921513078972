//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//COMPONENTES
import TableContainer from "../Table_Container_Tax";
import Set_expense from "../expense_extranjero/Set_Expense";
import SubTable from "../expense_report/Get_datail";
import Set_Detail_Policy from "./Set_Detail_Policy";
import Put_Detail_Policy from "./Put_Detail_Policy";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../expense.css";
import "../../components_cfdi/modal.css";
//IMAGENES/ICONOS
import SAVE from "../../imgComponents/save.png";
import DELETE from "../../imgComponents/borrar.png";
import Spinner from "../../imgComponents/S.gif";
import CREATE from "../../imgComponents/create.png";
import VER from "../../imgComponents/visualizar.png";
import ADD from "../../imgComponents/add.png";
import PDF from "../../imgComponents/pdf.png";

const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const TableDetailGrupExpensePolicyDetail = ({idGrupExpense,codModificable}) => {


  
  console.log(codModificable);
  var date = new Date();

  var dia = `${(date.getDate())}`.padStart(2,'0');
  var mes = `${(date.getMonth()+1)}`.padStart(2,'0');
  var año = date.getFullYear();

  var mesantes = Number(mes)-1;


  if(mesantes===0){
    mesantes=`${(12)}`.padStart(2,'0');;
  }else{
    mesantes=`${(mesantes)}`.padStart(2,'0');;
  }


  const recuperarPPD= `${año}-${mes}-${dia}`
  const recuperarPPDantes= `${año}-${mesantes}-${dia}`

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [menssage, setMenssage] = useState("");

  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);

  const [ff, setff] = useState(recuperarPPD);
  const [fi, setfi] = useState(recuperarPPDantes);
  const [origen, setOrigen] = useState(codModificable);

  const [rfc, setRFC] = useState("");
  const [bp, setBP] = useState("");

  const handleSubmit = (event) => {
    event?.preventDefault();
    setMenssage("Consultando grupo de gastos...");
    console.log(fi);
    console.log(ff);
    console.log(idGrupExpense);

    setReconsulta(true);

    axios
      .get(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${sessionStorage.getItem("IdGrupExpense")}/policy/${idGrupExpense}/details`, 
      {
      
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
       
      })
      .then((response) => {
        const data = response?.data;
        setData(data);
        setOpen(false);
        setMenssage("");
        const cargos = data.filter((item) => item.effect === "CARGO");
        const abonos = data.filter((item) => item.effect === "ABONO");

        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);

        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        console.log("==>cargos<==");
        console.log(sumCargos);
        console.log("==>abonos<==");
        console.log(sumAbonos);
        if (sumCargos?.toFixed(2) - sumAbonos?.toFixed(2) === 0) {
          setEquality("Los datos cuadran");
          console.log("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cuadran");
          console.log("Los datos no cuadran");
          setError_msg("");
          setSuccess_msg("none");
        }

        setReconsulta(false);

      })
      .catch((err) => {
        console.log("error", err);
        setData([]);
        setOpen(false);
        setReconsulta(false);

        setMenssage("")

      });
    
  };

  
  const handleReconsulta = (reconsultar)=>{

    if(reconsultar===true){
      handleSubmit();
    }
    else{
      console.log("no se reconsulto");
    }

  }
  useEffect(() => {
    if(idGrupExpense!==""){
      handleSubmit();
    }else{
      setTimeout(() => {
        if(idGrupExpense!==""){
          handleSubmit();
        }else{
          console.log("esta vacio")
        }
      }, 6000);
    }
  }, []);



  const columns = useMemo(
    
    () => [
      
      {
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => {

          return(
          <img
                title="Crear Detalle"
                src={CREATE}
                onClick={async () => {

                  
                  
                  const result = await CustomDialog(
                    
                      <Set_Detail_Policy 
                      codIdPolicy={idGrupExpense}
                      ></Set_Detail_Policy>,
                    {
                      className: "modalTItle",
                      title:
                      "Crear Detale",
                      showCloseIcon: true,
                      isCanClose:false,
                    }
                  );
                  {handleReconsulta(result)}
                
                
              }}
              ></img>
            )
        },
        id: "Acciones",

        Cell: ({ valor, row }) => {
          
          function DeleteTax(result) {
            setMenssage("Eliminando Grupo...")
            axios
              .delete(`https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${sessionStorage.getItem("IdGrupExpense")}/policy/${idGrupExpense}/details/${row.original.id}`, {
                headers: {
                  Authorization: sessionStorage.getItem("getIdTokenRefresh"),
                  "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                setOpen(false);
                setMenssage("");
                setTimeout(handleReconsulta(result), 3000);
                
              })
              .catch((err) => {
                console.log("error", err);
                console.log("error", err?.response);
                var errorM=err?.response?.data?.errorMessage;
                setOpen(false);
                setMenssage("")
                console.log()
                alert("Error.,"+errorM); 
              });


          }

          return (
            <div>

             <img
                title="Editar Detalle"
                src={SAVE}
                onClick={async () => {
                  const result = await CustomDialog(
                    <Put_Detail_Policy
                      codIdPolicy={idGrupExpense}
                      id_detail={row.original.id}
                      codaccounts={row.original.accounting_account}
                      codconcept={row.original.concept}
                      codamount={row.original.ammount}
                      codeffect={row.original.effect}
                      codidConcept={row.original.id_account}
                      codIdDetail={row.original.id}
                    ></Put_Detail_Policy>,
                    {
                      title: "Editar detalle del gasto",
                      className: "modalTItle",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                  {
                    handleReconsulta(result);
                  }
                
                }}
                className="centerText, taxes"
              ></img>

              <img
                title="Eliminar Detalle"
                src={DELETE}
                className="centerText, taxes"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este detalle?",
                    "Eliminar Detalle",
                    "Si",
                    "No"
                  );
                  if (result) {
                    DeleteTax(result);
                  } else {
                  }
                }}
              ></img>
            </div>
          );
        },
      },

      {
        Header: "Gasto",
        accessor: "gasto",
      },
      {
        Header: "Concepto",
        accessor: "concept",
      },
      {
        Header: "Cuenta",
        accessor: "accounting_account",
      },
      {
        Header: "Importe",
        accessor: (d) => {
          return d.ammount
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.ammount);
        },
      },
      {
        Header: "Efecto",
        accessor: "effect",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "UUID",
        accessor: "uuid",
      }
	
    ],
    []
  );

  if (Reconsulta === false) {

  return (
    <div className="">
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
            <Box sx={style2} >
              <div className="centermodaldescarga">
                <Typography id="modal-modal-title"  className=""  variant="h6" component="h2">
                  <img src={Spinner}></img>
                </Typography>
                <Typography id="modal-modal-description" className="" sx={{ mt: 2 }}>  
                  {menssage}
                </Typography>
              </div>
            </Box>
        </Modal>
        </div>

      <div><h1>DETALLES DE POLIZA</h1></div>
      <br></br>

      <div className="TableConsultarTodo2">
          <div class="success-msg" style={{ display: success_msg }}>
            <h2>
              <i class="fa fa-check"></i>
              {equality}
            </h2>
          </div>
          <div class="error-msg" style={{ display: error_msg }}>
            <h2>
              <i class="fa fa-times-circle"></i>
              {equality}
            </h2>
          </div>
      </div>
  
      <div className="TableReport DetailPolicy">
        <div style={{overflowX:"auto"}}>
          <TableContainer
            exportEx={true}
            columns={columns}
            visible={true}
            data={data}
            style={{ overflowx: 10 }}
            renderRowSubComponent={row => {
              return (
               <div style={{ padding: "20px" }}>
                  <SubTable verData={true}/>
               </div>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
} else {
  return <div className="loader"></div>;
}

};

export default TableDetailGrupExpensePolicyDetail;





