import { useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../main_components/catchErr/catchError";
import { get_route_users, get_route_warehouse, get_types_of_sales, put_route } from "../../services/configuration/configuration";
import { get_employess } from "../../services/humanResources/humanResources";

function EditRoutes(props) {

const dialog = useDialog();
const [vfuser, setvfuser]=useState(false);
const [vsuser, setvsuser]=useState(false);
const [vfw, setvfw]=useState(false);
const [vsw, setvsw]=useState(false);
const [vemploy1, setvemploy1]=useState(false);
const [vemploy2, setvemploy2]=useState(false);



const [route, setroute]=useState(props.row.original.route);
const [desroute, setdesroute]=useState(props.row.original.description);
const [coderoute, setcoderoute]=useState(props.row.original.code);
const [firstuser, setfirstuser]=useState(props.row.original.id_user_assigned_fk);
const [seconduser, setseconduser]=useState(props.row.original.id_second_user_assigned_fk);
const [firstw, setfirstw]=useState(props.row.original.id_warehouse_fk);
const [secondw, setsecondw]=useState(props.row.original.id_second_warehouse_fk);
const [typesale, settypesale]=useState(props.row.original.id_sale_type_fk);
const [days, setdays]=useState(props.row.original.days_for_delivery);
const [codeRouteDMS, setcodeRouteDMS]=useState(props.row.original.code_dms);
const [frec, setfrec]=useState(props.row.original.frequently_update);
const [sendms, setsendms]=useState(props.row.original.send_to_dms);
const [id_employee_assigned, setid_employee_assigned]=useState(props.row.original.id_employee_assigned_fk);
const [id_second_employee_assigned, setid_second_employee_assigned]=useState(props.row.original.id_second_employee_assigned_fk);

const [bandera, setbandera]=useState(true);
const [datawarehouse, setdatawarehouse]=useState([]);
const [datatypesale, setdatatypesale]=useState([]);
const [datausers, setdatausers]=useState([]);
const [dataemployes, setdataemployes]=useState([]);


useEffect(()=>{
    consultroute();  
    consulttypesale();
    consultusers();
    consultempleados();
},[]);



const consultusers = async () => {
  await refresh_token();
  try {
    var d = await get_route_users();
    setdatausers(d === null ? [] : d.data.sales_employess);
  } catch (error) {
    console.log(error);
  } finally {
  }
}
const consulttypesale = async () => {
  await refresh_token();
  try {
    var d = await get_types_of_sales();
    setdatatypesale(d === null ? [] : d.data.sale_types);
  } catch (error) {
    console.log(error);
  } finally {
  }
}
const consultroute = async () =>{
  await refresh_token();
  try {
    var d = await get_route_warehouse();
    setdatawarehouse(d === null ? [] : d.data.warehouses);
  } catch (error) {
    console.log(error);
  } finally {
  }
} 
const consultempleados = async () => {
  await refresh_token();
  try {
    var d = await get_employess();
    setdataemployes(d === null ? [] : d.data);
  } catch (error) {
    console.log(error);
  } finally {
  }
}

//actualizar una ruta
const updateRuta = async () => {

setvfuser(false);
setvsuser(false);
setvfw(false);
setvsw(false);

  if(firstuser===seconduser){
    props.alertas("No se puede tener el mismo usuario asignado en prevendedor y repartidor", false);
    setvfuser(true);
    setvsuser(true);
    return null;
  }else{
    setvfuser(false);
    setvsuser(false);
  }

  if(firstw===secondw){
    props.alertas("No se puede tener el mismo almacén para la Ruta y Repartidor", false)
    setvfw(true);
    setvsw(true);
    return null;
  }else{
    setvfw(false);
    setvsw(false);
  }

  if(id_employee_assigned===id_second_employee_assigned){
    props.alertas("No se puede tener el mismo empleado asignado en prevendedor y repartidor", false);
    setvemploy1(true);
    setvemploy2(true);
    return null;
  }


      props.reconsultar(true);
      setbandera(false);
      await refresh_token ();
        var data = {
          "route":route, //String = Número de ruta
          "id_warehouse": firstw,//int = ID del primer almacén asignado
          "id_sale_type": typesale,//int = ID del tipo de venta
          "description": desroute,//String = Descripción breve de la ruta
          "code": coderoute,//Sting = Código de la ruta
          "send_to_dms" : sendms===true?true:(sendms==="true"?true:false),//Boolean | NULL  = Enviar a DMS
          "id_user_assigned": firstuser,//int = ID del primer usuario registrado
          "id_second_user_assigned": seconduser,//int = ID del segundo usuario registrado
          "id_second_warehouse":secondw, //int = ID del segundo almacén asignado
          "days_for_delivery":days, //int = Días para entregar
          "code_dms": codeRouteDMS,//String = Código DMS
          "frequently_update": frec===false?false:(frec==="false"?false:true),//Boolean = Actualización frecuente
          "id_employee_assigned": id_employee_assigned,
          "id_second_employee_assigned":id_second_employee_assigned, 
        }
    
      try {
        const dt = await put_route(props.row.original.id, data);
        console.log(dt);
        props.alertas("Ruta actualizada con éxito", true);
        props.reconsultar(false);
        dialog.close();
        props.handleSubmit();
      } catch (err) {  
        console.log(err);
        catchErrorModul("Rutas", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
        console.log("error post", err);
        props.alertas("No se pudo actualizar la ruta", false);
        props.reconsultar(false);
        setbandera(true);
      };
}
 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}


return (
    <div>
      <div className="addrulesSE">
          <div className="gridroutes">
            <div className="gr1">
            <TextField
              label="Ruta"
              value={route}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setroute(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="gr2">
            <TextField
              label="Descripción de la ruta (área de influencia)"
              value={desroute}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setdesroute(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="gr3">
          <TextField
              label="Código de la Ruta"
              value={coderoute}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcoderoute(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
          </div>
          <div className="gridroutes2">
          <div className="gr4">
          <TextField
              select
              label="Usuario Asignado (Vendedor o Prevendedor)"
              disabled={!bandera}
              value={firstuser}
              style={
                vfuser===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfirstuser(event.target.value)}
              onBlur={()=>setvfuser(false)}
            >
             {
                datausers.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.username}
                  </option>
                ))
              }
              
            </TextField>
          </div>
          <div className="gr5">
          <TextField
              select
              value={seconduser}
              label="Segundo Usuario Asignado (Repartidor)"
              disabled={!bandera}
              style={
                vsuser===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setseconduser(event.target.value)} 
              onBlur={() => setvsuser(false)} 
            >
               {
                datausers.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.username}
                  </option>
                ))
              }
            </TextField>
          </div>
          </div>
          <div className="gridroutes2">
          <div className="gr4">
          <TextField
              select
              required
              value={id_employee_assigned}
              label="Empleado Asignado (Vendedor o Prevendedor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setid_employee_assigned(event.target.value)}  
              style={
                vemploy1===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvemploy1(false)}
              onBlur={
                id_employee_assigned==null?()=>setvemploy1(true):()=>setvemploy1(false)
              }
            
            >
              {
                dataemployes.map((item)=>(
                  <option key={item.id} value={item.id}>
                     {item.name + " " + item.first_last_name+" "+item.second_last_name}
                  </option>
                ))
              }
              
            </TextField>
          </div>
          <div className="gr5">
          <TextField
              select
              required
              value={id_second_employee_assigned}
              label="Segundo Empleado Asignado (Repartidor)"
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setid_second_employee_assigned(event.target.value)}  
              style={
                vemploy2===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              onSelect={()=>setvemploy2(false)}
              onBlur={
                id_second_employee_assigned==null?()=>setvemploy2(true):()=>setvemploy2(false)
              }
            >
              {
                dataemployes.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.name + " " + item.first_last_name+" "+item.second_last_name}
                  </option>
                ))
              }
            </TextField>
          </div>
          </div>
          <div className="gridroutes3">
          <div className="gr6">
          <TextField
              select
              label="Almacen asignado (El álmacen de la ruta o cámara)"
              disabled={!bandera}
              value={firstw}
              style={
                vfw===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfirstw(event.target.value)}  
              onBlur={() => setvfw(false)}
            >
               {
                datawarehouse.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.warehouse_name}
                  </option>
                ))
              }
            </TextField>
          </div>
          <div className="gr7">
          <TextField
              select
              label="Segundo Almacen asignado (El almacen del repartidor)"
              value={secondw}
              style={
                vsw===true?{"border":"2px solid red", "borderRadius":"5px"}:{}
              }
              disabled={!bandera}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsecondw(event.target.value)}  
              onBlur={()=> setvsw(false)}
            >
              {
                datawarehouse.map((item)=>(
                  <option key={item.id} value={item.id}>
                    {item.warehouse_name}
                  </option>
                ))
              }
            </TextField>
          </div>
          </div>
          <div className="gridroutes4">
          <div className="gr8">
          <TextField
              select
              disabled={!bandera}
              value={typesale}
              label="Tipo de Venta"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => settypesale(event.target.value)}  
            >
              {
              datatypesale.map((item)=>(
                <option key={item.id} value={item.id}>
                  {item.type}
              </option>
              ))
            }
            </TextField>
          </div>
          <div className="gr9">
          <TextField
              label="Días para la entrega"
              value={days}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setdays(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
            <div className="gr10">
          <TextField
              label="Código de ruta para DMS"
              value={codeRouteDMS}
              disabled={!bandera}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setcodeRouteDMS(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
            </div>
           
          </div>
          <div className="gridroutes5">
          <div className="gr11">
            <TextField
              select
              disabled={!bandera}
              value={frec}
              label="Actualización Frecuente"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setfrec(event.target.value)}  
            >
              <option key={1} value={true} selected>
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>     
            </div>  
            <div className="gr12">
            <TextField
              select
              disabled={!bandera}
              value={sendms}
              label="Envíar a DMS"
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setsendms(event.target.value)}  
            >
              <option key={1} value={true} selected>
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>     
            </div>  

          </div>
         <br></br>
          <br></br>
          <Box>
            {
              bandera===true?              
          <Button
            variant="contained"
            style={{"backgroundColor":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={updateRuta}
          >Actualizar Ruta
          </Button>:
          <Button
            variant="contained"
            disabled
            style={{"backgroundColor":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
    </div>
    </div>
  );
}
export default EditRoutes;