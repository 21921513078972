import React, { useEffect, useState, useRef} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../main_components/tokens/tokenrefresh";
import NotificationSystem from 'react-notification-system';
//COMPONENTES
import AlertResponse from "../../main_components/alerts/alertResponse";
import { catchErrorModul } from "../../main_components/catchErr/catchError";

function EditExpenseConcept(props) {
  
const notificationSystemRef = useRef();
const dialog = useDialog();
console.log(props);

const [namecat, setnamecat]=useState(props.row.original.catalog_name);
const [roleperm, setroleperm]=useState(props.row.original.role_approved);
const [enable, setenable]=useState(props.row.original.enable==true?true:false);
const [bandera, setbandera]=useState(true);
const [dataroles, setdataroles]=useState([]); 

useEffect(()=>{
  refresh_token ();
  setTimeout(() => {
    handleroles();  
  }, 2000);
  },[]); 

const handleroles=()=>{
  setTimeout(() => {
    axios
      .get(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expense-concepts/roles`,
        {
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data.allow_roles);
        const data = response.data.allow_roles
        setdataroles(data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, 2000);
}
//actualizar catalogo
function createnewdiscount(){

props.reconsultar(true);

setbandera(false);
refresh_token ();
setTimeout(() => {
  var data = {
    "enable": enable,//boolean = Habilitado | Deshabilitado
    "catalog_name": namecat, //string
    "role_approved": roleperm //int
}
var url = `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/expense-concepts/${props.row.original.id}`;
var axiosConfig = {
  headers: {
    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    "Content-Type": "application/json",
  },
}
axios
.put(url, data,axiosConfig)
.then((response) => {
  console.log(response);
  props.alertas("Catálogo de Gastos actualizado con éxito", true);
  props.reconsultar(false);
  dialog.close();
  props.handleSubmit();
})
.catch((err) => {
  console.log("error put", err);
  catchErrorModul("Cátalogo de Conceptos de Gastos", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));

  props.reconsultar(false);
  props.alertas("No se pudo actualizar el Catálogo de Gastos", false);
  setbandera(true);  
});
}, 2000);
}


function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>

      <div className="addpointsalecss">
          <div className="gridcatalogogastos">
            <div className="catalogo1">
            <TextField
              label="Nombre del Concepto"
              required
              sx={{ m: 1.5, width: "100%" }}
              value={namecat}
              disabled={!bandera}
              onChange={(event) => setnamecat(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            </div>
          </div>
          <div className="gridcatalogogastos2">
          <div className="catalogo2">
          <TextField
              select
              label="Habilitado"
              disabled={!bandera}
              value={enable}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setenable(!enable)}  
            >
              <option key={1} value={true} selected >
                  {"SI"}
              </option>
              <option key={2} value={false}>
                  {"NO"}
              </option>
            </TextField>
            </div>
            <div className="catalogo3">
            <TextField
              select
              label="Roles permitidos"
              disabled={!bandera}
              value={roleperm}
              InputLabelProps={{shrink: true,}}
              SelectProps={{native: true,}}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setroleperm(parseInt(event.target.value))}  
            >
            {
              dataroles.map((item)=>(
                <option key={item.id} value={item.id}>
                  {item.codeAndName}
                </option>
              ))
            }
            </TextField>
            </div>
          
          </div>
      
          <br></br>
          <br></br>
          <Box>
            {
              bandera==true?
              
            <Button
            variant="contained"
            style={{"background-color":"green"}}
            sx={{ m: 1.5, width: "25ch" }}
            onClick={createnewdiscount}
          >
            Guardar Cambios
          </Button>:
          <Button
          variant="contained"
          disabled
          style={{"background-color":"green", "height":"35px"}}
          sx={{ m: 1.5, width: "25ch" }}
        >
          <Spinner></Spinner>
        </Button>
            }
            <Button
              style={{"background-color":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!bandera}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}

export default EditExpenseConcept;
