import React, { useState} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog} from "react-st-modal";
import { refresh_token  } from "../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../main_components/catchErr/catchError";

function EditAccounting(props) {

console.log(props)

const dialog = useDialog();

const [code, setcode]=useState(props.row.original.code);
const [name, setname]=useState(props.row.original.name);
const [concep, setconcep]=useState(props.row.original.expense_concept);
const [type, settype]=useState(props.row.original.type);
const [efect, setefect]=useState(props.row.original.efect);

const [bandera, setbandera]=useState(true);


function updateAccounting(){

  var data = {
    "code":code,
    "name":name,
    "expense_concept":concep,
    "type":type,
    "efect":efect
  }

console.log("entra")
props.reconsultar(true);
setbandera(false);
refresh_token ();
setTimeout(() => {

var url = `https://r1lwievqy2.execute-api.us-east-1.amazonaws.com/development/accounting-account/${props.row.original.id}`;
var axiosConfig = {
  headers: {
    Authorization: sessionStorage.getItem("getIdTokenRefresh"),
    "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
    "Content-Type": "application/json",
  },
}
 axios
 .put(url, data,axiosConfig)
 .then((response) => {
   console.log(response);
   props.alertas("Cuenta contable actualizada con éxito", true);
   props.reconsultar(false);
   dialog.close();
   props.handleSubmit();
 })
 .catch((err) => {
   console.log("error post", err);
   catchErrorModul("Cuentas Contables", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
   
   props.alertas("No se pudo actualizar la cuenta contable, vuelva a intertarlo", false);
   props.reconsultar(false);
   setbandera(true);
 })
}, 2000);
} 

function handleChange(event){
  const inputValue = event.target.value;
  var formattedValue = '';
  console.log(inputValue);
  formattedValue=inputValue.replace(/(\d{4})(\d{2})(\d{1})/, '$1-$2-$3');
  setcode(formattedValue);

}
function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
      <div className="gridcc">
          <div className="cc1">
          <TextField
              label="Código"
              value={code}
              sx={{ m: 1.5, width: "100%" }}
              onChange={handleChange}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
          <div className="cc2">
          <TextField
              label="Nombre"
              value={name}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setname(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <div className="gridcc2">
          <div className="cc3">
            <TextField
              label="Concepto"
              value={concep}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setconcep(event.target.value)}  
              InputLabelProps={{ shrink: true }}
            ></TextField>
          </div>
        </div>
        <div className="gridcc3">
          <div className="cc4">
            <TextField
              select
              label="Tipo"
              value={type}
              defaultValue={""}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => settype(event.target.value)}  
            >
              <option key={2} value={"CARGO"}>{"CARGO"}</option>
              <option key={3} value={"ABONO"}>{"ABONO"}</option>
              <option key={4} value={"IMPUESTO"}>{"IMPUESTO"}</option>
          </TextField>
          </div>
          <div className="cc5">
          <TextField
              select
              label="Efecto"
              value={efect}
              defaultValue={""}
              InputLabelProps={{shrink: true}}
              SelectProps={{native: true}}
              sx={{ m: 1.5, width: "100%"}}
              onChange={(event) => setefect(event.target.value)}  
            >
              <option key={2} value={"CARGO"}>{"CARGO"}</option>
              <option key={3} value={"ABONO"}>{"ABONO"}</option>
          </TextField>
          </div>
          
        </div>
          <br></br>
          <br></br>
          <Box>
            {bandera===true?
            <Button
              variant="contained"
              style={{"background-color":"green"}}
              sx={{ m: 1.5, width: "35ch" }}
              onClick={updateAccounting}
            >
              Actualizar Cuenta Contable
            </Button>:
            <Button
            variant="contained"
            disabled
            style={{"background-color":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "35ch"}}
            onClick={updateAccounting}
          >
            <Spinner></Spinner>
          </Button>}


            <Button
              style={{"background-color":"red"}}
              variant="contained"
              disabled={!bandera}
              sx={{ m: 1.5, width: "20ch" }}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}
export default EditAccounting;
