import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import EFECTO from "../../dataComponets/efect.json"

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const typeTR = [
  { value: "Traslado", label: "Traslado" },
  { value: "Retencion", label: "Retencion" },
];

const effect = [
  { value: "CARGO", label: "CARGO" },
  { value: "ABONO", label: "ABONO" },
];

const Set_Detail_Policy = ({codIdPolicy}) => {


  const [efecto, setefecto] = React.useState("CARGO");

  const dialog = useDialog();

  const [open, setOpen] = React.useState(false);
  const [Concepto, setConcepto] =
    React.useState("90909090909090");
    const [effectSelect, seteffectSelect] =
    React.useState("CARGO");

  const [importe, setImporte] = React.useState(0);

  const [pro_servData, setpro_servData] = React.useState([]);

  const [accounts, setAccounts] = React.useState("");

  const handleOnSearch = (string, results) => {};
 
  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {
    setConcepto(item);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };
 
  const handleOnClear = () => {
    console.log("Cleared");
  };


  
  
  const handelImporte = (event) => {
    setImporte(event?.target?.value);
  };
  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };
 

  const handelEfect = (event) => {
    setefecto(event?.target?.value);
  };

  var data = {
      id_account: Concepto.id,
      amount: Number(importe),
      effect: efecto

  };

  console.log(data);


  const handleSubmit = (event) => {
    event?.preventDefault();
    var data = {
      id_account: Concepto.id,
      amount: Number(importe),
      effect: efecto

  };

    console.log(data);

    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/grouped_expenses/${sessionStorage.getItem("IdGrupExpense")}/policy/${codIdPolicy}/details`,
        data,
        axiosConfig
      )
      .then((res) => {
        setOpen(false);
        dialog.close(true);
      })
      .catch((err) => {
        setOpen(false);
        var error= err?.response?.data?.errorMessage;
        alert("Error.," + error);

      });
  };


  const handleprod_concept_list = (event) => {
    event?.preventDefault();
    axios
      .get(
        `https://7t0suvusg7.execute-api.us-east-1.amazonaws.com/od/accounting-accounts`,
        {
          params: {
            foreign: 0,
          },
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        const dataStatic = {
          id: "90909090909090",
          concept: "SELECCIONE UNA OPCIÓN",
          account: "SELECCIONE UNA OPCIÓN",
        };
        data[data.length] = dataStatic;
        setpro_servData(data);
      })
      .catch((err) => {
        setpro_servData([]);
      });
  };

  useEffect(() => {
    handleprod_concept_list();
  }, []);

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Creando detalle..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <form>
          <Box>


          <div style={{ width: "90%", margin:"0 auto"}}>
                  <ReactSearchAutocomplete
                    items={pro_servData}
                    fuseOptions={{ keys: ["id","name_code"]}}
                    resultStringKeyName={"name_code"}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    onClear={handleOnClear}
                    showIcon={true}
                    placeholder="Buscar concepto..."
                    styling={{
                      height: "34px",
                      border: "1px solid darkgreen",
                      borderRadius: "4px",
                      backgroundColor: "white",
                      boxShadow: "none",
                      hoverBackgroundColor: "lightgreen",
                      color: "black",
                      iconColor: "black",
                      lineColor: "black",
                      placeholderColor: "black",
                      clearIconMargin: "3px 8px 0 0",
                      zIndex: 10,
                    }}
                  />
                </div>

           
          </Box>

        
            <Box className="expen-7">
            <ValidationTextField
                label="Concepto"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ m: 2, width: "50%" }}
                value={Concepto?.name}
                InputLabelProps={{ shrink: true }}
              />
              <ValidationTextField
                label="Cuenta"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ m: 2, width: "34%" }}
                value={Concepto?.code}
                InputLabelProps={{ shrink: true }}
              />
             
            
            </Box>

            <Box>
            <TextField
              select
              label="Efecto"
              value={efecto}
              sx={{ m: 1.5, width: "44%" }}
              onChange={handelEfect}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {EFECTO.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
              <ValidationTextField
                label="Importe"
                variant="outlined"
                placeholder="Importe"
                type="number"
                sx={{ m: 2, width: "40%" }}
                onChange={handelImporte}
                onBlur={handelImportOnBurl}
                value={importe}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
  

          
          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Crear
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );

};

export default Set_Detail_Policy;
