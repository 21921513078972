import Pago from "../componentsPago/pago";

import { Component } from "react";

export class SetPago extends Component {
 

  render() {
    return (
      <div className="tc">
        <Pago />
      </div>
    );
  }
}

export default SetPago;
