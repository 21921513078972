//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "../SubTableContainer";
import ViewDetail from "./ViewDetail_Sales";
import {SelectColumnFilter,DefaultColumnFilter} from "../../components_cfdi/filters";
import ViewDetail_frezer from "./ViewDetail_frezer";
//ESTILOS
import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";
import "../../components_Expenses/expense.css";

//IMAGENES
import Spinner from "../../imgComponents/S.gif";
import reconsultaTable from "../../imgComponents/reconsultaTable.png";
import VER from "../../imgComponents/visualizar.png";
import FREZ from "../../imgComponents/fridge.png"

import { faBullseye } from "@fortawesome/free-solid-svg-icons";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%", 
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SubTable(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [equality, setEquality] = useState("");
  const [success_msg, setSuccess_msg] = useState("none");
  const [error_msg, setError_msg] = useState("none");
  const [Reconsulta, setReconsulta] = useState(false);
  const [ReconsultaDta, setReconsultaDta] = useState(false);

  const dialog = useDialog();

  const handleSubmit = (event) => {
    event?.preventDefault();
    setReconsulta(true);
    axios
      .get(
        `https://e26bmbbxf1.execute-api.us-east-1.amazonaws.com/demo/dashboard/${sessionStorage.getItem("id_socio")}`,

        {
      
          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data;
        console.log(data);

        const data2= data.slice().sort((a, b) => (Number(a.route)) > (Number(b.route)));
        console.log(data2);
        setData(data2);

        const cargos = data.filter((item) => item.efect === "CARGO");
        const abonos = data.filter((item) => item.efect === "ABONO");

        const sumCargos = cargos
          .map((cargoSum) => parseFloat(cargoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);

        const sumAbonos = abonos
          .map((abonoSum) => parseFloat(abonoSum.ammount))
          .reduce((previous, current) => {
            return previous + current;
          }, 0);
        console.log("==>cargos<==");
        console.log(sumCargos);
        console.log("==>abonos<==");
        console.log(sumAbonos);
        if (sumCargos - sumAbonos === 0) {
          setEquality("Los datos cuadran");
          console.log("Los datos cuadran");
          setSuccess_msg("");
          setError_msg("none");
        } else {
          setEquality("Los datos no cudran");
          console.log("Los datos no cudran");
          setError_msg("");
          setSuccess_msg("none");
        }

        setReconsulta(false);
      })
      .catch((err) => {
        setData([]);
        setReconsulta(false);
      });
  };

  const handleReconsulta = (reconsultar) => {
    if (reconsultar === true) {
      handleSubmit();
    } else {
      console.log("no se reconsulto");
    }
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: ".....",
        id: "Acciones",

        Cell: ({ valor, row }) => {

          return (
            <div>

              <img
                title="Ver detalle venta"
                src={VER}
                alt=""
                className="centerText"
                onClick={async () => {
                  const result = await CustomDialog(
                    <div>
                    <br></br>
                    <ViewDetail
                      id_detail={row.original.flete}
                      pointSale={row.original.name}
                      address={row.original.address}
                    ></ViewDetail>
                    </div>,
                    {
                      title: " ",
                      className: "saleDetailTikect",
                      showCloseIcon: true,
                      isCanClose: false,
                    }
                  );
                }}
              ></img>
               
            </div>
          );
        },
      },
      
      {
        Header: "Fecha",
        accessor: "fecha",
      },
      {
        Header: "Origen",
        accessor: "origen",
      },
      {
        Header: "Destino",
        accessor: "destino",
      },
      {
        Header: "KM",
        accessor: "drecorrida",
      },
      {
        Header: "Costo",
        accessor: "costo",
      },

      {
        Header: "Ganancia",
        accessor: "ganancia",
      },

      {
        Header: "Total",
        accessor: "total",
      },

     
      
    ],
    []
  );

  if (Reconsulta === false) {
    return (
      <div>
        <div>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style2}>
              <div className="centermodaldescarga">
                <Typography
                  id="modal-modal-title"
                  className=""
                  variant="h6"
                  component="h2"
                >
                  <img src={Spinner}></img>
                </Typography>
                <Typography
                  id="modal-modal-description"
                  className=""
                  sx={{ mt: 2 }}
                >
                  {"Eliminando detalle..."}
                </Typography>
              </div>
            </Box>
          </Modal>
        </div>

        <div className="TableConsultarTodo2 TableSalesDetail">
          
          <StickyTable>
            <SubTableContainer
              columns={columns}
              data={data}
              style={{ overflowx: 10 }}
            />
          </StickyTable>
        </div>
      </div>
    );
  } else {
    return <div className="loader"></div>;
  }
}

export default SubTable;
