import React, { useEffect, useState, useMemo } from "react";

import "../../components_cfdi/estilo.css";
import "../../components_cfdi/modal.css";
import "../../components_cfdi/table.css";


import axios from "axios";

import { StickyTable, Row, Cell } from "react-sticky-table";

function SubTable () {

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  
const handleSubmit = (event) => {
  event?.preventDefault();

  const url = `https://m0fsi6ifd9.execute-api.us-east-1.amazonaws.com/development/businesspartners/${sessionStorage.getItem("socioHabilitado")}`;

  axios
    .get(url, {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      const data = response.data;
      console.log(response.data);

      setData(data);
    })
    .catch((err) => {
      console.log("error", err);
      setData([]);
    });
};


useEffect(() => {
  handleSubmit();
}, []);



  const columns = useMemo(
    () => [
    
      {
        Header: "Num_Reg_Id_Trib",
        accessor: "num_reg_id_trib",
      },
      {
        Header: "Tax_Residence",
        accessor: "tax_residence",
      },
      {
        Header: "E-MAIL",
        accessor: "email",
      },
    ],
    []
  );

  return (
    <div className="TableConsultarTodo2">
      <table>
        <thead>
          <tr>
            <th>Número de regimen tributario</th>
            <th>Residencia fiscal</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{data.num_reg_id_trib}</td>
            <td>{data.tax_residence}</td>
            <td>{data.email}</td>
          </tr>
          
        </tbody>
        
      </table>
    </div>
  );
};

export default SubTable;
