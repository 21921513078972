import Set_bussines_pInvoice from "../../form_business_partner/set_bussines_pInvoice.jsx";
import { Component } from "react";


export class Set_bussines_PInvoice extends Component {
 
 
  render() {
 
      return (    
      
      <div className="tc" >
      <Set_bussines_pInvoice/> 

      </div>
          );
       }     
}

export default Set_bussines_PInvoice;


