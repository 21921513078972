import { useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDialog } from "react-st-modal";
import { refresh_token  } from "../../../main_components/tokens/tokenrefresh";
import { catchErrorModul } from "../../../main_components/catchErr/catchError";
import { put_brands } from "../../../services/assets/assets";

function EditBrandF(props) {

const dialog = useDialog();
const[nameBrand, setnameBrand] = useState(props.row.original.name);
const[typeB, settypeB]=useState(props.row.original.type);
const [band, setband]=useState(true);
//Actualizar Rol
const updatediscount = async () => {
  
var data = {
  "name":  nameBrand,//String = Nombre de la marca
  "type": typeB//String = CONGELADOR | VEHÍCULO
}
props.reconsultar(true);
setband(false);
await refresh_token ();

  try {
    const dt = await put_brands(props.row.original.id, data);
    console.log(dt);
    props.alertas("Marca actualizada con éxito", true);
    props.reconsultar(false);
    dialog.close();
    props.handleSubmit();
    
  } catch (err) {  
    console.log(err);
    setband(true);
    catchErrorModul("Marcas", JSON.stringify(data), JSON.stringify(err?.response===undefined?"Token expirado":err?.response));
    console.log("error post", err);
    props.alertas("No se pudo actualizar la Marca, vuelva a intertarlo", false);
    props.reconsultar(false);
  };
} 

function Spinner() {
  return (
    <div className="spinner-border spinner-border-sm text-light" role="status">
      <span className="sr-only">Cargando...</span>
    </div>
  );
}

  return (
    <div>
      <div className="addpointsalecss">
      <div className="gridmarca1">
            <div className="marca1">
            <TextField
              label="Nombre de la Marca"
              value={nameBrand}
              disabled={!band}
              sx={{ m: 1.5, width: "100%" }}
              onChange={(event) => setnameBrand(event.target.value.toUpperCase())}  
              InputLabelProps={{ shrink: true }}
  
            ></TextField>
            </div>
          </div>
          <div className="gridmarca2">
              <div className="marca2">
                  <TextField
                  select
                  value={typeB}
                  label="Tipo"
                  disabled={!band}
                  defaultValue={""}
                  InputLabelProps={{shrink: true}}
                  SelectProps={{native: true}}
                  sx={{ m: 1.5, width: "100%"}}
                  onChange={(event) => settypeB(event.target.value)}  
                 >
                  <option key={1} value={"CONGELADOR"}>
                      {"CONGELADOR"}
                  </option>
                  <option key={2} value={"VEHÍCULO"}>
                      {"VEHÍCULO"}
                  </option>
             </TextField>
              </div>
          </div>
          <br></br>
          <br></br>
          <Box>
            {
              band===true?
              <Button
              variant="contained"
              style={{"backgroundColor":"green"}}
              sx={{ m: 1.5, width: "30ch" }}
              onClick={updatediscount}
            >
              Guardar Cambios
            </Button>:
            <Button
            variant="contained"
            disabled
            style={{"backgroundColor":"green", "height":"35px"}}
            sx={{ m: 1.5, width: "30ch" }}
          ><Spinner/></Button> 
            }
            
            <Button
              style={{"backgroundColor":"red"}}
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              disabled={!band}
              onClick={() => {
                  dialog.close();
                } 
              }
            >
            cancelar
          </Button>
          </Box>
      </div>
    </div>
  );
}
export default EditBrandF;
