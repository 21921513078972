import TableConcept from '../../components_Expenses/expense_no_deducible/Consult_Expenses';
import { Component } from "react";


export class SetConsulExpenses extends Component {
 
  render() {



      return (    
      
      <div className="tc">
      <TableConcept></TableConcept>

      </div>
          );
       }     
}

export default SetConsulExpenses;
